import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import colors from "theme/colors";
import { useMediaBreakpointWidth } from "@hooks/useMediaBreakpointWidth";
import { Footer } from "@components/crud/Footer";
import {
  ModelInvoice,
  useAdminBillingPaymentGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { formatCurrency } from "@utils/formatCurrency";
import { Loader } from "@components/crud/Loader";
import { CRM_ORDER_PAYMENT_TYPES } from "@utils/constants";

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    borderRadius: "24px",
    width: 600
  },
  "@media (max-width: 656px)": {
    "& .MuiPaper-root": {
      maxWidth: "100%"
    }
  }
}));

export const RetryInvoiceModal = ({
  onClose,
  invoice,
  organizationId,
  onSave,
  isLoading
}: {
  onClose;
  invoice: ModelInvoice;
  organizationId: string;
  onSave;
  isLoading?: boolean;
}) => {
  const dialogBreakpoint = useMediaBreakpointWidth();
  const { data: paymentData, isLoading: isLoadingPaymentDetails } =
    useAdminBillingPaymentGet({
      organizationId: organizationId
    });
  const primaryPaymentDetails = paymentData?.data?.find((p) => p.isPrimary);
  return (
    <StyledDialog
      className="confirmation-dialog"
      open={true}
      disableScrollLock={true}
      maxWidth={dialogBreakpoint || "md"}
    >
      <Loader isLoading={isLoadingPaymentDetails}>
        <Grid container sx={{ padding: "24px", flexWrap: "nowrap" }}>
          <div style={{ paddingTop: "10px" }}>
            <div
              style={{
                background: "#F1F5FF",
                height: "40px",
                width: "40px",
                borderRadius: "99px",
                padding: "8px"
              }}
            >
              <InfoOutlinedIcon sx={{ color: colors.info.icon }} />
            </div>
          </div>

          <Grid xs={10}>
            <DialogTitle variant="h3">Retry Payment</DialogTitle>
            <DialogContent>
              <Typography style={{ fontSize: "14px" }}>
                {`Your previous payment attempt for invoice (SG-${invoice.invoiceNumber}) was not
              successful.`}
              </Typography>
              <Typography style={{ marginTop: "20px", fontSize: "14px" }}>
                Please review the payment information below and try again.
              </Typography>
              <Typography
                style={{
                  marginTop: "15px",
                  fontWeight: "bold",
                  fontSize: "14px"
                }}
              >
                Invoice Details:
              </Typography>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                marginTop="10px"
              >
                <Grid>Total Amount Due</Grid>
                <Grid style={{ fontWeight: "bold" }}>{`${formatCurrency(
                  invoice.total || 0
                )}`}</Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                marginTop="10px"
              >
                <Grid>Payment Method</Grid>
                <Grid>{`${CRM_ORDER_PAYMENT_TYPES.find(
                  (c) => c.value === primaryPaymentDetails?.paymentType
                )?.label} ${primaryPaymentDetails?.info?.payment
                  ?.number}`}</Grid>
              </Grid>
            </DialogContent>
          </Grid>

          <Grid xs={1} sx={{ paddingTop: "16px" }}>
            <IconButton onClick={onClose}>
              <CloseIcon htmlColor="#B3B3B3" />
            </IconButton>
          </Grid>
        </Grid>
      </Loader>
      <Footer
        cancelBtnClick={onClose}
        saveBtnClick={onSave}
        isDisabled={!primaryPaymentDetails}
        isLoading={isLoadingPaymentDetails || isLoading}
        saveBtnLabel="Retry"
      />
    </StyledDialog>
  );
};
