import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  List,
  ListItemText,
  Checkbox,
  IconButton,
  Badge,
  ListItemButton,
  Grid,
  Autocomplete
} from "@mui/material";
import { CloseIcon } from "@components/Icons";
import { Footer } from "@components/crud/Footer";
import { Loader } from "@components/crud/Loader";
import {
  adminLevelGet,
  useAdminAlbumAlbumIdFilterGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { FilterCuratorOption } from "./FIlterCuratorOption";
import {
  getLiveStreams,
  getSeasons,
  getTagsSearch,
  getTeams,
  getTrainingPrograms,
  getUsers
} from "@services/Network";
import { SearchInput } from "@components/SearchInput";
import formatFullName from "@utils/formatFullName";
import { Close } from "@mui/icons-material";
import { FilterCuratorItem } from "./FilterCuratorItem";
import { FilterTeamTpOption } from "./FilterTeamTpOption";
import { FilterTeamTpItem } from "./FilterTeamTpItem";
import { FilterSeasonOption } from "./FilterSeasonOption";
import { FilterSeasonItem } from "./FilterSeasonItem";
import { FilterTagOption } from "./FilterTagOption";
import { FilterStreamItem } from "./FilterStreamItem";
import styled from "styled-components";

const StyledSelectedOption = styled(Typography)`
  overflow-wrap: break-word;
  max-width: 560px;
`;

const StyledSelectedOptionsBox = styled("div")`
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 10px;
  margin-left: 15px;
`;

const initFilterState = [
  {
    category: "Videos/Photos",
    options: [
      {
        label: "Photos",
        value: "IMAGE"
      },
      {
        label: "Videos",
        value: "VIDEO"
      }
    ],
    selected: [],
    queryKey: "mediaType"
  },
  {
    category: "Time Frame",
    options: [
      {
        label: "All Time",
        value: "ALL_TIME"
      },
      {
        label: "Today",
        value: "TODAY"
      },
      {
        label: "Yesterday",
        value: "YESTERDAY"
      },
      {
        label: "This Week",
        value: "THIS_WEEK"
      },
      {
        label: "Last Week",
        value: "LAST_WEEK"
      },
      {
        label: "This Month",
        value: "THIS_MONTH"
      },
      {
        label: "Last Month",
        value: "LAST_MONTH"
      },
      {
        label: "This Quarter",
        value: "THIS_QUARTER"
      },
      {
        label: "Last Quarter",
        value: "LAST_QUARTER"
      },
      {
        label: "This Year",
        value: "THIS_YEAR"
      },
      {
        label: "Last Year",
        value: "LAST_YEAR"
      }
    ],
    selected: [],
    queryKey: "dtStart"
  },
  {
    category: "Curator",
    selected: [],
    options: [],
    queryKey: "curator"
  },
  {
    category: "Tags",
    options: [],
    selected: [],
    queryKey: "tags"
  },
  {
    category: "Team/Training Program",
    selected: [],
    options: [],
    queryKey: "teamsPrograms"
  },
  {
    category: "Live Stream",
    selected: [],
    options: [],
    queryKey: "streams"
  },
  {
    category: "Season",
    selected: [],
    options: [],
    queryKey: "season"
  },
  {
    category: "Level",
    selected: [],
    options: [],
    queryKey: "levels"
  }
] as {
  category: string;
  options: { label: string; value: string; details? }[];
  selected: string[];
  queryKey: string;
}[];
export const PhotosVideoFilterModal = ({
  open,
  handleClose,
  selectedFiterState,
  excludeFilters = [],
  albumId
}: {
  open: boolean;
  handleClose: (
    filters: {
      category: string;
      options: { label: string; value: string; details? }[];
      selected: string[];
      queryKey: string;
    }[]
  ) => void;
  selectedFiterState?: {
    category: string;
    options: { label: string; value: string; details? }[];
    selected: string[];
    queryKey: string;
  }[];
  excludeFilters?: string[];
  albumId?: string;
}) => {
  const [filters, setFilters] = useState(initFilterState);
  const [selectedCategory, setSelectedCategory] = useState(
    initFilterState.filter((f) => !excludeFilters.includes(f.category))[0]
      .category
  );

  const [isLoading, setIsLoading] = useState(false);
  const [curatorAddMenu, setCuratorAddMenu] = useState(false);
  const [curatorSearch, setCuratorSearch] = useState("");
  const [teamTpAddMenu, setTeamTPAddMenu] = useState(false);
  const [teamTpSearch, setTeamTpSearch] = useState("");
  const [seasonAddMenu, setSeasonAddMenu] = useState(false);
  const [seasonSearch, setSeasonSearch] = useState("");
  const [tagAddMenu, setTagAddMenu] = useState(false);
  const [tagSearch, setTagSearch] = useState("");
  const [streamSearch, setStreamSearch] = useState("");
  const [streamAddMenu, setStreamAddMenu] = useState(false);
  const organizationId = useRecoilValue(organizationAtom);

  const { data: albumFilter, isLoading: isAlbumFilterLoading } = albumId
    ? useAdminAlbumAlbumIdFilterGet(albumId, {
        query: {
          enabled: !!albumId,
          staleTime: Infinity
        }
      })
    : { data: null, isLoading: false };

  const { data: curatorResults, isFetching: isFetchingUsers } = getUsers(
    {
      ...(curatorSearch && curatorSearch !== ""
        ? { textSearch: encodeURIComponent(curatorSearch) }
        : {}),
      organizationId: organizationId
    },
    {
      query: {
        staleTime: Infinity,
        enabled: selectedCategory === "Curator" && !albumId
      }
    }
  );
  const { data: teamResults, isFetching: isFetchingTeams } = getTeams(
    {
      ...(teamTpSearch && teamTpSearch !== ""
        ? { textSearch: encodeURIComponent(teamTpSearch) }
        : {}),
      pageSize: 1000,
      organizationId: organizationId
    },
    {
      query: {
        staleTime: Infinity,
        enabled: selectedCategory === "Team/Training Program" && !albumId
      }
    }
  );
  const { data: tpResults, isFetching: isFetchingTp } = getTrainingPrograms(
    {
      ...(teamTpSearch && teamTpSearch !== ""
        ? { textSearch: encodeURIComponent(teamTpSearch) }
        : {}),
      pageSize: 1000,
      organizationId: organizationId
    },
    {
      query: {
        staleTime: Infinity,
        enabled: selectedCategory === "Team/Training Program" && !albumId
      }
    }
  );
  const { data: seasonResults, isFetching: isFetchingSeason } = getSeasons(
    {
      ...(seasonSearch && seasonSearch !== ""
        ? { textSearch: encodeURIComponent(seasonSearch) }
        : {}),
      organizationId: organizationId,
      pageSize: 1000
    },
    {
      query: {
        staleTime: Infinity,
        enabled: selectedCategory === "Season" && !albumId
      }
    }
  );
  const { data: tagResults, isFetching: isFetchingTags } = getTagsSearch(
    {
      q: encodeURIComponent(tagSearch) || "a"
    },
    {
      query: {
        staleTime: Infinity,
        enabled: selectedCategory === "Tags" && !albumId
      }
    }
  );
  const { data: streamResults, isFetching: isFetchingStreams } = getLiveStreams(
    {
      ...(streamSearch && streamSearch !== ""
        ? { textSearch: streamSearch }
        : {}),
      organizationId: organizationId,
      pageSize: 1000
    },
    {
      query: {
        staleTime: Infinity,
        enabled:
          selectedCategory === "Live Stream" &&
          !excludeFilters.includes("Live Stream") &&
          !albumId
      }
    }
  );

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    if (
      category === "Level" &&
      !filters.find((f) => f.category === category)?.options?.length
    )
      getLevelOptions();
  };

  useEffect(() => {
    if (selectedFiterState) setFilters(selectedFiterState);
  }, [selectedFiterState]);
  const handleFilterChange = (category, filterName, isSelected) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) => {
        if (filter.category === category) {
          const newSelected = isSelected
            ? [...filter.selected, filterName]
            : filter.selected.filter((item) => item !== filterName);

          return {
            ...filter,
            selected: newSelected
          };
        }
        return filter;
      })
    );
  };

  const handleClearAll = () => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) => {
        const newFilter = filter;
        newFilter.selected = [];
        if (
          filter.category === "Curator" ||
          filter.category === "Team/Training Program" ||
          filter.category === "Season" ||
          filter.category === "Tags" ||
          filter.category === "Live Stream"
        )
          filter.options = [];
        return filter;
      })
    );
  };
  const getLevelOptions = async () => {
    if (!albumId) {
      setIsLoading(true);
      const data = await adminLevelGet({
        organizationId: organizationId
      });
      const levels = data.data?.levels;
      if (levels) {
        setFilters((prevFilters) =>
          prevFilters.map((filter) => {
            if (filter.category === "Level") {
              const newFilter = filter;
              newFilter.options = levels.map((l) => ({
                label: l.name!,
                value: l.levelId!
              }));
              return {
                ...newFilter,
                selected: []
              };
            }
            return filter;
          })
        );
      }
      setIsLoading(false);
    }
    if (albumId) {
      const levels = albumFilter?.data.levels;
      if (levels) {
        setFilters((prevFilters) =>
          prevFilters.map((filter) => {
            if (filter.category === "Level") {
              const newFilter = filter;
              newFilter.options = levels.map((l) => ({
                label: l.name!,
                value: l.levelId!
              }));
              return {
                ...newFilter,
                selected: []
              };
            }
            return filter;
          })
        );
      }
    }
  };
  const curatorOptions = [
    ...(!albumId
      ? curatorResults
      : albumFilter?.data.curators?.map((u) => u.person) || [])
  ];
  const teamTpOptions = [
    ...(!albumId
      ? teamResults
      : (albumFilter?.data.teams || []).map((t) => ({
          id: t.teamId,
          name: t.name,
          type: "TEAM",
          details: t
        }))),
    ...(!albumId
      ? tpResults
      : (albumFilter?.data.trainingPrograms || []).map((t) => ({
          id: t.programId,
          name: t.name,
          type: "TRAINING_PROGRAM",
          details: t
        })))
  ];
  const seasonOptions = [
    ...(!albumId ? seasonResults : albumFilter?.data.seasons || [])
  ];
  const tagOptions = [
    ...(!albumId ? tagResults : albumFilter?.data.tags || [])
  ];
  const streamOptions = [
    ...(!albumId ? streamResults : albumFilter?.data.streams || [])
  ];

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose(filters);
      }}
    >
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "auto",
          width: "850px",
          backgroundColor: "white",
          borderRadius: 16
        }}
      >
        {/* Modal Header */}
        <Box
          style={{
            height: 60,
            position: "sticky", // Make the header sticky
            top: 0, // Stick the header to the top of the container
            zIndex: 1,
            backgroundColor: "#2B337A",
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: 16,
            paddingRight: 16
          }}
        >
          <Typography
            style={{
              fontSize: 18,
              color: "white"
            }}
          >
            Filters
          </Typography>
          <IconButton
            onClick={() => {
              handleClose(filters);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ display: "flex", height: "500px" }}>
          {/* Sidebar */}
          <List
            style={{
              width: "30%",
              borderRight: "1px solid #e0e0e0",
              backgroundColor: "#f8fafc"
            }}
          >
            {filters
              .filter((f) => !excludeFilters.includes(f.category))
              .map((f) => (
                <ListItemButton
                  key={f.category}
                  style={{
                    backgroundColor:
                      f.category === selectedCategory ? "white" : "",
                    borderBottom:
                      f.category === selectedCategory
                        ? "1px solid #e0e0e0"
                        : "",
                    borderTop:
                      f.category === selectedCategory ? "1px solid #e0e0e0" : ""
                  }}
                  onClick={() => handleCategorySelect(f.category)}
                >
                  <ListItemText primary={f.category} />
                  <Badge badgeContent={f.selected.length} color="error" />
                </ListItemButton>
              ))}
          </List>

          {/* Filter Content */}
          <Loader isLoading={isAlbumFilterLoading || isLoading}>
            <Box
              sx={{
                p: 2,
                width: "100%",
                maxWidth: "727px"
              }}
            >
              {selectedCategory === "Videos/Photos" &&
              !excludeFilters.includes("Videos/Photos") ? (
                filters[0]?.options?.map((p) => (
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    key={p.value}
                  >
                    <Grid item>
                      <Typography>{p.label}</Typography>
                    </Grid>
                    <Grid item>
                      <Checkbox
                        checked={
                          !!filters[0]?.selected?.find((s) => s === p.value)
                        }
                        onChange={(e) =>
                          handleFilterChange(
                            "Videos/Photos",
                            p.value,
                            e.target.checked
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                ))
              ) : selectedCategory === "Time Frame" &&
                !excludeFilters.includes("Time Frame") ? (
                filters[1]?.options?.map((p) => (
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    key={p.value}
                  >
                    <Grid item>
                      <Typography>{p.label}</Typography>
                    </Grid>
                    <Grid item>
                      <Checkbox
                        checked={
                          !!filters[1]?.selected?.find((s) => s === p.value)
                        }
                        onChange={(e) =>
                          handleFilterChange(
                            "Time Frame",
                            p.value,
                            e.target.checked
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                ))
              ) : selectedCategory === "Level" &&
                !excludeFilters.includes("Level") ? (
                filters[7]?.options?.map((p) => (
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    key={p.value}
                  >
                    <Grid item>
                      <Typography>{p.label}</Typography>
                    </Grid>
                    <Grid item>
                      <Checkbox
                        checked={
                          !!filters[7]?.selected?.find((s) => s === p.value)
                        }
                        onChange={(e) =>
                          handleFilterChange("Level", p.value, e.target.checked)
                        }
                      />
                    </Grid>
                  </Grid>
                ))
              ) : selectedCategory === "Curator" &&
                !excludeFilters.includes("Curator") ? (
                <Grid container direction="column" spacing="10px">
                  <Grid item xs={12}>
                    <Autocomplete
                      open={curatorAddMenu}
                      clearOnBlur={false}
                      key="Curator"
                      style={{ minWidth: "525px" }}
                      onOpen={() => setCuratorAddMenu(true)}
                      onClose={() => setCuratorAddMenu(false)}
                      getOptionLabel={(option) =>
                        option?.personId
                          ? `${option.firstName} ${option.lastName}`
                          : ""
                      }
                      filterSelectedOptions
                      ListboxProps={{
                        style: {
                          maxHeight: "400px"
                        }
                      }}
                      renderInput={(params) => (
                        <SearchInput
                          {...params}
                          onChange={(e) => setCuratorSearch(e.target.value)}
                          placeholder="Search"
                          required={false}
                        />
                      )}
                      filterOptions={(options) =>
                        curatorAddMenu && !isFetchingUsers ? options : []
                      }
                      renderOption={(prop, option) => {
                        if (option?.userId) {
                          return (
                            <FilterCuratorOption
                              {...prop}
                              key={option.userId}
                              //@ts-ignore
                              person={option}
                              onClick={() => {
                                setCuratorAddMenu(false);
                                setFilters((prevFilters) =>
                                  prevFilters.map((filter) => {
                                    if (filter.category === selectedCategory) {
                                      const newSelected = [
                                        ...filter.selected,
                                        option.userId!
                                      ];
                                      const newOptions = [
                                        ...filter.options,
                                        {
                                          label: formatFullName(option),
                                          value: option.userId!,
                                          details: option
                                        }
                                      ];
                                      return {
                                        ...filter,
                                        options: newOptions,
                                        selected: newSelected
                                      };
                                    }
                                    return filter;
                                  })
                                );
                              }}
                            />
                          );
                        }
                      }}
                      options={curatorOptions}
                      loading={isFetchingUsers}
                      loadingText="Loading..."
                    />
                  </Grid>
                  <StyledSelectedOptionsBox>
                    {filters?.[2]?.options?.map((op) => (
                      <Grid
                        item
                        container
                        direction="row"
                        key={op.value}
                        spacing="10px"
                      >
                        <Grid item container direction="row" xs={11.25}>
                          <FilterCuratorItem person={op.details} />
                        </Grid>
                        <Grid item xs={0.6}>
                          <IconButton
                            onClick={() => {
                              setFilters((prevFilters) =>
                                prevFilters.map((filter) => {
                                  if (filter.category === selectedCategory) {
                                    const newSelected = filter.selected.filter(
                                      (s) => s !== op.value
                                    );
                                    const newOptions = filter.options.filter(
                                      (o) => o.value !== op.value
                                    );
                                    return {
                                      ...filter,
                                      options: newOptions,
                                      selected: newSelected
                                    };
                                  }
                                  return filter;
                                })
                              );
                            }}
                          >
                            <Close fontSize="small" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </StyledSelectedOptionsBox>
                </Grid>
              ) : selectedCategory === "Team/Training Program" &&
                !excludeFilters.includes("Team/Training Program") ? (
                <Grid container direction="column" spacing="10px">
                  <Grid item xs={12}>
                    <Autocomplete
                      open={teamTpAddMenu}
                      key="TeamTP"
                      clearOnBlur={false}
                      style={{ minWidth: "525px" }}
                      onOpen={() => setTeamTPAddMenu(true)}
                      onClose={() => setTeamTPAddMenu(false)}
                      getOptionLabel={(option) =>
                        option?.id ? option.name! : ""
                      }
                      ListboxProps={{
                        style: {
                          maxHeight: "400px"
                        }
                      }}
                      renderInput={(params) => (
                        <SearchInput
                          {...params}
                          onChange={(e) => setTeamTpSearch(e.target.value)}
                          placeholder="Search"
                          required={false}
                        />
                      )}
                      filterOptions={(options) =>
                        teamTpAddMenu && !isFetchingTeams && !isFetchingTp
                          ? options
                          : []
                      }
                      renderOption={(prop, option) => {
                        if (option?.id) {
                          return (
                            <FilterTeamTpOption
                              {...prop}
                              key={option.id}
                              //@ts-ignore
                              teamProgram={option}
                              onClick={() => {
                                setTeamTPAddMenu(false);
                                setFilters((prevFilters) =>
                                  prevFilters.map((filter) => {
                                    if (filter.category === selectedCategory) {
                                      const newSelected = [
                                        ...filter.selected,
                                        option.id!
                                      ];
                                      const newOptions = [
                                        ...filter.options,
                                        {
                                          label: option.name!,
                                          value: option.id!,
                                          details: option
                                        }
                                      ];
                                      return {
                                        ...filter,
                                        options: newOptions,
                                        selected: newSelected
                                      };
                                    }
                                    return filter;
                                  })
                                );
                              }}
                            />
                          );
                        }
                      }}
                      options={teamTpOptions}
                      loading={isFetchingTeams || isFetchingTp}
                      loadingText="Loading..."
                    />
                  </Grid>
                  <StyledSelectedOptionsBox>
                    {filters?.[4]?.options?.map((op) => (
                      <Grid
                        item
                        container
                        direction="row"
                        key={op.value}
                        spacing="10px"
                      >
                        <Grid item container direction="row" xs={11.25}>
                          <FilterTeamTpItem teamProgram={op.details} />
                        </Grid>
                        <Grid item xs={0.6}>
                          <IconButton
                            onClick={() => {
                              setFilters((prevFilters) =>
                                prevFilters.map((filter) => {
                                  if (filter.category === selectedCategory) {
                                    const newSelected = filter.selected.filter(
                                      (s) => s !== op.value
                                    );
                                    const newOptions = filter.options.filter(
                                      (o) => o.value !== op.value
                                    );
                                    return {
                                      ...filter,
                                      options: newOptions,
                                      selected: newSelected
                                    };
                                  }
                                  return filter;
                                })
                              );
                            }}
                          >
                            <Close fontSize="small" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </StyledSelectedOptionsBox>
                </Grid>
              ) : selectedCategory === "Live Stream" &&
                !excludeFilters.includes("Live Stream") ? (
                <Grid container direction="column" spacing="10px">
                  <Grid item xs={12}>
                    <Autocomplete
                      open={streamAddMenu}
                      key="streams"
                      clearOnBlur={false}
                      style={{ minWidth: "525px" }}
                      onOpen={() => setStreamAddMenu(true)}
                      onClose={() => setStreamAddMenu(false)}
                      getOptionLabel={(option) =>
                        option?.streamId ? option.title! : ""
                      }
                      ListboxProps={{
                        style: {
                          maxHeight: "400px"
                        }
                      }}
                      renderInput={(params) => (
                        <SearchInput
                          {...params}
                          onChange={(e) => setStreamSearch(e.target.value)}
                          placeholder="Search"
                          required={false}
                        />
                      )}
                      filterOptions={(options) =>
                        streamAddMenu && !isFetchingStreams ? options : []
                      }
                      renderOption={(prop, option) => {
                        if (option?.streamId) {
                          return (
                            <FilterStreamItem
                              {...prop}
                              key={option.streamId}
                              stream={option}
                              selectedStreams={filters?.[5]?.selected || []}
                              onCheck={(checked) => {
                                setFilters((prevFilters) =>
                                  prevFilters.map((filter) => {
                                    if (filter.category === selectedCategory) {
                                      const newSelected = checked
                                        ? [...filter.selected, option.streamId!]
                                        : filter.selected.filter(
                                            (f) => f !== option.streamId
                                          );
                                      const newOptions = checked
                                        ? [
                                            ...filter.options,
                                            {
                                              label: option.title!,
                                              value: option.streamId!,
                                              details: option
                                            }
                                          ]
                                        : filter.options.filter(
                                            (f) => f.value !== option.streamId
                                          );
                                      return {
                                        ...filter,
                                        options: newOptions,
                                        selected: newSelected
                                      };
                                    }
                                    return filter;
                                  })
                                );
                              }}
                            />
                          );
                        }
                      }}
                      options={streamOptions}
                      loading={isFetchingStreams}
                      loadingText="Loading..."
                    />
                  </Grid>
                  <StyledSelectedOptionsBox>
                    {filters?.[5]?.options?.map((op) => (
                      <Grid
                        item
                        container
                        direction="row"
                        key={op.value}
                        spacing="10px"
                      >
                        <Grid item container direction="row" xs={11.25}>
                          <StyledSelectedOption>
                            {op.label}
                          </StyledSelectedOption>
                        </Grid>
                        <Grid item xs={0.6}>
                          <IconButton
                            onClick={() => {
                              setFilters((prevFilters) =>
                                prevFilters.map((filter) => {
                                  if (filter.category === selectedCategory) {
                                    const newSelected = filter.selected.filter(
                                      (s) => s !== op.value
                                    );
                                    const newOptions = filter.options.filter(
                                      (o) => o.value !== op.value
                                    );
                                    return {
                                      ...filter,
                                      options: newOptions,
                                      selected: newSelected
                                    };
                                  }
                                  return filter;
                                })
                              );
                            }}
                          >
                            <Close fontSize="small" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </StyledSelectedOptionsBox>
                </Grid>
              ) : selectedCategory === "Season" &&
                !excludeFilters.includes("Season") ? (
                <Grid container direction="column" spacing="10px">
                  <Grid item xs={12}>
                    <Autocomplete
                      open={seasonAddMenu}
                      key="Season"
                      clearOnBlur={false}
                      style={{ minWidth: "525px" }}
                      onOpen={() => setSeasonAddMenu(true)}
                      onClose={() => setSeasonAddMenu(false)}
                      getOptionLabel={(option) =>
                        option?.seasonId ? option.name! : ""
                      }
                      ListboxProps={{
                        style: {
                          maxHeight: "400px"
                        }
                      }}
                      renderInput={(params) => (
                        <SearchInput
                          {...params}
                          onChange={(e) => setSeasonSearch(e.target.value)}
                          placeholder="Search"
                          required={false}
                        />
                      )}
                      filterOptions={(options) =>
                        seasonAddMenu && !isFetchingSeason ? options : []
                      }
                      renderOption={(prop, option) => {
                        if (option?.seasonId) {
                          return (
                            <FilterSeasonOption
                              {...prop}
                              key={option.seasonId}
                              season={option}
                              selectedSeasons={filters?.[6]?.selected || []}
                              onCheck={(checked) => {
                                setFilters((prevFilters) =>
                                  prevFilters.map((filter) => {
                                    if (filter.category === selectedCategory) {
                                      const newSelected = checked
                                        ? [...filter.selected, option.seasonId!]
                                        : filter.selected.filter(
                                            (f) => f !== option.seasonId
                                          );
                                      const newOptions = checked
                                        ? [
                                            ...filter.options,
                                            {
                                              label: option.name!,
                                              value: option.seasonId!,
                                              details: option
                                            }
                                          ]
                                        : filter.options.filter(
                                            (f) => f.value !== option.seasonId
                                          );
                                      return {
                                        ...filter,
                                        options: newOptions,
                                        selected: newSelected
                                      };
                                    }
                                    return filter;
                                  })
                                );
                              }}
                            />
                          );
                        }
                      }}
                      options={seasonOptions}
                      loading={isFetchingSeason}
                      loadingText="Loading..."
                    />
                  </Grid>
                  <StyledSelectedOptionsBox>
                    {filters?.[6]?.options?.map((op) => (
                      <Grid
                        item
                        container
                        direction="row"
                        key={op.value}
                        spacing="10px"
                      >
                        <Grid item container direction="row" xs={11.25}>
                          <FilterSeasonItem season={op.details} />
                        </Grid>
                        <Grid item xs={0.6}>
                          <IconButton
                            onClick={() => {
                              setFilters((prevFilters) =>
                                prevFilters.map((filter) => {
                                  if (filter.category === selectedCategory) {
                                    const newSelected = filter.selected.filter(
                                      (s) => s !== op.value
                                    );
                                    const newOptions = filter.options.filter(
                                      (o) => o.value !== op.value
                                    );
                                    return {
                                      ...filter,
                                      options: newOptions,
                                      selected: newSelected
                                    };
                                  }
                                  return filter;
                                })
                              );
                            }}
                          >
                            <Close fontSize="small" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </StyledSelectedOptionsBox>
                </Grid>
              ) : selectedCategory === "Tags" &&
                !excludeFilters.includes("Tags") ? (
                <Grid container direction="column" spacing="10px">
                  <Grid item xs={12}>
                    <Autocomplete
                      open={tagAddMenu}
                      key="Tags"
                      clearOnBlur={false}
                      ListboxProps={{
                        style: {
                          maxHeight: "400px"
                        }
                      }}
                      onOpen={() => setTagAddMenu(true)}
                      onClose={() => setTagAddMenu(false)}
                      getOptionLabel={(option) =>
                        option?.tagId ? option.name! : ""
                      }
                      renderInput={(params) => (
                        <SearchInput
                          {...params}
                          onChange={(e) => setTagSearch(e.target.value)}
                          placeholder="Search"
                          required={false}
                        />
                      )}
                      filterOptions={(options) =>
                        tagAddMenu && !isFetchingTags ? options : []
                      }
                      renderOption={(prop, option) => {
                        if (option?.tagId) {
                          return (
                            <FilterTagOption
                              {...prop}
                              key={option.tagId}
                              tag={option}
                              selectedTags={filters?.[3]?.selected || []}
                              onCheck={(checked) => {
                                setFilters((prevFilters) =>
                                  prevFilters.map((filter) => {
                                    if (filter.category === selectedCategory) {
                                      const newSelected = checked
                                        ? [...filter.selected, option.tagId!]
                                        : filter.selected.filter(
                                            (f) => f !== option.tagId
                                          );
                                      const newOptions = checked
                                        ? [
                                            ...filter.options,
                                            {
                                              label: option.name!,
                                              value: option.tagId!,
                                              details: option
                                            }
                                          ]
                                        : filter.options.filter(
                                            (f) => f.value !== option.tagId
                                          );
                                      return {
                                        ...filter,
                                        options: newOptions,
                                        selected: newSelected
                                      };
                                    }
                                    return filter;
                                  })
                                );
                              }}
                            />
                          );
                        }
                      }}
                      options={tagOptions}
                      loading={isFetchingTags}
                      loadingText="Loading..."
                    />
                  </Grid>
                  <StyledSelectedOptionsBox>
                    {filters?.[3]?.options?.map((op) => (
                      <Grid
                        item
                        container
                        direction="row"
                        key={op.value}
                        spacing="10px"
                      >
                        <Grid item container direction="row" xs={11.25}>
                          <StyledSelectedOption>
                            {op?.details?.name}
                          </StyledSelectedOption>
                        </Grid>
                        <Grid item xs={0.6}>
                          <IconButton
                            onClick={() => {
                              setFilters((prevFilters) =>
                                prevFilters.map((filter) => {
                                  if (filter.category === selectedCategory) {
                                    const newSelected = filter.selected.filter(
                                      (s) => s !== op.value
                                    );
                                    const newOptions = filter.options.filter(
                                      (o) => o.value !== op.value
                                    );
                                    return {
                                      ...filter,
                                      options: newOptions,
                                      selected: newSelected
                                    };
                                  }
                                  return filter;
                                })
                              );
                            }}
                          >
                            <Close fontSize="small" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </StyledSelectedOptionsBox>
                </Grid>
              ) : (
                <></>
              )}
            </Box>
          </Loader>
        </Box>

        <Footer
          cancelBtnClick={() => handleClearAll()}
          saveBtnClick={() => {
            handleClose(filters);
          }}
          saveBtnLabel="Apply Filters"
          cancelBtnLabel="Clear All"
        />
      </Box>
    </Modal>
  );
};
