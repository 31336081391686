import { Loader } from "@components/crud/Loader";
import { TableView } from "@components/TableView";
import { hasPermission } from "@services/Casbin";
import { getOnboardingEvents } from "@services/Network";
import { ModelOnboardingEvent } from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import { IconButton, Typography } from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";
import { ToolTip } from "@components/ToolTip";
import GoverningBodyIcon from "@assets/icons/governingBody.svg";
import formatFullName from "@utils/formatFullName";
import {
  CRM_ACCOUNT_CATEGORIES,
  CRM_ACCOUNT_TYPES,
  CRM_ONBOARD_STATUS
} from "@utils/constants";
import { useRecoilValue } from "recoil";
import { profileAtom } from "@recoil/auth";
import { dateFormatWithTimeZone } from "@utils/dateUtils";
const IconStyle = {
  height: "20px",
  width: "20px"
};

const formatDateForDisplay = (dateStr) => {
  const inputDate = new Date(dateStr);
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const day = inputDate.getDate().toString().padStart(2, "0");
  const year = inputDate.getFullYear();
  // Create the formatted date string in "MM/DD/YYYY" format
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
};

export const OnboardingAndReviews = () => {
  const navigate = useNavigate();
  const [refreshKey] = useState(0);
  const [isLoadingPermissions, setPermissionsLoading] = useState(true);
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    view: false
  });
  const onView = (event) => navigate(`/crm/onboarding-review/${event.eventId}`);
  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("crm.accounts", "ADD");
      const edit = await checkPermission("crm.accounts", "EDIT");
      const view = await checkPermission("crm.accounts", "VIEW");
      const permission = {
        create,
        edit,
        view
      };
      setPermissions(permission);
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, []);

  const EVENT_COLUMNS: GridColDef<ModelOnboardingEvent>[] = [
    {
      headerName: "Actions",
      field: "action",
      minWidth: 70,
      flex: 0.75,
      sortable: false,
      renderHeader: () => <div style={{ paddingLeft: "10px" }}>Actions</div>,
      renderCell: (params) => {
        return (
          <div style={{ padding: "20px 0", display: "flex" }}>
            {permissions.view && (
              <IconButton onClick={() => onView(params.row)}>
                <ToolTip title="View Event" placement="top">
                  <Visibility style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
          </div>
        );
      }
    },
    {
      headerName: "Account",
      field: "account",
      minWidth: 225,
      flex: 1,
      renderCell: (params) => {
        if (params.row.account?.parentId) {
          const parent = params.row.account.parent;
          if (parent)
            return (
              <ToolTip
                title={
                  <div>
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        cursor: "pointer"
                      }}
                    >
                      <Typography color="#007AFF">{parent?.name}</Typography>
                      <IconButton
                        onClick={() =>
                          navigate(`/crm/accounts/${parent.accountId}`)
                        }
                      >
                        <LaunchIcon
                          style={{
                            color: "#007AFF",
                            height: "14px",
                            width: "14px"
                          }}
                        />
                      </IconButton>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "3px"
                      }}
                    >
                      <Typography color="#64748B" variant="body2">
                        {`${CRM_ACCOUNT_CATEGORIES.find(
                          (c) => c.value === parent.category
                        )?.label} • ${CRM_ACCOUNT_TYPES.find(
                          (c) => c.value === parent.type
                        )?.label}`}
                      </Typography>
                      <Typography color="#64748B" variant="body2">
                        {parent.officeAddress}
                      </Typography>
                    </div>
                  </div>
                }
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px"
                  }}
                >
                  <span>{params.row.account?.name}</span>
                  <span>
                    <img src={GoverningBodyIcon} />
                  </span>
                </div>
              </ToolTip>
            );
        }
        return <div>{params.row.account?.name}</div>;
      }
    },
    {
      headerName: "Type",
      field: "type",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) =>
        row.type === "ONBOARDING" ? "Onboard" : "Review"
    },
    {
      headerName: "Status",
      field: "status",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <span
            style={params.row.status === "AT_RISK" ? { color: "#E82C2C" } : {}}
          >
            {
              CRM_ONBOARD_STATUS.find((c) => c.value === params.row.status)
                ?.label
            }
          </span>
        );
      }
    },
    {
      headerName: "Created Date",
      field: "createdAt",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) => formatDateForDisplay(row.createdAt)
    },
    {
      headerName: "CSM",
      field: "csm",
      minWidth: 150,
      valueGetter: ({ row }) =>
        row.assignedToId ? formatFullName(row.assignedTo!.person!) : "",
      flex: 1
    },
    {
      headerName: "Onboarding/Review Meeting",
      field: "meetingDate",
      minWidth: 250,
      flex: 1,
      valueGetter: ({ row }) =>
        row.meetingDate
          ? dateFormatWithTimeZone(row.meetingDate)
          : "Not Booked yet"
    }
  ];
  const profile = useRecoilValue(profileAtom);
  const filterConfig = {
    field: "filter",
    placeholderOption: {
      label: "All",
      value: "ALL"
    },

    options: [
      {
        label: "Not Scheduled",
        value: "NOT_SCHEDULED"
      },
      {
        label: "Scheduled",
        value: "SCHEDULED"
      },
      {
        label: "At Risk",
        value: "AT_RISK"
      },
      {
        label: "Completed",
        value: "COMPLETED"
      },
      {
        label: "My Meetings",
        value: "MINE"
      },
      {
        label: "My Not Scheduled Meetings",
        value: "MINE_NOT_SCHEDULED"
      },
      {
        label: "My Scheduled Meetings",
        value: "MINE_SCHEDULED"
      },
      {
        label: "My Completed Meetings",
        value: "MINE_COMPLETED"
      }
    ],
    ...(profile?.sportsgravyUser?.jobTitle?.canConductOnboardingDemo &&
      profile?.sportsgravyUser?.jobTitle?.canSupportAccount && {
        defaultValue: "MINE"
      })
  };

  return (
    <>
      <Loader isLoading={isLoadingPermissions}>
        <TableView
          title="Onboarding & Reviews"
          useGet={getOnboardingEvents}
          columns={EVENT_COLUMNS}
          getRowId={(row) => row.eventId}
          filterConfig={filterConfig}
          defaultSort={[{ field: "account", sort: "asc" }]}
          onAdd={undefined}
          isDeleteDisabled={() => true}
          refreshKey={refreshKey}
          getRowHeight={() => "auto"}
          hasActionColumn={false}
          pinnedColumns={{ left: ["action", "account"] }}
        />
      </Loader>
    </>
  );
};
