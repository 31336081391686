/* eslint-disable @typescript-eslint/no-explicit-any */
import { Footer } from "@components/crud/Footer";
import { FormInput } from "@components/FormInput";
import { FormLabel, styled, Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid/Grid";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { EmailReplyEditor } from "@pages/crm/email-module/EmailReplyEditor";
import { uploadAdminMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { enqueueSnackbar } from "notistack";
import {
  CreateMailInputRelatesTo,
  usePostAdminCrmMail
} from "@sportsgravyengineering/sg-api-react-sdk";

const ReplyBox = styled("div")`
  border: 1px solid #e5e5e5;
  box-shadow: 0px 0px 8px 0px #00000029;
  border-radius: 6px;
  .reply-form {
    padding: 24px;
  }
`;

const StyledFormLabel = styled(FormLabel)`
  marginbottom: 0.25rem;
  display: block;
`;

export const EmailReply = ({
  email,
  replyType,
  onClose,
  onSuccessSent,
  replyOfId,
  userEmail
}: {
  email: any;
  replyType: "SINGLE" | "ALL";
  replyOfId: string;
  userEmail?: string;
  onClose: () => void;
  onSuccessSent: () => void;
}) => {
  const [descValid, setDescValid] = useState(false);
  const { control, getValues, setValue, reset } = useForm({ mode: "onBlur" });
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const validateDescriptionArray = (value) => {
    if (value && value.length >= 2) {
      setDescValid(true);
    } else if (value && value.length == 1 && value[0].insert !== "\n") {
      setDescValid(true);
    } else {
      setDescValid(false);
    }
  };
  const [files, setFiles] = useState<{ id: string; file: File }[]>([]);
  const [replyTo, setReplyTo] = useState<{ name: string; email: string }[]>([]);
  const [uploadingFiles, setUploadingFiles] = useState(false);
  useEffect(() => {
    let to = "";
    const replyingTo = [] as { name: string; email: string }[];
    if (replyType == "SINGLE") {
      to = email?.from?.email;
      replyingTo.push({
        name: email?.from?.name,
        email: email?.from?.email
      });
    }
    if (replyType == "ALL") {
      const uniqueFromEmails: string[] = [];
      email.forEach((email) => {
        if (
          !uniqueFromEmails.includes(email?.from?.email as string) &&
          userEmail !== email?.from?.email
        ) {
          uniqueFromEmails.push(email?.from?.email as string);
          replyingTo.push({
            name: email?.from?.name,
            email: email?.from?.email
          });
        }
        [...email.to, ...email.cc, ...email.bcc].map((t) => {
          if (
            !uniqueFromEmails.includes(t?.email as string) &&
            userEmail !== t?.email
          ) {
            uniqueFromEmails.push(t.email as string);
            replyingTo.push({
              name: t.name,
              email: t.email
            });
          }
        });
      });
      to = uniqueFromEmails.join(", ");
    }
    reset({ to });
    setReplyTo(replyingTo);
  }, [email, replyType]);
  const { mutate: save, isLoading: isSaving } = usePostAdminCrmMail();
  const onSendReply = async () => {
    const data = {
      to: replyTo,
      cc: [],
      bcc: [],
      body: getValues().description,
      subject: `RE: ${
        replyType === "SINGLE" ? email.subject : email[0].subject
      }`,
      relatesTo: "ACCOUNT" as CreateMailInputRelatesTo,
      timezone: "Europe/London",
      replyOfId: replyOfId,
      attachments: [] as string[]
    };
    if (files.length > 0) {
      setUploadingFiles(true);
      const filesPromises = await Promise.all(
        files.map(async (file) => {
          if (file.file instanceof File) {
            const promise = uploadAdminMediaUsingPresignedUrl(file.file);
            return promise;
          }
        })
      );
      setUploadingFiles(false);
      data.attachments = filesPromises as string[];
    }

    save(
      {
        data: data
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Email Sent!", {
            variant: "success"
          });
          onSuccessSent();
        },
        onError: () => {
          enqueueSnackbar("Failed to Send Email!", {
            variant: "error"
          });
        }
      }
    );
  };
  return (
    <>
      <ReplyBox>
        <div className="reply-form">
          <Grid container spacing={3}>
            <Grid xs={12}>
              <FormInput
                control={control}
                type="text"
                name="to"
                label="Email To"
                disabled
              />
            </Grid>
            <Grid xs={12}>
              <Grid xs={12}>
                <StyledFormLabel>
                  <Typography variant="formLabel">Email Content</Typography>
                </StyledFormLabel>
                <EmailReplyEditor
                  html={
                    getValues().description
                      ? getValues().description
                      : undefined
                  }
                  onBlur={() => {
                    validateDescriptionArray(getValues().description);
                  }}
                  onValueChange={(deltaOperations, hasMedia) => {
                    setValue("description", deltaOperations, {
                      shouldValidate: true,
                      shouldDirty: true
                    });
                    setValue("hasMedia", hasMedia);

                    validateDescriptionArray(deltaOperations);
                  }}
                  style={{
                    height: "350px"
                  }}
                  files={files}
                  setFiles={setFiles}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Footer
          cancelBtnClick={() => setOpenCancelDialog(true)}
          saveBtnClick={() => {
            onSendReply();
          }}
          saveBtnLabel="Send Reply"
          isLoading={uploadingFiles || isSaving}
          isDisabled={!descValid}
        />
        <ConfirmationDialog
          title="Are you sure you want to cancel?"
          body="All of your current changes will be lost."
          open={openCancelDialog}
          close={() => setOpenCancelDialog(false)}
          onCancel={() => setOpenCancelDialog(false)}
          onConfirm={() => onClose()}
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
        />
      </ReplyBox>
    </>
  );
};
