import {
  Autocomplete,
  Avatar,
  Backdrop,
  Box,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  styled
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import colors from "theme/colors";
import { Controller, useForm } from "react-hook-form";
import Grid from "@mui/system/Unstable_Grid";
import { FormInput } from "@components/FormInput";
import { StyledFormLabel } from "@components/StyledFormLabel";
import { Search } from "@mui/icons-material";
import { Footer } from "@components/crud/Footer";
import { useMemo, useState } from "react";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import {
  ModelAlbum,
  useAdminAlbumAlbumIdPut,
  useAdminAlbumGet,
  useAdminAlbumPost,
  useAdminUserGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";
import { useRecoilValue } from "recoil";
import { organizationAtom, profileAtom } from "@recoil/auth";
import { capitalize } from "@utils/capitalize";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "16px",
  marginLeft: "125px",
  width: "100%",
  maxWidth: "650px",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "0%"
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px"
  },
  "& .footer": {
    borderBottomLeftRadius: "16px",
    borderBottomRightRadius: "16px"
  }
}));

const StyledBoxHeader = styled(Box)({
  height: "64px",
  backgroundColor: colors.info.main,
  borderRadius: "16px 16px 0px 0px"
});

export const AddToAlbum = ({
  onClose,
  onSave,
  type,
  mediaIds
}: {
  onClose: () => void;
  onSave?: (data: ModelAlbum) => void;
  type: "ADD_TO_ALBUM" | "CREATE_ALBUM";
  mediaIds?: string[];
}) => {
  const user = useRecoilValue(profileAtom);
  const [albumSearch, setAlbumSearch] = useState<string | undefined>(undefined);
  const [selectedAlbum, setSelectedAlbum] = useState<ModelAlbum | undefined>(
    undefined
  );
  const organizationId = useRecoilValue(organizationAtom);
  const [userSearch, setUserSearch] = useState<string | undefined>(undefined);
  const {
    control,
    setValue,
    getValues,
    formState: { isValid }
  } = useForm();
  const [createAlbumType, setCreateAlbumType] = useState<
    "ADD_TO_ALBUM" | "CREATE_ALBUM"
  >(type);

  const { data: albums, isLoading: isAlbumLoading } = useAdminAlbumGet({
    organizationId: organizationId!,
    ...(albumSearch && albumSearch.length > 0
      ? { textSearch: albumSearch }
      : {}),
    contributorId: user?.userId
  });

  const albumsOptions = useMemo(() => {
    if (albums?.data) {
      return albums.data.map((album) => ({
        label: album.name!,
        value: album.albumId!,
        album: album
      }));
    }
    return [];
  }, [albums]);

  const { mutate: save, isLoading: isSaving } = useAdminAlbumPost();

  const { mutate: addClipToAlbum, isLoading: addingClipToAlbum } =
    useAdminAlbumAlbumIdPut();

  const saveHandler = async () => {
    const values = getValues();
    if (createAlbumType === "CREATE_ALBUM") {
      save(
        {
          data: {
            name: values.name,
            contributorIds: values?.shareWith?.map((v) => v.personId) || [],
            organizationId: organizationId!,
            mediaIds: mediaIds || []
          }
        },
        {
          onSuccess: (data) => {
            if (onSave) onSave(data.data);
            setValue("name", undefined);
            setValue("shareWith", undefined);
            onClose();
          },
          onError: () => {
            enqueueSnackbar("Failed to add album!", {
              variant: "error"
            });
          }
        }
      );
    } else {
      if (selectedAlbum && mediaIds)
        addClipToAlbum(
          {
            albumId: selectedAlbum.albumId!,
            data: {
              mediaIds: mediaIds
            }
          },
          {
            onSuccess: () => {
              onClose();
            },
            onError: () => {
              enqueueSnackbar("Failed to add clips to album!", {
                variant: "error"
              });
            }
          }
        );
    }
  };

  const { data: users, isLoading: userLoading } = useAdminUserGet({
    organizationId: organizationId!,
    textSearch: userSearch,
    pageSize: "100",
    roleAliases: ["ADMIN", "MANAGER", "COACH"]
  });

  const userOptions = useMemo(() => {
    return users?.data?.persons?.map((user) => ({
      personId: user.userId,
      name: user.firstName + " " + user.lastName,
      role: Array.from(
        new Set(
          user.user?.roles
            ?.map((role) => role.role?.alias && capitalize(role.role?.alias))
            .filter(Boolean)
        )
      ).join(", "),
      background: "#" + user.colorCode,
      avatar:
        user.avatar?.baseUrl && user.avatar?.path
          ? user.avatar?.baseUrl + user.avatar?.path
          : undefined
    }));
  }, [users]);

  return (
    <Backdrop
      sx={{
        overflow: "hidden",
        overflowY: "none",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={true}
    >
      <Container>
        <StyledBox>
          <StyledBoxHeader>
            <Grid
              container
              style={{
                padding: "18px 20px 20px 32px",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Grid sx={{ maxWidth: "88%" }}>
                <Typography
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                    fontStyle: "normal",
                    lineHeight: "20px",
                    fontWeight: "600"
                  }}
                >
                  {createAlbumType === "ADD_TO_ALBUM"
                    ? "Add to Album"
                    : "New Album"}
                </Typography>
              </Grid>
              <Grid>
                <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />
              </Grid>
            </Grid>
          </StyledBoxHeader>
          <Grid container spacing={3} padding="24px" zIndex="1000">
            {createAlbumType === "ADD_TO_ALBUM" && (
              <>
                <Grid xs={11}>
                  <StyledFormLabel required>
                    <Typography display="inline" variant="formLabel">
                      Album
                    </Typography>
                  </StyledFormLabel>
                  <Controller
                    name="album"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        freeSolo
                        value={field.value}
                        options={albumsOptions}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <Typography>{option.label}</Typography>
                          </li>
                        )}
                        noOptionsText="No albums found"
                        loadingText="Loading..."
                        getOptionLabel={(option) => option.label}
                        loading={isAlbumLoading}
                        renderInput={(params) => {
                          if (Array.isArray(params.InputProps.startAdornment)) {
                            params.InputProps.startAdornment.splice(
                              0,
                              0,
                              <InputAdornment position="start">
                                <Search />
                              </InputAdornment>
                            );
                          } else {
                            params.InputProps.startAdornment = (
                              <InputAdornment position="start">
                                <Search />
                              </InputAdornment>
                            );
                          }
                          return (
                            <TextField
                              {...params}
                              onChange={(e) => {
                                setAlbumSearch(e.target.value);
                              }}
                            />
                          );
                        }}
                        onChange={(e, value) => {
                          setSelectedAlbum(value?.album);
                          field.onChange(value);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  xs={1}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                    marginTop: "20px"
                  }}
                >
                  <IconButton
                    onClick={() => setCreateAlbumType("CREATE_ALBUM")}
                  >
                    <AddRoundedIcon style={{ color: "#007AFF" }} />
                  </IconButton>
                </Grid>
              </>
            )}

            {createAlbumType === "CREATE_ALBUM" && (
              <>
                <Grid xs={12}>
                  <FormInput
                    control={control}
                    name="name"
                    type="text"
                    label="Album Name"
                    required
                    rules={{
                      required: "Album Name is required"
                    }}
                  />
                </Grid>
                <Grid xs={12}>
                  <StyledFormLabel>
                    <Typography display="inline" variant="formLabel">
                      Allow other Coaches, Managers, and Administrators to edit
                      this album
                    </Typography>
                  </StyledFormLabel>
                  <Autocomplete
                    freeSolo
                    disableClearable={true}
                    multiple
                    options={userOptions || []}
                    loading={userLoading}
                    loadingText="Loading..."
                    renderOption={(props, option) => {
                      return (
                        <li {...props} style={{ display: "flex", gap: "8px" }}>
                          {option.avatar ? (
                            <Avatar
                              variant="square"
                              src={option.avatar}
                              style={{
                                height: "32px",
                                width: "32px",
                                borderRadius: "8px"
                              }}
                            />
                          ) : (
                            <Avatar
                              variant="square"
                              style={{
                                height: "32px",
                                width: "32px",
                                borderRadius: "8px",
                                background: option.background,
                                fontSize: "14px"
                              }}
                            >
                              {option.name
                                .split(" ")
                                .map((n) => n[0])
                                .join("")}
                            </Avatar>
                          )}
                          <div>
                            <Typography lineHeight="20px">
                              {option.name}
                            </Typography>
                            <Typography color="#64748B" lineHeight="20px">
                              {option.role}
                            </Typography>
                          </div>
                        </li>
                      );
                    }}
                    getOptionLabel={(option) =>
                      typeof option === "object" ? option.name : ""
                    }
                    filterSelectedOptions
                    renderInput={(params) => {
                      if (Array.isArray(params.InputProps.startAdornment)) {
                        params.InputProps.startAdornment.splice(
                          0,
                          0,
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        );
                      } else {
                        params.InputProps.startAdornment = (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        );
                      }
                      return (
                        <TextField
                          {...params}
                          onChange={(e) => setUserSearch(e.target.value)}
                        />
                      );
                    }}
                    onChange={(e, value) => {
                      setValue("shareWith", value?.map((v) => v));
                    }}
                    slotProps={{
                      paper: {
                        style: {
                          borderRadius: "8px",
                          border: "1px solid #E5E5E5",
                          marginTop: "8px",
                          boxShadow: "0px 1px 2px -1px #0000001A"
                        }
                      }
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Footer
            cancelBtnClick={onClose}
            saveBtnClick={saveHandler}
            isDisabled={!isValid || isSaving || addingClipToAlbum}
            isLoading={isSaving || addingClipToAlbum}
          />
        </StyledBox>
      </Container>
    </Backdrop>
  );
};
