/* eslint-disable no-useless-escape */
import { Button } from "@components/Button";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { StepProgressLine } from "@components/StepProgressLine";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { getCountries } from "@services/Network";
import Sentry from "@services/Sentry";
import {
  getAdminCrmAccountAccountId,
  ModelMedia,
  useAdminOrganizationPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { OrganizationContactForm } from "./OrganizationContactForm";
import { OrganizationDetailsForm } from "./OrganizationDetailsForm";
import { OrganizationSportsForm } from "./OrganizationSportsForm";
import { organizationAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";
import { uploadMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { cleanObject } from "@utils/cleanObject";
import { DevTool } from "@hookform/devtools";
import { ORGANIZATION_TYPE_OPTIONS } from "@utils/constants";
const steps = ["Organization Details", "Sports Details", "Contacts Details"];

export const OrganizationCreate = () => {
  const loggedInOrganizationId = useRecoilValue(organizationAtom);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const searchParams = new URLSearchParams(location.search);
  const accountId = searchParams.get("accountId");
  console.log(accountId);
  useEffect(() => {
    if (loggedInOrganizationId) {
      navigate(`/organizations/${loggedInOrganizationId}`);
    }
  }, [loggedInOrganizationId]);

  const [tab, setTab] = useState("Organization Details");
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [files, setFiles] = useState<(File | ModelMedia)[]>([]);
  const activeStepNumber = steps.findIndex((step) => step === tab);

  const {
    control,
    watch,
    setValue,
    trigger,
    handleSubmit,
    reset,
    formState: { isValid }
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      abbreviation: "",
      address1: "",
      address2: "",
      allowGoverningBodyBySport: "",
      country: "",
      email: "",
      governingBodyId: "",
      hasPrimaryContact: true,
      hasSecondaryContact: false,
      isGovernedByOrg: "no",
      loadDataFromSportsGravy: "",
      locality: "",
      name: "",
      offering: "",
      phone: "",
      avatarId: null,
      postalCode: "",
      primaryContact: null,
      providerId: "",
      province: "",
      secondaryContact: null,
      sportsFields: [],
      sportsOffered: [],
      type: "",
      versionId: "",
      website: "",
      shippingSameAs: "OFFICE"
    }
  });

  const {
    providerId,
    country,
    sportsOffered,
    allowGoverningBodyBySport,
    hasSecondaryContact,
    primaryContact,
    secondaryContact,
    type,
    isGovernedByOrg
  } = watch();

  const { data: countries, isFetching: isFetchingCountries } = getCountries({
    staleTime: Infinity
  });

  useEffect(() => {
    const fetchData = async () => {
      if (accountId) {
        const data = await getAdminCrmAccountAccountId(accountId);
        if (data.data.accountId) {
          const account = data.data;
          // const addressParts = account?.officeAddress?.split(", ") || [];
          // const contact = data.data.contact;
          const sportFields = account.sports?.map((s) => ({
            sportId: s.sportId,
            offeredFor: [],
            governingBody: null,
            maleSingularInterval: s?.isIntervalBased ? s.intervalSingular : "",
            malePluralInterval: s?.isIntervalBased ? s.intervalPlural : "",
            maleIntervalAbbreviation: s?.isIntervalBased
              ? s.intervalAbbreviation
              : "",
            femaleSingularInterval: s?.isIntervalBased
              ? s.intervalSingular
              : "",
            femalePluralInterval: s?.isIntervalBased ? s.intervalPlural : "",
            femaleIntervalAbbreviation: s?.isIntervalBased
              ? s.intervalAbbreviation
              : ""
          }));

          reset({
            name: account?.name || "",
            type:
              ORGANIZATION_TYPE_OPTIONS.find(
                (t) => t.value === account?.category
              )?.label || "NATIONAL_GOVERNING_BODY",
            email: account?.email || "",
            phone: account?.phone,
            website: account?.website || "",
            sportsOffered: account.sports?.map((s) => s.sportId) || [],
            abbreviation: "",
            address1: "",
            address2: "",
            allowGoverningBodyBySport: "",
            country: "",
            governingBodyId: "",
            hasPrimaryContact: true,
            hasSecondaryContact: false,
            isGovernedByOrg: "no",
            loadDataFromSportsGravy: "",
            locality: "",
            offering: "",
            avatarId: null,
            postalCode: "",
            primaryContact: null,
            providerId: "",
            province: "",
            secondaryContact: null,
            sportsFields: sportFields || [],
            versionId: "",
            shippingSameAs: "OFFICE"
          });
        }
      }
    };
    fetchData();
  }, [accountId]);

  const selectAddressSuggestion = (place, prefix = "") => {
    const addressComponents = place?.address_components || [];
    const streetNumber = addressComponents.find((c) =>
      c.types.includes("street_number")
    );
    const route = addressComponents.find((c) => c.types.includes("route"));
    const address1 = `${streetNumber?.long_name} ${route?.long_name}`;
    const subpremise = addressComponents.find((c) =>
      c.types.includes("subpremise")
    );
    const country = addressComponents.find((c) => c.types.includes("country"));
    const state = addressComponents.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    const city = addressComponents.find(
      (c) => c.types.includes("locality") || c.types.includes("sublocality")
    );
    const zip = addressComponents.find((c) => c.types.includes("postal_code"));
    console.log(addressComponents);
    setValue(`${prefix}address1`, address1);
    if (subpremise) setValue(`${prefix}address2`, subpremise?.long_name);
    setValue(`${prefix}country`, country?.short_name);
    setValue(`${prefix}province`, state?.short_name);
    setValue(`${prefix}locality`, city?.long_name);
    setValue(`${prefix}postalCode`, zip?.long_name);
    trigger(`${prefix}address1`);
    trigger(`${prefix}country`);
    trigger(`${prefix}province`);
    trigger(`${prefix}locality`);
    trigger(`${prefix}postalCode`);
  };

  const onBackClick = () => {
    setTab(steps[activeStepNumber - 1]);
    setTimeout(() => {
      trigger();
    }, 50);
  };

  const onCancelClick = () => {
    setIsConfirmationDialogOpen(true);
  };

  const onConfirmCancel = () => {
    setIsConfirmationDialogOpen(false);
    navigate("/organizations");
  };

  const onCancelCancel = () => {
    setIsConfirmationDialogOpen(false);
  };

  const onContinueClick = () => {
    setTab(steps[activeStepNumber + 1]);
  };

  const { mutateAsync, isLoading } = useAdminOrganizationPost();

  const onSaveClick = async (formValues) => {
    try {
      const filesPromises = files?.length
        ? await Promise.all(
            //@ts-ignore
            files.map((file) => {
              if (file instanceof File) {
                const promise = uploadMediaUsingPresignedUrl(file);
                console.log("PROMISE", promise);
                return promise;
              } else {
                return file.mediaId;
              }
            })
          )
        : undefined;
      const primaryContactId = formValues?.primaryContact?.personId;
      const secondaryContactId = formValues?.secondaryContact?.personId;
      const sports = formValues.sportsFields.map((field) => {
        return cleanObject(field);
      });
      let billingAddress = "",
        shippingAddress = "";
      const officeAddress = `${formValues.address1}|${
        formValues.address2 || ""
      }|${formValues.locality}|${formValues.province}|${
        formValues.postalCode
      }|${formValues.country}`;
      if (isBillingSame) {
        billingAddress = officeAddress;
      } else {
        billingAddress = `${formValues.billingAddress.address1}|${
          formValues.billingAddress.address2 || ""
        }|${formValues.billingAddress.locality}|${
          formValues.billingAddress.province
        }|${formValues.billingAddress.postalCode}|${
          formValues.billingAddress.country
        }`;
      }
      if (isShippingSame) {
        shippingAddress =
          formValues.shippingSameAs === "BILLING"
            ? billingAddress
            : officeAddress;
      } else {
        shippingAddress = `${formValues.shippingAddress.address1}|${
          formValues.shippingAddress.address2 || ""
        }|${formValues.shippingAddress.locality}|${
          formValues.shippingAddress.province
        }|${formValues.shippingAddress.postalCode}|${
          formValues.shippingAddress.country
        }`;
      }
      const data = {
        abbreviation: formValues.abbreviation,
        country: formValues.country,
        email: formValues.email,
        lines: [formValues.address1, formValues.address2].filter(
          (line) => !!line
        ),
        billingAddress: billingAddress,
        shippingAddress: shippingAddress,
        governingBodyId: formValues.governingBodyId,
        locality: formValues.locality,
        name: formValues.name,
        offering: formValues.offering,
        phone: `${formValues.phone}`,
        postalCode: formValues.postalCode,
        primaryContact: formValues.primaryContact,
        primaryContactId,
        providerId: formValues.providerId,
        province: formValues.province,
        secondaryContact: formValues.secondaryContact,
        secondaryContactId,
        sports: sports,
        type: formValues.type,
        versionId: formValues.versionId,
        website: formValues.website,
        avatarId: filesPromises ? filesPromises[0] : formValues.avatarId,
        revenueShare: parseFloat(formValues.revenueShare),
        ...(accountId && {
          accountId: accountId
        })
      };

      const response = await mutateAsync({ data });
      navigate(`/organizations/${response.data?.organizationId}`);
      enqueueSnackbar("Organization saved successfully.", {
        variant: "success"
      });
    } catch (e) {
      enqueueSnackbar("Something went wrong. Please try again.", {
        variant: "error"
      });
      Sentry.captureException(e);
    }
  };

  const getPrimaryAction = () => {
    if (activeStepNumber < steps.length - 1) {
      return onContinueClick;
    } else {
      return handleSubmit(onSaveClick);
    }
  };

  const selectPrimaryContact = (contact) => {
    setValue("primaryContact", contact);
  };

  const selectSecondaryContact = (contact) => {
    setValue("secondaryContact", contact);
  };
  const [isFileValid, setIsFileValid] = useState(true);
  const [isContactValid, setIsContactValid] = useState(false);

  const [isBillingSame, setIsBillingSame] = useState(false);
  const [isShippingSame, setIsShippingSame] = useState(false);
  console.log("IMAZ__", isValid);
  return (
    <Container>
      <Toolbar title="Add Organization" />
      <Form>
        <Loader isLoading={isFetchingCountries}>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <StepProgressLine
                steps={[
                  "Organization Details",
                  "Sports Details",
                  "Contacts Details"
                ]}
                activeStepNumber={activeStepNumber}
                onEditClick={(active) => {
                  setTab(steps[active]);
                  setTimeout(() => {
                    trigger();
                  }, 50);
                }}
              />
            </Grid>
            {tab === "Organization Details" && (
              <Grid xs={12}>
                <OrganizationDetailsForm
                  disabled={false}
                  isEditing={false}
                  control={control}
                  isBillingSame={!!isBillingSame}
                  isShippingSame={!!isShippingSame}
                  setIsBillingSame={setIsBillingSame}
                  setIsShippingSame={setIsShippingSame}
                  filesValid={(val) => {
                    setIsFileValid(val);
                  }}
                  providerId={providerId}
                  country={country}
                  selectAddressSuggestion={selectAddressSuggestion}
                  countries={countries}
                  setValue={setValue}
                  onMediaUpload={(file) => {
                    setFiles(file);
                  }}
                  isFetchingCountries={isFetchingCountries}
                  type={type}
                  updatedFiles={files}
                  trigger={trigger}
                />
              </Grid>
            )}
            {tab === "Sports Details" && (
              <Grid xs={12}>
                <OrganizationSportsForm
                  disabled={false}
                  control={control}
                  country={country}
                  allowGoverningBodyBySport={allowGoverningBodyBySport}
                  sportsOffered={sportsOffered}
                  isGovernedByOrg={isGovernedByOrg}
                  setValue={setValue}
                />
              </Grid>
            )}
            {tab === "Contacts Details" && (
              <Grid xs={12}>
                <OrganizationContactForm
                  disabled={false}
                  control={control}
                  country={country}
                  setValue={setValue}
                  hasSecondaryContact={hasSecondaryContact}
                  selectPrimaryContact={selectPrimaryContact}
                  primaryContact={primaryContact}
                  selectSecondaryContact={selectSecondaryContact}
                  contactValid={(val) => {
                    setIsContactValid(val);
                  }}
                  secondaryContact={secondaryContact}
                />
              </Grid>
            )}
          </Grid>
        </Loader>
      </Form>
      <Footer
        additionalBtns={[
          activeStepNumber > 0 ? (
            <Button
              variant="admin-secondary"
              type="button"
              onClick={onBackClick}
              isLoading={isLoading || isFetchingCountries}
            >
              Back
            </Button>
          ) : null,
          <Button
            key={1}
            variant="admin-secondary"
            type="button"
            onClick={onCancelClick}
            isLoading={isLoading || isFetchingCountries}
          >
            Cancel
          </Button>,
          <Button
            key={2}
            variant="admin-primary"
            type="button"
            onClick={getPrimaryAction()}
            disabled={
              !isValid ||
              !isFileValid ||
              (activeStepNumber === steps.length - 1 && !isContactValid)
            }
            isLoading={isLoading || isFetchingCountries}
          >
            {activeStepNumber < steps.length - 1 ? "Continue" : "Save"}
          </Button>
        ]}
        isDisabled={!isValid}
        isLoading={isLoading || isFetchingCountries}
      />
      <DevTool control={control} />
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        onConfirm={onConfirmCancel}
        onCancel={onCancelCancel}
      />
    </Container>
  );
};
