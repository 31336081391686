import { Quill } from "react-quill";

export const extractTextFromDelta = (delta) => {
  if (!Array.isArray(delta)) {
    return [];
  }
  return delta
    .filter((op) => typeof op.insert === "string") // Keep only string inserts
    .map((op) => op.insert); // Extract the text
};
export const extractHTMLFromDelta = (delta) => {
  if (!delta) return "<p></p>";
  const tempQuill = new Quill(document.createElement("div"));
  tempQuill.setContents(delta);
  return tempQuill.root.innerHTML;
};
