import React, { useEffect, useState } from "react";
import { Avatar, Box, Typography, List } from "@mui/material";
import { LocalOfferOutlined, LocationOnOutlined } from "@mui/icons-material";
import { ModelMedia } from "@sportsgravyengineering/sg-api-react-sdk";
import { organizationsAtom } from "@recoil/auth";
import GoogleMap from "@components/GoogleMap";
import Grid from "@mui/system/Unstable_Grid";
import { hasPermission } from "@services/Casbin";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";

export const ClipMediaDetails = ({ media }: { media: ModelMedia }) => {
  const [permissions, setPermissions] = useState({
    viewLs: false,
    viewTeamTp: false,
    viewUser: false,
    viewOrg: false
  });

  const [addressToView, setAddressToView] = useState<string | undefined>();
  const orgId = useRecoilValue(organizationAtom);
  const orgs = useRecoilValue(organizationsAtom);
  const currOrg = orgs.find((org) => org.organizationId === orgId);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        orgId!,
        permissionId as string,
        permission as string
      );
      return res;
    };

    const fetchPermissions = async () => {
      const viewTeam = await checkPermission("team.teams", "VIEW");
      const viewLs = await checkPermission("organization.post", "ON");
      const viewTp = await checkPermission(
        "training-programs.training-programs",
        "VIEW"
      );
      const viewUser = await checkPermission("user.users", "VIEW");
      const viewOrg = await checkPermission("general.organization", "VIEW");
      setPermissions({
        viewTeamTp: media?.clipOf?.teamId ? viewTeam : viewTp,
        viewUser,
        viewOrg,
        viewLs
      });
    };
    fetchPermissions();
  }, [media]);

  const sharedWithData = [
    ...((
      media.clipOf?.post?.shareTo as {
        connectionType: string;
        connectionTypeId: string;
      }[]
    )?.find(
      (st) =>
        st.connectionType === "ORGANIZATION" && st.connectionTypeId === orgId
    )
      ? [
          {
            name: currOrg?.name,
            type: "Organization",
            img:
              currOrg?.avatar?.baseUrl &&
              currOrg?.avatar?.baseUrl + currOrg?.avatar?.path,
            id: orgId
          }
        ]
      : []),
    ...(media.teams || []).map((t) => ({
      name: t.name,
      id: t.teamId,
      type: "Team",
      img:
        currOrg?.avatar?.baseUrl &&
        currOrg?.avatar?.baseUrl + currOrg?.avatar?.path
    })),
    ...(media.trainingPrograms || []).map((tp) => ({
      name: tp.name,
      id: tp.programId,
      type: "Training Program",
      img:
        currOrg?.avatar?.baseUrl &&
        currOrg?.avatar?.baseUrl + currOrg?.avatar?.path
    }))
  ];

  const AvatarName = ({
    details: {
      avatar,
      name,
      type,
      initialsText,
      redirectUrl,
      colorCode,
      hasAccess
    }
  }: {
    details: {
      avatar?: ModelMedia;
      redirectUrl?: string;
      name: string;
      type?: string;
      initialsText?: string;
      colorCode?: string;
      hasAccess: boolean;
    };
  }) => {
    return (
      <Box display="flex" mt={1} alignItems="center">
        {avatar && avatar.baseUrl ? (
          <img src={avatar.baseUrl + avatar.path} width="32px" height="32px" />
        ) : (
          <Avatar
            style={{
              backgroundColor: colorCode || "#F54748",
              color: "white",
              fontSize: "12px",
              borderRadius: "8px",
              fontWeight: 700
            }}
          >
            {initialsText}
          </Avatar>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          <Typography
            style={{
              color: redirectUrl && hasAccess ? "#007AFF" : "#000",
              fontWeight: 400,
              marginLeft: "10px",
              cursor: redirectUrl && hasAccess ? "pointer" : "default",
              fontSize: "14px"
            }}
            onClick={() =>
              redirectUrl && hasAccess && window.open(redirectUrl, "_blank")
            }
          >
            {name}
          </Typography>
          {type && (
            <Typography
              style={{
                color: "#64748B",
                fontWeight: 400,
                marginLeft: "10px",
                fontSize: "12px"
              }}
            >
              {type}
            </Typography>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        padding: "16px",
        borderRadius: "8px",
        backgroundColor: "#F7F9FC"
      }}
    >
      <Box style={{ flex: 1 }}>
        <Typography
          style={{
            fontWeight: 700,
            color: permissions.viewLs ? "#007AFF" : "#000",
            cursor: permissions.viewLs ? "pointer" : "default",
            fontSize: "20px"
          }}
          onClick={() =>
            permissions.viewLs &&
            window.open(`/live-stream/${media.clipOfId}`, "_blank")
          }
        >
          {media.clipOf?.title}
        </Typography>
        <AvatarName
          details={{
            avatar: currOrg?.avatar,
            name:
              media.clipOf?.team?.name ||
              (media.clipOf?.trainingProgram?.name as string),
            type: media.clipOf?.team ? "Team" : "Training Program",
            redirectUrl: media.clipOf?.team
              ? `/teams/${media.clipOf?.teamId}`
              : `/training-programs/${media.clipOf?.programId}`,
            colorCode: media.clipOf?.createdBy?.person?.colorCode,
            hasAccess: permissions.viewTeamTp
          }}
        />
      </Box>
      <Grid container sx={{ display: "flex", marginTop: "16px" }}>
        <Grid xs={6}>
          <Typography
            style={{
              color: "#0F0F0F",
              fontWeight: 600,
              fontSize: "14px"
            }}
          >
            {new Date(media.createdAt!).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric"
            })}
          </Typography>
          {media.clipOf?.sport && (
            <Typography
              style={{
                color: "#0F0F0F",
                fontWeight: 400,
                fontSize: "14px",
                marginTop: "5px"
              }}
            >
              {media.clipOf?.sport.name}
            </Typography>
          )}

          <Box
            display="flex"
            alignItems="center"
            mt={1}
            sx={{
              cursor: "pointer"
            }}
            onClick={() => setAddressToView(media.clipOf?.location)}
          >
            <LocationOnOutlined />
            <Typography
              style={{
                color: "#007AFF",
                fontWeight: 400,
                marginLeft: "10px",
                fontSize: "14px"
              }}
            >
              {media.clipOf?.location}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" mt={1}>
            <LocalOfferOutlined />
            <Typography
              style={{
                color: "#0F0F0F",
                fontWeight: 400,
                marginLeft: "10px"
              }}
            >
              {media.tags && media.tags.length > 0
                ? media?.tags.join(", ")
                : "No Tags Provided"}
            </Typography>
          </Box>
          {media.createdBy && (
            <Box mt={2}>
              <Typography style={{ color: "#0F0F0F", fontWeight: 400 }}>
                Created By
              </Typography>

              <AvatarName
                details={{
                  avatar: media.createdBy.person?.avatar,
                  name:
                    media.createdBy.person?.firstName +
                    " " +
                    media.createdBy.person?.lastName,
                  colorCode: media.createdBy.person?.colorCode,
                  initialsText: `${media.createdBy.person?.firstName?.[0]} ${media.createdBy.person?.lastName?.[0]}`,
                  redirectUrl: `/users/${media.createdBy.personId}`,
                  hasAccess: permissions.viewUser
                }}
              />
            </Box>
          )}
        </Grid>
        <Grid
          xs={6}
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <Box style={{ flex: 1, marginLeft: "32px" }}>
            <Typography style={{ color: "#0F0F0F", fontWeight: 400 }}>
              Shared with
            </Typography>

            <Box
              style={{
                maxHeight: "200px",
                overflowY: "auto"
              }}
            >
              <List dense>
                {sharedWithData.map((item, index) => (
                  <AvatarName
                    key={index}
                    details={{
                      avatar: currOrg?.avatar,
                      name: item.name!,
                      type: item.type,
                      redirectUrl:
                        item.type === "Team"
                          ? `/teams/${item.id}`
                          : item.type === "Organization"
                          ? `/organizations/${item.id}`
                          : `/training-programs/${item.id}`,
                      hasAccess:
                        item.type === "Team"
                          ? permissions.viewTeamTp
                          : item.type === "Organization"
                          ? permissions.viewOrg
                          : true
                    }}
                  />
                ))}
              </List>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {addressToView && (
        <GoogleMap
          close={() => setAddressToView(undefined)}
          address={addressToView}
          showSearchBar={false}
          showClickedAddress={false}
          allowMarkerDrag={false}
        />
      )}
    </Box>
  );
};
