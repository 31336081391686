import React, { useEffect, useMemo, useState } from "react";
import { Button } from "@components/Button";
import {
  Autocomplete,
  Box,
  Container,
  MenuItem,
  Modal,
  Popover,
  Select,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import CloseIcon from "@mui/icons-material/Close";
import { getCalendarEvent, getCreatePostSport } from "@services/Network";
import { Controller, useForm } from "react-hook-form";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import EventTimeIcon from "@assets/icons/eventTimeIcon.svg";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import {
  DatePicker,
  LocalizationProvider,
  TimeField
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LiveStreamIcon from "../../../src/assets/icons/liveIconRed.svg";
import DescriptionIcon from "../../../src/assets/icons/descriptionIcon.svg";
import { ShareWith } from "./components/ShareWith";
import { CalendarLocationSearch } from "./components/CalendarLocationSearch";
import {
  AdminCalendarEventIdPutBody,
  AdminCalendarPostBody,
  ModelOrganizationEvent,
  ModelPerson,
  ModelSetting,
  ModelUser,
  adminSportLocationPost,
  useAdminCalendarEventIdPut,
  useAdminCalendarPost,
  useAdminSettingsGet,
  useAdminTeamGet,
  useAdminTrainingProgramGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { LiveStreamShareWith } from "./components/LiveStreamShareWith";
import { CalendarRecurrence } from "./components/CalendarRecurrence";
import { useSnackbar } from "notistack";
import { RRule, datetime } from "rrule";
import { useNavigate } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import colors from "theme/colors";
import EventNoteIcon from "@mui/icons-material/EventNote";
import EditCalendarTwoToneIcon from "@mui/icons-material/EditCalendarTwoTone";
import { hasPermission } from "@services/Casbin";
import { FormCheckbox } from "@components/FormCheckbox";
import { ToolTip } from "@components/ToolTip";
import InfoIcon from "@assets/icons/info.svg";
import { capitalizeFirstCharacter } from "@utils/capitalize";

const PostBtn = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "8px 20px",
  gap: "8px",
  width: "100px",
  height: "40px",
  backgroundColor: theme.palette.info.main,
  borderRadius: "100px",
  color: theme.palette.white.main,
  textTransform: "none",

  "&:hover": {
    backgroundColor: theme.palette.info.main,
    opacity: 0.8
  }
}));
const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "16px",
  width: "100%",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "0%"
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px"
  },
  "& fieldset": {
    border: "none !important"
  },
  "& svg[data-testid='CalendarIcon'], svg[data-testid='ClockIcon']": {
    display: "none"
  },
  "& .time-picker button, .date-picker button": {
    display: "none"
  },
  "& input, select": {
    color: "#2B337A",
    "&:active": {
      color: "#000"
    }
  },
  "& .timezone .MuiInputBase-root": {
    padding: 0
  },
  "& .timezone .MuiInputBase-root::before, .recurrence .MuiInputBase-root::before, .shareWith .MuiInputBase-root::before":
    {
      borderBottom: "none !important"
    },
  "& .recurrence .MuiSelect-select, .sport .MuiSelect-select, .MuiFormControlLabel-label":
    {
      color: "#2B337A"
    },
  "& .sport .MuiSelect-select": {
    paddingTop: "0px",
    paddingBottom: "0px"
  },
  "& .shareWith .MuiInputBase-input::placeholder": {
    fontSize: "16px",
    color: "#64748B",
    fontWeight: 400
  },
  "& .loader": {
    minHeight: "350px"
  },
  "& .MuiAutocomplete-root .loader": {
    minHeight: "0px !important"
  }
}));
const StyledBoxHeader = styled(Box)({
  height: "64px",
  backgroundColor: colors.info.main,
  borderRadius: "16px 16px 0px 0px"
});
const StyledTextField = styled(TextField)(({ error }) => ({
  marginTop: "12px",
  borderBottom: error ? "none" : "1px solid #2B337A",
  fontSize: "20px",
  lineHeight: "20px",
  "& ::placeholder": {
    color: "#2B337A"
  }
}));
const StyledOption = styled("option")({
  fontSize: "13px",
  padding: "5px 10px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#E5E5E5"
  }
});
const StyledButton = styled("button")(() => ({
  backgroundColor: "#fff",
  color: "#2B337A",
  padding: "8px 12px",
  fontSize: "14px",
  fontWeight: "600",
  height: "34px",
  borderRadius: "100px",
  textTransform: "none",
  border: "2px solid #2B337A",
  cursor: "pointer",
  "&:hover": {
    opacity: "0.6"
  }
}));
const StyledFormCheckBox = styled("div")(() => ({
  "& .MuiTypography-formLabel": {
    color: colors.info.main,
    fontWeight: 400
  }
}));
type RequestDataPost = {
  data: AdminCalendarPostBody;
};

type RequestDatePut = {
  data: AdminCalendarEventIdPutBody;
  eventId: string;
};
type RequestData = RequestDataPost | RequestDatePut;
export const CalendarEventCreateUpdate = (props: {
  button;
  onClose;
  displayMessage?;
  setMessage?;
  details?;
  reLoadCalendar;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const orgId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const org = organizations.find((o) => o.organizationId === orgId);
  const [sportId, setSportId] = useState("");
  const [selectedContest, setSelectedContest] = useState("GENERAL");
  dayjs.extend(timezone);
  let sportOptions: { label: string; value: string | undefined }[] = [];

  let sportOptionsLoading = false;
  const [interval, setInterval] = useState(15);
  const [date, setDate] = useState<Dayjs | null>(
    dayjs(props.details.startDate)
  );
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [recurrence, setRecurrence] = useState("none");
  const [recurrenceCustomText, setRecurrenceCustomText] = useState("none");
  const [repeat, setRepeat] = useState({});
  const [contestOptions, setContestOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [isLiveStream, setIsLiveStream] = useState(props.details.isLiveStream);
  const [persons, setPersons] = useState<ModelPerson[] | ModelUser[]>([]);
  const [connections, setConnections] = useState([]);
  const [addLocationLoading, setAddLocationLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [anchorEl1, setAnchorEl1] = React.useState<HTMLButtonElement | null>(
    null
  );

  const getEvent = getCalendarEvent(
    props.details.id,
    props.details.recurrenceId
  );

  const roundToNearestInterval = (time: Dayjs, interval: number) => {
    let roundedMinute = Math.ceil(time.minute() / interval) * interval;
    if (roundedMinute >= 60) {
      time = time.add(1, "hour");
      roundedMinute -= 60;
    }
    return time.minute(roundedMinute).startOf("minute");
  };

  const [startTime, setStartTime] = useState<Dayjs | null>(
    roundToNearestInterval(dayjs(props.details.startDate), interval)
  );

  const [endTime, setEndTime] = useState<Dayjs | null>(() => {
    if (props.details.action === "create") {
      return roundToNearestInterval(
        props.details.view === "month"
          ? dayjs(props.details.endDate).add(interval, "minute")
          : dayjs(props.details.endDate),
        interval
      );
    } else {
      return dayjs(props.details.endDate);
    }
  });

  const {
    data: settingTimeInterval,
    isLoading: isLoadingSettingsTimeInterval
  } = useAdminSettingsGet({
    organizationId: orgId!,
    parentId: "general"
  });

  const { data: team, isLoading: teamsLoading } = useAdminTeamGet({
    organizationId: orgId!
  });
  const { data: trainingProgram, isLoading: tpLoading } =
    useAdminTrainingProgramGet({
      organizationId: orgId!
    });

  useEffect(() => {
    if (settingTimeInterval?.data) {
      const settingsOrg = settingTimeInterval.data as ModelSetting[];
      const timeIntervalSetting = settingsOrg.find(
        (s) => s.settingId === "general.default-time-picker-interval"
      );
      if (timeIntervalSetting) {
        const orgSetting =
          timeIntervalSetting.organizationSettings &&
          timeIntervalSetting.organizationSettings.length > 0
            ? timeIntervalSetting.organizationSettings.find(
                (o) => o.organizationId === orgId
              )?.value
            : timeIntervalSetting.value || timeIntervalSetting.default;
        if (orgSetting) {
          const numericValue = parseInt(orgSetting.match(/\d+/)[0], 10);
          const unit = orgSetting.match(/[A-Z]+/)[0];

          if (unit === "MIN") {
            setInterval(numericValue);
          } else if (unit === "H") {
            setInterval(numericValue * 60);
          }
        }
      }
    }
  }, [settingTimeInterval]);

  useEffect(() => {
    setStartTime(
      roundToNearestInterval(dayjs(props.details.startDate), interval)
    );
    if (props.details.action === "create") {
      setEndTime(
        roundToNearestInterval(
          props.details.view === "month"
            ? dayjs(props.details.endDate).add(interval, "minute")
            : dayjs(props.details.endDate),
          interval
        )
      );
    }
  }, [interval]);

  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl1);
  const id = open ? "simple-popover" : undefined;
  const id1 = open ? "simple-popover1" : undefined;
  const handlePopover = (event: React.MouseEvent<HTMLButtonElement>, type) => {
    if (type == "start") setAnchorEl(event.currentTarget);
    if (type == "end") setAnchorEl1(event.currentTarget);
  };

  const form = useForm({
    mode: "onBlur",
    defaultValues: {
      isLiveStream: false,
      isPublic: false,
      name: "",
      description: "",
      sportId: "",
      eventType: "GENERAL",
      startDate: date,
      endTime: endTime,
      startTime: startTime,
      timezone: dayjs.tz.guess(),
      repeat: "none",
      locationId: org!.locationId,
      shareTo: "",
      isHomeTeam: true,
      shareWithTeam_Program: "shareWithTeam"
    }
  });

  useEffect(() => {
    if (getEvent?.data && !getEvent.isLoading) {
      const data: ModelOrganizationEvent =
        getEvent.data as ModelOrganizationEvent;
      const defaultValue = {
        name: data.name,
        isLiveStream: data.streamId ? true : false,
        isPublic: !!data.liveStream?.isPublic,
        description: data.description,
        sportId: data?.sportId || "",
        shareTo: data?.teamId || data?.programId || "",
        startDate: dayjs(data.start),
        endTime: dayjs(data.end),
        startTime: dayjs(data.start),
        timezone: data.timezone,
        locationId: data.locationId,
        private: !data.streamId && !data.isPublic ? true : false,
        eventType: data?.eventType || "GENERAL",
        isSharedWithOrg: !!data.liveStream?.isSharedWithOrg,
        shareWithTeam_Program: data.liveStream?.isSharedWithOrg
          ? "isSharedWithOrg"
          : "shareWithTeam",
        isHomeTeam: !!data.isHomeTeam,
        repeat: "none"
      };
      //@ts-ignore
      form.reset(defaultValue);
      setSelectedContest(data?.eventType ? data.eventType : "GENERAL");
      setIsLiveStream(data.streamId ? true : false);
      if (data.repeat != "" && data.repeat) {
        const recurrenceString = data.repeat;
        const regex = /FREQ=([A-Z]+)/;
        const match = recurrenceString.match(regex);
        const frequency = match ? match[1] : null;
        const rrule = RRule.fromString(data.repeat);
        if (
          rrule.origOptions.byweekday ||
          (rrule.origOptions.interval || 0) > 1
        ) {
          form.setValue("repeat", "custom", { shouldValidate: true });
          setRecurrence("custom");
          setRepeat(rrule.origOptions);
        } else {
          if (frequency == "WEEKLY") {
            form.setValue("repeat", "week", { shouldValidate: true });
            setRecurrenceCustomText("Repeats Every week");
            setRepeat(rrule.origOptions);
          }
          if (frequency == "DAILY") {
            form.setValue("repeat", "day", { shouldValidate: true });
            setRecurrenceCustomText("Repeats Every day");
            setRepeat(rrule.origOptions);
          }
          if (frequency == "MONTHLY") {
            setRecurrenceCustomText("Repeats Every month");
            form.setValue("repeat", "month", { shouldValidate: true });
            setRepeat(rrule.origOptions);
          }
          setRecurrence(frequency as string);
        }
      }
      if (data.attendees) {
        setPersons(data.attendees);
      }
      //@ts-ignore
      const transformedPrograms = data.programs.map((program) => ({
        name: program.name,
        id: program.programId,
        description: "Training Program",
        orgOwned: !!program.organizationId,
        kind: program.$kind
      }));
      //@ts-ignore
      const teams = data.teams.map((team) => ({
        name: team.name,
        id: team.teamId,
        description: "Team",
        orgOwned: !!team.organizationId,
        kind: team.$kind
      }));
      //@ts-ignore
      const transformedConnectionGroups = data.connectionGroups
        .map((group) => {
          if (group.team) {
            return {
              name: group.team.name,
              description: `${group.name} > ${group.team.name}`,
              id: group.groupId,
              orgOwned: !!group.team.organizationId,
              kind: "Team"
            };
          } else if (group.program) {
            return {
              name: group.name,
              description: `${group.program.name} > ${group.name}`,
              id: group.groupId,
              orgOwned: !!group.program.organizationId,
              kind: group.$kind
            };
          }
        })
        .flat();

      const combinedData = [
        ...teams,
        ...transformedPrograms,
        ...transformedConnectionGroups
      ];
      //@ts-ignore
      setConnections(combinedData);
    }
  }, [getEvent?.data]);

  const sport = getCreatePostSport({ organizationId: orgId! });

  sportOptions = useMemo(
    () =>
      sport?.data.map((sport) => ({
        label: sport.name!,
        value: sport.sportId
      })) || [],
    [sport!.data]
  );
  sportOptionsLoading = sport!.isLoading;

  useEffect(() => {
    const contestOptions = [
      {
        label: "General Event (No LiveStream)",
        value: "GENERAL"
      },
      ...(org?.offering?.includes("TEAMS")
        ? [
            { label: "Game / Match", value: "GAME" },
            { label: "Scrimmage", value: "SCRIMMAGE" },
            { label: "Practice", value: "PRACTICE" }
          ]
        : []),
      ...(org?.offering?.includes("TRAINING_PROGRAMS")
        ? [
            {
              label: "Training Session",
              value: "TRAINING_SESSION"
            }
          ]
        : []),
      { label: "Athletic Event", value: "EVENT" }
    ];
    setContestOptions(contestOptions);
  }, [org]);

  useEffect(() => {
    if (
      props.details.action === "create" &&
      !sportOptionsLoading &&
      sportOptions &&
      sportOptions.length == 1
    ) {
      form.setValue("sportId", sportOptions[0].value as string);
    }
  }, [sportOptions]);

  const checkPermissionForTeamProgram = async (teamOrProgram, id) => {
    const res = await hasPermission(
      teamOrProgram,
      id!,
      "organization.post",
      "ON"
    );
    return res;
  };

  const shareToOptions = useMemo(() => {
    if (selectedContest !== "TRAINING_SESSION") {
      const teamOptions =
        (team?.data.teams &&
          team?.data?.teams
            .filter(
              async (team) =>
                team.sportId === sportId ||
                (sportId === "" &&
                  (await checkPermissionForTeamProgram("TEAM", team.teamId)))
            )
            .map((team) => ({
              label: team.name!,
              value: team.teamId!,
              data: team,
              kind: "Team"
            }))) ||
        [];
      return [...teamOptions];
    } else {
      const trainingProgramOptions =
        (trainingProgram?.data.trainingPrograms &&
          trainingProgram?.data?.trainingPrograms
            .filter(
              async (trainingProgram) =>
                trainingProgram.sportId === sportId ||
                (sportId === "" &&
                  (await checkPermissionForTeamProgram(
                    "TRAINING_PROGRAM",
                    trainingProgram!.programId
                  )))
            )
            .map((trainingProgram) => ({
              label: trainingProgram.name!,
              value: trainingProgram.programId!,
              kind: "TrainingProgram",
              data: trainingProgram
            }))) ||
        [];

      return [...trainingProgramOptions];
    }
  }, [team, trainingProgram, sportId, selectedContest]);

  useEffect(() => {
    if (shareToOptions.length == 1 && props.details.action === "create") {
      form.setValue("shareTo", shareToOptions?.[0]?.value);
      if (["GAME", "SCRIMMAGE", "EVENT"].includes(selectedContest))
        form.setValue("name", `${shareToOptions?.[0]?.label} vs `, {
          shouldDirty: false
        });
      // setConnections([shareToOptions[0]]);
    }
  }, [shareToOptions]);

  const getFormattedTime = (hour, minute) => {
    return dayjs().hour(hour).minute(minute).format("hh:mm A");
  };
  const generateTimeOptions = (type) => {
    const today = dayjs();
    const timeOptions: string[] = [];
    let startHour = 0;
    let startMinute = 0;

    if (type === "start") {
      if (date && date.isSame(today, "day")) {
        startHour = today.hour();
        startMinute = Math.ceil(today.minute() / interval) * interval;
      }
      for (let hour = startHour; hour < 24; hour++) {
        for (let minute = startMinute; minute < 60; minute += interval) {
          timeOptions.push(getFormattedTime(hour, minute));
        }
        startMinute = 0;
      }
    } else if (type === "end") {
      const startHour = startTime!.hour();
      const startMinute = startTime!.minute();
      let minute = startMinute + interval;
      let hour = startHour;
      for (; hour < 24; hour++) {
        for (; minute < 60; minute += interval) {
          timeOptions.push(getFormattedTime(hour, minute));
        }
        minute = startMinute;
      }
    }

    return timeOptions;
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl1(null);
  };
  const timeZoneOptions = Intl.supportedValuesOf("timeZone").map((key) => {
    return {
      label: key,
      value: key
    };
  });
  const generateAdminSportsLocationRequest = (
    addressComponents,
    orgId: string
  ) => {
    let name = "";
    let locality = "";
    let province = "";
    let postalCode = "";
    let country = "";

    const streetNumber = addressComponents.find((component) =>
      component.types.includes("street_number")
    );
    const route = addressComponents.find((component) =>
      component.types.includes("route")
    );
    const city = addressComponents.find((component) =>
      component.types.includes("locality")
    );
    const state = addressComponents.find((component) =>
      component.types.includes("administrative_area_level_1")
    );
    const postalCodeComponent = addressComponents.find((component) =>
      component.types.includes("postal_code")
    );
    const countryComponent = addressComponents.find((component) =>
      component.types.includes("country")
    );
    if (streetNumber && route) {
      name = `${streetNumber.long_name} ${route.long_name}`;
    } else if (route) {
      name = route.long_name;
    }
    if (city) locality = city.long_name;
    if (state) province = state.long_name;
    if (postalCodeComponent) postalCode = postalCodeComponent.long_name;
    if (countryComponent) country = countryComponent.short_name;
    const lines = [name];
    return {
      organizationId: orgId,
      name,
      lines,
      locality,
      province,
      postalCode,
      country
    };
  };
  const { mutate: save, isLoading: isSaving } =
    props.details.action == "create"
      ? useAdminCalendarPost()
      : useAdminCalendarEventIdPut();

  const saveHandler =
    (resetInsteadOfRoute = false, viewLiveStream = false) =>
    async (formValues) => {
      const values = {
        ...formValues,
        organizationId: orgId
      };
      if (!isLiveStream) {
        //@ts-ignore
        values.attendees = [];
        persons.map((person) => {
          if (person.person) {
            values["attendees"].push({ userId: person.person.userId });
          } else {
            values["attendees"].push({ userId: person.userId });
          }
        });
        if (connections.length > 0) {
          values.programs = [];
          values.teams = [];
          values.connectionGroups = [];
          connections.map(
            (connection: {
              id: string;
              name: string;
              description: string;
              kind: string;
              orgOwned: boolean;
            }) => {
              if (connection.kind == "TrainingProgram")
                values["programs"].push(connection.id);
              if (connection.kind == "Team")
                values["teams"].push(connection.id);
              if (connection.kind == "ConnectionGroup")
                values["connectionGroups"].push(connection.id);
            }
          );
        }
        if (connections.length == 0) {
          delete values["programs"];
          delete values["teams"];
          delete values["connectionGroups"];
        }
        delete values["shareWithTeam_Program"];
      }

      if (isLiveStream) {
        if (connections.length > 0) {
          //@ts-ignore
          values.programs = [];
          //@ts-ignore
          values.teams = [];
          //@ts-ignore
          connections.map(
            (connection: { kind: string; value?: string; id?: string }) => {
              if (connection.kind == "TrainingProgram")
                values["programs"].push(connection.value || connection.id);
              if (connection.kind == "Team")
                values["teams"].push(connection.value || connection.id);
            }
          );
        }
        delete values["attendees"];
        if (values["shareWithTeam_Program"] == "isSharedWithOrg")
          values["isSharedWithOrg"] = true;
        if (values["shareWithTeam_Program"] != "isSharedWithOrg")
          values["isSharedWithOrg"] = false;
      }

      if (values["shareTo"] && values["shareTo"] !== "") {
        if (selectedContest !== "TRAINING_SESSION")
          values["teamId"] = values["shareTo"];
        else values["programId"] = values["shareTo"];
      }
      delete values["shareTo"];

      if (values["sportId"] === "") delete values["sportId"];

      if (selectedContest === "GENERAL" && values["private"] == true)
        values["isPublic"] = false;
      else if (!isLiveStream) values["isPublic"] = true;
      delete values["private"];

      if (values["eventType"] === "GENERAL") delete values["eventType"];

      if (!["GAME", "SCRIMMAGE", "EVENT"].includes(selectedContest))
        delete values["isHomeTeam"];

      const endHour = endTime!.get("hours");
      const endMin = endTime!.get("minutes");
      if (recurrenceCustomText != "none")
        values["repeat"] = RRule.optionsToString(repeat);
      if (recurrenceCustomText == "none" || recurrence == "none")
        values["repeat"] = "";
      if (endHour == 0 && endMin == 0) {
        values["end"] = endTime
          ?.set("date", date?.get("date") as number)
          .add(1, "day")
          .toISOString();
      } else {
        values["end"] = endTime
          ?.set("date", date?.get("date") as number)
          .toISOString();
      }
      values["start"] = startTime
        ?.set("date", date?.get("date") as number)
        .toISOString();
      delete values["startDate"];
      delete values["endTime"];
      delete values["startTime"];
      if (values["address_components"]) {
        setAddLocationLoading(true);
        const sportLocation = await adminSportLocationPost(
          generateAdminSportsLocationRequest(
            values["address_components"],
            orgId!
          )
        );
        setAddLocationLoading(false);
        //@ts-ignore
        values["locationId"] = sportLocation.data.locationId;
      }
      save(
        {
          //@ts-ignore
          data: values as RequestData,
          ...(props.details.action === "edit"
            ? { eventId: props.details.id as string }
            : {})
        },
        {
          onSuccess: (data) => {
            if (props.details.action == "create") {
              enqueueSnackbar("Event added successfully!", {
                variant: "success"
              });
            } else {
              enqueueSnackbar("Event updated successfully!", {
                variant: "success"
              });
            }
            if (viewLiveStream) {
              navigate(`/live-stream/${data.data.recurrences[0].streamId}`);
            }
            props.reLoadCalendar();
            if (resetInsteadOfRoute) {
              form.reset();
            } else {
              props.onClose();
            }
          },
          onError: () => {
            if (props.details.action == "create") {
              enqueueSnackbar("Failed to add event!", {
                variant: "error"
              });
            } else {
              enqueueSnackbar("Failed to update event!", {
                variant: "error"
              });
            }
          }
        }
      );
    };

  return (
    <Modal open={true}>
      <Container
        sx={{
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
          minWidth: "930px"
        }}
      >
        <StyledBox>
          <StyledBoxHeader>
            <Grid
              container
              style={{
                padding: "18px 20px 20px 32px",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Grid>
                <Typography
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                    fontStyle: "normal",
                    lineHeight: "20px",
                    fontWeight: "600"
                  }}
                >
                  {props.details.title}
                </Typography>
              </Grid>
              <Grid>
                <CloseIcon
                  data-testid="post-comment-close-icon"
                  htmlColor="#fff"
                  style={{ cursor: "pointer", marginLeft: "24px" }}
                  onClick={props.onClose}
                />
              </Grid>
            </Grid>
          </StyledBoxHeader>
          <Loader
            isLoading={
              getEvent?.isLoading ||
              isLoadingSettingsTimeInterval ||
              sport?.isLoading ||
              teamsLoading ||
              tpLoading
            }
          >
            <Box sx={{ margin: "0 24px" }}>
              <div
                style={{
                  display: "flex",
                  marginTop: "20px",
                  alignItems: "center"
                }}
              >
                <EditCalendarTwoToneIcon style={{ fill: "#9FA2AA" }} />
                <Controller
                  name="eventType"
                  control={form.control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      data-testid="add-event-contestType"
                      className="sport"
                      displayEmpty
                      value={field.value}
                      onChange={(e) => {
                        setSelectedContest(e.target.value);
                        field.onChange(e.target.value);
                        if (e.target.value === "GENERAL") {
                          setIsLiveStream(false);
                          form.setValue("isLiveStream", false);
                        }
                      }}
                    >
                      <MenuItem value="" style={{ display: "none" }}>
                        Select Event Type
                      </MenuItem>
                      {contestOptions.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <Controller
                  name="sportId"
                  control={form.control}
                  rules={{
                    required: selectedContest !== "GENERAL"
                  }}
                  render={({ field }) => (
                    <Select
                      data-testid="add-event-sport"
                      className="sport"
                      displayEmpty
                      value={field.value}
                      onChange={(e) => {
                        form.setValue("shareTo", "");
                        setSportId(e.target.value);
                        field.onChange(e.target.value);
                      }}
                      disabled={sportOptions.length == 1}
                    >
                      <MenuItem value="" style={{ display: "none" }}>
                        Select Sport
                      </MenuItem>
                      {sportOptions.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <Controller
                  name="shareTo"
                  control={form.control}
                  rules={{
                    required: selectedContest !== "GENERAL"
                  }}
                  render={({ field }) => (
                    <Select
                      className="sport"
                      value={field.value}
                      data-testid="event-shareTo"
                      displayEmpty
                      disabled={shareToOptions.length == 1}
                      onChange={(e) => {
                        const selectedOption = shareToOptions.find(
                          (option) => option.value === e.target.value
                        );
                        // form.setValue("shareTo", "");
                        if (
                          !form.formState.dirtyFields.name &&
                          ["GAME", "SCRIMMAGE", "EVENT"].includes(
                            selectedContest
                          ) &&
                          props.details.action === "create"
                        ) {
                          form.setValue(
                            "name",
                            `${selectedOption?.label} vs `,
                            {
                              shouldDirty: false
                            }
                          );
                        }
                        form.setValue("shareWithTeam_Program", "shareWithTeam");
                        // setConnections([selectedOption]);
                        field.onChange(e.target.value);
                      }}
                    >
                      <MenuItem value="" selected style={{ display: "none" }}>
                        {selectedContest === "TRAINING_SESSION"
                          ? "Select Training Program"
                          : "Select Team"}
                      </MenuItem>
                      {shareToOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {selectedContest === "GENERAL" && (
                  <StyledFormCheckBox>
                    <FormCheckbox
                      control={form.control}
                      name="private"
                      label="Private Event"
                      tooltip={
                        <ToolTip title="Check this box to keep this event private. It will not be visible to the public and will only appear on the calendars of shared guests.">
                          <img
                            src={InfoIcon}
                            style={{
                              paddingLeft: "8px",
                              height: "20px",
                              color: "#B3B3B3"
                            }}
                          />
                        </ToolTip>
                      }
                    />
                  </StyledFormCheckBox>
                )}
                {["GAME", "SCRIMMAGE", "EVENT"].includes(selectedContest) && (
                  <StyledFormCheckBox>
                    <FormCheckbox
                      control={form.control}
                      name="isHomeTeam"
                      label="Home Team"
                    />
                  </StyledFormCheckBox>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "12px"
                }}
              >
                <EventNoteIcon style={{ fill: "#9FA2AA", marginTop: "12px" }} />
                <Controller
                  name="name"
                  control={form.control}
                  rules={{ required: "Please enter a value" }}
                  render={({ fieldState, field }) => {
                    return (
                      <div
                        style={{ display: "flex", width: "100%" }}
                        data-testid="event-title"
                      >
                        <StyledTextField
                          multiline
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(
                              capitalizeFirstCharacter(event.target.value)
                            );
                            if (!event.target.value)
                              form.setError("name", {
                                message: "Title is required"
                              });
                            else form.clearErrors("name");
                          }}
                          onBlur={() => {
                            if (!field.value)
                              form.setError("name", {
                                message: "Title is required"
                              });
                            else form.clearErrors("name");
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          fullWidth
                          hiddenLabel
                          style={{ marginLeft: "14px" }}
                          variant="standard"
                          placeholder="Add Title"
                          inputProps={{
                            maxLength: 75
                          }}
                        />
                      </div>
                    );
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "12px",
                  maxWidth: "559px",
                  justifyContent: "space-between"
                }}
              >
                <img src={EventTimeIcon} />
                <Controller
                  name="startDate"
                  control={form.control}
                  render={() => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        open={datePickerOpen}
                        value={date}
                        disablePast
                        className="date-picker"
                        onOpen={() => setDatePickerOpen(true)}
                        onClose={() => setDatePickerOpen(false)}
                        onChange={(newValue) => {
                          setStartTime(
                            //@ts-ignore
                            startTime?.set("date", newValue?.get("date"))
                          );
                          setEndTime(
                            //@ts-ignore
                            endTime?.set("date", newValue?.get("date"))
                          );
                          setDate(newValue);
                          form.setValue("startDate", newValue, {
                            shouldDirty: true,
                            shouldValidate: true
                          });
                        }}
                        format="ddd, MMM DD"
                        sx={{ maxWidth: "120px" }}
                        slotProps={{
                          textField: {
                            onClick: () => setDatePickerOpen(true)
                          }
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
                <Controller
                  name="startTime"
                  control={form.control}
                  render={() => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimeField
                        data-testId="startTimeInput"
                        value={startTime}
                        //@ts-ignore
                        onClick={(e) => handlePopover(e, "start")}
                        onChange={(e) => {
                          setStartTime(e);
                          form.setValue("startTime", e, {
                            shouldDirty: true,
                            shouldValidate: true
                          });
                        }}
                        sx={{ width: "90px" }}
                      />
                    </LocalizationProvider>
                  )}
                />{" "}
                <span style={{ color: "#B3B3B3", fontSize: "14px" }}>-</span>
                <Controller
                  name="endTime"
                  control={form.control}
                  render={() => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimeField
                        value={endTime}
                        data-testId="endTimeInput"
                        //@ts-ignore
                        onClick={(e) => handlePopover(e, "end")}
                        sx={{ width: "100px" }}
                        onChange={(e) => {
                          setEndTime(e);
                          form.setValue("endTime", e, {
                            shouldDirty: true,
                            shouldValidate: true
                          });
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
                <Controller
                  name="timezone"
                  control={form.control}
                  render={({ field }) => (
                    <Autocomplete
                      data-testId="timeZoneInput"
                      options={timeZoneOptions}
                      //@ts-ignore
                      value={field.value}
                      onChange={(e, value) => field.onChange(value.value)}
                      isOptionEqualToValue={(option, value) =>
                        //@ts-ignore
                        option.value === value
                      }
                      disableClearable
                      sx={{ minWidth: "200px  " }}
                      //@ts-ignore
                      getOptionLabel={(option) =>
                        option.label ? option.label : option
                      }
                      renderInput={(params) => (
                        <TextField
                          className="timezone"
                          {...params}
                          hiddenLabel
                          variant="standard"
                        />
                      )}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: "559px"
                }}
              >
                <CheckBoxOutlineBlankIcon style={{ opacity: 0 }} />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between"
                  }}
                >
                  <Controller
                    name="repeat"
                    rules={{ required: true }}
                    control={form.control}
                    render={({ field }) => (
                      <Select
                        disabled={isLiveStream}
                        className="recurrence"
                        value={field.value}
                        data-testId="repeatSelect"
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          setRecurrence(e.target.value);
                          if (e.target.value === "none") {
                            setRecurrenceCustomText("");
                            setRepeat({});
                          }
                          if (e.target.value === "day") {
                            const customRepeat = new RRule({
                              freq: RRule["DAILY"],
                              interval: 1,
                              count: 300,
                              dtstart: datetime(
                                date?.year() as number,
                                //@ts-ignore
                                (date?.month() + 1) as number,
                                date?.date() as number,
                                startTime?.get("hour") as number,
                                startTime?.get("minute") as number
                              )
                            });
                            setRepeat(customRepeat.origOptions);
                            setRecurrenceCustomText("Repeats Every day");
                          }
                          if (e.target.value === "week") {
                            setRecurrenceCustomText("Repeats Every week");
                            const customRepeat = new RRule({
                              freq: RRule["WEEKLY"],
                              interval: 1,
                              dtstart: datetime(
                                date?.year() as number,
                                //@ts-ignore
                                (date?.month() + 1) as number,
                                date?.date() as number,
                                startTime?.get("hour") as number,
                                startTime?.get("minute") as number
                              ),
                              count: 300
                            });
                            setRepeat(customRepeat.origOptions);
                          }
                          if (e.target.value === "month") {
                            setRecurrenceCustomText("Repeats Every month");
                            const customRepeat = new RRule({
                              freq: RRule["MONTHLY"],
                              interval: 1,
                              dtstart: datetime(
                                date?.year() as number,
                                //@ts-ignore
                                (date?.month() + 1) as number,
                                date?.date() as number,
                                startTime?.get("hour") as number,
                                startTime?.get("minute") as number
                              ),
                              count: 300
                            });
                            setRepeat(customRepeat.origOptions);
                          }
                          if (e.target.value === "custom") {
                            const customRepeat = new RRule({
                              freq: RRule["DAILY"],
                              interval: 1,
                              dtstart: datetime(
                                date?.year() as number,
                                //@ts-ignore
                                (date?.month() + 1) as number,
                                date?.date() as number,
                                startTime?.get("hour") as number,
                                startTime?.get("minute") as number
                              ),
                              count: 300
                            });
                            setRepeat(customRepeat.origOptions);
                          }
                        }}
                      >
                        <MenuItem value="none">Does not repeat</MenuItem>
                        <MenuItem value="day">Every Day</MenuItem>
                        <MenuItem value="week">Every Week</MenuItem>
                        <MenuItem value="month">Every Month</MenuItem>
                        <MenuItem value="custom">Custom</MenuItem>
                      </Select>
                    )}
                  />
                  <StyledButton
                    onClick={() =>
                      form.setValue("timezone", dayjs.tz.guess(), {
                        shouldDirty: true,
                        shouldValidate: true
                      })
                    }
                  >
                    Use Current Time Zone
                  </StyledButton>
                </div>
              </div>
              {recurrence == "custom" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    maxWidth: "559px"
                  }}
                >
                  <CheckBoxOutlineBlankIcon style={{ opacity: 0 }} />
                  <span
                    style={{
                      fontSize: "13px",
                      color: "#000",
                      fontWeight: 600,
                      marginLeft: "14px"
                    }}
                  >
                    <CalendarRecurrence
                      action={props.details.action}
                      repeat={repeat}
                      setCustomText={setRecurrenceCustomText}
                      startDate={date?.get("date")}
                      fullDate={date?.toDate()}
                      onConfirm={(customText, interval, timeFrame, days) => {
                        const customRepeat = new RRule({
                          freq: RRule[timeFrame],
                          interval: interval,
                          byweekday: timeFrame == "WEEKLY" ? days : [],
                          dtstart: datetime(
                            date?.year() as number,
                            //@ts-ignore
                            (date?.month() + 1) as number,
                            date?.date() as number,
                            startTime?.get("hour") as number,
                            startTime?.get("minute") as number
                          ),
                          count: 300
                        });
                        setRepeat(customRepeat.origOptions);
                      }}
                      onChange={() => {
                        form.setValue("repeat", "custom", {
                          shouldValidate: true,
                          shouldDirty: true
                        });
                      }}
                    />
                  </span>
                </div>
              )}
              <CalendarLocationSearch name="locationId" form={form} />
              {selectedContest !== "GENERAL" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <img src={LiveStreamIcon} />
                  <Typography
                    style={{
                      marginLeft: "14px",
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: 400,
                      color: "#2B337A"
                    }}
                  >
                    Add Live Stream
                  </Typography>
                  <Controller
                    name="isLiveStream"
                    control={form.control}
                    render={({ field }) => (
                      <>
                        <div
                          data-testid="addLiveStreamCheckBox"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "16px"
                          }}
                        >
                          <Typography
                            style={{
                              color: !field.value ? "#64748B" : "#B3B3B3"
                            }}
                          >
                            Off
                          </Typography>
                          <Switch
                            checked={field.value}
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.target.checked);
                              setIsLiveStream(e.target.checked);
                              setRepeat({});
                              setPersons([]);
                              // setConnections([]);
                              setRecurrence("none");
                              form.setValue("repeat", "none");
                            }}
                          />
                          <Typography
                            style={{
                              color: field.value ? "#64748B" : "#B3B3B3"
                            }}
                          >
                            On
                          </Typography>
                        </div>
                      </>
                    )}
                  />
                </div>
              )}
              {!isLiveStream && (
                <ShareWith
                  control={form.control}
                  name={"attendees"}
                  required={false}
                  //@ts-ignore
                  setPersons={setPersons}
                  //@ts-ignore
                  setConnectionGroups={setConnections}
                  onChange={(list) => {
                    form.setValue("attendees", list, {
                      shouldDirty: true,
                      shouldValidate: true
                    });
                  }}
                  connectionGroups={connections}
                  persons={persons}
                  action={props.details.action}
                />
              )}
              {isLiveStream && (
                <LiveStreamShareWith
                  form={form}
                  selectedContest={selectedContest}
                  setConnections={setConnections}
                />
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "15px"
                }}
              >
                <img src={DescriptionIcon} />
                <Controller
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event);
                      }}
                      className="shareWith"
                      style={{ marginLeft: "14px" }}
                      hiddenLabel
                      variant="standard"
                      placeholder="Add Description"
                    />
                  )}
                />
              </div>
            </Box>
            <Box style={{ padding: "0px 32px 5px 32px" }}>
              <Grid container></Grid>
              <Grid
                style={{
                  margin: "30px",
                  display: "flex",
                  gap: "16px",
                  justifyContent: "flex-end"
                }}
              >
                <PostBtn onClick={props.onClose} variant="admin-secondary">
                  Cancel
                </PostBtn>
                <PostBtn
                  disabled={
                    !form.formState.isValid || isSaving || addLocationLoading
                  }
                  onClick={form.handleSubmit(saveHandler(false))}
                  variant="admin-primary"
                  isLoading={isSaving || addLocationLoading}
                >
                  {props.button}
                </PostBtn>
                {isLiveStream && (
                  <PostBtn
                    disabled={
                      !form.formState.isValid || isSaving || addLocationLoading
                    }
                    onClick={form.handleSubmit(saveHandler(false, true))}
                    variant="admin-primary"
                    isLoading={isSaving || addLocationLoading}
                  >
                    Save & View Live Stream
                  </PostBtn>
                )}
              </Grid>
            </Box>
          </Loader>
        </StyledBox>
        <Popover
          style={{ marginLeft: "40px" }}
          id={id}
          open={open}
          anchorEl={anchorEl}
          disableScrollLock={true}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Container style={{ maxHeight: "200px", padding: 0 }}>
            {generateTimeOptions("start").map((time, index) => (
              <StyledOption
                onClick={(e) => {
                  const selectedStartTime = dayjs(
                    //@ts-ignore
                    e.target.value,
                    "hh:mm A"
                    //@ts-ignore
                  ).set("date", date?.get("date"));
                  const selectedEndTime = selectedStartTime
                    .add(interval, "minute")
                    //@ts-ignore
                    .set("date", date?.get("date"));
                  setStartTime(selectedStartTime);
                  setEndTime(selectedEndTime);
                  form.setValue("startTime", selectedStartTime, {
                    shouldDirty: true,
                    shouldValidate: true
                  });
                  setAnchorEl(null);
                }}
                key={index}
                value={time}
              >
                {time}
              </StyledOption>
            ))}
          </Container>
        </Popover>
        <Popover
          style={{ marginLeft: "40px" }}
          id={id1}
          open={open1}
          anchorEl={anchorEl1}
          disableScrollLock={true}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Container style={{ maxHeight: "200px", padding: 0 }}>
            {generateTimeOptions("end").map((time, index) => (
              <StyledOption
                onClick={(e) => {
                  //@ts-ignore
                  setEndTime(dayjs(e.target.value, "hh:mm A"));
                  form.setValue("endTime", dayjs(e.target.value, "hh:mm A"), {
                    shouldDirty: true,
                    shouldValidate: true
                  });
                  setAnchorEl1(null);
                }}
                key={index}
                value={time}
              >
                {time}
              </StyledOption>
            ))}
          </Container>
        </Popover>
      </Container>
    </Modal>
  );
};
