import { ModelMedia } from "@sportsgravyengineering/sg-api-react-sdk";
import { GalleryItem, GalleryItemType } from "./GalleryItem";
import "./styles/gallery.css";
export const Gallery = ({
  items,
  selectedItems,
  onSelect,
  onClick
}: {
  items: GalleryItemType[];
  selectedItems: string[];
  onSelect: (id: string) => void;
  onClick: (id: string, media: ModelMedia) => void;
}) => {
  return (
    <div
      className="gallery-grid"
      style={{
        marginTop: "10px"
      }}
    >
      {items.map((item) => (
        <GalleryItem
          key={item.id}
          item={item}
          isSelected={selectedItems.includes(item.id)}
          onSelect={
            item.mediaDetails?.state === "PUBLISHED" ? onSelect : undefined
          }
          clickToSelect={selectedItems.length > 0}
          onClick={onClick}
        />
      ))}
    </div>
  );
};
