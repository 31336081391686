import {
  Autocomplete,
  Avatar,
  Backdrop,
  Box,
  Container,
  InputAdornment,
  TextField,
  Typography,
  styled
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import colors from "theme/colors";
import { Controller, useForm } from "react-hook-form";
import Grid from "@mui/system/Unstable_Grid";
import { FormInput } from "@components/FormInput";
import { StyledFormLabel } from "@components/StyledFormLabel";
import { Search } from "@mui/icons-material";
import { Footer } from "@components/crud/Footer";
import { useMemo, useState } from "react";
import {
  ModelAlbum,
  ModelPerson,
  useAdminAlbumAlbumIdPut,
  useAdminUserGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { capitalize } from "@utils/capitalize";
import { enqueueSnackbar } from "notistack";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "16px",
  marginLeft: "125px",
  width: "100%",
  maxWidth: "650px",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "0%"
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px"
  },
  "& .footer": {
    borderBottomLeftRadius: "16px",
    borderBottomRightRadius: "16px"
  }
}));

const StyledBoxHeader = styled(Box)({
  height: "64px",
  backgroundColor: colors.info.main,
  borderRadius: "16px 16px 0px 0px"
});

export const EditAlbum = ({
  onClose,
  album
}: {
  onClose: () => void;
  album: ModelAlbum;
}) => {
  const organizationId = useRecoilValue(organizationAtom);
  const [userSearch, setUserSearch] = useState<string | undefined>(undefined);
  const {
    control,
    getValues,
    formState: { isValid }
  } = useForm({
    defaultValues: {
      name: album.name,
      shareWith: album.contributors?.map((c) => {
        return {
          personId: c?.person?.userId,
          name: c?.person?.firstName + " " + c?.person?.lastName,
          role: Array.from(
            new Set(
              c.roles
                ?.map(
                  (role) => role.role?.alias && capitalize(role.role?.alias)
                )
                .filter(Boolean)
            )
          ).join(", "),
          background: "#" + c?.person?.colorCode,
          avatar:
            c.person?.avatar?.baseUrl && c.person?.avatar?.path
              ? c.person.avatar?.baseUrl + c.person.avatar?.path
              : undefined
        };
      })
    }
  });

  const { mutate: editAlbum, isLoading: isSaving } = useAdminAlbumAlbumIdPut();

  const saveHandler = async () => {
    const values = getValues();
    editAlbum(
      {
        albumId: album.albumId!,
        data: {
          name: values.name,
          contributorIds: values.shareWith?.map((s) => s.personId) as string[]
        }
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Album edited successfully", { variant: "success" });
          onClose();
        },
        onError: () => {
          enqueueSnackbar("Album edit failed", { variant: "error" });
        }
      }
    );
  };

  const { data: users, isLoading: userLoading } = useAdminUserGet({
    organizationId: organizationId!,
    textSearch: userSearch,
    pageSize: "100",
    roleAliases: ["ADMIN", "MANAGER", "COACH"]
  });

  const userOptions = useMemo(() => {
    return (users?.data?.persons || [])
      .concat(album.contributors?.map((c) => c?.person as ModelPerson) || [])
      ?.map((user) => ({
        personId: user?.userId,
        name: user?.firstName + " " + user?.lastName,
        role: Array.from(
          new Set(
            user?.user?.roles
              ?.map((role) => role.role?.alias && capitalize(role.role?.alias))
              .filter(Boolean)
          )
        ).join(", "),
        background: "#" + user?.colorCode,
        avatar:
          user?.avatar?.baseUrl && user?.avatar?.path
            ? user.avatar?.baseUrl + user.avatar?.path
            : undefined
      }));
  }, [users]);

  return (
    <Backdrop
      sx={{
        overflow: "hidden",
        overflowY: "none",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={true}
    >
      <Container>
        <StyledBox>
          <StyledBoxHeader>
            <Grid
              container
              style={{
                padding: "18px 20px 20px 32px",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Grid sx={{ maxWidth: "88%" }}>
                <Typography
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                    fontStyle: "normal",
                    lineHeight: "20px",
                    fontWeight: "600"
                  }}
                >
                  Edit Album
                </Typography>
              </Grid>
              <Grid>
                <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />
              </Grid>
            </Grid>
          </StyledBoxHeader>
          <Grid container spacing={3} padding="24px" zIndex="1000">
            <Grid xs={12}>
              <FormInput
                control={control}
                name="name"
                type="text"
                label="Album Name"
                required
                rules={{
                  required: "Album Name is required"
                }}
              />
            </Grid>
            <Grid xs={12}>
              <StyledFormLabel>
                <Typography display="inline" variant="formLabel">
                  Allow other Coaches, Managers, and Administrators to edit this
                  album
                </Typography>
              </StyledFormLabel>
              <Controller
                control={control}
                name="shareWith"
                render={({ field }) => (
                  <Autocomplete
                    freeSolo
                    disableClearable={true}
                    multiple
                    options={userOptions || []}
                    loading={userLoading}
                    loadingText="Loading..."
                    value={field.value}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} style={{ display: "flex", gap: "8px" }}>
                          {option?.avatar ? (
                            <Avatar
                              variant="square"
                              src={option.avatar}
                              style={{
                                height: "32px",
                                width: "32px",
                                borderRadius: "8px"
                              }}
                            />
                          ) : (
                            <Avatar
                              variant="square"
                              style={{
                                height: "32px",
                                width: "32px",
                                borderRadius: "8px",
                                background: option?.background,
                                fontSize: "14px"
                              }}
                            >
                              {option?.name
                                .split(" ")
                                .map((n) => n[0])
                                .join("")}
                            </Avatar>
                          )}
                          <div>
                            <Typography lineHeight="20px">
                              {option?.name}
                            </Typography>
                            <Typography color="#64748B" lineHeight="20px">
                              {option?.role}
                            </Typography>
                          </div>
                        </li>
                      );
                    }}
                    getOptionLabel={(option) =>
                      typeof option === "object" ? option.name : ""
                    }
                    filterSelectedOptions
                    renderInput={(params) => {
                      if (Array.isArray(params.InputProps.startAdornment)) {
                        params.InputProps.startAdornment.splice(
                          0,
                          0,
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        );
                      } else {
                        params.InputProps.startAdornment = (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        );
                      }
                      return (
                        <TextField
                          {...params}
                          onChange={(e) => setUserSearch(e.target.value)}
                        />
                      );
                    }}
                    onChange={(e, value) => {
                      field.onChange(value);
                    }}
                    slotProps={{
                      paper: {
                        style: {
                          borderRadius: "8px",
                          border: "1px solid #E5E5E5",
                          marginTop: "8px",
                          boxShadow: "0px 1px 2px -1px #0000001A"
                        }
                      }
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Footer
            cancelBtnClick={onClose}
            saveBtnClick={saveHandler}
            isDisabled={!isValid || isSaving}
            isLoading={isSaving}
          />
        </StyledBox>
      </Container>
    </Backdrop>
  );
};
