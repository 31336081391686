import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { Form } from "@components/crud/Form";
import { Footer } from "@components/crud/Footer";
import {
  ContactResponse,
  getAdminCrmAccountAccountIdContact,
  ModelOpportunity,
  ModelOrder,
  useAdminCrmOrderOrderIdGet,
  useAdminCrmOrderOrderIdPut,
  useConfigGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import formatFullName from "@utils/formatFullName";
import { OrderDetailsForm } from "./OrderDetailsForm";
import { FullName } from "@utils/types";
import { enqueueSnackbar } from "notistack";
import { hasPermission } from "@services/Casbin";
import { Tier } from "@pages/settings/CRMSetting";
import { ORDER_BILLING_OPTIONS } from "@utils/constants";

export const OrderEdit = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const form = useForm({
    mode: "onBlur"
  });

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const edit = await checkPermission("crm.orders", "EDIT");
      if (!edit) navigate("/not-found");
    };
    fetchPermissions();
  }, []);
  const {
    reset,
    getValues,
    formState: { isDirty, isValid }
  } = form;
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedContact, setSelectedContact] = useState("");
  const [contacts, setContacts] = useState<
    {
      label: string;
      value: string;
      isPrimary: boolean;
      details: ContactResponse;
      jobTitle: string;
      workEmail: string;
      workPhone: string;
    }[]
  >([]);
  const { data: orderData, isLoading: isLoading } = useAdminCrmOrderOrderIdGet(
    orderId!
  );
  const { data: settings, isLoading: isLoadingConfig } = useConfigGet();
  const [pricingTier, setPricingTier] = useState<Tier[]>([]);
  const [startDateRequirement, setStartDateRequirement] = useState<number>();
  const [billingOptions, setBillingOptions] = useState<
    { label: string; value: string; description?: string }[]
  >([]);
  useEffect(() => {
    if (settings && settings.data) {
      const priceTiersList = (settings.data.find(
        (item) => item.key === "crm.order.pricing-tier"
      )?.value || []) as Tier[];
      setPricingTier(priceTiersList);
      const startDateRequirement = settings.data.find(
        (item) => item.key === "crm.order.start-date.after-order-date"
      )?.value as number;

      setStartDateRequirement(startDateRequirement);

      const billingOptions = [] as {
        label: string;
        value: string;
        description?: string;
      }[];
      if (
        settings.data.find(
          (item) => item.key === "crm.order.billing-option.yearly-monthly"
        )?.value
      )
        billingOptions.push(ORDER_BILLING_OPTIONS[0]);
      if (
        settings.data.find(
          (item) =>
            item.key === "crm.order.billing-option.yearly-monthly-per-athlete"
        )?.value
      )
        billingOptions.push(ORDER_BILLING_OPTIONS[1]);
      if (
        settings.data.find(
          (item) => item.key === "crm.order.billing-option.upfront"
        )?.value
      )
        billingOptions.push(ORDER_BILLING_OPTIONS[2]);
      setBillingOptions([...billingOptions]);
    }
  }, [settings]);
  useEffect(() => {
    if (orderData?.data) {
      const order = orderData.data as ModelOrder & {
        opportunity: ModelOpportunity;
      };
      reset({
        submittedBy: formatFullName(order.submittedBy?.person as FullName),
        orderDate: new Date(order.date),
        product: order.product,
        orderType: order.type,
        noOfAthletes: order.initialNumberOfAthletes,
        costOfAthletesPeryear: `$ ${order.ratePerAthlete} Per Athlete, Per Registration`,
        amount: order.totalAmount,
        billingOption: order.billingType,
        startDate: order.revisedStartDate
          ? new Date(order.revisedStartDate)
          : new Date(order.originalStartDate),
        endDate: order.revisedEndDate
          ? new Date(order.revisedEndDate)
          : new Date(order.originalEndDate),
        notes: order.notes,
        account: {
          name: order.account?.name || "",
          parent: order.account?.parent?.name || "",
          category: order.account?.category,
          type: order.account?.type,
          officeAddress: order.account?.officeAddress,
          email: order.account?.email,
          website: order.account?.website,
          sportsOffered: order.account?.sports?.map((s) => s.sportId) || [],
          noOfAthletes: order.account?.numberOfAthletes,
          aeOwner: order.account?.aeOwner?.person
            ? formatFullName(order.account?.aeOwner?.person)
            : ""
        },
        contact: {
          firstName: order.contact?.firstName,
          lastName: order.contact?.lastName,
          personalEmail: order.contact?.email,
          homeAddress: order.contact?.location,
          phoneType: "MOB",
          phone: order.contact?.phone,
          whatsappNumber: order.contact?.whatsappNumber
        }
      });
      setSelectedAccount(order.accountId || "");
    }
  }, [orderData, pricingTier]);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const { mutate: save, isLoading: isSaving } = useAdminCrmOrderOrderIdPut();
  const onSave = () => {
    const startDateState = form.getFieldState("startDate");
    const data = {
      numberOfAthletes: parseInt(getValues().noOfAthletes),
      yearlyAmount: parseFloat(getValues().amount),
      ratePerAthlete: orderData?.data?.ratePerAthlete,
      ...(startDateState.isDirty
        ? {
            revisedStartDate: getValues().startDate,
            revisedEndDate: getValues().endDate
          }
        : {
            revisedStartDate: orderData?.data?.revisedStartDate,
            revisedEndDate: orderData?.data?.revisedEndDate
          }),
      billingType: getValues().billingOption,
      ...(getValues().notes && {
        notes: getValues().notes
      }),
      accountId: selectedAccount,
      contactId: selectedContact
    };
    save(
      {
        data: data,
        orderId: orderId!
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Order Edited successfully!", {
            variant: "success"
          });
          navigate("/crm/orders");
        },
        onError: () => {
          enqueueSnackbar("Failed to Edit Order!", {
            variant: "error"
          });
        }
      }
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedAccount && orderData?.data) {
        try {
          const data = await getAdminCrmAccountAccountIdContact(
            selectedAccount
          );
          setContacts(
            data.data.map((c) => ({
              label: `${c.contact.firstName} ${c.contact.lastName}`,
              value: c.contactId as string,
              isPrimary: !!c.isPrimary,
              details: c.contact as ContactResponse,
              jobTitle: c.jobTitle || "",
              workEmail: c.workEmail || "",
              workPhone: c.workPhone || ""
            }))
          );
          if (orderData.data.accountId !== selectedAccount) {
            const primaryOrFirstContact =
              data.data.find((c) => c.isPrimary)?.contactId ||
              data.data?.[0]?.contactId;

            setSelectedContact(primaryOrFirstContact || "");
          } else {
            setSelectedContact(orderData.data.contactId!);
          }
        } catch (error) {
          setContacts([]);
        }
      } else setSelectedContact("");
    };
    fetchData();
  }, [selectedAccount, orderData]);

  return (
    <Container>
      <Toolbar title={`Edit Order: ${orderId}`} />
      <Loader isLoading={isLoading || isLoadingConfig}>
        <Form>
          <OrderDetailsForm
            form={form}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            selectedContact={selectedContact}
            setSelectedContact={setSelectedContact}
            contacts={contacts}
            setContacts={setContacts}
            billingOptions={billingOptions}
            startDateRequirement={startDateRequirement}
          />
        </Form>
      </Loader>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={() => {
          onSave();
        }}
        isDisabled={!isDirty || !isValid}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/crm/orders")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
