import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import MediaContianerWithDelete from "@components/MediaContaierWithDelete";
import {
  Box,
  Typography,
  styled,
  FormLabel,
  Autocomplete,
  TextField,
  InputAdornment,
  MenuItem,
  Grid as MuiGrid
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  useAdminSportGet,
  useLookupCountryCountryIdSubdivisionGet,
  useAdminTeamGet,
  useAdminTrainingProgramGet,
  useTagSearchGet,
  AdminLiveStreamPostRequest,
  useAdminLiveStreamPost,
  useTagGet,
  useConfigGet,
  useAdminSettingsGet,
  ModelSetting,
  mediaGet,
  useAdminSportLocationGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ThumbnailPickerImage from "@assets/images/thumbnailPicker.png";
import { FormSelect } from "@components/FormSelect";
import { useRecoilValue } from "recoil";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { FormDatePicker } from "@components/FormDatePicker";
import { FormCheckbox } from "@components/FormCheckbox";
import { AddIcon } from "@components/Icons";
import { FormTimePicker } from "@components/FormTimePicker";
import GoogleMap from "@components/GoogleMap";
import { parseISO } from "date-fns";
import { getCountries } from "@services/Network";
import { uploadMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { Loader } from "@components/crud/Loader";
import { LocationOnOutlined, Search } from "@mui/icons-material";
import { SearchInput } from "@components/SearchInput";
import colors from "theme/colors";
import { capitalize } from "@utils/capitalize";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));
const StyledDiv = styled("div")(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("md")]: {
    width: "100%"
  }
}));
const FormInputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const StyledMediaContainer = styled(Grid)`
  .media-container img {
    background: #e5e5e5;
  }
`;

export const LiveStreamCreate = () => {
  const timeZoneOptions = Intl.supportedValuesOf("timeZone").map((key) => {
    return {
      label: key,
      value: key
    };
  });
  const organizationId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const organization = organizations.find(
    (org) => org.organizationId === organizationId
  );
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openChangeAddress, setopenChangeAddress] = useState(false);
  const [preEvent, setPreEvent] = useState(false);
  const [postEvent, setPostEvent] = useState(false);
  const [tagSearch, setTagSearch] = useState("");
  const [sportId, setSportId] = useState<string | undefined>(undefined);
  const [teamId, setTeamId] = useState<string>();
  const [opponent, setOpponent] = useState<string>();
  const [isHomeTeam, setIsHomeTeam] = useState(false);
  const [programId, setProgramId] = useState<string>();
  const [contestType, setContestType] = useState();
  const [loading, setLoading] = useState(false);
  const [nameCharacterCount, setNameCharacterCount] = useState(0);
  const [descCharacterCount, setDescCharacterCount] = useState(0);
  const [isScheduleToday, setIsScheduleToday] = useState(true);
  const [eventStartTime, setEventStartTime] = useState(new Date());
  const [eventEndTime, setEventEndTime] = useState(new Date());
  const [thumbnailLoading, setThumbnailLoading] = useState(false);
  const [settingThumbnailId, setSettingThumbnailId] = useState<
    string | undefined
  >(undefined);
  const [thumbnailDefaultImages, setThumbnailDefaultImages] = useState<unknown>(
    []
  );
  const [file, setFile] = useState<File | null>(null);
  const [allowPublicSharing, setAllowPublicSharing] = useState(false);
  const [allowPreGame, setAllowPreGame] = useState(false);
  const [allowPostGame, setAllowPostGame] = useState(false);
  const [timeInterval, setTimeInterval] = useState(15);
  const [defaultThumbnailUrl, setDefaultThumbnailUrl] = useState<
    string | undefined
  >(undefined);

  const [sportLocationInputValue, setSportLocationInputValue] =
    useState<string>("");

  const [sportLocation, setSportLocation] = useState<string | undefined>(
    undefined
  );

  const { data: sports, isLoading: sportOptionsLoading } = useAdminSportGet({
    organizationId: organizationId!
  });
  const { data: countries, isLoading: countryLoading } = getCountries({
    staleTime: Infinity
  });
  const { data: provinces, isLoading: provinceLoading } =
    useLookupCountryCountryIdSubdivisionGet(organization?.country as string, {
      query: {
        enabled: !!organization?.country
      }
    });

  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name as string,
        value: sport.sportId
      })) || [],
    [sports]
  );

  const contestTypeOptions = useMemo(() => {
    const options = [
      {
        label: "Game",
        value: "GAME"
      },
      {
        label: "Scrimmage",
        value: "SCRIMMAGE"
      },
      {
        label: "Practice",
        value: "PRACTICE"
      },
      ...(organization?.offering?.includes("TRAINING_PROGRAMS")
        ? [
            {
              label: "Training Session",
              value: "TRAINING_SESSION"
            }
          ]
        : []),
      {
        label: "Athletic Event",
        value: "EVENT"
      }
    ];
    if (sportId && sports?.data?.length) {
      const sport = sports?.data.find((sp) => sp.sportId === sportId);
      const isMatch = sport?.countries?.find(
        (c) => c.countryId === organization?.country || "US"
      )?.isMatch;
      if (isMatch) options[0].label = "Match";
    }
    return options;
  }, [sportId, sports]);
  const { data: team, isLoading: teamLoading } = useAdminTeamGet({
    organizationId: organizationId!,
    pageSize: 1000
  });
  const { data: trainingProgram, isLoading: trainingProgramLoading } =
    useAdminTrainingProgramGet({
      organizationId: organizationId!,
      pageSize: 1000
    });

  const shareToOptions = useMemo(() => {
    const orgOption = [
      {
        label: "My Organization",
        value: organizationId!
      },
      ...(contestType === "TRAINING_SESSION"
        ? [
            {
              label: "Training Program",
              value: "TRAINING_PROGRAM"
            }
          ]
        : [
            {
              label: "Team",
              value: "TEAM"
            }
          ])
    ];
    return [...orgOption];
  }, [teamId, programId, contestType]);

  const teamOptions = useMemo(() => {
    const teamOptions =
      (team?.data.teams &&
        team?.data?.teams.map((team) => ({
          label: team.name!,
          value: team.teamId,
          data: team
        }))) ||
      [];
    return [...teamOptions];
  }, [team]);

  const programOptions = useMemo(() => {
    const trainingProgramOptions =
      (trainingProgram?.data.trainingPrograms &&
        trainingProgram?.data?.trainingPrograms.map((trainingProgram) => ({
          label: trainingProgram.name!,
          value: trainingProgram.programId,
          data: trainingProgram
        }))) ||
      [];

    return [...trainingProgramOptions];
  }, [trainingProgram]);

  const { data: tags } = useTagSearchGet({ q: tagSearch });
  const { data: sportTags } = useTagGet({ sportIds: [sportId!] });
  const tagsOptions = useMemo(() => {
    const sportTag =
      (sportTags?.data?.popularTags &&
        sportTags?.data?.popularTags.length > 0 &&
        sportTags?.data?.popularTags.map((tag) => ({
          label: tag.name!
        }))) ||
      [];
    const tag =
      (tags?.data &&
        tags?.data?.map((tag) => ({
          label: tag.name!
        }))) ||
      [];
    return [...tag, ...sportTag];
  }, [tags, sportTags]);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const getOrgAddress = (): string => {
    const province = provinces?.data.find(
      (province) => province.subdivisionId == organization?.province
    );
    const country = countries?.find(
      (country) => country?.countryId == organization?.country
    );
    if (organization?.lines && province && country) {
      return `${organization?.lines[0]}\n${
        organization?.lines[1] ? organization.lines[1] + "\n" : ""
      }${organization?.locality}\n${
        province.name
      } ${organization?.postalCode}\n${country.name}`;
    }

    return "";
  };
  const {
    handleSubmit,
    control,
    formState: { isValid },
    reset,
    setValue,
    getValues
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: "",
      countries: "",
      scheduledDate: parseISO(new Date().toISOString()),
      scheduledTime: parseISO(new Date().toISOString()),
      timeZone: "",
      location: "",
      locationCheckbox: true,
      status: "NOT_STARTED",
      sportId: "",
      tags: [],
      postEventAt: "",
      preEventAt: "",
      description: "",
      preEvent: false,
      postEvent: false,
      shareTo: "",
      preEventEndAt: parseISO(new Date().toISOString()),
      postEventEndAt: "",
      scheduledEndAt: "",
      isPublic: !organizationId ? true : false,
      organization: organization?.name || "SportsGravy LLC",
      teamId: undefined as string | undefined,
      programId: undefined as string | undefined,
      icon: "",
      title: "",
      opponent: ""
    }
  });
  useEffect(() => {
    if (!provinceLoading && !countryLoading) {
      setValue("location", getOrgAddress());
    }
  }, [provinceLoading, countryLoading]);

  const {
    data: sgDefaultLivestreamThumbnail,
    isLoading: sgDefaultLivestreamThumbnailLoading
  } = useConfigGet();

  const { mutate: save, isLoading: isSaving } = useAdminLiveStreamPost();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    async (formValues) => {
      for (const key in formValues) {
        if (formValues[key] === null) {
          delete formValues[key];
        }
      }
      const values: AdminLiveStreamPostRequest = {
        ...formValues,
        ...(organizationId && { organizationId: organizationId })
      };
      if (sportLocation) {
        delete values["location"];
        delete values["lat"];
        delete values["lng"];
        values["sportLocationId"] = sportLocation;
      } else {
        values["location"] = sportLocationInputValue;
      }
      if (file && !file.mediaId) {
        setLoading(true);
        const promise = await uploadMediaUsingPresignedUrl(file);
        values["thumbnailId"] = promise;
      } else if (settingThumbnailId && defaultThumbnailUrl)
        values["thumbnailId"] = settingThumbnailId;
      const scheduleEndTime = new Date(
        new Date(formValues.scheduledEndAt)
      ).getTime();
      const date = new Date(new Date(formValues.scheduledDate)).toISOString();
      values["scheduledAt"] = new Date(
        new Date(formValues.scheduledDate).setHours(
          new Date(formValues.scheduledTime).getHours(),
          new Date(formValues.scheduledTime).getMinutes(),
          0,
          0
        )
      );
      values["scheduledEndAt"] = new Date(
        new Date(date).setTime(scheduleEndTime)
      );
      if (preEvent === false) {
        delete values["preEventAt"];
        delete values["preEventEndAt"];
      }
      if (postEvent === false) {
        delete values["postEventAt"];
        delete values["postEventEndAt"];
      }
      if (preEvent === true) {
        values["preEventEndAt"] = values["scheduledAt"];
        values["preEventAt"] = new Date(
          new Date(date).setTime(new Date(formValues.preEventAt).getTime())
        );
      }
      if (postEvent === true) {
        values["postEventAt"] = values["scheduledEndAt"];
        values["postEventEndAt"] = new Date(
          new Date(date).setTime(new Date(formValues.postEventEndAt).getTime())
        );
      }
      const index = shareToOptions.findIndex(
        (obj) => obj.value === getValues("shareTo")
      );
      if (!organizationId) {
        values["shareTo"] = [];
      } else {
        if (index == 0) {
          values["shareTo"] = [];
        } else {
          values["shareTo"] = [
            {
              connectionType: shareToOptions[index].value,
              connectionTypeId:
                getValues("shareTo") === "TEAM" ? teamId : programId
            }
          ];
        }
      }
      try {
        save(
          {
            data: values
          },
          {
            onSuccess: () => {
              setLoading(false);
              enqueueSnackbar("Live Stream added successfully!", {
                variant: "success"
              });
              if (resetInsteadOfRoute) {
                reset();
                setFile(null);

                if (inputFileRef.current) {
                  inputFileRef.current.value = "";
                }
              } else {
                navigate("/live-stream");
              }
            },
            onError: () => {
              setLoading(false);
              enqueueSnackbar("Failed to add live stream!", {
                variant: "error"
              });
            }
          }
        );
      } catch (error) {
        enqueueSnackbar("Failed to generate image data!", {
          variant: "error"
        });
      }
    };

  const onImageChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field
  ) => {
    const tempFiles = (event.target as HTMLInputElement).files;
    field.onChange(event);
    if (tempFiles && tempFiles[0]) setFile(tempFiles[0]);
  };

  const selectAddressSuggestion = (address_components) => {
    const addressComponents = address_components || [];
    const premise = addressComponents.find((c) => c.types.includes("premise"));
    const streetNumber = addressComponents.find((c) =>
      c.types.includes("street_number")
    );
    const route = addressComponents.find((c) => c.types.includes("route"));
    const establishment = addressComponents.find((c) =>
      c.types.includes("establishment")
    );
    const plus_code = address_components.find((c) =>
      c.types.includes("plus_code")
    );
    const country = addressComponents.find((c) => c.types.includes("country"));
    const state = addressComponents.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    const city = addressComponents.find(
      (c) => c.types.includes("locality") || c.types.includes("sublocality")
    );
    const zip = addressComponents.find((c) => c.types.includes("postal_code"));
    return (
      (premise ? premise.long_name + " , " : "") +
      (streetNumber ? streetNumber.long_name + " " : "") +
      (route ? route.long_name + " , " : "") +
      (establishment ? establishment.long_name + " , " : "") +
      (plus_code ? plus_code.long_name + "," : "") +
      (city?.long_name ? city.long_name + " " : "") +
      (zip?.long_name ? zip.long_name + " , " : "") +
      (state?.long_name ? state.long_name + " , " : "") +
      (country?.long_name ? country.long_name : "")
    );
  };

  const closeAddressChange = () => {
    setopenChangeAddress(false);
  };
  const onAddressChanges = (changedAddress) => {
    setValue("location", selectAddressSuggestion(changedAddress), {
      shouldValidate: true
    });
    setSportLocationInputValue(selectAddressSuggestion(changedAddress));
    setSportLocation(undefined);
    setValue("locationCheckbox", false);
  };

  useEffect(() => {
    if (sportOptions && sportOptions.length > 0)
      if (sportOptions.length == 1) {
        setSportId(sportOptions?.[0]?.value as string);
        setValue("sportId", sportOptions?.[0]?.value as string);
        if (Array.isArray(thumbnailDefaultImages)) {
          const defaultImage = thumbnailDefaultImages.find(
            (sp) => sp.sportId === sportId
          );
          if (defaultImage) {
            setSettingThumbnailId(defaultImage.mediaId);
          }
        }
      } else {
        if (sgDefaultLivestreamThumbnail?.data) {
          const defaultImage = sgDefaultLivestreamThumbnail?.data?.find(
            (defThumb) =>
              defThumb.key === "live-stream.default-live-stream-image"
          )?.value as string;
          if (defaultImage) {
            setSettingThumbnailId(defaultImage);
          }
        }
      }
  }, [sportOptions, thumbnailDefaultImages, sgDefaultLivestreamThumbnail]);

  useEffect(() => {
    if (teamOptions.length == 1) {
      setTeamId(teamOptions?.[0]?.value);
      setValue("teamId", teamOptions?.[0]?.value);
    }
  }, [teamOptions]);

  useEffect(() => {
    if (programOptions.length == 1) {
      setTeamId(programOptions?.[0]?.value);
      setValue("programId", programOptions?.[0]?.value);
    }
  }, [programOptions]);

  useEffect(() => {
    if (teamId && teamOptions.length > 0) {
      const teamName = teamOptions.find((t) => t.value === teamId)?.label;
      const title = `${isHomeTeam ? teamName : opponent || ""} vs ${
        isHomeTeam ? opponent || "" : teamName
      }`;
      setValue("title", title);
      setNameCharacterCount(title.length);
    } else if (programId && programOptions.length > 0) {
      const programName = programOptions.find(
        (t) => t.value === programId
      )?.label;
      const title = `${
        organization?.abbreviation || "SG"
      } ${programName} Training Session`;
      setValue("title", title);
      setNameCharacterCount(title.length);
    }
  }, [teamId, programId, isHomeTeam, opponent]);

  const hasOpposing = () => {
    if (
      !contestType ||
      contestType === "TRAINING_SESSION" ||
      contestType === "PRACTICE"
    )
      return false;
    return true;
  };

  const { data: settings, isLoading: isLoadingSettings } = !organizationId
    ? useConfigGet()
    : useAdminSettingsGet({
        organizationId: organizationId!,
        parentId: "org-live-stream"
      });

  const {
    data: settingTimeInterval,
    isLoading: isLoadingSettingsTimeInterval
  } = !organizationId
    ? useConfigGet()
    : useAdminSettingsGet({
        organizationId: organizationId!,
        parentId: "general"
      });

  useEffect(() => {
    if (settingTimeInterval && organizationId) {
      const settingsOrg = settingTimeInterval.data as ModelSetting[];
      const timeIntervalSetting = settingsOrg.find(
        (s) => s.settingId === "general.default-time-picker-interval"
      );
      const timeZoneSettings = settingsOrg.find(
        (s) => s.settingId === "general.org-local-timezone"
      );
      if (timeIntervalSetting) {
        const orgSetting =
          timeIntervalSetting.organizationSettings &&
          timeIntervalSetting.organizationSettings.length > 0
            ? timeIntervalSetting.organizationSettings.find(
                (o) => o.organizationId === organizationId
              )?.value
            : timeIntervalSetting?.value || timeIntervalSetting.default;
        if (orgSetting) {
          const numericValue = parseInt(orgSetting.match(/\d+/)[0], 10);
          const unit = orgSetting.match(/[A-Z]+/)[0];

          if (unit === "MIN") {
            setTimeInterval(numericValue);
          } else if (unit === "H") {
            setTimeInterval(numericValue * 60);
          }
        }
      }
      if (timeZoneSettings) {
        const orgSetting =
          timeZoneSettings.organizationSettings &&
          timeZoneSettings.organizationSettings.length > 0
            ? timeZoneSettings.organizationSettings.find(
                (o) => o.organizationId === organizationId
              )?.value
            : timeZoneSettings?.value || timeZoneSettings.default;
        if (orgSetting) setValue("timeZone", orgSetting);
      }
    }
  }, [settingTimeInterval]);

  useEffect(() => {
    if (settings && organizationId) {
      const settingsOrg = settings.data as ModelSetting[];
      const publicSharingSetting = settingsOrg.find(
        (s) => s.settingId === "org-live-stream.allow-public-sharing"
      );
      const preGameSetting = settingsOrg.find(
        (s) => s.settingId === "org-live-stream.allow-pre-game"
      );
      const postGameSetting = settingsOrg.find(
        (s) => s.settingId === "org-live-stream.allow-post-game"
      );
      const thumbnailImages = settingsOrg.find(
        (s) => s.settingId === "org-live-stream.default-live-stream-images"
      );
      if (publicSharingSetting) {
        const orgSetting =
          publicSharingSetting.organizationSettings &&
          publicSharingSetting.organizationSettings.length > 0
            ? publicSharingSetting.organizationSettings.find(
                (o) => o.organizationId === organizationId
              )
            : null;
        if (orgSetting) setAllowPublicSharing(Boolean(orgSetting.value));
      }
      if (preGameSetting) {
        const orgSetting =
          preGameSetting.organizationSettings &&
          preGameSetting.organizationSettings.length > 0
            ? preGameSetting.organizationSettings.find(
                (o) => o.organizationId === organizationId
              )
            : null;
        if (orgSetting) setAllowPreGame(Boolean(orgSetting.value));
      }
      if (postGameSetting) {
        const orgSetting =
          postGameSetting.organizationSettings &&
          postGameSetting.organizationSettings.length > 0
            ? postGameSetting.organizationSettings.find(
                (o) => o.organizationId === organizationId
              )
            : null;
        if (orgSetting) setAllowPostGame(Boolean(orgSetting.value));
      }
      if (thumbnailImages) {
        const orgSetting =
          thumbnailImages.organizationSettings &&
          thumbnailImages.organizationSettings.length > 0
            ? thumbnailImages.organizationSettings.find(
                (o) => o.organizationId === organizationId
              )
            : null;
        if (orgSetting && Array.isArray(orgSetting.value))
          setThumbnailDefaultImages(orgSetting.value);
      }
    }
  }, [settings]);

  useEffect(() => {
    if (
      sportId &&
      sports?.data &&
      !getValues().icon &&
      Array.isArray(thumbnailDefaultImages)
    ) {
      const defaultImage = thumbnailDefaultImages.find(
        (sp) => sp.sportId === sportId
      );
      if (defaultImage) {
        setSettingThumbnailId(defaultImage.mediaId);
      }
    }
  }, [sportId]);

  const roundToNearestInterval = (date, interval) => {
    const minutes = date.getMinutes();
    const roundedMinutes = Math.ceil(minutes / interval) * interval;

    const roundedDate = new Date(date);
    if (roundedMinutes === 60) {
      // If rounded minutes are 60, it means we need to round up to the next hour
      roundedDate.setHours(roundedDate.getHours() + 1);
      roundedDate.setMinutes(0); // Reset minutes to 0
    } else {
      roundedDate.setMinutes(roundedMinutes); // Set rounded minutes
    }

    return roundedDate;
  };

  useEffect(() => {
    const roundTime = roundToNearestInterval(new Date(), timeInterval);
    setValue("scheduledTime", roundTime);
    setValue("preEventEndAt", roundTime);
  }, [timeInterval]);

  useEffect(() => {
    {
      const fetchMedia = async () => {
        setThumbnailLoading(true);
        if (settingThumbnailId) {
          try {
            const data = await mediaGet({
              mediaIds: [settingThumbnailId]
            });
            if (data.data.media && data.data.media.length > 0) {
              const media = data.data.media[0].media;
              if (media && media.baseUrl && media.path)
                setDefaultThumbnailUrl(media?.baseUrl + media?.path);
              else setDefaultThumbnailUrl(undefined);
            }
            setThumbnailLoading(false);
          } catch (error) {
            setThumbnailLoading(false);
            setDefaultThumbnailUrl(undefined);
          }
        }
      };
      if (settingThumbnailId) {
        fetchMedia();
      }
    }
  }, [settingThumbnailId]);

  const { data: orgSportsLocations } = useAdminSportLocationGet({
    organizationId: organizationId!
  });

  useEffect(() => {
    if (orgSportsLocations && orgSportsLocations.data) {
      const location = orgSportsLocations.data.locations?.find(
        (l) => l.locationId === organization?.locationId
      );
      if (location) {
        setSportLocation(location.locationId);
        setSportLocationInputValue(location?.name || "");
        setValue("location", location?.name || "", {
          shouldValidate: true
        });
      }
    }
  }, [orgSportsLocations]);
  return (
    <Container>
      <Toolbar title="Add Live Stream" />
      <Loader
        isLoading={
          isLoadingSettings ||
          isLoadingSettingsTimeInterval ||
          sgDefaultLivestreamThumbnailLoading
        }
      >
        <Form>
          <Grid data-testid="live-add-form" container spacing={3}>
            <Grid xs={12} md={12} data-testid="live-thumbnail">
              <Controller
                name="icon"
                control={control}
                render={({ field }) => (
                  <FormInputContainer>
                    <StyledFormLabel required>
                      <Typography display="inline" variant="formLabel">
                        {"Thumbnail"}
                      </Typography>
                    </StyledFormLabel>
                    <Grid container spacing={3} xs={12} md={12}>
                      <StyledMediaContainer xs={12} md={4}>
                        <Loader isLoading={thumbnailLoading}>
                          {file ? (
                            <MediaContianerWithDelete
                              onConfirmDelete={() => {
                                if (inputFileRef && inputFileRef.current)
                                  inputFileRef.current.value = "";
                                setFile(null);
                                setValue("icon", null);
                              }}
                              index={0}
                            >
                              <img
                                src={URL.createObjectURL(file)}
                                style={{
                                  width: "300px",
                                  height: "160px",
                                  objectFit: "cover"
                                }}
                              />
                            </MediaContianerWithDelete>
                          ) : defaultThumbnailUrl ? (
                            <MediaContianerWithDelete
                              onConfirmDelete={() => {
                                setSettingThumbnailId(undefined);
                                setDefaultThumbnailUrl(undefined);
                              }}
                              index={0}
                            >
                              <img
                                src={
                                  defaultThumbnailUrl || ThumbnailPickerImage
                                }
                                style={{
                                  width: "300px",
                                  height: "160px",
                                  objectFit: "cover"
                                }}
                                onClick={() => {
                                  inputFileRef.current?.click();
                                }}
                              />
                            </MediaContianerWithDelete>
                          ) : (
                            <img
                              src={ThumbnailPickerImage}
                              style={{
                                width: "300px",
                                height: "160px",
                                objectFit: "contain"
                              }}
                              onClick={() => {
                                inputFileRef.current?.click();
                              }}
                            />
                          )}
                        </Loader>
                      </StyledMediaContainer>
                    </Grid>
                    <input
                      type="file"
                      onChange={(e) => onImageChange(e, field)}
                      ref={inputFileRef}
                      style={{ display: "none" }}
                      accept="image/*"
                    />
                  </FormInputContainer>
                )}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="liveSteam-organization">
              <FormInput
                control={control}
                name="organization"
                type="text"
                label="Organization"
                required={true}
                disabled
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="liveSteam-sport">
              <FormSelect
                control={control}
                required
                name="sportId"
                label="Sport"
                options={sportOptions}
                isLoading={sportOptionsLoading}
                disabled={sportOptions.length == 1}
                onChange={(e) => {
                  setSportId(e.target.value);
                }}
                rules={{
                  required: "Sport is required"
                }}
              />
            </Grid>

            <Grid xs={12} md={6} data-testid="liveSteam-contestType">
              <FormSelect
                control={control}
                required
                name="contestType"
                label="Event Type"
                options={contestTypeOptions}
                // disabled={sportOptions.length == 1}
                onChange={(e) => {
                  if (
                    e.target.value === "TRAINING_SESSION" ||
                    contestType === "TRAINING_SESSION"
                  ) {
                    setValue("teamId", undefined);
                    setValue("programId", undefined);
                  }
                  setContestType(e.target.value);
                }}
                rules={{
                  required: "Event Type is required"
                }}
              />
            </Grid>

            <Grid xs={12} md={6} data-testid="liveSteam-team-program">
              <FormSelect
                control={control}
                required
                name={
                  contestType === "TRAINING_SESSION" ? "programId" : "teamId"
                }
                label={
                  contestType === "TRAINING_SESSION"
                    ? "Training Program"
                    : "Team"
                }
                options={
                  contestType === "TRAINING_SESSION"
                    ? programOptions
                    : teamOptions
                }
                disabled={
                  contestType === "TRAINING_SESSION"
                    ? programOptions.length === 1
                    : teamOptions.length === 1
                }
                onChange={(e) => {
                  if (contestType === "TRAINING_SESSION") {
                    setTeamId(undefined);
                    setProgramId(e.target.value);
                    setValue("shareTo", "TRAINING_PROGRAM");
                  } else {
                    setProgramId(undefined);
                    setTeamId(e.target.value);
                    setValue("shareTo", "TEAM");
                  }

                  if (
                    sportId &&
                    sports?.data &&
                    !getValues().icon &&
                    Array.isArray(thumbnailDefaultImages)
                  ) {
                    const defaultImage = thumbnailDefaultImages.find(
                      (sp) => sp.sportId === sportId
                    );
                    if (defaultImage) {
                      setSettingThumbnailId(defaultImage.mediaId);
                    }
                  }
                }}
                rules={{
                  required:
                    contestType === "TRAINING_SESSION"
                      ? "Training Program is required"
                      : "Team is required"
                }}
              />
              {hasOpposing() && contestType !== "EVENT" && (
                <div>
                  <FormCheckbox
                    control={control}
                    onChange={(e) => {
                      setIsHomeTeam(e.target.checked);
                    }}
                    name="isHomeTeam"
                    label="Home Team"
                  />
                </div>
              )}
            </Grid>
            {hasOpposing() && (
              <Grid xs={12} md={6} data-testid="liveSteam-opponent">
                <FormInput
                  control={control}
                  name="opponent"
                  type="text"
                  onChange={(e) => {
                    setOpponent(
                      (e as ChangeEvent<HTMLInputElement>)?.target.value
                    );
                    setValue(
                      "opponent",
                      (e as ChangeEvent<HTMLInputElement>)?.target.value
                        .charAt(0)
                        .toUpperCase() +
                        (
                          e as ChangeEvent<HTMLInputElement>
                        )?.target.value.slice(1)
                    );
                  }}
                  label={`Opposing ${
                    contestType === "EVENT" ? "Organization" : "Team"
                  }`}
                  required={true}
                  rules={{
                    required: "Opponent is required",
                    maxLength: {
                      value: 50,
                      message: "Opponent Name must not exceed 50 characters"
                    }
                  }}
                />
                <div style={{ textAlign: "end" }}>
                  <Typography style={{ opacity: "50%" }}>
                    {"Character Count Remaining: " +
                      (50 - (opponent?.length || 0))}
                  </Typography>
                </div>
              </Grid>
            )}

            <Grid xs={12} md={6} data-testid="liveSteam-title">
              <FormInput
                control={control}
                name="title"
                type="text"
                label="Title"
                required={true}
                onChange={(e) => {
                  setValue(
                    "title",
                    (e as ChangeEvent<HTMLInputElement>)?.target.value
                      .charAt(0)
                      .toUpperCase() +
                      (e as ChangeEvent<HTMLInputElement>)?.target.value.slice(
                        1
                      )
                  );
                  setNameCharacterCount(
                    (e as ChangeEvent<HTMLInputElement>)?.target?.value
                      ?.length || 0
                  );
                }}
                rules={{
                  required: "Title is required",
                  maxLength: {
                    value: 70,
                    message: "Title must not exceed 70 characters"
                  }
                }}
              />
              <div style={{ textAlign: "end" }}>
                <Typography style={{ opacity: "50%" }}>
                  {"Character Count Remaining: " + (70 - nameCharacterCount)}
                </Typography>
              </div>
            </Grid>
            {/* <Grid xs={12} md={6} data-testid="liveSteam-status">
            <FormSelect
              control={control}
              name="status"
              label="Status"
              required={true}
              disabled={true}
              options={statusoptions}
              rules={{
                required: "Status is required"
              }}
            />
          </Grid> */}
            <Grid xs={12} md={hasOpposing() ? 12 : 6} container direction="row">
              <Grid xs={hasOpposing() ? 6 : 12} data-testid="liveSteam-shareTo">
                <FormSelect
                  control={control}
                  name="shareTo"
                  label="Share With"
                  required={!!organizationId}
                  disabled={!organizationId}
                  options={shareToOptions}
                  isLoading={teamLoading || trainingProgramLoading}
                  rules={
                    organizationId
                      ? {
                          required: "Share With is required"
                        }
                      : {}
                  }
                />
                {allowPublicSharing && (
                  <div style={{ marginTop: "14px" }}>
                    <FormCheckbox
                      control={control}
                      disabled={!organizationId}
                      name="isPublic"
                      label="Make available to the public"
                    />
                  </div>
                )}
              </Grid>
            </Grid>

            <Grid
              xs={12}
              md={6}
              sm={12}
              xl={3}
              data-testid="liveSteam-scheduleDate"
            >
              <FormDatePicker
                name="scheduledDate"
                required
                minDate={new Date()}
                disablePast
                control={control}
                onChange={(e) => {
                  const date = new Date(e);
                  const today = new Date();
                  const isToday = date.toDateString() === today.toDateString();
                  setIsScheduleToday(isToday);
                }}
                label="Event Date"
                rules={{
                  required: "Scheduled Date is required"
                }}
              />
            </Grid>
            <Grid
              xs={12}
              md={6}
              sm={12}
              xl={3}
              data-testid="liveSteam-scheduleTime"
            >
              <FormTimePicker
                name="scheduledTime"
                required
                timeSteps={{ minutes: timeInterval }}
                minTime={
                  isScheduleToday
                    ? new Date(new Date().getTime() + 5 * 60000)
                    : undefined
                }
                control={control}
                label="Event Start Time"
                rules={{
                  required: "Scheduled Time is required"
                }}
                onChange={(e) => {
                  const givenDate = new Date(e);
                  const futureDate = new Date(new Date().getTime() + 5 * 60000);
                  const isGreaterThanFuture = givenDate >= futureDate;
                  if (!isScheduleToday || isGreaterThanFuture) {
                    setValue("preEventEndAt", e);
                    setEventStartTime(e);
                  } else {
                    setValue(
                      "scheduledTime",
                      parseISO(new Date().toISOString())
                    );
                  }
                }}
              />
            </Grid>
            <Grid
              xs={12}
              md={6}
              sm={12}
              xl={3}
              data-testid="liveSteam-scheduleEndTime"
            >
              <FormTimePicker
                control={control}
                timeSteps={{ minutes: timeInterval }}
                required={true}
                name="scheduledEndAt"
                minTime={eventStartTime}
                label="Event End Time"
                rules={{
                  required: "Event End Time is required"
                }}
                onChange={(e) => {
                  const givenDate = new Date(e);
                  const isGreaterThanFuture = givenDate > eventStartTime;
                  if (isGreaterThanFuture) {
                    setValue("postEventAt", e);
                    setEventEndTime(e);
                  } else {
                    setValue("scheduledEndAt", null);
                    setValue("postEventAt", null);
                  }
                }}
              />
            </Grid>
            <Grid
              xs={12}
              md={6}
              sm={12}
              xl={3}
              data-testid="liveSteam-timeZone"
            >
              <FormSelect
                control={control}
                required
                name="timeZone"
                label="Event Time Zone"
                options={timeZoneOptions}
                rules={{
                  required: "Time Zone is required"
                }}
              />
            </Grid>
            <Grid xs={12} container direction={"row"}>
              <Grid
                container
                xs={12}
                md={6}
                sm={12}
                xl={12}
                flexDirection={"row"}
                spacing="0px"
                alignItems={"center"}
              >
                {allowPreGame && (
                  <Grid xl={3} md={12}>
                    <FormCheckbox
                      control={control}
                      name="preEvent"
                      label="Schedule Pre Game"
                      onChange={(e) => {
                        setPreEvent(e.target.checked);
                      }}
                    />
                  </Grid>
                )}
                {preEvent && (
                  <>
                    <Grid xl={3} md={12}>
                      <FormTimePicker
                        timeSteps={{ minutes: timeInterval }}
                        name="preEventAt"
                        control={control}
                        maxTime={eventStartTime}
                        label="Start Time"
                        onChange={(e) => {
                          const givenDate = new Date(e);
                          const isGreaterThanFuture =
                            givenDate > eventStartTime;
                          if (isGreaterThanFuture) {
                            setValue("preEventAt", null);
                          }
                        }}
                        required={preEvent}
                        rules={{
                          required: "Pre Event Time is required"
                        }}
                      />
                    </Grid>
                    <Grid xl={3} md={12}>
                      <FormTimePicker
                        timeSteps={{ minutes: timeInterval }}
                        name="preEventEndAt"
                        control={control}
                        label="End Time"
                        required={preEvent}
                        disabled={true}
                        rules={{
                          required: "Pre Event End Time is required"
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid xs={12} container direction={"row"}>
              <Grid
                container
                xs={12}
                md={6}
                sm={12}
                xl={12}
                flexDirection={"row"}
                spacing="10px"
                alignItems={"center"}
              >
                {allowPostGame && (
                  <Grid xl={3} md={12}>
                    <FormCheckbox
                      control={control}
                      name="postEvent"
                      label="Schedule Post Game"
                      onChange={(e) => {
                        setPostEvent(e.target.checked);
                      }}
                    />
                  </Grid>
                )}
                {postEvent && (
                  <>
                    <Grid xl={3} md={12}>
                      <FormTimePicker
                        timeSteps={{ minutes: timeInterval }}
                        name="postEventAt"
                        control={control}
                        label="Start Time"
                        required={postEvent}
                        disabled={true}
                        rules={{
                          required: "Post Event Time is required"
                        }}
                      />
                    </Grid>
                    <Grid xl={3} md={12}>
                      <FormTimePicker
                        name="postEventEndAt"
                        timeSteps={{ minutes: timeInterval }}
                        control={control}
                        label="End Time"
                        minTime={eventEndTime}
                        required={postEvent}
                        onChange={(e) => {
                          const givenDate = new Date(e);
                          const isGreaterThanFuture = givenDate > eventEndTime;
                          if (!isGreaterThanFuture) {
                            setValue("postEventEndAt", null);
                          }
                        }}
                        rules={{
                          required: "Post Event End Time is required"
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <StyledDiv>
              <Grid xs={12} md={12} xl={12} data-testid="liveSteam-tags">
                <Controller
                  name="tags"
                  control={control}
                  render={() => (
                    <Box>
                      <StyledFormLabel>
                        <Typography display="inline" variant="formLabel">
                          {"Tags"}
                        </Typography>
                      </StyledFormLabel>
                      <Grid
                        spacing={3}
                        sx={{ padding: "0px 0px 12px 0px", marginTop: "4px" }}
                      >
                        <Autocomplete
                          freeSolo
                          disableClearable={true}
                          multiple
                          id="tags-outlined"
                          options={tagsOptions}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <Typography>{option.label}</Typography>
                            </li>
                          )}
                          filterSelectedOptions
                          renderInput={(params) => {
                            if (
                              Array.isArray(params.InputProps.startAdornment)
                            ) {
                              params.InputProps.startAdornment.splice(
                                0,
                                0,
                                <InputAdornment position="start">
                                  <Search />
                                </InputAdornment>
                              );
                            } else {
                              params.InputProps.startAdornment = (
                                <InputAdornment position="start">
                                  <Search />
                                </InputAdornment>
                              );
                            }
                            return <TextField {...params} />;
                          }}
                          onChange={(e, value) => {
                            setValue(
                              "tags",
                              value?.map((v) => v.label)
                            );
                          }}
                          onInputChange={(event, newInputValue) => {
                            setTagSearch(newInputValue);
                          }}
                        />
                      </Grid>
                    </Box>
                  )}
                />
              </Grid>
              <Grid container xs={12} md={12} xl={12}>
                <Grid sx={{ padding: "12px" }} xl={12} md={12}>
                  <Controller
                    name="location"
                    control={control}
                    rules={{ required: "Location is required" }}
                    render={({ fieldState }) => (
                      <FormInputContainer>
                        <StyledFormLabel required>
                          <Typography display="inline" variant="formLabel">
                            {"Location"}
                          </Typography>
                        </StyledFormLabel>
                        <Autocomplete
                          options={
                            orgSportsLocations?.data?.locations?.map(
                              (location) => ({
                                value: location.locationId as string,
                                label: location.name as string,
                                location: location
                              })
                            ) || []
                          }
                          inputValue={sportLocationInputValue}
                          renderInput={(params) => {
                            return (
                              <SearchInput
                                data-testid="SEARCH_LOCATION_INPUT"
                                {...params}
                                {...fieldState}
                                value={sportLocationInputValue}
                                onChange={(e) => {
                                  setSportLocationInputValue(e.target.value);
                                  if (e.target.value === "") {
                                    setSportLocation(undefined);
                                    setValue("locationCheckbox", false);
                                  }
                                  setValue("location", e.target.value, {
                                    shouldValidate: true
                                  });
                                }}
                                placeholder="Search Sport Location"
                                clearable={sportLocationInputValue !== ""}
                                onClearClick={() => {
                                  setSportLocationInputValue("");
                                  setSportLocation(undefined);
                                  setValue("locationCheckbox", false);
                                  setValue("location", "", {
                                    shouldValidate: true
                                  });
                                }}
                              />
                            );
                          }}
                          renderOption={(props, option) => {
                            const address = `${option.location.lines?.join(
                              ", "
                            )}, ${option.location.locality}, ${
                              option.location.province
                            }, ${option.location.country}, ${
                              option.location.postalCode
                            }`;
                            return (
                              <MenuItem
                                data-testId={`LOCATION_OPTION_${option.value}`}
                                key={option.value}
                                id={option.value}
                                selected={
                                  sportLocation
                                    ? option.value === sportLocation
                                    : option.value === undefined
                                }
                                onClick={() => {
                                  setSportLocationInputValue(option.label);
                                  setSportLocation(option.value);
                                  if (option.label === organization?.name)
                                    setValue("locationCheckbox", true);
                                  else setValue("locationCheckbox", false);
                                  setValue("location", option.label, {
                                    shouldValidate: true
                                  });
                                }}
                              >
                                <MuiGrid
                                  container
                                  direction="row"
                                  spacing="10px"
                                  alignItems="center"
                                >
                                  <MuiGrid item>
                                    <LocationOnOutlined htmlColor="grey" />
                                  </MuiGrid>
                                  <MuiGrid
                                    item
                                    xs={10}
                                    container
                                    direction="column"
                                    spacing="5px"
                                  >
                                    <MuiGrid item>
                                      <Typography
                                        style={{
                                          color: "#000000",
                                          fontSize: "13px",
                                          fontWeight: 400
                                        }}
                                      >
                                        {option.label}
                                      </Typography>
                                    </MuiGrid>
                                    <MuiGrid item>
                                      <Typography
                                        style={{
                                          color: "#64748B",
                                          fontSize: "12px"
                                        }}
                                      >
                                        {address || ""}
                                      </Typography>
                                    </MuiGrid>
                                  </MuiGrid>
                                </MuiGrid>
                              </MenuItem>
                            );
                          }}
                        />
                      </FormInputContainer>
                    )}
                  />

                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}
                  >
                    <FormCheckbox
                      control={control}
                      disabled={
                        !orgSportsLocations?.data.locations?.find(
                          (l) => l.name === organization?.name
                        )
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          const location =
                            orgSportsLocations?.data.locations?.find(
                              (l) => l.name === organization?.name
                            );
                          if (location) {
                            setSportLocation(location.locationId);
                            setSportLocationInputValue(location?.name || "");
                            setValue("location", location?.name || "", {
                              shouldValidate: true
                            });
                          }
                        } else {
                          setSportLocation(undefined);
                          setValue("location", "", {
                            shouldValidate: true
                          });
                          setSportLocationInputValue("");
                        }
                      }}
                      name="locationCheckbox"
                      label="Use Organization Address"
                    />
                    <AddIcon
                      style={{
                        color: colors.info.main,
                        width: "18px",
                        height: "18px",
                        marginRight: "4px"
                      }}
                    />
                    <Typography
                      style={{
                        cursor: "pointer",
                        width: "190px",
                        color: colors.info.main,
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "18px"
                      }}
                      onClick={() => {
                        setopenChangeAddress(true);
                      }}
                    >
                      Add New Address
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </StyledDiv>
            <Grid xs={12} md={6} xl={6} data-testid="liveSteam-notes">
              <FormInput
                control={control}
                name="description"
                type="text"
                label="Description"
                multiline={true}
                rows={10}
                TextProps={{
                  inputProps: {
                    maxLength: 300
                  }
                }}
                onChange={(e) => {
                  const value = (e as ChangeEvent<HTMLInputElement>).target
                    .value;
                  setDescCharacterCount(value.length);
                  setValue("description", capitalize(value));
                }}
              />
              <div style={{ textAlign: "end" }}>
                <Typography style={{ opacity: "50%" }}>
                  {"Character Count Remaining: " +
                    (300 - (descCharacterCount || 0))}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Form>
      </Loader>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler(false))}
        saveAndNewBtnClick={handleSubmit(saveHandler(true))}
        isDisabled={
          !isValid ||
          isSaving ||
          loading ||
          (!file && !settingThumbnailId) ||
          !sportLocationInputValue
        }
        isLoading={isSaving || loading}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/live-stream")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
      {openChangeAddress && (
        <GoogleMap
          close={closeAddressChange}
          onAddressChange={onAddressChanges}
        />
      )}
    </Container>
  );
};
