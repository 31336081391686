import { ModelContact } from "@sportsgravyengineering/sg-api-react-sdk";

export const findContactEmailInMeeting = (
  eventAttendees: { email: string }[],
  participant: ModelContact
) => {
  const workEmails = participant.accounts?.map((a) => a.workEmail || "") || [];
  const personalEmail = participant.email || "";
  return (
    eventAttendees.find(
      (e) => personalEmail === e.email || workEmails.find((w) => w === e.email)
    )?.email || ""
  );
};
