import { Loader } from "@components/crud/Loader";
import {
  Autocomplete,
  Button,
  Grid,
  InputAdornment,
  ListItemText,
  MenuItem,
  styled,
  Typography
} from "@mui/material";
import { useForm } from "react-hook-form";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { FormSelect } from "@components/FormSelect";
import { FormMultiSelect } from "@components/FormMultiSelect";
import {
  ModelJobTitle,
  useAdminJobTitleGet,
  useAdminRoleGet,
  useConfigGet,
  useConfigPut,
  useLookupCountryGet,
  useAdminDepartmentGet,
  ModelDepartment
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";
import { CRMApprovalSettings, OrderApproval } from "./CRMApprovalSettings";
import { StyledFormLabel } from "@components/StyledFormLabel";
import { DeleteIcon } from "@components/Icons";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { Add, Edit } from "@mui/icons-material";
import colors from "theme/colors";
import { CRMAccountSettings } from "./CRMAccountSettings";
import { SearchInput } from "@components/SearchInput";
import React from "react";
import { Toolbar } from "@components/crud/Toolbar";
import { Footer } from "@components/crud/Footer";
import { FormCheckbox } from "@components/FormCheckbox";
const StyledGrid = styled(Grid)`
  padding: 0 24px;
  .toolbar {
    padding: 24px 0 0 0;
  }
`;

export type Tier = {
  id: number;
  startAthlete: string;
  endAthlete: string;
  price: string;
  setupPrice: string;
  countryId?: string;
};

export const CRMSetting = () => {
  const form = useForm({
    mode: "onBlur"
  });
  const [disabled, setDisabled] = useState<boolean>(true);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const { reset, control, getValues, setValue } = form;
  const { data: settings, isLoading: isLoading } = useConfigGet();
  const { data: roles, isLoading: isRoleLoading } = useAdminRoleGet({
    type: "SYSTEM",
    includeChildren: true,
    pageSize: "100"
  });
  const EmailToOptions = useMemo(() => {
    const getOptions = (roles) => {
      return roles
        .map((role) => {
          let options = [
            {
              label: role.name,
              value: role.roleId
            }
          ];

          if (role.children) {
            options = [...options, ...getOptions(role.children)];
          }

          return options;
        })
        .flat();
    };

    if (roles && roles.data && roles.data.roles) {
      return [
        {
          value: "LEAD_OWNER",
          label: "Lead owner"
        },
        ...getOptions(roles.data.roles)
      ];
    }

    return [];
  }, [roles]);

  const { data: jobs, isLoading: isJobsLoading } = useAdminJobTitleGet({
    pageSize: 100
  });

  const JobOptions = useMemo(() => {
    const getOptions = (jobs: ModelJobTitle[]) => {
      return jobs
        .map((job) => {
          const options = [
            {
              label: job.name!,
              value: job.jobtitleId!
            }
          ];
          return options;
        })
        .flat();
    };

    if (jobs && jobs.data && jobs.data.jobTitles) {
      return getOptions(jobs.data.jobTitles);
    }

    return [];
  }, [jobs]);

  const { data: departments, isLoading: isDepartmentsLoading } =
    useAdminDepartmentGet({
      pageSize: 100
    });

  const DepartmentOptions = useMemo(() => {
    const getOptions = (departments: ModelDepartment[]) => {
      return departments
        .map((dept) => {
          const options = [
            {
              label: dept.name!,
              value: dept.departmentId!
            }
          ];
          return options;
        })
        .flat();
    };

    if (departments && departments.data && departments.data.departments) {
      return getOptions(departments.data.departments);
    }

    return [];
  }, [departments]);

  const [orderApprovals, setOrderApprovals] = useState<OrderApproval[]>([
    {
      job: "",
      amount: "1000"
    }
  ]);
  const [priceTiers, setPriceTiers] = useState<OrderApproval[]>([]);
  const [pricingTierCountry, setPricingTierCountry] = useState("US");
  const [pricingTierCountryInputValue, setPricingTierCountryInputValue] =
    useState("United States");
  const { data: countriesResponse, isFetching: isFetchingCountries } =
    useLookupCountryGet({
      query: {
        staleTime: Infinity
      }
    });
  const countries = React.useMemo(() => {
    return countriesResponse?.data.map((country) => {
      return {
        label: country.name as string,
        value: country.countryId as string
      };
    });
  }, [countriesResponse]);

  const [rows, setRows] = useState<Tier[]>([]);

  const [toDelete, setToDelete] = useState<
    | {
        index: number;
        tier: Tier;
      }
    | undefined
  >(undefined);

  const [pricingTierInvalid, setPricingTierInvalid] = useState<
    string | undefined
  >();
  const [emailReminderFrequencyOptions, setEmailReminderFrequencyOptions] =
    useState([]);
  const [meetingLengthOptions, setMeetingLengthOptions] = useState([]);
  const [reviewFrequency, setReviewFrequency] = useState([]);
  const [surveyFrequncy, setSurveyFrequency] = useState([]);
  const [surveyDays, setSurveyDays] = useState([]);
  useEffect(() => {
    if (settings && settings.data) {
      const orderApprovalsData = (settings.data.find(
        (item) => item.key === "crm.order.approvals-required"
      )?.value || []) as OrderApproval[];
      const priceTierApprovalsData = (settings.data.find(
        (item) => item.key === "crm.opportunity.pricing-tier-approval-required"
      )?.value || []) as OrderApproval[];
      const priceTiersList = (settings.data.find(
        (item) => item.key === "crm.order.pricing-tier"
      )?.value || []) as Tier[];
      const approvals =
        orderApprovalsData?.reduce((acc, link, idx) => {
          const key = `amount.job${idx}`;
          acc[key] = link.job;
          if (link.amount) acc[`amount.amount${idx}`] = link.amount;
          return acc;
        }, {}) || {};
      const priceTierApprovals =
        priceTierApprovalsData?.reduce((acc, link, idx) => {
          const key = `percentage.job${idx}`;
          acc[key] = link.job;
          if (link.percentage)
            acc[`percentage.percentage${idx}`] = link.percentage;
          return acc;
        }, {}) || {};
      const ratings =
        (settings.data.find((item) => item.key === "crm.account.org-rating")
          ?.value as { type: string; start: number; end: string | number }[]) ||
        [];
      reset({
        autoGenerateRenewalOpp: settings.data.find(
          (item) => item.key === "crm.opportunity.generate-renewal-days"
        )?.value,
        newMQLEmailNotif: settings.data.find(
          (item) => item.key === "crm.lead.new-mql-email"
        )?.value,
        defaultLeadOwner: settings.data.find(
          (item) => item.key === "crm.lead.default-lead-owner"
        )?.value,
        rating: {
          AAA: ratings.find((rating) => rating.type === "AAA")?.start + " +",
          AA:
            ratings.find((rating) => rating.type === "AA")?.start +
            " - " +
            ratings.find((rating) => rating.type === "AA")?.end,
          A:
            ratings.find((rating) => rating.type === "A")?.start +
            " - " +
            ratings.find((rating) => rating.type === "A")?.end,

          B:
            ratings.find((rating) => rating.type === "B")?.start +
            " - " +
            ratings.find((rating) => rating.type === "B")?.end,
          C:
            ratings.find((rating) => rating.type === "C")?.start +
            " - " +
            ratings.find((rating) => rating.type === "C")?.end
        },
        ...approvals,
        ...priceTierApprovals,
        pricingTierCountry: "US",
        startDateRequirement: settings.data.find(
          (item) => item.key === "crm.order.start-date.after-order-date"
        )?.value,
        atRiskRule1: settings.data.find(
          (item) => item.key === "crm.order.at-risk-rule1"
        )?.value,
        atRiskRule2: settings.data.find(
          (item) => item.key === "crm.order.at-risk-rule2"
        )?.value,
        newOrderEmailNotifications: settings.data.find(
          (item) => item.key === "crm.order.email-notification.new-order"
        )?.value,
        emailReminderFrequency: settings.data.find(
          (item) =>
            item.key === "crm.order.email-notification.reminder-frequency"
        )?.value,
        isBilledMonthly: settings.data.find(
          (item) => item.key === "crm.order.billing-option.yearly-monthly"
        )?.value,
        isBilledPerAthlete: settings.data.find(
          (item) =>
            item.key === "crm.order.billing-option.yearly-monthly-per-athlete"
        )?.value,
        isPaidUpfront: settings.data.find(
          (item) => item.key === "crm.order.billing-option.upfront"
        )?.value,
        upfrontDiscount: settings.data.find(
          (item) => item.key === "crm.order.upfront-discount"
        )?.value,
        upcomingInvoiceEmailReminder: settings.data.find(
          (item) => item.key === "crm.order.upcoming-invoice-reminder"
        )?.value,
        onBoardingSessionRequirement: settings.data.find(
          (item) => item.key === "crm.onboarding.start-date-range"
        )?.value,
        onBoardingSessionLength: settings.data.find(
          (item) => item.key === "crm.onboarding.session-length"
        )?.value,
        onBoardingReviewFrequency: settings.data.find(
          (item) => item.key === "crm.onboarding.review-frequency"
        )?.value,
        onBoardingReviewDateRange: settings.data.find(
          (item) => item.key === "crm.onboarding.review-date-range"
        )?.value,
        onBoardingReviewMeetingLength: settings.data.find(
          (item) => item.key === "crm.onboarding.review-meeting-length"
        )?.value,
        onBoardingEmailNotification: settings.data.find(
          (item) => item.key === "crm.onboarding.email-notification"
        )?.value,
        newBillingEmailNotifications: settings.data.find(
          (item) => item.key === "crm.order.billing.email-notification"
        )?.value,
        npsSurveyFrequency: settings.data.find(
          (item) => item.key === "crm.survey.nps-frequency"
        )?.value,
        npsSurveyFrequencyDay: settings.data.find(
          (item) => item.key === "crm.survey.nps-frequency-day"
        )?.value,
        npsAutomateDays: settings.data.find(
          (item) => item.key === "crm.survey.nps-automate-days"
        )?.value,
        csatSurveyFrequency: settings.data.find(
          (item) => item.key === "crm.survey.csat-frequency"
        )?.value,
        csatSurveyFrequencyDay: settings.data.find(
          (item) => item.key === "crm.survey.csat-frequency-day"
        )?.value,
        csatAutomateDays: settings.data.find(
          (item) => item.key === "crm.survey.csat-automate-days"
        )?.value
      });

      setOrderApprovals(orderApprovalsData);
      setPriceTiers(priceTierApprovalsData);
      setRows(priceTiersList);
      setEmailReminderFrequencyOptions(
        settings.data.find(
          (item) =>
            item.key === "crm.order.email-notification.reminder-frequency"
        )?.options || []
      );
      setMeetingLengthOptions(
        settings.data.find(
          (item) => item.key === "crm.onboarding.review-meeting-length"
        )?.options || []
      );
      setReviewFrequency(
        settings.data.find(
          (item) => item.key === "crm.onboarding.review-frequency"
        )?.options || []
      );
      setSurveyFrequency(
        settings.data.find((item) => item.key === "crm.survey.csat-frequency")
          ?.options || []
      );
      setSurveyDays(
        settings.data.find(
          (item) => item.key === "crm.survey.csat-frequency-day"
        )?.options || []
      );
    }
  }, [settings]);

  const { mutate: save, isLoading: isSaving } = useConfigPut();
  const onSave = () => {
    const data = [
      {
        key: "crm.lead.new-mql-email",
        value: getValues("newMQLEmailNotif")
      },
      {
        key: "crm.lead.new-mql-email",
        value: getValues("newMQLEmailNotif")
      },
      {
        key: "crm.opportunity.generate-renewal-days",
        value: getValues("autoGenerateRenewalOpp")
      },
      {
        key: "crm.order.approvals-required",
        value: getValues("crm.order.approvals-required")
      },
      {
        key: "crm.opportunity.pricing-tier-approval-required",
        value: getValues("crm.opportunity.pricing-tier-approval-required")
      },
      {
        key: "crm.account.org-rating",
        value: getValues("crm.account.org-rating")
      },
      {
        key: "crm.order.pricing-tier",
        value: getValues("crm.order.pricing-tier")
      },

      {
        key: "crm.order.start-date.after-order-date",
        value: getValues("crm.order.start-date.after-order-date")
      },
      {
        key: "crm.order.at-risk-rule1",
        value: getValues("crm.order.at-risk-rule1")
      },
      {
        key: "crm.order.at-risk-rule2",
        value: getValues("crm.order.at-risk-rule2")
      },
      {
        key: "crm.order.email-notification.new-order",
        value: getValues("crm.order.email-notification.new-order")
      },
      {
        key: "crm.order.email-notification.reminder-frequency",
        value: getValues("crm.order.email-notification.reminder-frequency")
      },
      {
        key: "crm.order.billing-option.yearly-monthly",
        value: getValues("crm.order.billing-option.yearly-monthly")
      },
      {
        key: "crm.order.billing-option.yearly-monthly-per-athlete",
        value: getValues("crm.order.billing-option.yearly-monthly-per-athlete")
      },
      {
        key: "crm.order.billing-option.upfront",
        value: getValues("crm.order.billing-option.upfront")
      },
      {
        key: "crm.order.upfront-discount",
        value: getValues("crm.order.upfront-discount")
      },
      {
        key: "crm.order.upcoming-invoice-reminder",
        value: getValues("crm.order.upcoming-invoice-reminder")
      },
      {
        key: "crm.onboarding.start-date-range",
        value: getValues("crm.onboarding.start-date-range")
      },
      {
        key: "crm.onboarding.session-length",
        value: getValues("crm.onboarding.session-length")
      },
      {
        key: "crm.onboarding.review-frequency",
        value: getValues("crm.onboarding.review-frequency")
      },
      {
        key: "crm.onboarding.review-date-range",
        value: getValues("crm.onboarding.review-date-range")
      },
      {
        key: "crm.onboarding.review-meeting-length",
        value: getValues("crm.onboarding.review-meeting-length")
      },
      {
        key: "crm.onboarding.email-notification",
        value: getValues("crm.onboarding.email-notification")
      },
      {
        key: "crm.order.billing.email-notification",
        value: getValues("crm.order.billing.email-notification")
      },
      {
        key: "crm.survey.nps-frequency",
        value: getValues("crm.survey.nps-frequency")
      },
      {
        key: "crm.survey.nps-frequency-day",
        value: getValues("crm.survey.nps-frequency-day")
      },
      {
        key: "crm.survey.nps-automate-days",
        value: getValues("crm.survey.nps-automate-days")
      },
      {
        key: "crm.survey.csat-frequency",
        value: getValues("crm.survey.csat-frequency")
      },
      {
        key: "crm.survey.csat-frequency-day",
        value: getValues("crm.survey.csat-frequency-day")
      },
      {
        key: "crm.survey.csat-automate-days",
        value: getValues("crm.survey.csat-automate-days")
      }
    ];
    if (settings?.data) {
      save(
        {
          data: data
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Saved Successfully!", {
              variant: "success"
            });
            setDisabled(true);
          },
          onError: () => {
            enqueueSnackbar("Failed to save !", {
              variant: "error"
            });
          }
        }
      );
    }
  };
  const validatePricingTiersAndSave = (pricingTiers: Tier[]) => {
    setPricingTierInvalid(undefined);
    const countryPricingTier = pricingTiers.filter(
      (p) => p.countryId === pricingTierCountry
    );
    if (countryPricingTier.length === 0) return;

    for (let i = 0; i < countryPricingTier.length - 1; i++) {
      const currentTier = countryPricingTier[i];
      const nextTier = countryPricingTier[i + 1];

      // Convert startAthlete and endAthlete to integers
      const currentStart = parseInt(currentTier.startAthlete, 10);
      const currentEnd = parseInt(currentTier.endAthlete, 10);
      const nextStart = parseInt(nextTier.startAthlete, 10);
      const nextEnd = parseInt(nextTier.endAthlete, 10);

      // Check if all required fields are present
      if (
        !(
          currentTier.price &&
          currentTier.startAthlete &&
          currentTier.endAthlete &&
          currentTier.setupPrice
        )
      )
        return;

      // Check if the current end overlaps with the next start
      if (currentEnd >= nextStart) {
        setPricingTierInvalid(
          `Invalid range: ${currentStart}-${currentEnd} overlaps with ${nextStart}-${nextEnd}`
        );
        return;
      }

      // Check if the ranges are continuous
      if (currentEnd + 1 !== nextStart) {
        setPricingTierInvalid(
          `Invalid range: ${currentStart}-${currentEnd} overlaps with ${nextStart}-${nextEnd}`
        );
        return;
      }
    }

    // If all checks pass, save the pricing tiers
    setValue("crm.order.pricing-tier", pricingTiers);
  };
  return (
    <>
      <StyledGrid
        item
        container
        direction="column"
        spacing="20px"
        marginLeft="0"
        width="calc(100% - 20px)"
        paddingBottom="24px"
      >
        {disabled && (
          <Toolbar
            title=""
            addBtnClick={() => setDisabled(false)}
            addBtnLabel="Edit"
            addBtnIcon={<Edit />}
          />
        )}
        <Loader
          isLoading={
            isLoading ||
            isRoleLoading ||
            isJobsLoading ||
            isFetchingCountries ||
            isDepartmentsLoading
          }
        >
          <Grid item container xs={12} direction="column">
            <Grid item>
              <Typography
                variant="permissionNames"
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  letterSpacing: "10%",
                  lineHeight: "14.52px",
                  opacity: "50%"
                }}
              >
                LEAD SETTINGS
              </Typography>
            </Grid>

            <HeaderUnderLine width="100%" />
          </Grid>
          <Grid item container direction="row" spacing="24px">
            <Grid item xs={6}>
              <FormSelect
                control={control}
                disabled
                name="defaultLeadOwner"
                options={[
                  {
                    label: "Automatically assign based on territory",
                    value: "AUTO_ASSIGN_TERRITORY"
                  }
                ]}
                label="Default Lead Owner"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <FormMultiSelect
                control={control}
                name="newMQLEmailNotif"
                options={EmailToOptions}
                label="New MQL Email Notification"
                required
                onChange={(e) => {
                  setValue("newMQLEmailNotif", e.target.value);
                }}
                disabled={disabled}
                onRemove={(value) => {
                  const updatedValues = getValues().newMQLEmailNotif.filter(
                    (role) => role !== value
                  );
                  setValue("newMQLEmailNotif", updatedValues);
                }}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} direction="column">
            <Grid item>
              <Typography
                variant="permissionNames"
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  letterSpacing: "10%",
                  lineHeight: "14.52px",
                  opacity: "50%"
                }}
              >
                OPPORTUNITY SETTINGS
              </Typography>
            </Grid>

            <HeaderUnderLine width="100%" />
          </Grid>
          <Grid item container direction="row" spacing="24px">
            <Grid item xs={6}>
              <FormSelect
                control={control}
                name="autoGenerateRenewalOpp"
                options={[
                  {
                    label: "30 days before subscription end date",
                    value: "30D"
                  },
                  {
                    label: "60 days before subscription end date",
                    value: "60D"
                  },
                  {
                    label: "90 days before subscription end date",
                    value: "90D"
                  }
                ]}
                label="Auto Generate Renewal Opportunities"
                required
                disabled={disabled}
                onChange={(e) => {
                  setValue(
                    "crm.opportunity.generate-renewal-days",
                    e.target.value
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} direction="column">
            <Grid item marginTop="10px" marginBottom="-5px">
              <StyledFormLabel required>
                <Typography variant="formLabel">
                  Approval Required For Order Amounts Greater Than
                </Typography>
              </StyledFormLabel>
            </Grid>
            <Grid item marginTop="20px">
              <CRMApprovalSettings
                key="crm.order.approvals-required"
                orderApprovals={orderApprovals}
                setOrderApprovals={setOrderApprovals}
                control={control}
                jobOptions={JobOptions}
                onSave={(val) => {
                  const convertedFilValues = val
                    .filter((v) => v.amount && v.job)
                    .map((v) => ({
                      job: v.job,
                      amount: v.amount!.replace(/,/g, "")
                    }));
                  setValue("crm.order.approvals-required", convertedFilValues);
                }}
                newApproval={{ job: "", amount: "10000" }}
                inputField={{
                  name: "amount",
                  rules: {
                    required: "Amount is required"
                  },
                  formatCurrency: true,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item marginTop="24px">
              <StyledFormLabel required>
                <Typography variant="formLabel">
                  Approval Required for Changing Pricing Tier
                </Typography>
              </StyledFormLabel>
            </Grid>
            <Grid item marginTop="20px">
              <CRMApprovalSettings
                key="crm.opportunity.pricing-tier-approval-required"
                orderApprovals={priceTiers}
                setOrderApprovals={setPriceTiers}
                control={control}
                jobOptions={JobOptions}
                onSave={(val) => {
                  const convertedFilValues = val
                    .filter((v) => v.percentage && v.job)
                    .map((v) => ({
                      job: v.job,
                      percentage: v.percentage!.replace(/,/g, "")
                    }));
                  setValue(
                    "crm.opportunity.pricing-tier-approval-required",
                    convertedFilValues
                  );
                }}
                newApproval={{ job: "", percentage: "0" }}
                inputField={{
                  name: "percentage",
                  rules: {
                    required: "Value is required",
                    validate: (value) => {
                      if (parseFloat(value) > 100) {
                        return "Value should be less than 100";
                      }
                      return true;
                    }
                  },
                  formatCurrency: false,
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  )
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item container xs={12} direction="column" marginTop="20px">
              <Grid item>
                <Typography
                  variant="permissionNames"
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    letterSpacing: "10%",
                    lineHeight: "14.52px",
                    opacity: "50%"
                  }}
                >
                  COUNTRY PRICING TIERS
                </Typography>
              </Grid>

              <HeaderUnderLine width="100%" />
            </Grid>
            <Grid item container direction="column" mt="15px">
              <Autocomplete
                clearOnBlur={false}
                options={countries || []}
                inputValue={pricingTierCountryInputValue}
                renderInput={(params) => {
                  return (
                    <SearchInput
                      data-testid="SEARCH_ORGANIZATION_INPUT"
                      {...params}
                      value={pricingTierCountryInputValue}
                      onChange={(e) => {
                        setPricingTierCountryInputValue(e.target.value);
                      }}
                      onBlur={() => {
                        const country = countries?.find((country) =>
                          pricingTierCountry
                            ? country.value === pricingTierCountry
                            : country.value === "US"
                        );
                        setPricingTierCountryInputValue(country?.label || "");
                      }}
                      placeholder="Search for Countries"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        disabled: disabled
                      }}
                    />
                  );
                }}
                renderOption={(
                  props,
                  option: { value: string | undefined; label: string }
                ) => {
                  return (
                    <MenuItem
                      data-testId={`COUNTRY_OPTION_${option.value}`}
                      key={option.value}
                      id={option.value}
                      selected={option.value === pricingTierCountry}
                      disabled={option.value === pricingTierCountry}
                      onClick={() => {
                        setPricingTierCountryInputValue(option.label);
                        setPricingTierCountry(option.value!);
                      }}
                    >
                      <ListItemText>{option.label}</ListItemText>
                    </MenuItem>
                  );
                }}
              />
            </Grid>
            <Grid
              container
              direction="column"
              marginTop="24px"
              spacing="10px"
              columns={7}
            >
              <Grid item container direction="row" gridColumn={1}>
                <Grid item xs={6}>
                  <StyledFormLabel required>
                    <Typography variant="formLabel">
                      Pricing Tiers For Yearly Athlete Registrations
                    </Typography>
                  </StyledFormLabel>
                </Grid>
                <Grid item xs={2.9}></Grid>
                <Grid item xs={3}>
                  <StyledFormLabel required>
                    <Typography variant="formLabel">
                      Onetime Setup Fee
                    </Typography>
                  </StyledFormLabel>
                </Grid>
              </Grid>
              {rows
                .filter((r) => r.countryId === pricingTierCountry)
                .map((tier) => (
                  <Grid
                    item
                    container
                    direction="row"
                    key={tier.id + pricingTierCountry}
                  >
                    <Grid item xs={2.6}>
                      <FormInput
                        control={control}
                        label=""
                        name="startAthlete"
                        type="text"
                        enteredValue={tier.startAthlete.toString()}
                        onChange={(e) => {
                          const value = (e as ChangeEvent<HTMLInputElement>)
                            .target.value;
                          const values = rows.map((row) =>
                            row.id === tier.id
                              ? {
                                  ...row,
                                  startAthlete: value
                                }
                              : row
                          );
                          setRows(values);
                          validatePricingTiersAndSave(values);
                        }}
                        disabled={disabled}
                        TextProps={{
                          onInput: (e) => {
                            const input = e as ChangeEvent<HTMLInputElement>;
                            input.target.value = input.target.value.replace(
                              /[^0-9.]/g,
                              ""
                            );
                            const parts = input.target.value.split(".");
                            if (parts.length > 2) {
                              input.target.value =
                                parts[0] + "." + parts.slice(1).join("");
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={0.5}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Typography style={{ color: "#B3B3B3" }}>to</Typography>
                    </Grid>
                    <Grid item xs={2.6}>
                      <FormInput
                        control={control}
                        label=""
                        name="endAthlete"
                        type="text"
                        enteredValue={tier.endAthlete.toString()}
                        onChange={(e) => {
                          const value = (e as ChangeEvent<HTMLInputElement>)
                            .target.value;
                          const values = rows.map((row) =>
                            row.id === tier.id
                              ? {
                                  ...row,
                                  endAthlete: value
                                }
                              : row
                          );
                          setRows(values);
                          validatePricingTiersAndSave(values);
                        }}
                        disabled={disabled}
                        TextProps={{
                          onInput: (e) => {
                            const input = e as ChangeEvent<HTMLInputElement>;
                            input.target.value = input.target.value.replace(
                              /[^0-9.]/g,
                              ""
                            );
                            const parts = input.target.value.split(".");
                            if (parts.length > 2) {
                              input.target.value =
                                parts[0] + "." + parts.slice(1).join("");
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={0.5}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Typography style={{ color: "#B3B3B3" }}>at</Typography>
                    </Grid>
                    <Grid item xs={2.6}>
                      <FormInput
                        control={control}
                        label=""
                        name="price"
                        type="text"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          )
                        }}
                        enteredValue={tier.price.toString()}
                        onChange={(e) => {
                          const value = (e as ChangeEvent<HTMLInputElement>)
                            .target.value;
                          const values = rows.map((row) =>
                            row.id === tier.id
                              ? {
                                  ...row,
                                  price: value
                                }
                              : row
                          );
                          setRows(values);
                          validatePricingTiersAndSave(values);
                        }}
                        disabled={disabled}
                        TextProps={{
                          onInput: (e) => {
                            const input = e as ChangeEvent<HTMLInputElement>;
                            input.target.value = input.target.value.replace(
                              /[^0-9.]/g,
                              ""
                            );
                            const parts = input.target.value.split(".");
                            if (parts.length > 2) {
                              input.target.value =
                                parts[0] + "." + parts.slice(1).join("");
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={2.6} marginLeft="10px">
                      <FormInput
                        control={control}
                        label=""
                        name="setupPrice"
                        type="text"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          )
                        }}
                        disabled={disabled}
                        enteredValue={tier.setupPrice.toString()}
                        onChange={(e) => {
                          const value = (e as ChangeEvent<HTMLInputElement>)
                            .target.value;
                          const values = rows.map((row) =>
                            row.id === tier.id
                              ? {
                                  ...row,
                                  setupPrice: value
                                }
                              : row
                          );
                          setRows(values);
                          validatePricingTiersAndSave(values);
                        }}
                        TextProps={{
                          onInput: (e) => {
                            const input = e as ChangeEvent<HTMLInputElement>;
                            input.target.value = input.target.value.replace(
                              /[^0-9.]/g,
                              ""
                            );
                            const parts = input.target.value.split(".");
                            if (parts.length > 2) {
                              input.target.value =
                                parts[0] + "." + parts.slice(1).join("");
                            }
                          }
                        }}
                      />
                    </Grid>
                    {!disabled && (
                      <Grid
                        item
                        xs={0.5}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                        paddingLeft="15px"
                      >
                        <div
                          style={{
                            padding: "8px",
                            borderRadius: "4px",
                            border: "1px solid #E5E5E5",
                            height: "40px",
                            width: "40px",
                            cursor: "pointer"
                          }}
                          onClick={() => {
                            setToDelete({ index: tier.id, tier: tier });
                          }}
                        >
                          <DeleteIcon height="24px" width="24px" />
                        </div>
                      </Grid>
                    )}
                  </Grid>
                ))}
              {pricingTierInvalid && (
                <Grid item style={{ marginTop: "-10px" }}>
                  <Typography style={{ color: "#E82C2C" }} variant="body2">
                    {pricingTierInvalid}
                  </Typography>
                </Grid>
              )}
            </Grid>
            {!disabled && (
              <Grid marginTop="16px">
                <Button
                  startIcon={<Add />}
                  style={{
                    textTransform: "capitalize",
                    color: colors.primary.main
                  }}
                  onClick={() => {
                    const previousEnd = rows?.[rows.length - 1]?.endAthlete;
                    setRows([
                      ...rows,
                      {
                        id: Math.round(Math.random() * 1000),
                        startAthlete: previousEnd
                          ? String(parseInt(previousEnd) + 1)
                          : "0",
                        endAthlete: "",
                        price: "0",
                        setupPrice: "0",
                        countryId: pricingTierCountry
                      }
                    ]);
                  }}
                >
                  Add Pricing Tier
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid item container xs={12} direction="column" paddingBottom="24px">
            <CRMAccountSettings
              onSave={(values) => setValue("crm.account.org-rating", values)}
              form={form}
              isLoading={isSaving}
              disabled={disabled}
            />
          </Grid>
          <Grid item container xs={12} direction="column" marginTop="20px">
            <Grid item>
              <Typography
                variant="permissionNames"
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  letterSpacing: "10%",
                  lineHeight: "14.52px",
                  opacity: "50%"
                }}
              >
                ORDER SETTINGS
              </Typography>
            </Grid>
            <HeaderUnderLine width="100%" />
          </Grid>
          <Grid item container direction="row" alignItems="center">
            <Grid item>
              <Typography
                style={{
                  fontSize: "14px",
                  font: "inter",
                  lineHeight: "18px",
                  marginRight: "10px",
                  fontWeight: "bold"
                }}
              >
                Customer Start Date Requirement:
              </Typography>
            </Grid>
            <Grid item>
              <FormInput
                type="number"
                TextProps={{
                  style: {
                    maxWidth: "84px"
                  }
                }}
                disabled={disabled}
                control={control}
                label=""
                name="startDateRequirement"
                rules={{
                  validate: (value) => {
                    if (parseInt(value) < 2) {
                      return "Value should be at least 2 days";
                    }
                    return true;
                  }
                }}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    !isNaN(e.target.value) &&
                    parseInt(e.target.value) >= 2
                  )
                    setValue(
                      "crm.order.start-date.after-order-date",
                      parseInt(e.target.value)
                    );
                }}
              />
            </Grid>

            <Grid item>
              <Typography
                style={{
                  fontSize: "14px",
                  font: "inter",
                  lineHeight: "18px",
                  marginLeft: "10px",
                  fontWeight: "bold"
                }}
              >
                Business Days After Order Date
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="row" alignItems="center">
            <Grid item>
              <Typography
                style={{
                  fontSize: "14px",
                  font: "inter",
                  lineHeight: "18px",
                  marginRight: "10px",
                  fontWeight: "bold"
                }}
              >
                Order Status - At Risk Designation Rule 1:
              </Typography>
            </Grid>
            <Grid item>
              <FormInput
                type="number"
                TextProps={{
                  style: {
                    maxWidth: "84px"
                  }
                }}
                control={control}
                label=""
                disabled={disabled}
                name="atRiskRule1"
                onChange={(e) => {
                  if (e.target.value && !isNaN(e.target.value))
                    setValue(
                      "crm.order.at-risk-rule1",
                      parseInt(e.target.value)
                    );
                }}
              />
            </Grid>

            <Grid item>
              <Typography
                style={{
                  fontSize: "14px",
                  font: "inter",
                  lineHeight: "18px",
                  marginLeft: "10px",
                  fontWeight: "bold"
                }}
              >
                Business Days After Order Date
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="row" alignItems="center">
            <Grid item>
              <Typography
                style={{
                  fontSize: "14px",
                  font: "inter",
                  lineHeight: "18px",
                  marginRight: "10px",
                  fontWeight: "bold"
                }}
              >
                Order Status - At Risk Designation Rule 2:
              </Typography>
            </Grid>
            <Grid item>
              <FormInput
                type="number"
                TextProps={{
                  style: {
                    maxWidth: "84px"
                  }
                }}
                control={control}
                label=""
                name="atRiskRule2"
                disabled={disabled}
                onChange={(e) => {
                  if (e.target.value && !isNaN(e.target.value))
                    setValue(
                      "crm.order.at-risk-rule2",
                      parseInt(e.target.value)
                    );
                }}
              />
            </Grid>

            <Grid item>
              <Typography
                style={{
                  fontSize: "14px",
                  font: "inter",
                  lineHeight: "18px",
                  marginLeft: "10px",
                  fontWeight: "bold"
                }}
              >
                Business Days After Order Date
              </Typography>
            </Grid>
          </Grid>
          <Grid item width="100%">
            <FormMultiSelect
              control={control}
              label="Email Notification For New Orders"
              required
              options={[
                {
                  label: "{Order Submitted By Email}",
                  value: "ORDER_SUBMITTED_BY_EMAIL"
                },
                ...DepartmentOptions
              ]}
              name="newOrderEmailNotifications"
              disabledOptions={["ORDER_SUBMITTED_BY_EMAIL"]}
              disabled={disabled}
              onChange={(e) => {
                setValue(
                  "crm.order.email-notification.new-order",
                  e.target.value
                );
              }}
              onRemove={(value, newVal) => {
                setValue("crm.order.email-notification.new-order", newVal);
              }}
            />
          </Grid>
          <Grid item width="50%">
            <FormSelect
              control={control}
              label="Review and Accept Terms Reminder Email Frequency"
              options={emailReminderFrequencyOptions}
              name="emailReminderFrequency"
              disabled={disabled}
              onChange={(e) => {
                setValue(
                  "crm.order.email-notification.reminder-frequency",
                  e.target.value
                );
              }}
            />
          </Grid>

          <Grid item container xs={12} direction="column" marginTop="20px">
            <Grid item>
              <Typography
                variant="permissionNames"
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  letterSpacing: "10%",
                  lineHeight: "14.52px",
                  opacity: "50%"
                }}
              >
                BILLING
              </Typography>
            </Grid>
            <HeaderUnderLine width="100%" />
          </Grid>
          <Grid item container direction="column" spacing="15px">
            <Grid item>
              <Typography
                style={{
                  fontWeight: "bold"
                }}
              >
                Order Billing Options
              </Typography>
            </Grid>
            <Grid item>
              <FormCheckbox
                control={control}
                name="isBilledMonthly"
                label="Yearly Subscription - Billed Monthly"
                disabled={disabled}
                labelTypographyProps={{
                  style: {
                    fontWeight: "400"
                  }
                }}
                onChange={(e) => {
                  setValue(
                    "crm.order.billing-option.yearly-monthly",
                    e.target.checked
                  );
                }}
              />
            </Grid>
            <Grid item>
              <FormCheckbox
                control={control}
                name="isBilledPerAthlete"
                label="Yearly Subscription - Billed Monthly Per Athlete Registered"
                disabled={disabled}
                labelTypographyProps={{
                  style: {
                    fontWeight: "400"
                  }
                }}
                onChange={(e) => {
                  setValue(
                    "crm.order.billing-option.yearly-monthly-per-athlete",
                    e.target.checked
                  );
                }}
              />
            </Grid>
            <Grid item>
              <FormCheckbox
                control={control}
                name="isPaidUpfront"
                label="Yearly Subscription - Paid Upfront"
                disabled={disabled}
                labelTypographyProps={{
                  style: {
                    fontWeight: "400"
                  }
                }}
                onChange={(e) => {
                  setValue(
                    "crm.order.billing-option.upfront",
                    e.target.checked
                  );
                }}
              />
            </Grid>
            <Grid item width="50%">
              <FormInput
                control={control}
                name="upfrontDiscount"
                label="Discount for Yearly Suscription Paid Upfront"
                required
                type="text"
                disabled={disabled}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  )
                }}
                rules={{
                  validate: (value) => {
                    if (parseInt(value) > 100) {
                      return "Value should be less than 100";
                    }
                    return true;
                  }
                }}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    !isNaN(e.target.value) &&
                    parseInt(e.target.value) < 100
                  )
                    setValue(
                      "crm.order.upfront-discount",
                      parseInt(e.target.value)
                    );
                }}
              />
            </Grid>
            <Grid item width="100%">
              <FormMultiSelect
                control={control}
                label="Email Notifications for Billing"
                required
                options={[
                  {
                    label: "{Assigned CSM email}",
                    value: "ASSIGNED_CSM_EMAIL"
                  },
                  {
                    label: "{Order Submitted By Email}",
                    value: "ORDER_SUBMITTED_BY_EMAIL"
                  },
                  ...DepartmentOptions
                ]}
                name="newBillingEmailNotifications"
                disabledOptions={[
                  "ASSIGNED_CSM_EMAIL",
                  "ORDER_SUBMITTED_BY_EMAIL"
                ]}
                onChange={(e) => {
                  setValue(
                    "crm.order.billing.email-notification",
                    e.target.value
                  );
                }}
                onRemove={(value, newVal) => {
                  setValue("crm.order.billing.email-notification", newVal);
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item container direction="row" alignItems="center">
              <Grid item>
                <Typography
                  style={{
                    fontSize: "14px",
                    font: "inter",
                    lineHeight: "18px",
                    marginRight: "10px",
                    fontWeight: "bold"
                  }}
                >
                  Send Upcoming Invoice Email Reminder:
                </Typography>
              </Grid>
              <Grid item>
                <FormInput
                  type="number"
                  TextProps={{
                    style: {
                      maxWidth: "84px"
                    }
                  }}
                  control={control}
                  label=""
                  name="upcomingInvoiceEmailReminder"
                  disabled={disabled}
                  onChange={(e) => {
                    if (e.target.value && !isNaN(e.target.value))
                      setValue(
                        "crm.order.upcoming-invoice-reminder",
                        parseInt(e.target.value)
                      );
                  }}
                />
              </Grid>

              <Grid item>
                <Typography
                  style={{
                    fontSize: "14px",
                    font: "inter",
                    lineHeight: "18px",
                    marginLeft: "10px",
                    fontWeight: "bold"
                  }}
                >
                  Days Before Due Date
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} direction="column" marginTop="20px">
            <Grid item>
              <Typography
                variant="permissionNames"
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  letterSpacing: "10%",
                  lineHeight: "14.52px",
                  opacity: "50%"
                }}
              >
                CUSTOMER ACCOUNT ONBOARDING & REVIEW
              </Typography>
            </Grid>
            <HeaderUnderLine width="100%" />
          </Grid>
          <Grid item container direction="column" spacing="15px">
            <Grid item container direction="row" alignItems="center">
              <Grid item>
                <Typography
                  style={{
                    fontSize: "14px",
                    font: "inter",
                    lineHeight: "18px",
                    marginRight: "10px",
                    fontWeight: "bold"
                  }}
                >
                  Onboarding Meeting Requirement:
                </Typography>
              </Grid>
              <Grid item>
                <FormInput
                  type="number"
                  TextProps={{
                    style: {
                      maxWidth: "84px"
                    }
                  }}
                  control={control}
                  label=""
                  name="onBoardingSessionRequirement"
                  disabled={disabled}
                  onChange={(e) => {
                    if (e.target.value && !isNaN(e.target.value))
                      setValue(
                        "crm.onboarding.start-date-range",
                        parseInt(e.target.value)
                      );
                  }}
                />
              </Grid>

              <Grid item>
                <Typography
                  style={{
                    fontSize: "14px",
                    font: "inter",
                    lineHeight: "18px",
                    marginLeft: "10px",
                    fontWeight: "bold"
                  }}
                >
                  Business Days Before or After Start Date
                </Typography>
              </Grid>
            </Grid>
            <Grid item width="50%">
              <FormSelect
                control={control}
                label="Onboarding Meeting Length"
                options={meetingLengthOptions}
                name="onBoardingSessionLength"
                onChange={(e) => {
                  setValue("crm.onboarding.session-length", e.target.value);
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item width="50%">
              <FormSelect
                control={control}
                label="Review Frequency"
                options={reviewFrequency}
                name="onBoardingReviewFrequency"
                onChange={(e) => {
                  setValue("crm.onboarding.review-frequency", e.target.value);
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item container direction="row" alignItems="center">
              <Grid item>
                <Typography
                  style={{
                    fontSize: "14px",
                    font: "inter",
                    lineHeight: "18px",
                    marginRight: "10px",
                    fontWeight: "bold"
                  }}
                >
                  Review Meeting Requirement:
                </Typography>
              </Grid>
              <Grid item>
                <FormInput
                  type="number"
                  TextProps={{
                    style: {
                      maxWidth: "84px"
                    }
                  }}
                  control={control}
                  label=""
                  name="onBoardingReviewDateRange"
                  onChange={(e) => {
                    if (e.target.value && !isNaN(e.target.value))
                      setValue(
                        "crm.onboarding.review-date-range",
                        parseInt(e.target.value)
                      );
                  }}
                  disabled={disabled}
                />
              </Grid>

              <Grid item>
                <Typography
                  style={{
                    fontSize: "14px",
                    font: "inter",
                    lineHeight: "18px",
                    marginLeft: "10px",
                    fontWeight: "bold"
                  }}
                >
                  Business Days Before or After Suggested Review Date
                </Typography>
              </Grid>
            </Grid>
            <Grid item width="50%">
              <FormSelect
                control={control}
                label="Review Meeting Length"
                options={meetingLengthOptions}
                name="onBoardingReviewMeetingLength"
                onChange={(e) => {
                  setValue(
                    "crm.onboarding.review-meeting-length",
                    e.target.value
                  );
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item width="100%">
              <FormMultiSelect
                control={control}
                label="Email Notifications for Onboarding, Review Meetings and Surveys Associated with Orders"
                required
                options={[
                  {
                    label: "{Assigned CSM email}",
                    value: "ASSIGNED_CSM_EMAIL"
                  },
                  ...DepartmentOptions
                ]}
                name="onBoardingEmailNotification"
                disabledOptions={["ASSIGNED_CSM_EMAIL"]}
                onChange={(e) => {
                  setValue("crm.onboarding.email-notification", e.target.value);
                }}
                onRemove={(value, newVal) => {
                  setValue("crm.onboarding.email-notification", newVal);
                }}
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} direction="column" marginTop="20px">
            <Grid item>
              <Typography
                variant="permissionNames"
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  letterSpacing: "10%",
                  lineHeight: "14.52px",
                  opacity: "50%"
                }}
              >
                B2B CUSTOMER ACCOUNT SURVEYS
              </Typography>
            </Grid>

            <HeaderUnderLine width="100%" />
          </Grid>
          <Grid item container direction="column" spacing="15px">
            <Grid item container direction="row" spacing="20px">
              <Grid item width="50%">
                <FormSelect
                  control={control}
                  label="NPS Survey Frequency"
                  options={surveyFrequncy}
                  name="npsSurveyFrequency"
                  onChange={(e) => {
                    setValue("crm.survey.nps-frequency", e.target.value);
                  }}
                  disabled={disabled}
                />
              </Grid>
              <Grid item width="50%">
                <FormSelect
                  control={control}
                  label="NPS Survey Frequency Day of the Week "
                  options={surveyDays}
                  name="npsSurveyFrequencyDay"
                  onChange={(e) => {
                    setValue("crm.survey.nps-frequency-day", e.target.value);
                  }}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
            <Grid item container direction="row" alignItems="center">
              <Grid item>
                <Typography
                  style={{
                    fontSize: "14px",
                    font: "inter",
                    lineHeight: "18px",
                    marginRight: "10px",
                    fontWeight: "bold"
                  }}
                >
                  Automatically Create and Send NPS Survey
                </Typography>
              </Grid>
              <Grid item>
                <FormInput
                  type="number"
                  TextProps={{
                    style: {
                      maxWidth: "84px"
                    }
                  }}
                  control={control}
                  label=""
                  name="npsAutomateDays"
                  disabled={disabled}
                  onChange={(e) => {
                    if (e.target.value && !isNaN(e.target.value))
                      setValue(
                        "crm.survey.nps-automate-days",
                        parseInt(e.target.value)
                      );
                  }}
                />
              </Grid>

              <Grid item>
                <Typography
                  style={{
                    fontSize: "14px",
                    font: "inter",
                    lineHeight: "18px",
                    marginLeft: "10px",
                    fontWeight: "bold"
                  }}
                >
                  Business Days After Onboarding Meeting
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="row" spacing="20px">
              <Grid item width="50%">
                <FormSelect
                  control={control}
                  label="CSAT Survey Frequency"
                  options={surveyFrequncy}
                  name="csatSurveyFrequency"
                  onChange={(e) => {
                    setValue("crm.survey.csat-frequency", e.target.value);
                  }}
                  disabled={disabled}
                />
              </Grid>
              <Grid item width="50%">
                <FormSelect
                  control={control}
                  label="CSAT Survey Frequency Day of the Week "
                  options={surveyDays}
                  name="csatSurveyFrequencyDay"
                  onChange={(e) => {
                    setValue("crm.survey.csat-frequency-day", e.target.value);
                  }}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
            <Grid item container direction="row" alignItems="center">
              <Grid item>
                <Typography
                  style={{
                    fontSize: "14px",
                    font: "inter",
                    lineHeight: "18px",
                    marginRight: "10px",
                    fontWeight: "bold"
                  }}
                >
                  Automatically Create and Send CSAT Survey
                </Typography>
              </Grid>
              <Grid item>
                <FormInput
                  type="number"
                  TextProps={{
                    style: {
                      maxWidth: "84px"
                    }
                  }}
                  control={control}
                  label=""
                  name="csatAutomateDays"
                  disabled={disabled}
                  onChange={(e) => {
                    if (e.target.value && !isNaN(e.target.value))
                      setValue(
                        "crm.survey.csat-automate-days",
                        parseInt(e.target.value)
                      );
                  }}
                />
              </Grid>

              <Grid item>
                <Typography
                  style={{
                    fontSize: "14px",
                    font: "inter",
                    lineHeight: "18px",
                    marginLeft: "10px",
                    fontWeight: "bold"
                  }}
                >
                  Business Days After Onboarding Meeting
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Loader>
      </StyledGrid>
      <ConfirmationDialog
        open={!!toDelete}
        title="Delete Order Approval"
        body={`Are you sure you want to delete Tier`}
        close={() => setToDelete(undefined)}
        onConfirm={() => {
          const priceTier = rows;
          const filteredPriceTier = priceTier.filter(
            (tier) => tier.id !== toDelete?.tier.id
          );
          setRows([...filteredPriceTier]);
          validatePricingTiersAndSave(filteredPriceTier);
        }}
        onCancel={() => setToDelete(undefined)}
        confirmBtnVariant="admin-error"
        icon="error"
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => setDisabled(true)}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
      {!disabled && (
        <Footer
          saveBtnClick={onSave}
          isLoading={isSaving}
          isDisabled={isSaving}
          saveBtnLabel="Save"
          cancelBtnClick={() => setOpenCancelDialog(true)}
        />
      )}
    </>
  );
};
