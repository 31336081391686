import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Footer } from "@components/crud/Footer";
import { FormSelect } from "@components/FormSelect";
import { CloseIcon } from "@components/Icons";
import {
  Button,
  Grid,
  IconButton,
  List,
  Modal,
  styled,
  Typography
} from "@mui/material";
import { CRM_ACTIVITY_MEETING_TYPES } from "@utils/constants";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { QuickAddContactModal } from "../contacts/components/QuickAddContactForm";
import formatFullName from "@utils/formatFullName";
import { useRecoilValue } from "recoil";
import { profileAtom } from "@recoil/auth";
import {
  adminCrmMeetingAvailableSlotsGet,
  CreateMeetingInputExternalParticipantsItem,
  CreateMeetingInputInternalParticipantsItem,
  CreateMeetingInputRelatesTo,
  CreateMeetingInputType,
  ModelContact,
  usePostAdminCrmMeeting
} from "@sportsgravyengineering/sg-api-react-sdk";
import { FullName } from "@utils/types";
import {
  DateCalendar,
  PickersDay,
  PickersDayProps
} from "@mui/x-date-pickers-pro";
import { StyledFormLabel } from "@components/StyledFormLabel";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import colors from "theme/colors";
import { enqueueSnackbar } from "notistack";
import { FormInput } from "@components/FormInput";
import {
  participant,
  SearchPartipants,
  AccountOption
} from "../components/SearchParticipants";
import { DevTool } from "@hookform/devtools";

export const MainContainer = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "auto",
  width: "850px",
  backgroundColor: "white",
  borderRadius: 16,
  "&:focus-visible": {
    outline: "transparent"
  }
});

export const Header = styled("div")({
  height: 60,
  position: "sticky", // Make the header sticky
  top: 0, // Stick the header to the top of the container
  zIndex: 1,
  backgroundColor: "#2B337A",
  borderTopLeftRadius: 16,
  borderTopRightRadius: 16,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: 16,
  paddingRight: 16
});

export const HeaderText = styled("span")({
  fontSize: 18,
  color: "white"
});
const StyledDateCalendar = styled(DateCalendar)`
  .MuiDateCalendar-root {
    border: 1px solid #e5e5e5;
  }
  .MuiPickersCalendarHeader-root {
    border-bottom: 1px solid #e5e5e5;
  }
`;
const generateTimeSlots = () => {
  const times = [] as { label: string; value: string }[];
  const startTime = new Date();
  startTime.setHours(8, 0, 0); // Start at 8:00 AM
  const endTime = new Date();
  endTime.setHours(16, 30, 0); // End at 4:30 PM

  while (startTime <= endTime) {
    const hours = startTime.getHours();
    const minutes = startTime.getMinutes();

    // Format hours and minutes for label with leading zero for hours
    const isPM = hours >= 12;
    const labelHours = (hours % 12 || 12).toString().padStart(2, "0");
    const valueHours = (hours % 12 || 12).toString();
    const formattedMinutes = minutes === 0 ? "00" : minutes;
    const period = isPM ? "PM" : "AM";

    const timeLabel = `${labelHours}:${formattedMinutes} ${period}`;
    const timeValue = `${valueHours}:${formattedMinutes} ${period}`;

    times.push({
      label: timeLabel,
      value: timeValue
    });

    // Increment by 30 minutes
    startTime.setMinutes(startTime.getMinutes() + 30);
  }
  return times;
};
export const CreateOnboardingEventModal = ({
  open,
  setOpen,
  selectedAccount,
  selectedContact,
  autoSuggestOptions,
  name,
  meetingDuration,
  eventId,
  onRefresh
}: {
  open: boolean;
  eventId: string;
  setOpen: (val: boolean) => void;
  selectedAccount: string;
  selectedContact?: ModelContact | undefined;
  autoSuggestOptions?: participant[];
  name: string;
  meetingDuration: number;
  onRefresh: () => void;
}) => {
  dayjs.extend(timezone);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [isScheduled, setScheduled] = useState(true);
  const [participants, setParticipants] = useState<participant[]>([]);
  const [addContact, setAddContact] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(
    !!autoSuggestOptions?.length
  );
  const [selectedDate, setSelectedDate] = useState<Date>();
  const form = useForm({
    mode: "onBlur",
    defaultValues: {
      meetingParticipants: [] as participant[],
      name: name,
      meetingType: "SCHEDULED"
    }
  });
  const {
    control,
    getValues,
    setValue,
    formState: { isDirty, isValid }
  } = form;

  const userName = formatFullName(
    useRecoilValue(profileAtom)?.person as {
      firstName: string;
      lastName: string;
      middleName: string | undefined;
      suffix: string | undefined;
    } | null
  );
  const userId = useRecoilValue(profileAtom)?.userId || "";
  const userEmail = useRecoilValue(profileAtom)?.email || "";

  const [isFullDays, setIsFullDays] = useState<number[]>([]);
  const [availableSlots, setAvailableSlots] = useState();
  const [isLoadingSlots, setIsLoadingSlots] = useState(false);
  const [monthYear, setMonthYear] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear()
  });
  const [selectedTime, setSelectedTime] = useState<string>();
  useEffect(() => {
    const getFullDays = (resp) => {
      const fullDays = [] as number[];
      Object.keys(resp).map((key) => {
        if (resp[key].isFull) fullDays.push(parseInt(key));
      });
      return fullDays;
    };
    const fetchData = async () => {
      const data = await adminCrmMeetingAvailableSlotsGet({
        month: monthYear.month.toString(),
        year: monthYear.year.toString(),
        attendees: participants.map((p) => p.value),
        timezone: dayjs.tz.guess(),
        slotInterval: 30,
        meetingDuration: meetingDuration || 60
      });
      setAvailableSlots(data.data);
      setIsFullDays([...getFullDays(data.data)]);
      setIsLoadingSlots(false);
    };
    setIsLoadingSlots(true);
    fetchData();
  }, [participants, monthYear]);
  useEffect(() => {
    const userParticipants = [
      {
        label: userName,
        text: userEmail,
        value: userId,
        isInternal: true
      },
      ...(selectedContact
        ? [
            {
              label: formatFullName(selectedContact as FullName),
              text: selectedContact.email,
              value: selectedContact.contactId!,
              isInternal: false
            }
          ]
        : [])
    ];
    setValue("meetingParticipants", userParticipants);
    setParticipants(userParticipants);
  }, [userId, userName, selectedContact]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const list = document.getElementById("participant-list");
      if (dropdownVisible && list && !list.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const ServerDay = (props: PickersDayProps<Date>) => {
    const { day, outsideCurrentMonth, ...other } = props;
    const isDisabled =
      props.disabled ||
      (!props.outsideCurrentMonth && isFullDays.indexOf(day.getDate()) >= 0);

    return (
      <PickersDay
        key={day.toString()}
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        disabled={isDisabled}
        style={
          isDisabled
            ? {
                backgroundColor: "#F3F4F7"
              }
            : {}
        }
      />
    );
  };
  const getTimezoneAbbreviation = (timezone) => {
    const date = new Date();
    const options = { timeZone: timezone, timeZoneName: "long" };
    const timeZoneName = new Intl.DateTimeFormat("en-US", options)
      .formatToParts(date)
      .find((part) => part.type === "timeZoneName").value;
    const abbreviation = timeZoneName
      ?.split(" ")
      ?.map((word) => word[0])
      ?.join("");

    return `${timeZoneName} (${abbreviation})`;
  };
  const isTimeTaken = (time) => {
    return (
      availableSlots &&
      selectedDate &&
      availableSlots[selectedDate.getDate().toString()]?.slotsTaken.includes(
        time
      )
    );
  };
  const { mutate: save, isLoading: isSaving } = usePostAdminCrmMeeting();
  const onSave = () => {
    if (isScheduled) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [time, period] = selectedTime!.split(" ");
      // eslint-disable-next-line prefer-const
      let [hours, minutes] = selectedTime!.split(":").map(Number);

      if (period === "PM" && hours !== 12) {
        hours += 12;
      } else if (period === "AM" && hours === 12) {
        hours = 0;
      }
      selectedDate!.setHours(hours, isNaN(minutes) ? 0 : minutes, 0, 0);
    }
    const endTime =
      isScheduled && selectedDate ? new Date(selectedDate) : new Date();
    if (isScheduled) endTime.setMinutes(endTime.getMinutes() + meetingDuration);
    const data = {
      type: isScheduled ? "SCHEDULED" : ("INSTANT" as CreateMeetingInputType),
      name: getValues().name,
      internalParticipants: participants
        .filter((p) => p.isInternal)
        .map((p) => ({
          userId: p.value,
          email: p.text
        })) as CreateMeetingInputInternalParticipantsItem[],
      externalParticipants: participants
        .filter((p) => !p.isInternal)
        .map((p) => ({
          contactId: p.value,
          email: p.text
        })) as CreateMeetingInputExternalParticipantsItem[],
      date: isScheduled ? (selectedDate as Date) : new Date(),
      timezone: dayjs.tz.guess(),
      relatesTo: "ACCOUNT" as CreateMeetingInputRelatesTo,
      accountId: selectedAccount,
      onboardingEventId: eventId,
      ...(selectedDate &&
        isScheduled &&
        endTime > selectedDate && {
          meetingEndTime: endTime
        })
    };
    save(
      {
        data: data
      },
      {
        onSuccess: (resp) => {
          enqueueSnackbar("Meeting Created Successfully!", {
            variant: "success"
          });

          setOpen(false);
          onRefresh();
          if (!isScheduled && resp.data.meetingUrl) {
            window.open(resp.data.meetingUrl, "_blank");
          }
        },
        onError: () => {
          enqueueSnackbar("Failed to Create Meeting!", {
            variant: "error"
          });
        }
      }
    );
  };
  if (!addContact) {
    return (
      <Modal
        open={open}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <MainContainer>
          <Header>
            <HeaderText>Create Meeting</HeaderText>
            <IconButton
              onClick={() => {
                setOpen(false);
                //setRefreshKey(refreshKey + 1);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Header>
          <div
            style={{
              minHeight: "450px",
              maxHeight: "650px",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <Grid
              container
              direction="column"
              spacing="15px"
              style={{ padding: "24px" }}
            >
              <Grid item width="100%">
                <FormInput
                  label="Meeting Title"
                  required
                  name="name"
                  disabled
                  control={control}
                  type="text"
                  rules={{
                    required: "Meeting Title is required"
                  }}
                />
              </Grid>
              <Grid item width="100%">
                <FormSelect
                  label="Meeting Type"
                  required
                  name="meetingType"
                  control={control}
                  options={CRM_ACTIVITY_MEETING_TYPES}
                  onChange={(e) => {
                    setScheduled(e.target.value === "SCHEDULED");
                  }}
                />
              </Grid>
              {isScheduled && (
                <Grid item>
                  <SearchPartipants
                    label="Participants"
                    control={control}
                    name="meetingParticipants"
                    required={false}
                    showAddOption={!!selectedAccount}
                    labelField="email"
                    type="MEETING"
                    selectedOption={{
                      label: userName,
                      text: userEmail,
                      value: userId,
                      isInternal: true
                    }}
                    addNewlick={
                      selectedAccount
                        ? () => {
                            setAddContact(true);
                          }
                        : undefined
                    }
                    onChange={(option) => {
                      setParticipants(option);
                    }}
                  />
                  {dropdownVisible && !!autoSuggestOptions?.length && (
                    <List
                      id="participant-list"
                      style={{
                        width: "785px",
                        maxHeight: "250px",
                        overflowY: "auto",
                        position: "absolute",
                        zIndex: 1000000,
                        backgroundColor: "white",
                        border: "1px solid #E5E5E5",
                        borderRadius: "4px"
                      }}
                    >
                      {autoSuggestOptions
                        .filter(
                          (p) => !participants.find((p1) => p1.text === p.text)
                        )
                        .map((option: participant, index) => {
                          console.log("IMAZ___option");
                          return (
                            <li
                              key={option.text! + index}
                              style={{
                                borderBottom: "1px solid #E5E5E5",
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                const prevTo = [...participants];
                                setParticipants([...prevTo, option]);
                                setValue("meetingParticipants", [
                                  ...prevTo,
                                  option
                                ]);
                              }}
                            >
                              <AccountOption
                                person={option}
                                onClick={() => {}}
                              />
                            </li>
                          );
                        })}
                    </List>
                  )}
                </Grid>
              )}
              {isScheduled && (
                <Grid
                  item
                  container
                  spacing="15px"
                  alignItems="flex-start"
                  direction="row"
                >
                  <Grid
                    item
                    container
                    spacing="10px"
                    alignItems="flex-start"
                    direction="column"
                    xs={5}
                  >
                    <Grid item>
                      <StyledFormLabel required>
                        <Typography variant="formLabel">
                          Pick a Date and Time
                        </Typography>
                      </StyledFormLabel>
                    </Grid>
                    <Grid item alignSelf="flex-start">
                      <StyledDateCalendar
                        loading={isLoadingSlots}
                        sx={{
                          border: "1px solid #e5e5e5",
                          height: "300px"
                        }}
                        views={["day"]}
                        onChange={(e) => {
                          setSelectedDate(e);
                        }}
                        disablePast
                        shouldDisableDate={(date: Date) => {
                          return date.getDay() == 0 || date.getDay() == 6;
                        }}
                        slots={{
                          day: ServerDay
                        }}
                        onMonthChange={(e: Date) => {
                          setMonthYear({
                            month: e.getMonth(),
                            year: e.getFullYear()
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                  {selectedDate && (
                    <Grid
                      item
                      container
                      direction="column"
                      xs={7}
                      spacing="20px"
                      marginTop="15px"
                    >
                      <Grid item container direction="row" spacing="20px">
                        {generateTimeSlots().map((t) => {
                          const isDisabled = isTimeTaken(t.value);
                          return (
                            <Grid item key={t.value} xs={3}>
                              <Button
                                disabled={isDisabled}
                                style={{
                                  width: "100px",
                                  ...(!isDisabled && {
                                    backgroundColor:
                                      selectedTime === t.value
                                        ? colors.primary.main
                                        : "#F3F4F7",
                                    color:
                                      selectedTime === t.value
                                        ? "white"
                                        : "black"
                                  })
                                }}
                                onClick={() => {
                                  setSelectedTime(t.value);
                                }}
                              >
                                {t.label}
                              </Button>
                            </Grid>
                          );
                        })}
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{
                            color: "black",
                            fontWeight: 500
                          }}
                        >
                          Time Zone: {getTimezoneAbbreviation()}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
            <Footer
              cancelBtnClick={() => setOpenCancelDialog(true)}
              saveBtnClick={() => {
                onSave();
              }}
              saveBtnLabel="Continue"
              isDisabled={
                !isDirty || !isValid || participants.length < 2 || !selectedTime
              }
              isLoading={isSaving}
            />
            <DevTool control={control} />
          </div>
          <ConfirmationDialog
            title="Are you sure you want to cancel?"
            body="All of your current changes will be lost."
            open={openCancelDialog}
            close={() => setOpenCancelDialog(false)}
            onCancel={() => setOpenCancelDialog(false)}
            onConfirm={() => setOpen(false)}
            cancelBtnText="Cancel"
            confirmBtnText="Confirm"
          />
        </MainContainer>
      </Modal>
    );
  } else {
    return (
      <QuickAddContactModal
        showAddContactModal={addContact}
        setShowAddContactModal={setAddContact}
        selectedAccount={selectedAccount}
        onSave={(resp) => {
          if (resp.contactId) {
            const values = getValues()["meetingParticipants"];
            const newValues = [...values];
            newValues.push({
              label: formatFullName(resp),
              value: resp.contactId,
              text: resp.email,
              isInternal: false
            });
            setValue("meetingParticipants", newValues, {
              shouldDirty: true,
              shouldValidate: true
            });
            setParticipants(newValues);
          }
        }}
      />
    );
  }
};
