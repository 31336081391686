import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { FormSwitch } from "@components/FormSwitch";
import Spacer from "@components/Spacer";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import { FormLabel, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import Sentry from "@services/Sentry";
import {
  ModelPermission,
  ModelRole,
  RoleAlias,
  RoleType,
  adminRoleRoleIdGet,
  useAdminPermissionGet,
  useAdminRoleGet,
  useAdminSubroleGet,
  useAdminSubrolePost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { organizationAtom } from "../../recoil/auth";
import { capitalize, capitalizeFirstCharacter } from "../../utils/capitalize";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));
const StyledDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  backgroundColor: "#F4F6F9",
  padding: "12px 20px 12px 0",
  boxShadow: "0px 1px 2px 0px #0000001A",
  borderRadius: "4px",
  minWidth: 600,
  width: "65%",
  [theme.breakpoints.down("xl")]: {
    width: "100%"
  }
}));

export const SubroleCreate = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [role, setRole] = useState<ModelRole | undefined>(undefined);
  const [disabledCheckBox, setDisabledCheckBox] = useState<string[]>([]);
  const [permanentDisabledCheckBox, setPermanentDisabledCheckBox] = useState<
    string[]
  >([]);
  const [permanentCheckedCheckBox, setPermanentCheckedCheckBox] = useState<
    string[]
  >([]);
  const [organizationId] = useRecoilState(organizationAtom);
  const [permissionLoading, setPermissionLoading] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  const {
    handleSubmit,
    control,
    formState: { isValid },
    reset,
    setValue,
    getValues,
    watch
  } = useForm({
    mode: "onBlur"
  });
  const roleType = watch("type");
  const renderChildren = (
    parentId,
    children,
    control,
    setValue,
    getValues,
    indentLevel = 1
  ) => {
    return (
      <>
        {children
          .filter(
            (c) =>
              !role ||
              role?.permissions?.some(
                (rp) => rp.permissionId === c.permissionId
              )
          )
          .map((child) => {
            const key = child.permissionId.replace(/\./g, "__");
            const override = role?.permissions
              ?.find((rp) => rp.permissionId === child.permissionId)
              ?.permission?.roleTypeAliasPermissions?.find(
                (rtap) =>
                  rtap.roleAlias === role?.alias && rtap.roleType === role.type
              );
            let overrideName = child.name;
            let overrideActions = child.actions;
            if (override) {
              overrideName = override.nameOverride;
              overrideActions = override.allowActions;
            }
            return (
              <div data-testid={child.permissionId} key={key}>
                <StyledDiv>
                  <Typography
                    variant="permissionNames"
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: 300,
                      paddingLeft: `${indentLevel * 20}px`
                    }}
                  >
                    {overrideName}
                  </Typography>

                  <div
                    style={{ display: "flex", flexDirection: "row", flex: 1 }}
                  >
                    {child.type === "MULTI" &&
                      ["ALL", "VIEW", "ADD", "EDIT", "DELETE"].map((action) => {
                        if (overrideActions.includes(action)) {
                          return (
                            <FormCheckbox
                              key={`${key}__${action}`}
                              control={control}
                              name={`${key}__${action}`}
                              label={action[0] + action.slice(1).toLowerCase()}
                              labelPadding="4px 0"
                              labelTypographyProps={{
                                variant: "body1",
                                marginLeft: "8px"
                              }}
                              disabled={
                                disabledCheckBox.includes(
                                  `${key}__${action}`
                                ) ||
                                (action === "VIEW" &&
                                  (getValues(`${key}__ADD`) == true ||
                                    getValues(`${key}__EDIT`) == true ||
                                    getValues(`${key}__DELETE`) == true)) ||
                                permanentDisabledCheckBox.includes(
                                  `${key}__${action}`
                                )
                              }
                              onChange={(e) => {
                                const checked = e.target.checked;
                                if (action === "ALL") {
                                  overrideActions.map((action) => {
                                    if (
                                      !permanentCheckedCheckBox.includes(
                                        `${key}__${action}`
                                      )
                                    )
                                      setValue(`${key}__${action}`, checked);
                                  });
                                } else if (action === "VIEW") {
                                  if (!checked) {
                                    overrideActions.map((action) => {
                                      if (
                                        ["ADD", "EDIT", "DELETE"].includes(
                                          action
                                        )
                                      ) {
                                        setValue(`${key}__${action}`, checked);
                                      }
                                    });
                                  }
                                } else if (["DELETE"].includes(action)) {
                                  if (checked) {
                                    setValue(`${key}__VIEW`, checked);
                                  }
                                } else if (["EDIT"].includes(action)) {
                                  if (checked) {
                                    setValue(`${key}__VIEW`, checked);
                                    if (
                                      !control._fields[`${key}__ADD`] != true
                                    ) {
                                      setValue(`${key}__ADD`, checked);
                                    }
                                  }
                                } else if (["ADD"].includes(action)) {
                                  if (checked) {
                                    setValue(`${key}__VIEW`, checked);
                                    if (
                                      !control._fields[`${key}__EDIT`] != true
                                    ) {
                                      setValue(`${key}__EDIT`, checked);
                                    }
                                  }
                                }

                                const actionsLength =
                                  overrideActions.length - 1;
                                let actionsChecked = 0;
                                overrideActions.map((action) => {
                                  if (
                                    getValues(`${key}__${action}`) &&
                                    action !== "ALL"
                                  ) {
                                    actionsChecked++;
                                  }
                                });
                                setValue(
                                  `${key}__ALL`,
                                  actionsLength === actionsChecked
                                );
                                const permission = key.split("__")[0];
                                const type = key.split("__")[1];
                                const permissionType = type.includes("-")
                                  ? type.split("-").pop().trim()
                                  : type.trim();
                                if (
                                  key == "team__teams" ||
                                  key == "training-programs__training-programs"
                                ) {
                                  setValue(
                                    `${permission}__associated-${permissionType}__ALL`,
                                    getValues(`${permission}__${type}__ALL`)
                                  );
                                  setValue(
                                    `${permission}__associated-${permissionType}__VIEW`,
                                    true
                                  );
                                  setValue(
                                    `${permission}__associated-${permissionType}__EDIT`,
                                    getValues(`${permission}__${type}__EDIT`)
                                  );
                                  setValue(
                                    `${permission}__associated-${permissionType}__DELETE`,
                                    getValues(`${permission}__${type}__DELETE`)
                                  );
                                  getValues(`${permission}__${type}__ALL`) ==
                                  true
                                    ? setDisabledCheckBox((prev) => [
                                        ...prev,
                                        `${permission}__associated-${permissionType}__ALL`
                                      ])
                                    : setDisabledCheckBox((prev) =>
                                        prev.filter(
                                          (item) =>
                                            item !==
                                            `${permission}__associated-${permissionType}__ALL`
                                        )
                                      );
                                  getValues(`${permission}__${type}__EDIT`) ==
                                  true
                                    ? setDisabledCheckBox((prev) => [
                                        ...prev,
                                        `${permission}__associated-${permissionType}__EDIT`
                                      ])
                                    : setDisabledCheckBox((prev) =>
                                        prev.filter(
                                          (item) =>
                                            item !==
                                            `${permission}__associated-${permissionType}__EDIT`
                                        )
                                      );
                                  getValues(`${permission}__${type}__DELETE`) ==
                                  true
                                    ? setDisabledCheckBox((prev) => [
                                        ...prev,
                                        `${permission}__associated-${permissionType}__DELETE`
                                      ])
                                    : setDisabledCheckBox((prev) =>
                                        prev.filter(
                                          (item) =>
                                            item !==
                                            `${permission}__associated-${permissionType}__DELETE`
                                        )
                                      );
                                }
                                if (
                                  getValues(`${key}__ADD`) === true ||
                                  getValues(`${key}__EDIT`) === true ||
                                  getValues(`${key}__DELETE`) === true
                                ) {
                                  setDisabledCheckBox((prev) => [
                                    ...prev,
                                    `${key}__VIEW`
                                  ]);
                                }
                                if (
                                  (getValues(`${key}__ADD`) == false ||
                                    getValues(`${key}__ADD`) == undefined) &&
                                  (getValues(`${key}__EDIT`) == false ||
                                    getValues(`${key}__EDIT`) == undefined) &&
                                  (getValues(`${key}__DELETE`) == false ||
                                    getValues(`${key}__DELETE`) == undefined)
                                ) {
                                  setDisabledCheckBox((prev) =>
                                    prev.filter(
                                      (item) => item !== `${key}__VIEW`
                                    )
                                  );
                                }
                                if (key === "general__calendar") {
                                  if (getValues(`${key}__VIEW`)) {
                                    setDisabledCheckBox((prev) =>
                                      prev.filter(
                                        (item) =>
                                          item !== `general__calendar-import`
                                      )
                                    );
                                  } else {
                                    setValue("general__calendar-import", false);
                                    setDisabledCheckBox((prev) => [
                                      ...prev,
                                      `general__calendar-import`
                                    ]);
                                  }
                                }
                              }}
                            />
                          );
                        } else {
                          return (
                            <div
                              key={`${key}__${action}`}
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                width: "100%"
                              }}
                            />
                          );
                        }
                      })}
                    {child.type === "BOOL" && (
                      <FormSwitch
                        control={control}
                        name={`${key}`}
                        disabled={
                          disabledCheckBox.includes(`${key}`) ||
                          (getValues(`team__all-social`) == true &&
                            (key == "team__associated-comment" ||
                              key == "team__associated-post" ||
                              key == "team__associated-share")) ||
                          (getValues(`training-programs__all-social`) == true &&
                            (key == "training-programs__associated-post" ||
                              key == "training-programs__associated-comment" ||
                              key == "training-programs__associated-share"))
                        }
                        onChange={(e) => {
                          const on = e.target.checked;
                          const permission = key.split("__")[0];
                          if (key == `${permission}__all-social`) {
                            setValue(`${permission}__associated-comment`, on);
                            setValue(`${permission}__associated-post`, on);
                            setValue(`${permission}__associated-share`, on);
                            if (
                              getValues(`${permission}__all-social`) == true
                            ) {
                              setDisabledCheckBox((prev) => [
                                ...prev,
                                `${permission}__associated-comment`,
                                `${permission}__associated-post`,
                                `${permission}__associated-share`
                              ]);
                            } else {
                              setDisabledCheckBox((prev) =>
                                prev.filter(
                                  (item) =>
                                    item !== `${permission}__associated-comment`
                                )
                              );
                              setDisabledCheckBox((prev) =>
                                prev.filter(
                                  (item) =>
                                    item !== `${permission}__associated-post`
                                )
                              );
                              setDisabledCheckBox((prev) =>
                                prev.filter(
                                  (item) =>
                                    item !== `${permission}__associated-share`
                                )
                              );
                            }
                          }
                          if (key == `${permission}__associated-post`) {
                            setValue(`${permission}__associated-share`, on);
                            setValue(`${permission}__associated-comment`, on);
                          }
                        }}
                      />
                    )}
                  </div>
                </StyledDiv>
                {([
                  "general",
                  "user",
                  "admin",
                  "live-streaming",
                  "tech",
                  "hr",
                  "support"
                ].includes(parentId) ||
                  [
                    "crm.leads",
                    "crm.accounts-owner-edit",
                    "crm.contacts",
                    "crm.manager-feedback",
                    "crm.override-pricing",
                    ...(roleType === "ORGANIZATION" || organizationId
                      ? ["crm.activities", "feed.canned-messaged"]
                      : [])
                  ].includes(child.permissionId)) && (
                  <Spacer
                    pb={8}
                    style={{ backgroundColor: "#FFF", maxWidth: 800 }}
                  />
                )}

                {child.children.length > 0 &&
                  renderChildren(
                    child.permissionId,
                    child.children,
                    control,
                    setValue,
                    getValues,
                    indentLevel + 1
                  )}
              </div>
            );
          })}
        {indentLevel < 3 && (
          <Spacer pb={8} style={{ backgroundColor: "#FFF", maxWidth: 800 }} />
        )}
      </>
    );
  };
  const { data: parentRoleOptionsRequest, isLoading: isParentRoleLoading } =
    useAdminRoleGet({
      ...(organizationId && { organizationId: organizationId }),
      type: organizationId ? RoleType.ORGANIZATION : undefined,
      pageSize: "100"
    });

  const { data: subRoles, isLoading: isSubRolesLoading } = useAdminSubroleGet({
    organizationId: organizationId,
    pageSize: "100"
  });

  const [roleTypes, setRoleTypes] = useState(
    organizationId
      ? [
          {
            label: capitalize(RoleType.ORGANIZATION),
            value: RoleType.ORGANIZATION
          }
        ]
      : Object.values(RoleType)
          .filter((type) => type === "ORGANIZATION" || type === "SYSTEM")
          .sort((a) => (a === "SYSTEM" ? -1 : 1))
          .map((roleType) => {
            return { label: capitalize(roleType), value: roleType };
          })
  );
  const roleAliases = Object.values(RoleAlias)
    .filter(
      (roleAlias) =>
        roleAlias === "ADMIN" ||
        roleAlias === "COACH" ||
        roleAlias === "MANAGER"
    )
    .map((roleAlias) => {
      return { label: capitalize(roleAlias), value: roleAlias };
    });

  const { data: permissionsRequest, isFetching: isPermissionsFetching } =
    useAdminPermissionGet(
      {
        organizationId
      },
      {
        query: {
          staleTime: Infinity,
          refetchOnWindowFocus: false,
          refetchOnMount: false,
          refetchOnReconnect: false,
          retry: false
        }
      }
    );

  const permissions = useMemo(
    () => permissionsRequest?.data || [],
    [permissionsRequest]
  );

  const rolePermissions = useMemo(() => {
    if (!permissions.length || !role) {
      return {};
    }

    return (
      role.permissions?.reduce((acc, permission) => {
        const key = permission?.permissionId?.replace(/\./g, "__") as string;
        switch (permission?.permission?.type) {
          case "BOOL":
            acc[key] = permission?.actions?.includes("ON");
            break;
          case "MULTI":
            permission?.actions?.map((action) => {
              acc[key + "__" + action] = true;
            });
            break;
        }
        return acc;
      }, {}) || {}
    );
  }, [permissions, role]);

  const validateName = (name) => {
    return subRoles?.data.roles
      ?.map((sr) => sr.name?.toLocaleLowerCase() === name.toLocaleLowerCase())
      .includes(true)
      ? "subrole"
      : parentRoleOptionsRequest?.data?.roles
          ?.map((r) => r.name?.toLocaleLowerCase() === name.toLocaleLowerCase())
          .includes(true)
      ? "role"
      : false;
  };

  useEffect(() => {
    if (role) {
      const permissions = role.permissions;
      if (
        permissions?.find((p) => p.permissionId == "general.calendar") &&
        permissions?.find((p) => p.permissionId == "general.calendar-import")
      ) {
        const calendarPer = permissions?.find(
          (p) => p.permissionId == "general.calendar"
        );
        if (!calendarPer?.actions?.includes("VIEW")) {
          setDisabledCheckBox((prev) => [...prev, `general__calendar-import`]);
        }
      }
      if (
        permissions?.find((p) => p.permissionId === "team.associated-teams")
      ) {
        if (role.type === "ORGANIZATION" || role.type === "PERSON") {
          setDisabledCheckBox((prev) => [
            ...prev,
            `team__associated-teams__VIEW`
          ]);
          setPermanentDisabledCheckBox((prev) => [
            ...prev,
            "team__associated-teams__VIEW"
          ]);
          setPermanentCheckedCheckBox((prev) => [
            ...prev,
            "team__associated-teams__VIEW"
          ]);
          setValue("team__associated-teams__VIEW", true);
        }
      }
      if (
        permissions?.find(
          (p) => p.permissionId === "training-programs.associated-programs"
        )
      ) {
        if (role.type === "ORGANIZATION" || role.type === "PERSON") {
          setDisabledCheckBox((prev) => [
            ...prev,
            `training-programs__associated-programs__VIEW`
          ]);
          setPermanentDisabledCheckBox((prev) => [
            ...prev,
            "training-programs__associated-programs__VIEW"
          ]);
          setPermanentCheckedCheckBox((prev) => [
            ...prev,
            "training-programs__associated-programs__VIEW"
          ]);
          setValue("training-programs__associated-programs__VIEW", true);
        }
      }
      if (permissions?.find((p) => p.permissionId === "general.dashboard")) {
        setPermanentDisabledCheckBox((prev) => [
          ...prev,
          "general__dashboard__VIEW"
        ]);
        setPermanentCheckedCheckBox((prev) => [
          ...prev,
          "general__dashboard__VIEW"
        ]);
        setValue("general__dashboard__VIEW", true);
      }
    }
  }, [role]);

  useEffect(() => {
    if (role && rolePermissions) {
      const { parentId, type, displayText, description, name } = getValues();
      reset({
        name: name,
        parentId,
        type,
        description: description,
        displayText: displayText,
        ...rolePermissions
      });
      setRefreshKey(refreshKey + 1);
    }
  }, [role, rolePermissions]);
  const parentRoles = useMemo(() => {
    if (!parentRoleOptionsRequest?.data?.roles) {
      return [];
    }
    return parentRoleOptionsRequest.data.roles;
  }, [parentRoleOptionsRequest]);

  const setDefaultsFromParent = async (alias, type) => {
    try {
      setPermissionLoading(true);
      const parentId = parentRoles.find(
        (role) => role.alias === alias && role.type === type
      )!.roleId;
      const parent = await adminRoleRoleIdGet(parentId!);
      setPermissionLoading(false);
      if (parent) {
        setDisabledCheckBox([]);
        setRole(parent.data);
      }
    } catch (e) {
      setPermissionLoading(false);
      Sentry.captureException(e);
    }
  };

  const { mutate: update, isLoading: isUpdateLoading } = useAdminSubrolePost();
  const saveHandler = (formValues) => {
    const permissions = Object.keys(formValues).reduce(
      (acc, key) => {
        if (
          ["name", "displayText", "type", "parentId", "description"].includes(
            key
          )
        ) {
          return acc;
        }
        if (formValues[key]) {
          const parts = key.split("__");
          const action = parts[parts.length - 1];
          let permissionId = parts.join(".");
          if (["ALL", "VIEW", "ADD", "EDIT", "DELETE"].includes(action)) {
            permissionId = parts.slice(0, parts.length - 1).join(".");
            if (!acc[permissionId]) {
              acc[permissionId] = [];
            }
            acc[permissionId].push(action);
          } else {
            acc[permissionId] = ["ON"];
          }
        } else {
          const parts = key.split("__");
          const action = parts[parts.length - 1];
          let permissionId = parts.join(".");
          if (["ALL", "VIEW", "ADD", "EDIT", "DELETE"].includes(action)) {
            permissionId = parts.slice(0, parts.length - 1).join(".");
            if (!acc[permissionId]) {
              acc[permissionId] = [];
            }
          } else {
            if (!acc[permissionId]) {
              acc[permissionId] = [];
            }
          }
        }
        return acc;
      },
      {} as { [key: string]: string[] }
    );

    update(
      {
        data: {
          name: formValues.name,
          displayText: formValues.displayText || role?.displayText || "",
          description: formValues.description,
          parentId: role?.roleId,
          type: role?.type,
          ...(organizationId && { organizationId: organizationId }),
          permissions: Object.keys(permissions).map((permissionId) => {
            return {
              permissionId,
              actions: permissions[permissionId]
            };
          }) as ModelPermission[]
        }
      },
      {
        onSuccess: (response) => {
          enqueueSnackbar("Sub role added successfully!", {
            variant: "success"
          });
          navigate(`/subroles/${response.data.roleId}`);
        },
        onError: () => {
          enqueueSnackbar("Failed to add sub role!", { variant: "error" });
        }
      }
    );
  };

  return (
    <Container>
      <Toolbar title="Add Sub Role" />
      <Form>
        <Loader isLoading={isPermissionsFetching || isSubRolesLoading}>
          <Grid container spacing={2}>
            <Grid data-testid="subrole-name" xs={12} md={6}>
              <FormInput
                control={control}
                name="name"
                type="text"
                label="Name"
                required={true}
                onChange={(e) =>
                  setValue("name", capitalizeFirstCharacter(e.target.value))
                }
                rules={{
                  required: "Name is required",
                  validate: (v) =>
                    validateName(v) == "subrole"
                      ? "Subrole with this name already exists"
                      : validateName(v) == "role"
                      ? "Role with this name already exists"
                      : true
                }}
              />
            </Grid>
            {!organizationId && (
              <Grid data-testid="subrole-displayText" xs={12} md={6}>
                <FormInput
                  control={control}
                  name="displayText"
                  type="text"
                  label="Display Text"
                  required={true}
                  rules={{
                    required: "Display Text is required"
                  }}
                />
              </Grid>
            )}
            <Grid data-testid="subrole-role" xs={12} md={6}>
              <FormSelect
                control={control}
                name="parentId"
                label="Role"
                required={true}
                options={roleAliases}
                isLoading={isParentRoleLoading}
                onChange={(e) => {
                  if (organizationId) {
                    setDefaultsFromParent(
                      e.target.value,
                      RoleType.ORGANIZATION
                    );
                  } else {
                    const filteredRoleTypes =
                      [
                        ...new Set(
                          parentRoles
                            .filter((r) => r.alias === e.target.value)
                            .map((r) => r.type!)
                        )
                      ] || [];
                    const filteredRoleType = filteredRoleTypes
                      .filter(
                        (roleType) =>
                          roleType === "ORGANIZATION" || roleType === "SYSTEM"
                      )
                      .sort((a) => (a === "SYSTEM" ? -1 : 1))

                      .map((roleType) => {
                        return {
                          label: capitalize(roleType),
                          value: roleType
                        };
                      });
                    setRoleTypes(filteredRoleType);
                    if (filteredRoleType.length == 1) {
                      setDefaultsFromParent(
                        e.target.value,
                        filteredRoleType[0].value
                      );
                      setValue("type", filteredRoleType[0].value);
                    }
                    if (filteredRoleType.length > 1 && getValues().type != "") {
                      setValue("type", filteredRoleType[0].value);
                      setDefaultsFromParent(
                        e.target.value,
                        filteredRoleType[0].value
                      );
                    }
                  }
                }}
                rules={{
                  required: "Role is required"
                }}
              />
            </Grid>
            {!organizationId && (
              <Grid data-testid="subrole-roleType" xs={12} md={6}>
                <FormSelect
                  control={control}
                  name="type"
                  label="Role Type"
                  required={true}
                  options={roleTypes}
                  rules={{
                    required: "Role Type is required"
                  }}
                  onChange={(e) => {
                    if (!organizationId) {
                      setDefaultsFromParent(
                        getValues("parentId"),
                        e.target.value
                      );
                    }
                  }}
                />
              </Grid>
            )}
            <Grid data-testid="subrole-description" xs={12}>
              <FormInput
                control={control}
                name="description"
                type="text"
                label="Description"
                required={true}
                multiline={true}
                TextProps={{
                  rows: 2
                }}
                rules={{
                  required: "Description is required"
                }}
              />
            </Grid>
            <Grid
              data-testid="permissions"
              data-testid1={`${getValues().parentId}.${getValues().type}`}
              xs={12}
            >
              <StyledFormLabel required={true}>
                <Typography display="inline" variant="body1">
                  Permissions
                </Typography>
              </StyledFormLabel>
              <Loader isLoading={permissionLoading}>
                {role &&
                  permissions
                    .filter(
                      (permission) =>
                        !role ||
                        role?.permissions?.some(
                          (rp) =>
                            rp.permission?.parentId === permission.permissionId
                        )
                    )
                    .map((permission) => {
                      const key = permission.permissionId?.replace(/\./g, "__");
                      return (
                        <React.Fragment
                          key={key?.concat(refreshKey.toString())}
                        >
                          <Spacer mt={8} />
                          <Typography
                            data-testid={permission.permissionId}
                            sx={{
                              textTransform: "uppercase",
                              color: "#ccc",
                              fontWeight: "bold"
                            }}
                          >
                            {permission.name}
                          </Typography>
                          {renderChildren(
                            permission.permissionId,
                            permission.children,
                            control,
                            setValue,
                            getValues
                          )}
                        </React.Fragment>
                      );
                    })}
              </Loader>
            </Grid>
          </Grid>
        </Loader>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler)}
        isDisabled={
          !isValid ||
          isUpdateLoading ||
          isParentRoleLoading ||
          isSubRolesLoading
        }
        isLoading={isUpdateLoading}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/subroles")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
