import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  Link,
  Typography,
  styled,
  Stack
} from "@mui/material";
import { differenceInYears } from "date-fns";
import { useEffect, useState } from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { SearchInput } from "@components/SearchInput";
import { getUsers } from "@services/Network";
import formatAddress from "@utils/formatAddress";
import formatFullName from "@utils/formatFullName";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import {
  capitalize,
  capitalizeEveryWord,
  capitalizeFirstCharacter
} from "@utils/capitalize";
import {
  ModelUser,
  ModelUserRoleAliasBan
} from "@sportsgravyengineering/sg-api-react-sdk";

const ProfileThumbnail = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "32px",
  height: "32px",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.white.main
}));

const StyledPersonOption = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: "12px 16px",
  borderBottom: `1px solid ${theme.palette.divider}`,
  cursor: "pointer",

  "&:hover": {
    backgroundColor: "#F5F5F5"
  }
}));

const StyledAddPersonOption = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: "12px 16px"
}));

const LinkButton = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginLeft: "8px",
  cursor: "pointer",

  "&:hover": {
    opacity: 0.8
  },

  "&.disabled": {
    cursor: "default",

    "&:hover": {
      opacity: 1
    }
  }
}));

const suffixOptions = [
  { label: "Sr.", value: "Sr." },
  { label: "Jr.", value: "Jr." },
  { label: "II", value: "II" },
  { label: "III", value: "III" },
  { label: "IV", value: "IV" }
];

type Person = {
  personId: string;
  firstName: string;
  lastName: string;
  birthedAt: string;
  photo?: string;
  addressPrimary: {
    lines: string[];
    locality: string;
    province: string;
    postalCode: string;
    country: string;
  };
  user?: ModelUser;
};

const PersonOption = ({
  person,
  onClick,
  disableBannedRole,
  organizationId,
  ...props
}: {
  person: Person | null;
  onClick: () => void;
  disableBannedRole?;
  organizationId?: string;
}) => {
  const fullName = formatFullName(person);
  const age = person?.birthedAt
    ? differenceInYears(new Date(), new Date(person.birthedAt))
    : null;
  const ageText = age ? `${age} years old` : "";
  const address = formatAddress(person?.addressPrimary);
  const optionComponents = [fullName, ageText, address];
  const optionText = optionComponents
    .filter((component) => !!component)
    .join(" | ");
  const avatarUrl = person?.avatar?.baseUrl
    ? person?.avatar?.baseUrl + person?.avatar?.path
    : undefined;
  const initialsText = person
    ? `${person?.firstName?.[0]}${person?.lastName?.[0]}`
    : "";
  const bannedRolesSG =
    person?.user?.roleAliasBans?.filter((r) => !r.organizationId) || [];

  const getBannedValues = (
    orgBannedRoles: ModelUserRoleAliasBan[],
    SGBannedRoles
  ) => {
    let isBanned = false;
    let isBannedFromMultiple = false;
    let bannedRolesNames = "";
    let countForMultiple = 2;

    if (SGBannedRoles.length) {
      isBanned = true;
      countForMultiple = countForMultiple - 1;
    }

    const isAdminBannedFromSG = SGBannedRoles.map(
      (role) => role.alias
    ).includes("ADMIN");

    if (orgBannedRoles.length) {
      const organizationSet = new Set();
      orgBannedRoles.forEach((role) => {
        const orgId = role.organizationId ? role.organizationId : "0";
        organizationSet.add(orgId);
      });
      if (organizationSet.size > countForMultiple) isBannedFromMultiple = true;
      if (!isBannedFromMultiple && isBanned)
        bannedRolesNames = orgBannedRoles
          .map((role) => capitalizeFirstCharacter(role?.alias) || "")
          .join(", ");
    }

    return {
      isBanned,
      isBannedFromMultiple,
      bannedRolesNames,
      isAdminBannedFromSG
    };
  };
  const { isBanned, isBannedFromMultiple, isAdminBannedFromSG } =
    getBannedValues(
      person?.user?.roleAliasBans?.filter((r) => !!r.organizationId) || [],
      bannedRolesSG
    );
  return (
    <StyledPersonOption
      container
      data-testid="ADD_PERSON_OPTION_CONTAINER"
      {...props}
      direction="row"
      alignItems="center"
      onClick={onClick}
      spacing="10px"
      style={
        isAdminBannedFromSG ||
        (disableBannedRole &&
          (bannedRolesSG.map((r) => r.alias).includes(disableBannedRole) ||
            person?.user?.roleAliasBans
              ?.filter((r) => r.organizationId === organizationId)
              ?.map((r) => r.alias)
              .includes(disableBannedRole)))
          ? { pointerEvents: "none" }
          : {}
      }
    >
      <Grid item maxWidth="50px">
        <ProfileThumbnail>
          {avatarUrl ? (
            <img
              src={avatarUrl}
              width="32px"
              height="32px"
              alt={`${person?.firstName} ${person?.lastName}`}
            />
          ) : (
            <span>{initialsText}</span>
          )}
        </ProfileThumbnail>
      </Grid>
      <Grid item xs={11}>
        {optionText}
      </Grid>
      {isBanned && (
        <Stack
          direction="row"
          alignItems="center"
          gap={0.75}
          marginTop={"3px"}
          marginLeft={"50px"}
          data-testid="ADD_USER_SEARCH_BANNED_VIEW"
        >
          <WarningAmberIcon
            sx={{
              color: "#E1A201",
              width: "15px",
              height: "15px",
              top: "294px",
              left: "368px"
            }}
          />
          <Typography
            sx={{
              color: "#E1A201",
              fontSize: "12px",
              font: "inter",
              lineHeight: "14.52px",
              letterSpacing: "10%",
              fontWeight: 400
            }}
          >
            {isBannedFromMultiple
              ? "This person has been banned by multiple organizations"
              : "This person has been banned by" +
                " Sportsgravy for the following roles: " +
                bannedRolesSG.map((role) => capitalize(role.alias)).join(", ")}
          </Typography>
        </Stack>
      )}
    </StyledPersonOption>
  );
};

const AddPersonOption = ({ onClick }: { onClick: () => void }) => {
  return (
    <StyledAddPersonOption container direction="row" alignItems="center">
      <Grid
        data-testid="add-contact-button"
        container
        item
        xs={12}
        direction="row"
        alignItems="center"
      >
        <Typography>Not finding who you're looking for?</Typography>
        <LinkButton onClick={onClick}>Add Person</LinkButton>
      </Grid>
    </StyledAddPersonOption>
  );
};

// TODO
// Get clarification on the requirements for the following fields:
//   - Are you governed by an organization?
//   - Load data from SportsGravy
//
// Then update the logic accordingly

export const OrganizationContactForm = ({
  control,
  disabled,
  hasSecondaryContact,
  primaryContact,
  secondaryContact,
  selectPrimaryContact,
  selectSecondaryContact,
  setValue,
  contactValid,
  country,
  organizationId
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  disabled: boolean;
  hasSecondaryContact: boolean;
  primaryContact: Person | null;
  secondaryContact: Person | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectPrimaryContact: (any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectSecondaryContact: (any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue?: any;
  contactValid?: (val: boolean) => void;
  country?: string;
  organizationId?: string;
}) => {
  const [isNewPrimaryContact, setIsNewPrimaryContact] = useState(false);
  const [isNewSecondaryContact, setIsNewSecondaryContact] = useState(false);
  const [primaryContactSearch, setPrimaryContactSearch] = useState("");
  const [secondaryContactSearch, setSecondaryContactSearch] = useState("");
  const [primaryMenuOpen, setPrimaryMenuOpen] = useState(false);
  const [secondaryMenuOpen, setSecondaryMenuOpen] = useState(false);

  const { data: primaryContactResults, isFetching: isFetchingPrimaryContacts } =
    getUsers(
      {
        textSearch: encodeURIComponent(primaryContactSearch),
        isMajor: true,
        country: country || "US"
      },
      { staleTime: Infinity, enabled: !!primaryContactSearch?.length }
    );

  const {
    data: secondaryContactResults,
    isFetching: isFetchingSecondaryContacts
  } = getUsers(
    {
      textSearch: encodeURIComponent(secondaryContactSearch),
      isMajor: true,
      country: country || "US"
    },
    { staleTime: Infinity, enabled: !!secondaryContactSearch?.length }
  );

  const onPrimaryContactSearchChange = (event) => {
    setPrimaryContactSearch(event.target.value);
  };

  const onSecondaryContactSearchChange = (event) => {
    setSecondaryContactSearch(event.target.value);
  };

  const onPersonOptionClick = (person: Person | null, isPrimary: boolean) => {
    if (isPrimary) {
      selectPrimaryContact(person);
      setPrimaryMenuOpen(false);
    } else {
      selectSecondaryContact(person);
      setSecondaryMenuOpen(false);
    }
  };

  const onChangePrimaryContact = () => {
    setIsNewPrimaryContact(false);
    selectPrimaryContact(null);
  };

  const onChangeSecondaryContact = () => {
    setIsNewSecondaryContact(false);
    selectSecondaryContact(null);
  };

  useEffect(() => {
    if (contactValid) {
      if (
        (hasSecondaryContact ? isNewSecondaryContact : true) &&
        (isNewPrimaryContact || primaryContact)
      )
        contactValid(true);
      else if (
        (hasSecondaryContact ? secondaryContact : true) &&
        (isNewPrimaryContact || primaryContact)
      )
        contactValid(true);
      else contactValid(false);
    }
  }, [
    isNewPrimaryContact,
    primaryContact,
    hasSecondaryContact,
    isNewSecondaryContact,
    secondaryContact
  ]);
  const isPrimaryContactFormDisabled = disabled || !isNewPrimaryContact;
  const isSecondaryContactFormDisabled = disabled || !isNewSecondaryContact;

  const primaryContactOptions = [...primaryContactResults, null];
  const secondaryContactOptions = [...secondaryContactResults, null];

  return (
    <Grid
      data-testid="org-contact-form"
      container
      direction="column"
      spacing="25px"
    >
      <Grid item container direction="row" spacing="24px">
        <Grid item xs={9}>
          <FormCheckbox
            name="hasPrimaryContact"
            control={control}
            label="Primary Contact"
            disabled
          />
        </Grid>
        <HeaderUnderLine marginLeft="21px" />
      </Grid>
      {isNewPrimaryContact || primaryContact ? (
        <>
          <Grid item container direction="row" spacing="24px">
            <Grid item xs={3}>
              <FormInput
                name="primaryContact.firstName"
                control={control}
                rules={{ required: "First Name is required" }}
                label="First Name"
                onChange={(e) => {
                  setValue(
                    "primaryContact.firstName",
                    capitalizeEveryWord(e.target.value)
                  );
                }}
                type="text"
                required
                disabled={isPrimaryContactFormDisabled}
              />
            </Grid>
            <Grid item xs={3}>
              <FormInput
                name="primaryContact.middleName"
                control={control}
                label="Middle Name"
                type="text"
                disabled={isPrimaryContactFormDisabled}
              />
            </Grid>
            <Grid item xs={3}>
              <FormInput
                name="primaryContact.lastName"
                control={control}
                rules={{ required: "Last Name is required" }}
                label="Last Name"
                type="text"
                required
                onChange={(e) => {
                  setValue(
                    "primaryContact.lastName",
                    capitalizeEveryWord(e.target.value)
                  );
                }}
                disabled={isPrimaryContactFormDisabled}
              />
            </Grid>
            <Grid item xs={3}>
              <FormSelect
                name="primaryContact.suffix"
                control={control}
                label="Suffix"
                options={suffixOptions}
                disabled={isPrimaryContactFormDisabled}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing="24px">
            <Grid item xs={6}>
              <FormInput
                name="primaryContact.email"
                control={control}
                rules={{ required: "Email is required" }}
                label="Email"
                type="text"
                required
                disabled={isPrimaryContactFormDisabled}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                name="primaryContact.phone"
                control={control}
                required
                rules={{ required: "Mobile Phone is required" }}
                label="Mobile Phone"
                type="tel"
                disabled={isPrimaryContactFormDisabled}
              />
            </Grid>
          </Grid>
          {!disabled && (
            <Grid
              data-testid="org-primary-contact-change"
              item
              container
              direction="row"
              spacing="24px"
            >
              <Grid item container xs={12} justifyContent="flex-end" zIndex="1">
                <LinkButton
                  className={disabled ? "disabled" : ""}
                  onClick={onChangePrimaryContact}
                >
                  Change Contact
                </LinkButton>
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <Grid
          data-testid="org-add-contact"
          item
          container
          direction="row"
          spacing="24px"
        >
          <Grid item xs={12}>
            <Autocomplete
              open={primaryMenuOpen}
              clearOnBlur={false}
              onOpen={() => setPrimaryMenuOpen(true)}
              onClose={() => setPrimaryMenuOpen(false)}
              value={primaryContact}
              getOptionLabel={(option) =>
                option ? `${option.firstName} ${option.lastName}` : ""
              }
              isOptionEqualToValue={(option, value) =>
                option.personId === value.personId
              }
              renderInput={(params) => (
                <SearchInput
                  {...params}
                  value={primaryContactSearch}
                  placeholder="Search"
                  onChange={onPrimaryContactSearchChange}
                />
              )}
              filterOptions={(options) =>
                primaryMenuOpen && !isFetchingPrimaryContacts ? options : []
              }
              renderOption={(props, option: Person, state) => {
                if (state.index === primaryContactOptions.length - 1) {
                  return (
                    <AddPersonOption
                      key={state.index}
                      onClick={() => {
                        setIsNewPrimaryContact(true);
                        setValue("primaryContact", {
                          firstName: "",
                          middleName: "",
                          lastName: "",
                          email: "",
                          phone: "",
                          suffix: ""
                        });
                      }}
                    />
                  );
                }
                return (
                  <PersonOption
                    {...props}
                    key={option.personId}
                    person={option}
                    onClick={() => onPersonOptionClick(option, true)}
                  />
                );
              }}
              options={primaryContactOptions || []}
              loading={isFetchingPrimaryContacts}
              loadingText="Loading..."
            />
          </Grid>
        </Grid>
      )}

      <Grid item container direction="row" spacing="24px">
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing="24px">
        <Grid item xs={9}>
          <FormCheckbox
            name="hasSecondaryContact"
            control={control}
            label="Secondary Contact"
            disabled={disabled}
          />
        </Grid>
        <HeaderUnderLine marginLeft="21px" />
      </Grid>

      {hasSecondaryContact &&
        (isNewSecondaryContact || secondaryContact ? (
          <>
            <Grid item container direction="row" spacing="24px">
              <Grid item xs={3} data-testid="secondaryContact.firstName">
                <FormInput
                  name="secondaryContact.firstName"
                  control={control}
                  onChange={(e) => {
                    setValue(
                      "secondaryContact.firstName",
                      capitalizeEveryWord(e.target.value)
                    );
                  }}
                  rules={{ required: "First Name is required" }}
                  label="First Name"
                  type="text"
                  required
                  disabled={isSecondaryContactFormDisabled}
                />
              </Grid>
              <Grid item xs={3} data-testid="secondaryContact.middleName">
                <FormInput
                  name="secondaryContact.middleName"
                  control={control}
                  label="Middle Name"
                  type="text"
                  disabled={isSecondaryContactFormDisabled}
                />
              </Grid>
              <Grid item xs={3} data-testid="secondaryContact.lastName">
                <FormInput
                  name="secondaryContact.lastName"
                  control={control}
                  rules={{ required: "Last Name is required" }}
                  label="Last Name"
                  type="text"
                  onChange={(e) => {
                    setValue(
                      "secondaryContact.lastName",
                      capitalizeEveryWord(e.target.value)
                    );
                  }}
                  required
                  disabled={isSecondaryContactFormDisabled}
                />
              </Grid>
              <Grid item xs={3} data-testid="secondaryContact.suffix">
                <FormSelect
                  name="secondaryContact.suffix"
                  control={control}
                  label="Suffix"
                  options={suffixOptions}
                  disabled={isSecondaryContactFormDisabled}
                />
              </Grid>
            </Grid>
            <Grid item container direction="row" spacing="24px">
              <Grid item xs={6} data-testid="secondaryContact.email">
                <FormInput
                  name="secondaryContact.email"
                  control={control}
                  rules={{ required: "Email is required" }}
                  label="Email"
                  type="text"
                  required
                  disabled={isSecondaryContactFormDisabled}
                />
              </Grid>
              <Grid item xs={6} data-testid="secondaryContact.phone">
                <FormInput
                  name="secondaryContact.phone"
                  control={control}
                  label="Mobile Phone"
                  type="tel"
                  required
                  rules={{ required: "Mobile Phone is required" }}
                  disabled={isSecondaryContactFormDisabled}
                />
              </Grid>
            </Grid>
            {!disabled && (
              <Grid
                data-testid="org-secondary-contact-change"
                item
                container
                direction="row"
                spacing="24px"
              >
                <Grid item container xs={12} justifyContent="flex-end">
                  <LinkButton
                    className={disabled ? "disabled" : ""}
                    onClick={onChangeSecondaryContact}
                  >
                    Change Contact
                  </LinkButton>
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          <Grid
            data-testid="org-add-contact-secondary"
            item
            container
            direction="row"
            spacing="24px"
          >
            <Grid item xs={12}>
              <Autocomplete
                open={secondaryMenuOpen}
                clearOnBlur={false}
                onChange={selectSecondaryContact}
                onOpen={() => setSecondaryMenuOpen(true)}
                onClose={() => setSecondaryMenuOpen(false)}
                value={secondaryContact}
                getOptionLabel={(option) =>
                  option ? `${option.firstName} ${option.lastName}` : ""
                }
                isOptionEqualToValue={(option, value) =>
                  option.personId === value.personId
                }
                renderInput={(params) => (
                  <SearchInput
                    {...params}
                    value={secondaryContactSearch}
                    placeholder="Search"
                    onChange={onSecondaryContactSearchChange}
                  />
                )}
                filterOptions={(options) =>
                  secondaryMenuOpen && !isFetchingSecondaryContacts
                    ? options
                    : []
                }
                renderOption={(props, option: Person, state) => {
                  if (state.index === secondaryContactOptions.length - 1) {
                    return (
                      <AddPersonOption
                        key={state.index}
                        onClick={() => {
                          setIsNewSecondaryContact(true);
                          setValue("secondaryContact", {
                            firstName: "",
                            middleName: "",
                            lastName: "",
                            email: "",
                            phone: "",
                            suffix: ""
                          });
                        }}
                      />
                    );
                  }
                  return (
                    <PersonOption
                      {...props}
                      key={option.personId}
                      person={option}
                      onClick={() => onPersonOptionClick(option, false)}
                      organizationId={organizationId}
                    />
                  );
                }}
                options={secondaryContactOptions || []}
                loading={isFetchingSecondaryContacts}
                loadingText="Loading..."
              />
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
};
