import {
  Box,
  Button,
  Grid,
  styled,
  TextField,
  Typography
} from "@mui/material";
import {
  DateCalendar,
  PickersDay,
  PickersDayProps
} from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button as SGButton } from "@components/Button";
import { Loader } from "@components/crud/Loader";
import { formatDateForDisplay } from "@utils/formatDate";
import colors from "theme/colors";
import {
  AdminCrmMeetingEventIdAvailableSlotsGet200Item,
  useAdminCrmMeetingEventIdAvailableSlotsGet,
  usePostAdminCrmMeetingOnboardingEventId
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;
const StyledDateCalendar = styled(DateCalendar)`
  .MuiDateCalendar-root {
    border: 1px solid #e5e5e5;
  }
  .MuiPickersCalendarHeader-root {
    border-bottom: 1px solid #e5e5e5;
  }
`;

const areDatesEqual = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};
const getTimezoneAbbreviation = (timezone) => {
  const date = new Date();
  const options = { timeZone: timezone, timeZoneName: "long" };
  const timeZoneName = new Intl.DateTimeFormat("en-US", options)
    .formatToParts(date)
    .find((part) => part.type === "timeZoneName").value;
  const abbreviation = timeZoneName
    ?.split(" ")
    ?.map((word) => word[0])
    ?.join("");

  return `${timeZoneName} (${abbreviation})`;
};

const generateTimeSlots = () => {
  const times = [] as { label: string; value: string }[];
  const startTime = new Date();
  startTime.setHours(8, 0, 0); // Start at 8:00 AM
  const endTime = new Date();
  endTime.setHours(16, 30, 0); // End at 4:30 PM

  while (startTime <= endTime) {
    const hours = startTime.getHours();
    const minutes = startTime.getMinutes();

    // Format hours and minutes for label with leading zero for hours
    const isPM = hours >= 12;
    const labelHours = (hours % 12 || 12).toString().padStart(2, "0");
    const valueHours = (hours % 12 || 12).toString();
    const formattedMinutes = minutes === 0 ? "00" : minutes;
    const period = isPM ? "PM" : "AM";

    const timeLabel = `${labelHours}:${formattedMinutes} ${period}`;
    const timeValue = `${valueHours}:${formattedMinutes} ${period}`;

    times.push({
      label: timeLabel,
      value: timeValue
    });

    // Increment by 30 minutes
    startTime.setMinutes(startTime.getMinutes() + 30);
  }
  return times;
};

export const ScheduleOnboarding = () => {
  const { eventId } = useParams();
  dayjs.extend(timezone);
  console.log(eventId);

  const [suggestedDate, setSuggestedDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState<string>();
  const [isScheduled, setIsScheduled] = useState(false);
  const [notes, setNotes] = useState<string>("");
  const [isFullDays, setIsFullDays] = useState<number[]>([]);
  const [eventData, setEventData] = useState<{
    data: {
      startDate: Date;
      endDate: Date;
      activityId?: string;
      type: string;
      orderStartDate: Date;
      startDateRequirement: number;
    };
    slotsByDay: AdminCrmMeetingEventIdAvailableSlotsGet200Item[];
  }>();
  const timeSlots = generateTimeSlots();
  const { data: eventDetails, isLoading: isLoadingEventData } =
    useAdminCrmMeetingEventIdAvailableSlotsGet(eventId!, {
      timezone: dayjs.tz.guess()
    });
  useEffect(() => {
    const getFullDays = (resp) => {
      const fullDays = [] as number[];
      Object.keys(resp).map((key) => {
        if (resp[key].isFull) fullDays.push(parseInt(key));
      });
      return fullDays;
    };
    if (eventDetails?.data) {
      const event = eventDetails.data as {
        slotsByDay: AdminCrmMeetingEventIdAvailableSlotsGet200Item[];
        startDate: string;
        endDate: string;
        activityId?: string;
        type: string;
        orderStartDate: string;
        startDateRequirement: number;
      };
      setIsScheduled(!!event.activityId);
      if (!event.activityId) {
        setEventData({
          data: {
            startDate: new Date(event.startDate),
            endDate: new Date(event.endDate),
            orderStartDate: new Date(event.orderStartDate),
            type: event.type,
            startDateRequirement: event.startDateRequirement
          },
          slotsByDay: event.slotsByDay
        });
        setIsFullDays([...getFullDays(event.slotsByDay)]);
      } else {
        setEventData({
          data: {
            type: event.type
          },
          slotsByDay: []
        });
      }
    }

    console.log(setSuggestedDate);
  }, [eventDetails]);
  useEffect(() => {
    if (eventData?.data?.startDate) {
      const isWeekend = (date) => date.getDay() === 0 || date.getDay() === 6;
      const isFullDay = (day) => isFullDays.includes(day);

      const today = new Date();
      const nextDay = new Date(today);
      nextDay.setDate(today.getDate() + 1);
      while (isWeekend(nextDay)) {
        nextDay.setDate(nextDay.getDate() + 1);
      }
      const startDate = new Date(
        eventData.data.startDate > today ? eventData.data.startDate : nextDay
      );
      const endDate = new Date(eventData.data.endDate);
      const availableDate = new Date(startDate);
      while (availableDate <= endDate) {
        const dayOfMonth = availableDate.getDate();
        if (!isWeekend(availableDate) && !isFullDay(dayOfMonth)) {
          break;
        }
        availableDate.setDate(dayOfMonth + 1);
      }
      setSuggestedDate(availableDate);
      setSelectedDate(availableDate);
    }
  }, [isFullDays, eventData]);
  const ServerDay = (props: PickersDayProps<Date>) => {
    const { day, outsideCurrentMonth, ...other } = props;
    const isDisabled =
      props.disabled ||
      isFullDays.indexOf(day.getDate()) >= 0 ||
      !(
        eventData &&
        day >= eventData.data.startDate &&
        day <= eventData.data.endDate
      );

    const isOrderDate = areDatesEqual(
      day,
      eventData!.data!.type === "ONBOARDING"
        ? eventData!.data!.orderStartDate
        : suggestedDate
    );
    return (
      <PickersDay
        key={day.toString()}
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        disabled={isDisabled}
        style={
          isOrderDate
            ? {
                backgroundColor: "#1ABC9C",
                color: "white"
              }
            : {}
        }
      />
    );
  };
  const isTimeTaken = (time) => {
    return (
      eventData?.slotsByDay &&
      selectedDate &&
      eventData.slotsByDay[
        selectedDate.getDate().toString()
      ]?.slotsTaken.includes(time)
    );
  };
  const { mutate: save, isLoading: isSaving } =
    usePostAdminCrmMeetingOnboardingEventId();
  const onSave = () => {
    const [time, period] = selectedTime!.split(" ");
    // eslint-disable-next-line prefer-const
    let [hours, minutes] = time!.split(":").map(Number);

    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }
    selectedDate!.setHours(hours, isNaN(minutes) ? 0 : minutes, 0, 0);

    const data = {
      timezone: dayjs.tz.guess(),
      date: selectedDate,
      ...(notes && {
        customerNotes: notes
      })
    };
    save(
      {
        eventId: eventId!,
        data: data
      },
      {
        onSuccess: () => {
          setIsScheduled(true);
        },
        onError: () => {
          enqueueSnackbar("Failed to Schedule Meeting!", {
            variant: "error"
          });
        }
      }
    );
  };
  return (
    <div
      style={{
        justifyItems: "center"
      }}
    >
      <Loader isLoading={isLoadingEventData && !eventData?.data}>
        {isScheduled ? (
          <>
            <FormContainer>
              <Typography style={{ fontWeight: 300, fontSize: "26px" }}>
                Congratulations
              </Typography>
              <Typography
                style={{
                  color: "#666666"
                }}
              >
                {eventData?.data?.type === "ONBOARDING"
                  ? "You have successfully submitted a date and time for your onboarding session. A notification has been sent to the primary and secondary contacts for your organization."
                  : "You have successfully submitted a date and time for your Account Review Session. A notification has been sent to the primary and secondary contacts for your organization."}
              </Typography>
              <div style={{ marginTop: "20px" }}>
                <SGButton
                  onClick={() => {
                    window.close();
                  }}
                >
                  Close
                </SGButton>
              </div>
            </FormContainer>
          </>
        ) : (
          <FormContainer
            style={{
              justifyContent: "center"
            }}
          >
            <Typography style={{ fontWeight: 300, fontSize: "26px" }}>
              {eventData?.data?.type === "ONBOARDING"
                ? "Select Onboarding Date/Time"
                : ""}
            </Typography>
            <Typography
              style={{
                color: "#666666"
              }}
            >
              {` Please choose a convenient date and time for your SportsGravy
              ${
                eventData?.data?.type === "ONBOARDING" ? "onboarding" : "review"
              } session.`}
            </Typography>
            <Box
              style={{
                marginTop: "10px",
                backgroundColor: "#F3F4F7",
                padding: "12px 16px 12px 16px"
              }}
            >
              <Typography
                style={{
                  color: "#0F0F0F"
                }}
              >
                {eventData?.data?.type === "ONBOARDING"
                  ? `We require initial onboarding sessions to be scheduled ${eventData
                      ?.data
                      ?.startDateRequirement} business days before or after your Organization’s Start Date (${formatDateForDisplay(
                      eventData?.data?.orderStartDate
                    )}) which is highlighted in green below.`
                  : `We would like to schedule a review session with you on ${formatDateForDisplay(
                      suggestedDate
                    )} which is highlighted in green below.`}
              </Typography>
            </Box>

            <StyledDateCalendar
              loading={false}
              style={{
                border: "1px solid #e5e5e5",
                height: "300px",
                marginTop: "20px",
                marginLeft: "0px"
              }}
              views={["day"]}
              defaultValue={suggestedDate}
              value={selectedDate}
              onChange={(newValue) => {
                setSelectedDate(newValue);
              }}
              disablePast
              shouldDisableDate={(date: Date) => {
                return (
                  date.getDay() == 0 ||
                  date.getDay() == 6 ||
                  areDatesEqual(new Date(), date)
                );
              }}
              slots={{
                day: eventData?.data ? ServerDay : undefined
              }}
            />

            {suggestedDate && (
              <Grid
                container
                direction="column"
                spacing="20px"
                marginTop="15px"
              >
                <Grid item container direction="row" spacing="20px">
                  {timeSlots.map((t) => {
                    const isDisabled = isTimeTaken(t.value);
                    return (
                      <Grid item key={t.value}>
                        <Button
                          disabled={isDisabled}
                          style={{
                            width: "100px",
                            ...(!isDisabled && {
                              backgroundColor:
                                selectedTime === t.value
                                  ? colors.primary.main
                                  : "#F3F4F7",
                              color:
                                selectedTime === t.value ? "white" : "black"
                            })
                          }}
                          onClick={() => {
                            setSelectedTime(t.value);
                          }}
                        >
                          {t.label}
                        </Button>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
            <Box display="flex" marginTop="30px">
              <Typography
                style={{
                  color: "black",
                  fontWeight: "bold"
                }}
              >
                Time Zone:
              </Typography>
              <Typography style={{ marginLeft: "4px" }}>
                {getTimezoneAbbreviation(dayjs.tz.guess())}
              </Typography>
            </Box>
            <Box marginTop="20px" display="flex" flexDirection="column">
              <Typography variant="formLabel">Notes</Typography>
              <TextField
                type="text"
                rows={4}
                value={notes}
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
                multiline
                style={{ marginTop: "10px" }}
              />
            </Box>
            <div style={{ marginTop: "20px" }}>
              <SGButton
                onClick={() => {
                  onSave();
                }}
                isLoading={isSaving}
              >
                Submit
              </SGButton>
            </div>
          </FormContainer>
        )}
      </Loader>
    </div>
  );
};
