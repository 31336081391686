import { ToolTip } from "@components/ToolTip";
import Check from "@mui/icons-material/Check";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {
  ModelMedia,
  ModelStream
} from "@sportsgravyengineering/sg-api-react-sdk";
import { convertSecondsToDuration } from "@utils/convertsecondsToDuration";
import ImagePlaceholder from "@assets/images/imagePlaceholder.png";
export type GalleryItemType = {
  id: string;
  type: "IMAGE" | "VIDEO" | "ALBUM";
  date?: string;
  thumbnailSrc: string;
  duration?: number;
  mediaDetails?: ModelMedia;
  title?: string;
  count?: number;
  clipOf?: ModelStream;
};

export const GalleryItem = ({
  item,
  isSelected,
  onSelect,
  clickToSelect,
  onClick
}: {
  item: GalleryItemType;
  isSelected?: boolean;
  onSelect?: (id: string) => void;
  clickToSelect?: boolean;
  onClick?: (id: string, media: ModelMedia) => void;
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        className={`gallery-item ${isSelected ? "selected" : ""}`}
        onClick={() => {
          if (clickToSelect && onSelect) onSelect(item.id);
          else onClick && onClick(item.id, item.mediaDetails!);
        }}
      >
        {item.count === 0 ? (
          <img
            src={ImagePlaceholder}
            alt="Thumbnail"
            className="media-content"
          />
        ) : (
          <img
            src={item.thumbnailSrc}
            alt="Thumbnail"
            className="media-content"
            onError={(e) => {
              e.currentTarget.src = ImagePlaceholder;
            }}
          />
        )}

        {/* For video duration */}
        {item.type === "VIDEO" && item.duration && (
          <span className="duration">
            {convertSecondsToDuration(item.duration)}
          </span>
        )}

        {/* Selection circle */}
        {onSelect && (
          <div className={`select-circle ${isSelected ? "checked" : ""}`}>
            <IconButton
              onClick={(e) => {
                onSelect(item.id);
                e.stopPropagation();
              }}
            >
              <Check fontSize="small" htmlColor="white" />
            </IconButton>
          </div>
        )}
      </div>
      {(item.title || item.count) && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            marginTop: "8px",
            alignItems: "center"
          }}
        >
          {item.title && (
            <ToolTip title={item.title}>
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: 600,
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  wordBreak: "break-word"
                }}
              >
                {item.title}
              </Typography>
            </ToolTip>
          )}
          {item.count && (
            <Typography
              sx={{
                textAlign: "center",
                color: "#666666"
              }}
            >
              {item.count}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};
