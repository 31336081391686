/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import AutoComplete from "./AutoComplete";
import Marker from "./Marker";
import { Backdrop, Grid, IconButton, styled } from "@mui/material";
import { Box } from "@mui/system";
import { Button } from "./Button";
import { Close, ZoomIn, ZoomOut } from "@mui/icons-material";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";

const Wrapper = styled("main")(() => ({
  width: "100%",
  height: "100%",
  position: "relative"
}));

const CloseButton = styled(IconButton)(() => ({
  position: "absolute",
  right: "24px",
  top: "24px",
  backgroundColor: "#FFFFFF",
  color: "#9FA2AA",
  height: "40px",
  width: "40px",
  zIndex: 9999,
  "&:hover": {
    backgroundColor: "#FFFFFF",
    color: "#000"
  }
}));
const ZoomButton = styled(IconButton)(() => ({
  backgroundColor: "#FFFFFF",
  color: "#9FA2AA",
  height: "40px",
  width: "40px",
  "&:hover": {
    backgroundColor: "#FFFFFF",
    color: "#000"
  }
}));
const AutoCompleteWrapper = styled("div")(() => ({
  position: "absolute",
  zIndex: 1,
  width: "100%"
}));
const StyledBox = styled(Box)(() => ({
  marginTop: "50px",
  position: "fixed",
  backgroundColor: "#000",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  maxWidth: "1000px",
  height: "554px",
  zIndex: 9999,
  overflow: "hidden",
  borderRadius: "5px",
  "& main .gm-fullscreen-control": {
    display: "none"
  },
  "& main .gmnoprint": {
    display: "none"
  }
}));

const AddressBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  position: "absolute",
  bottom: "1px",
  color: "#000",
  backgroundColor: "#fff",
  padding: "10px",
  borderRadius: "5px",
  width: "100%",
  alignItems: "center"
}));
const GoogleMap = (props: {
  close;
  onAddressChange?;
  formattedAddress?;
  address?;
  setPlace?;
  showSearchBar;
  showClickedAddress;
  allowMarkerDrag?: boolean;
}) => {
  const organizationId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const organization = organizations.find(
    (org) => org.organizationId === organizationId
  );
  const [mapApiLoaded, setMapApiLoaded] = useState(false);
  const [mapInstance, setMapInstance] = useState(null);
  const [mapApi, setMapApi] = useState(null);
  const [place, setPlace] = useState(undefined);
  const [center, setCenter] = useState([]);
  const [zoom, setZoom] = useState(12);
  const [address, setAddress] = useState("");
  const [addressComponents, setAddressComponents] = useState("");
  const [draggable, setDraggable] = useState(true);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  useEffect(() => {
    setCurrentLocation();
    // console.log(organization?.lines[0]+ organization?.locality+ organization?.province);
  }, []);

  const onMarkerInteraction = (childKey, childProps, mouse) => {
    if (props.allowMarkerDrag) {
      setDraggable(false);
      setLat(mouse.lat);
      setLng(mouse.lng);
    }
  };

  const onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
    setDraggable(true);
    generateAddress(lat, lng);
  };

  const onChange = ({ center, zoom }) => {
    setCenter(center);
    setZoom(zoom);
  };

  const onClick = (value) => {
    if (props.allowMarkerDrag) {
      setLat(value.lat);
      setLng(value.lng);
      generateAddress(value.lat, value.lng);
    }
  };

  const apiHasLoaded = (map, maps) => {
    setMapApiLoaded(true);
    setMapInstance(map);
    setMapApi(maps);
    generateAddress();
    if (props.address) {
      setMapToAddress(maps, props.address);
    } else {
      setMapToAddress(
        maps,
        organization?.lines[0] + organization?.locality + organization?.province
      );
    }
  };

  const addPlace = (place) => {
    setPlace(place.name);
    setLat(place.geometry.location.lat());
    setLng(place.geometry.location.lng());
    generateAddress(
      place.geometry.location.lat(),
      place.geometry.location.lng()
    );
  };

  const generateAddress = (lat?, lng?) => {
    try {
      const geocoder = new mapApi.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            setAddressComponents(results[0].address_components);
            setAddress(results[0].formatted_address);
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  const setCurrentLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCenter([position.coords.latitude, position.coords.longitude]);
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      });
    }
  };
  const setMapToAddress = (mapApi, address) => {
    const geocoder = new mapApi.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status === "OK" && results.length > 0) {
        const location = results[0].geometry.location;
        setCenter([location.lat(), location.lng()]);
        setLat(location.lat());
        setLng(location.lng());
      } else {
        console.error(
          "Geocode was not successful for the following reason:",
          status
        );
      }
    });
  };

  const closeAddressSelect = () => {
    setMapApiLoaded(false);
    setMapApi(null);
    setMapInstance(null);
    props.close();
  };
  return (
    <Backdrop
      sx={{
        overflow: "hidden",
        overflowY: "none",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={true}
    >
      <StyledBox data-testid="google-map">
        <Wrapper>
          <CloseButton onClick={closeAddressSelect} data-testid="close-icon">
            <Close />
          </CloseButton>
          {mapApiLoaded && props.showSearchBar && (
            <AutoCompleteWrapper>
              <AutoComplete
                map={mapInstance}
                mapApi={mapApi}
                addplace={addPlace}
              />
            </AutoCompleteWrapper>
          )}
          <GoogleMapReact
            fullscreenControl={false}
            center={center}
            zoom={zoom}
            draggable={draggable}
            onChange={onChange}
            onChildMouseDown={onMarkerInteraction}
            onChildMouseUp={onMarkerInteractionMouseUp}
            onChildMouseMove={onMarkerInteraction}
            onClick={onClick}
            bootstrapURLKeys={{
              key: import.meta.env.VITE_APP_MAP_API_KEY as string,
              libraries: ["places", "geometry"]
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              apiHasLoaded(map, maps);
            }}
          >
            <Marker text={address} lat={lat} lng={lng} />
          </GoogleMapReact>
          {address === "" && (
            <div
              style={{
                position: "absolute",
                bottom: "24px",
                right: "24px",
                zIndex: 9999
              }}
            >
              <ZoomButton
                data-testid="zoomIn-icon"
                onClick={() => {
                  setZoom(zoom + 1);
                }}
              >
                <ZoomIn />
              </ZoomButton>
              <ZoomButton
                data-testid="zoomOut-icon"
                onClick={() => {
                  setZoom(zoom - 1);
                }}
                style={{
                  marginLeft: "10px"
                }}
              >
                <ZoomOut />
              </ZoomButton>
            </div>
          )}
          {address != "" && props.showClickedAddress && (
            <AddressBox>
              <span
                style={{
                  fontWeight: "500px",
                  fontSize: "16px",
                  lineHeight: "24px",
                  padding: "12px",
                  maxWidth: "75%"
                }}
              >
                {address}
              </span>
              <Button
                variant="admin-primary"
                onClick={() => {
                  props.onAddressChange(addressComponents);
                  if (props.setPlace) {
                    props.setPlace(place);
                  }
                  closeAddressSelect();
                  if (props.formattedAddress) {
                    props.formattedAddress(address);
                  }
                }}
              >
                Confirm Location
              </Button>
            </AddressBox>
          )}
        </Wrapper>
      </StyledBox>
    </Backdrop>
  );
};

GoogleMap.defaultProps = {
  showSearchBar: true,
  showClickedAddress: true,
  allowMarkerDrag: true
};

export default GoogleMap;
