export const PERSON_STATUS = {
  PRIMARY: "PRIMARY",
  DUPLICATE: "DUPLICATE",
  NEW: "NEW",
  MERGED: "MERGED",
  SIGNUP_BASIC: "SIGNUP_BASIC",
  SIGNUP_PERSONAL: "SIGNUP_PERSONAL"
};

export const GENDERS = [
  { label: "Male", value: "MALE" },
  { label: "Female", value: "FEMALE" }
];

export const ERP_TYPE_OPTIONS = [
  { value: "Cloud", label: "Cloud" },
  { value: "Software", label: "Software" }
];

export const MAX_SIZE_ICON = 100000;

export const compressionOptions = {
  maxSizeMB: 0.05,
  useWebWorker: true
};

export const TrainingProgramTypeOptions = [
  { label: "Individual Group", value: "INDIVIDUAL_TRAINING" },
  {
    label: "Small Group Training",
    value: "SMALL_GROUP_TRAINING"
  },
  { label: "Large Group Training", value: "TRAINING_CAMP" }
];

export const SUFFIX_OPTIONS = [
  { value: "", label: "" },
  { value: "Jr", label: "Jr" },
  { value: "Sr", label: "Sr" },
  { value: "I", label: "I" },
  { value: "II", label: "II" },
  { value: "III", label: "III" },
  { value: "IV", label: "IV" },
  { value: "V", label: "V" }
];

export const STORAGE_UNITS = [
  {
    value: "GB",
    label: "GB"
  },
  {
    value: "TB",
    label: "TB"
  }
];
export const VIDEO_LENGTH_UNITS = [
  {
    value: "MIN",
    label: "Min"
  },
  {
    value: "HR",
    label: "Hr"
  }
];

export const AD_LAYOUT_OPTIONS = [
  {
    label: "Photo",
    value: "Photo",
    adType: ["FEED_POST"],
    Imageposition: [],
    fields: ["image", "description"],
    size: {
      width: 420,
      height: 420
    }
  },
  {
    label: "Video",
    value: "Video",
    adType: ["FEED_POST"],
    Imageposition: [],
    fields: ["video", "description"],
    size: {
      width: 420,
      height: 420
    }
  },
  {
    label: "Photo + Link",
    value: "Photo_Link",
    adType: ["FEED_POST"],
    Imageposition: [],
    fields: ["image", "button", "title", "description"],
    size: {
      width: 420,
      height: 420
    }
  },
  {
    label: "Video + Link",
    value: "Video_Link",
    adType: ["FEED_POST"],
    Imageposition: [],
    fields: ["video", "button", "title", "description"],
    size: {
      width: 420,
      height: 420
    }
  },
  {
    label: "Link",
    value: "Link",
    adType: ["FEED_POST"],
    Imageposition: [],
    fields: ["image", "button", "title", "description"],
    size: {
      width: 420,
      height: 420
    }
  },
  {
    label: "Text Ad with Logo",
    value: "Text_Ad_with_Logo",
    adType: ["OUTSTREAM"],
    Imageposition: [],
    fields: ["logo", "title", "description"],
    size: {}
  },
  {
    label: "Text Ad without Logo",
    value: "Text_Ad_without_Logo",
    adType: ["OUTSTREAM"],
    fields: ["title", "description"],
    Imageposition: [],
    size: {}
  },
  {
    label: "Small Banner Ad (320px x 50px)",
    value: "Small_Banner_Ad_(320px x 50px)",
    adType: ["SPONSORED_BY", "OUTSTREAM"],
    fields: ["image"],
    Imageposition: ["top"],
    size: {
      width: 320,
      height: 50
    }
  },
  {
    label: "Large Banner Ad (320px x 180px)",
    value: "Large_Banner_Ad_(320px x 180px)",
    adType: ["SPONSORED_BY", "OUTSTREAM"],
    fields: ["image"],
    Imageposition: ["bottom"],
    size: {
      width: 320,
      height: 180
    }
  },
  {
    label: "Extra Large Banner Ad (320px x 250px)",
    value: "Extra_Large_Banner_Ad_(320px x 250px)",
    adType: ["OUTSTREAM"],
    fields: ["image"],
    Imageposition: ["bottom"],
    size: {
      width: 320,
      height: 250
    }
  },
  {
    label: "Large Contextual Ad - Image Top (320px x 114px)",
    value: "Large_Contextual_Ad_Image_Top_(320px x 114px)",
    adType: ["OUTSTREAM"],
    fields: ["image", "title", "description"],
    Imageposition: ["top"],
    size: {
      width: 320,
      height: 114
    }
  },
  {
    label: "Large Contextual Ad - Image Middle (320px x 114px)",
    value: "Large_Contextual_Ad_Image_Middle_(320px x 114px)",
    adType: ["OUTSTREAM"],
    fields: ["image", "title", "description"],
    Imageposition: ["middle"],
    size: {
      width: 320,
      height: 114
    }
  },
  {
    label: "Large Contextual Ad - Image Bottom (320px x 114px)",
    value: "Large_Contextual_Ad_Image_Bottom_(320px x 114px)",
    adType: ["OUTSTREAM"],
    fields: ["image", "title", "description"],
    Imageposition: ["bottom"],
    size: {
      width: 320,
      height: 114
    }
  },
  {
    label: "Extra Large Contextual Ad - Image Top (320px x 180)",
    value: "Extra_Large_Contextual_Ad_Image_Top_(320px x 180)",
    adType: ["OUTSTREAM"],
    fields: ["image", "title", "description"],
    Imageposition: ["top"],
    size: {
      width: 320,
      height: 180
    }
  },
  {
    label: "Extra Large Contextual Ad - Image Middle (320px x 180)",
    value: "Extra_Large_Contextual_Ad_Image_Middle_(320px x 180)",
    adType: ["OUTSTREAM"],
    fields: ["image", "title", "description"],
    Imageposition: ["middle"],
    size: {
      width: 320,
      height: 180
    }
  },
  {
    label: "Extra Large Contextual Ad - Image Bottom (320px x 180)",
    value: "Extra_Large_Contextual_Ad_Image_Bottom_(320px x 180)",
    adType: ["OUTSTREAM"],
    fields: ["image", "title", "description"],
    Imageposition: ["bottom"],
    size: {
      width: 320,
      height: 180
    }
  }
];

export const AD_PLACEMENT_OPTIONS = [
  { label: "Feed Post Ad", value: "FEED_POST" },
  {
    label: "Sponsored By",
    value: "SPONSORED_BY"
  },
  {
    label: "Out-Stream",
    value: "OUTSTREAM"
  },
  {
    label: "In-Stream",
    value: "INSTREAM"
  }
];

export const CALL_TO_ACTION_OPTIONS = [
  { label: "Learn More", value: "LEARN_MORE" },
  { label: "Buy Now", value: "BUY_NOW" },
  { label: "Register", value: "REGISTER" },
  { label: "Sign Up", value: "SIGN_UP" }
];

export const FEATURE_PLATFORMS_OPTIONS = [
  {
    label: "iOS",
    value: "IOS"
  },
  {
    label: "AND",
    value: "AND"
  },
  {
    label: "Web",
    value: "WEB"
  },
  {
    label: "API",
    value: "API"
  },
  {
    label: "DEVops",
    value: "DEV"
  }
];

export const TESTCASE_STATUS_OPTIONS = [
  {
    label: "Failed",
    value: "FAILED"
  },
  {
    label: "Passed",
    value: "PASSED"
  },
  {
    label: "Blocked",
    value: "BLOCKED"
  },
  {
    label: "Hold",
    value: "HOLD"
  },
  {
    label: "Not Tested",
    value: "NOT_TESTED"
  }
];

export const RELATED_TO_OPTIONS = [
  {
    label: "Sports Organizations",
    value: "SPORTS_ORGANIZATION"
  },
  {
    label: "Personal Use",
    value: "PERSONAL_USE"
  }
];

export const DISPLAY_CHANNEL_OPTIONS = [
  {
    label: "Website",
    value: "WEBSITE"
  },
  {
    label: "Web App",
    value: "WEB_APP"
  },
  {
    label: "Mobile App",
    value: "MOBILE_APP"
  }
];

export const TEST_CASE_RUNS_STATUS_OPTIONS = [
  {
    label: "UI Status = Failed",
    value: "UI_FAILED"
  },
  {
    label: "UI Status = Passed",
    value: "UI_PASSED"
  },
  {
    label: "UI Status = Blocked",
    value: "UI_BLOCKED"
  },
  {
    label: "UI Status = Hold",
    value: "UI_HOLD"
  },
  {
    label: "UI Status = Not Tested",
    value: "UI_NOT_TESTED"
  },
  {
    label: "FN Status = Failed",
    value: "FN_FAILED"
  },
  {
    label: "FN Status = Passed",
    value: "FN_PASSED"
  },
  {
    label: "FN Status = Blocked",
    value: "FN_BLOCKED"
  },
  {
    label: "FN Status = Hold",
    value: "FN_HOLD"
  },
  {
    label: "FN Status = Not Tested",
    value: "FN_NOT_TESTED"
  }
];

export const PLATFORM_OPTIONS = [
  { label: "Web", value: "web" },
  { label: "iOS", value: "ios" },
  { label: "AND", value: "and" },
  { label: "API", value: "api" },
  { label: "DEVOps", value: "dev" }
];

export const SPORT_CONTEST_TYPE_OPTIONS = [
  { label: "Game", value: "GAME" },
  { label: "Match", value: "MATCH" }
];

export const HELP_ARTICLE_DISPLAY_CHANNELS = [
  {
    label: "Webiste",
    value: "WEBSITE"
  },
  {
    label: "Web App",
    value: "WEB_APP"
  },
  {
    label: "Mobile App",
    value: "MOBILE_APP"
  }
];

export const HELP_ARTICLE_RELATED_TO = [
  {
    label: "Personal Use",
    value: "PERSONAL_USE"
  },
  {
    label: "Sports Organization",
    value: "SPORTS_ORGANIZATION"
  }
];

export const EMPLOYMENT_STATUS_TYPES = [
  {
    label: "Full Time",
    value: "FULL_TIME"
  },
  {
    label: "Part Time",
    value: "PART_TIME"
  },
  {
    label: "Temporary/Seasonal",
    value: "TEMP"
  }
];

export const EMPLOYMENT_TYPES = [
  {
    label: "Employee",
    value: "EMPLOYEE"
  },
  {
    label: "Contractor",
    value: "CONTRACTOR"
  },
  {
    label: "Intern",
    value: "INTERN"
  },
  {
    label: "Volunteer",
    value: "VOLUNTEER"
  }
];

export const ORGANIZATION_TYPE_OPTIONS = [
  { value: "NATIONAL_GOVERNING_BODY", label: "National Governing Body" },
  { value: "STATE_GOVERNING_BODY", label: "State Governing Body" },
  { value: "REGIONAL_GOVERNING_BODY", label: "Regional Governing Body" },
  { value: "LOCAL_GOVERNING_BODY", label: "Local Governing Body" },
  { value: "SCHOOL", label: "School" },
  { value: "LEAGUE", label: "League" },
  { value: "TEAM", label: "Team" },
  { value: "ASSOCIATION", label: "Association" },
  { value: "CLUB", label: "Club" },
  { value: "TRAINING_COMPANY", label: "Training Company" }
];

export const RELEASE_STATUS = [
  { label: "In Progress", value: "IN_PROGRESS" },
  { label: "Released", value: "RELEASED" }
];

export const TIME_FILTER_OPTIONS = [
  {
    label: "All Time",
    value: "ALL_TIME",
    tooltip: "All Time"
  },
  {
    label: "Yesterday",
    value: "YESTERDAY",
    tooltip: "Compared to Previous Day"
  },
  { label: "This Week", value: "THIS_WEEK", tooltip: "Compared to Last Week" },
  {
    label: "Last Week",
    value: "LAST_WEEK",
    tooltip: "Compare to Previous Week"
  },
  {
    label: "This Month",
    value: "THIS_MONTH",
    tooltip: "Compared to Last Month"
  },
  {
    label: "Last Month",
    value: "LAST_MONTH",
    tooltip: "Compared to Previous Month"
  },
  {
    label: "This Quarter",
    value: "THIS_QUARTER",
    tooltip: "Compared to Last Quarter"
  },
  {
    label: "Last Quarter",
    value: "LAST_QUARTER",
    tooltip: "Compared to Previous Quarter"
  },
  { label: "This Year", value: "THIS_YEAR", tooltip: "Compared to Last Year" },
  {
    label: "Last Year",
    value: "LAST_YEAR",
    tooltip: "Compared to Previous Year"
  },
  {
    label: "Custom",
    value: "CUSTOM",
    tooltip: "Custom"
  }
];

export const SGAPP_ORG_FILTER_OPTIONS = [
  {
    label: "Organization Insights",
    value: "ORGANIZATION_INSIGHTS",
    chartType: "insights",
    title: "Organization Insights",
    description: "Quick Insights related to SportGravy B2B Customers"
  },
  {
    label: "Organization Insights by Size",
    value: "ORGANIZATION_INSIGHTS_BY_SIZE",
    chartType: "bar",
    title: "Organization Insights by Size",
    description: "Quick Insights related to SportGravy B2B Customers",
    height: 250,
    yAxis: [
      "Small Org’s \n0 to 499",
      "Medium Org’s 500 to 999",
      "Large Org’s 1000 +"
    ]
  },
  {
    label: "Organization Insights by Offerings",
    value: "ORGANIZATION_INSIGHTS_BY_OFFERINGS",
    chartType: "bar",
    title: "Organization Insights by Offerings",
    description: "Quick Insights related to SportGravy B2B Customers",
    height: 250,
    yAxis: [
      "Teams Only",
      "Training Programs Only",
      "Teams and Training Programs"
    ]
  },
  {
    label: "Organization Insights by Country",
    value: "ORGANIZATION_INSIGHTS_BY_COUNTRY",
    chartType: "bar",
    title: "Organization Insights by Country",
    description: "Quick Insights related to SportGravy B2B Customers",
    yAxis: ["United States", "Canada"],
    height: 200
  },
  {
    label: "Organization Insights by Location",
    value: "ORGANIZATION_INSIGHTS_BY_LOCATION",
    chartType: "bar",
    title: "Organization Insights by Location",
    description: "Quick Insights related to SportGravy B2B Customers",
    yAxis: ["East", "West", "Central", "South"],
    height: 300
  },
  {
    label: "Organization Insights by Type",
    value: "ORGANIZATION_INSIGHTS_BY_TYPE",
    chartType: "bar",
    title: "Organization Insights by Type",
    description: "Quick Insights related to SportGravy B2B Customers",
    height: 500,
    yAxis: [
      "National Governing Body",
      "State Governing Body",
      "Regional Governing Body",
      "Local governing Body",
      "School",
      "League",
      "Association",
      "Club",
      "Training Company"
    ]
  }
];

export const PlayStoreUrl =
  "https://play.google.com/store/apps/details?id=com.sportsgravy";
export const AppStoreUrl = "itms-apps://itunes.apple.com/app/1509169282";

export const JIRA_CARD_STATUSES = [
  {
    label: "Review",
    value: "REVIEW"
  },
  {
    label: "In Progress",
    value: "IN_PROGRESS"
  },
  {
    label: "Done",
    value: "DONE"
  },
  {
    label: "Ready/Committed",
    value: "READY_COMMITTED"
  },
  {
    label: "QA",
    value: "QA"
  },
  {
    label: "Onhold",
    value: "ON_HOLD"
  },
  {
    label: "Today’s Plan",
    value: "TODAYS_PLAN"
  }
];

export const ACTIVITY_RELATED_TO = [
  {
    label: "Account",
    value: "ACCOUNT"
  },
  {
    label: "Contact",
    value: "CONTACT"
  },
  {
    label: "Lead",
    value: "LEAD"
  },
  {
    label: "Opportunity",
    value: "OPPORTUNITY"
  },
  {
    label: "Order",
    value: "ORDER"
  }
];

export const ACTIVITY_OUTCOME = [
  {
    label: "Connected with Contact",
    value: "CONNECTED_WITH_CONTACT"
  },
  {
    label: "Rejected by Contact",
    value: "REJECTED_BY_CONTACT"
  },
  {
    label: "No Answer",
    value: "NO_ANSWER"
  },
  {
    label: "Left Voicemail",
    value: "LEFT_VOICEMAIL"
  },
  {
    label: "Scheduled Demo",
    value: "SCHEDULED_DEMO"
  },
  {
    label: "Opportunity Created",
    value: "OPPORTUNITY_CREATED"
  }
];

export const LEAD_STATUS = [
  {
    label: "Not Contacted",
    value: "NOT_CONTACTED"
  },
  {
    label: "Attempted to Contact",
    value: "ATTEMPTED_CONTACT"
  },
  {
    label: "Contacted - Call Back",
    value: "CONTACTED_CALL_BACK"
  },
  {
    label: "Contacted - Qualified Lead",
    value: "CONTACTED_QUALIFIED_LEAD"
  },
  {
    label: "Contacted - Junk Lead",
    value: "CONTACTED_JUNK_LEAD"
  },
  {
    label: "Contact in Future",
    value: "CONTACT_IN_FUTURE"
  },
  {
    label: "Contacted",
    value: "CONTACTED"
  },
  {
    label: "Not Qualified",
    value: "NOT_QUALIFIED"
  },
  {
    label: "Lost Lead",
    value: "LOST_LEAD"
  },
  {
    label: "Pre-Qualified",
    value: "PRE_QUALIFIED"
  }
];

export const LEAD_SOURCES = [
  { label: "Website", value: "WEBSITE" },
  { label: "Referral", value: "REFERRAL" },
  { label: "Facebook", value: "FACEBOOK" },
  { label: "Instagram", value: "INSTAGRAM" },
  { label: "Email Campaign", value: "EMAIL_CAMPAIGN" },
  { label: "Direct Mail", value: "DIRECT_MAIL" },
  { label: "Cold Email", value: "COLD_EMAIL" },
  { label: "Paid Advertising", value: "PAID_ADVERTISING" },
  { label: "Organic Search", value: "ORGANIC_SEARCH" },
  { label: "Inbound Call", value: "INBOUND_CALL" },
  { label: "Outbound Call", value: "OUTBOUND_CALL" },
  { label: "Free Trial Signup", value: "FREE_TRIAL_SIGNUP" }
];

export const CRM_ACCOUNT_TYPES = [
  { label: "National Governing Body", value: "NATIONAL_GOVERNING_BODY" },
  { label: "State Governing Body", value: "STATE_GOVERNING_BODY" },
  { label: "Regional Governing Body", value: "REGIONAL_GOVERNING_BODY" },
  { label: "Local Governing Body", value: "LOCAL_GOVERNING_BODY" },
  { label: "School", value: "SCHOOL" },
  { label: "League", value: "LEAGUE" },
  { label: "Team", value: "TEAM" },
  { label: "Association", value: "ASSOCIATION" },
  { label: "Club", value: "CLUB" },
  { label: "Training Company", value: "TRAINING_COMPANY" }
];
export const CRM_ACCOUNT_CATEGORIES = [
  { label: "Sports Organization", value: "SPORTS_ORGANIZATION" },
  { label: "Advertiser", value: "ADVERTISER" },
  { label: "Retail", value: "RETAIL" },
  { label: "Health Care", value: "HEALTH_CARE" },
  { label: "Technology", value: "TECHNOLOGY" },
  { label: "Sports", value: "SPORTS" },
  { label: "Manufacturing", value: "MANUFACTURING" }
];
export const CRM_OPPORTUNITY_TYPES = [
  { label: "New Business", value: "NEW_BUSINESS" },
  { label: "Renewal", value: "RENEWAL" }
];
export const CRM_OPPORTUNITY_STAGES = [
  {
    label: "Prospect",
    value: "PROSPECT",
    description: "Potential customers who are interested in your offering."
  },
  {
    label: "Qualification",
    value: "QUALIFIED",
    description:
      "Determining if the prospect is a good fit for your offering and has the need, budget, and authority to make a purchase."
  },
  {
    label: "Needs Analysis",
    value: "NEEDS_ANALYSIS",
    description:
      "Understanding the prospect's specific requirements, challenges, and desired outcomes."
  },
  {
    label: "Presentation / Demo",
    value: "DEMO",
    description:
      "Presenting your offering to the prospect and how it will address their needs and provide them value."
  },
  {
    label: "Proposal / Quote",
    value: "VALUE_PROPOSITION",
    description:
      "Providing a formal proposal/quote that explains your solution, pricing, and its terms."
  },
  {
    label: "Negotiation / Review",
    value: "NEGOTIATION",
    description:
      "Addressing any concerns or objections raised by the prospect and negotiate contract terms."
  },
  {
    label: "Decision Due",
    value: "DECISION_DUE",
    description: "I'm optimistic about winning this opportunity.",
    icon: true
  },
  {
    label: "Decision Due",
    value: "DECISION_DUE_NEGATIVE",
    description: "I'm nervous about losing this opportunity.",
    icon: true
  },
  {
    label: "Closed Won",
    value: "CLOSED_WON",
    description:
      "Getting approval from prospect to move forward with your offering and submit order."
  },
  {
    label: "Closed Lost",
    value: "CLOSED_LOST",
    description:
      "Prospect has decided not to move forward with our offering at this time."
  }
];
export const CRM_JOB_TYPES = [
  { value: "BDR", label: "BDR" },
  { value: "CSM", label: "CSM" },
  { value: "AE", label: "AE" }
];

export const YES_NO = [
  { value: "YES", label: "Yes" },
  { value: "NO", label: "No" }
];

export const CRM_ORG_RATING = [
  {
    label: "AAA",
    value: "AAA"
  },
  {
    label: "AA",
    value: "AA"
  },
  {
    label: "A",
    value: "A"
  },
  {
    label: "B",
    value: "B"
  },
  {
    label: "C",
    value: "C"
  }
];
export const CRM_ACTIVITY_CALL_OUTCOMES = [
  {
    label: "No Answer",
    value: "NO_ANSWER"
  },
  {
    label: "Connected With Contact",
    value: "CONNECTED_WITH_CONTACT"
  },
  {
    label: "Rejected By Contact",
    value: "REJECTED_BY_CONTACT"
  },
  {
    label: "Left Voicemail",
    value: "LEFT_VOICEMAIL"
  },
  {
    label: "Scheduled Demo",
    value: "SCHEDULED_DEMO"
  },
  {
    label: "Opportunity Created",
    value: "OPPORTUNITY_CREATED"
  }
];
export const CRM_CALL_DIRECTIONS = [
  { label: "Incoming", value: "inbound" },
  { label: "Outgoing", value: "outbound" }
];
export const CRM_ACTIVITY_NEXT_ACTIONS = [
  { label: "Call", value: "CALL" },
  { label: "Email", value: "EMAIL" },
  { label: "Meeting", value: "MEETING" },
  { label: "Face to Face", value: "FACE_TO_FACE" }
];
export const CRM_ACTIVITY_MEETING_TYPES = [
  { label: "Instant Meeting", value: "INSTANT" },
  { label: "Schedule Meeting", value: "SCHEDULED" }
];
export const CRM_ORDER_PAYMENT_TYPES = [
  {
    label: "Credit Card",
    value: "CREDIT_CARD"
  },
  {
    label: "Debit Card",
    value: "DEBIT_CARD"
  },
  {
    label: "Bank (ACH)",
    value: "BANK_ACH"
  }
];
export const CRM_ORDER_BANK_ACH_TYPES = [
  {
    label: "Personal Savings",
    value: "9"
  },
  {
    label: "Personal Checking",
    value: "8"
  },
  {
    label: "Business Savings",
    value: "11"
  },
  {
    label: "Business Checking",
    value: "10"
  }
];

export const MERCHANT_ACCOUNT_TYPES = [
  {
    label: "Sole Proprietor",
    value: 0
  },
  {
    label: "Corporation",
    value: 1
  },
  {
    label: "LLC",
    value: 2
  },
  {
    label: "Partner",
    value: 3
  },
  {
    label: "Non-Profit",
    value: 4
  },
  {
    label: "Government",
    value: 6
  }
];

export const MAX_CLIP_DURATION = 180;
export const MIN_CLIP_DURATION = 5;

export const ActivityStatusTypes = [
  {
    label: "Not Started",
    value: "NOT_STARTED"
  },
  {
    label: "In Progress",
    value: "IN_PROGRESS"
  },
  {
    label: "Completed",
    value: "COMPLETED"
  },
  {
    label: "On Hold",
    value: "ON_HOLD"
  }
];

export const BUILD_STATUS = [
  { label: "Passed", value: "PASSED" },
  { label: "Failed", value: "FAILED" },
  { label: "Not Tested", value: "NOT_TESTED" }
];
export const ORDER_BILLING_OPTIONS = [
  {
    label: "Yearly Subscription - Billed Monthly",
    value: "MONTHLY_DIVIDED_YEARLY",
    description: "Invoiced and paid on a monthly basis"
  },
  {
    label: "Yearly Subscription - Billed Monthly Per Athlete Registered",
    value: "MONTHLY_PER_ATHLETE",
    description: "Invoiced and paid on a monthly basis per athlete registration"
  },
  {
    label: "Yearly Subscription - Paid Upfront",
    value: "YEARLY_UPFRONT",
    description:
      "Invoiced and paid upfront on a yearly basis and includes a 10% discount"
  }
];
export const CRM_ONBOARD_STATUS = [
  {
    label: "Pending",
    value: "PENDING"
  },
  {
    label: "Completed",
    value: "Completed"
  },
  {
    label: "At Risk",
    value: "AT_RISK"
  }
];
