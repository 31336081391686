import { Checkbox, Grid } from "@mui/material";
import { ModelStream } from "@sportsgravyengineering/sg-api-react-sdk";

export const FilterStreamItem = ({
  stream,
  onCheck,
  selectedStreams
}: {
  stream: ModelStream;
  onCheck: (checked: boolean) => void;
  selectedStreams: string[];
}) => {
  return (
    <Grid container direction="row" spacing="15px" alignItems="center">
      <Grid item>
        <Checkbox
          checked={!!selectedStreams.find((x) => x === stream.streamId)}
          onChange={(e) => {
            onCheck(e.target.checked);
          }}
        />
      </Grid>
      <Grid item>{stream.title}</Grid>
    </Grid>
  );
};
