import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography, styled, Grid as StableGrid } from "@mui/material";
import { Edit, InfoOutlined } from "@mui/icons-material";
import { AddIcon } from "@components/Icons";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import {
  ModelPerson,
  useConfigGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";
import { AccountDetails } from "../accounts/components/AccountDetails";
import { ContactDetails } from "../accounts/components/ContactDetails";
import { formatDateForDisplay } from "@utils/formatDate";
import { ToolTip } from "@components/ToolTip";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { getTimezoneAbbreviation } from "@utils/dateUtils";
import { getMeetingDurationInMinutes } from "@utils/onboardingUtils";
import formatFullName from "@utils/formatFullName";
import { extractHTMLFromDelta } from "@utils/convertDeltaToText";
import { CreateOnboardingEventModal } from "./CreateOnboardingEventModal";

const StyledHeader = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #000000;
  opacity: 0.7;
  text-transform: uppercase;
`;

const StyledLink = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #007aff;
  cursor: pointer;
`;

const TabHeader = ({
  header,
  buttonText,
  onClick
}: {
  header: string;
  buttonText?: string;
  onClick?: () => void;
}) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <StyledHeader>{header}</StyledHeader>
      {buttonText && onClick && (
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          {buttonText.includes("Edit") ? (
            <Edit style={{ height: "16px", width: "16px", color: "#007aff" }} />
          ) : (
            <AddIcon
              style={{ height: "16px", width: "16px", color: "#007aff" }}
            />
          )}
          <StyledLink onClick={onClick}>{buttonText}</StyledLink>
        </div>
      )}
    </div>
  );
};

const websiteClick = (website) => {
  if (!website) return;
  if (website.includes("http://") || website.includes("https://"))
    window.open(website, "_blank");
  else window.open("https://" + website, "_blank");
};

const MeetingNotes = ({ htmlContent }: { htmlContent: string }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    // Check if content overflows and toggle the showMore state accordingly
    if ((contentRef.current?.scrollHeight || 0) > 300) {
      setShowMore(true);
    }
  }, []);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <div style={{ position: "relative" }}>
      <div
        ref={contentRef}
        style={{
          maxHeight: isExpanded ? "none" : "300px",
          overflow: isExpanded ? "visible" : "hidden",
          position: "relative",
          listStylePosition: "inside"
        }}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
      {showMore && (
        <span
          onClick={toggleExpand}
          style={
            isExpanded
              ? {
                  cursor: "pointer",
                  color: "#007AFF",
                  textDecoration: "underline",
                  display: "block",
                  marginTop: "8px"
                }
              : {
                  position: "absolute",
                  bottom: "3px",
                  right: "10px",
                  cursor: "pointer",
                  color: "#007AFF",

                  backgroundColor: "white",
                  padding: "2px 5px",
                  borderRadius: "3px",
                  fontSize: "14px"
                }
          }
        >
          {isExpanded ? "Show Less" : "....see more"}
        </span>
      )}
    </div>
  );
};

export const OnboardingDashboard = ({
  accountId,
  details,
  onRefresh
}: {
  accountId: string;
  details;
  onRefresh: () => void;
}) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const dateFormat = (date, durationMinutes) => {
    const startDate = dayjs.utc(date).tz(dayjs.tz.guess());
    const endDate = startDate.add(durationMinutes, "minutes");
    const formattedDate = `${startDate.format(
      "MM/DD/YYYY h:mmA"
    )} - ${endDate.format("h:mmA")} ${getTimezoneAbbreviation(
      dayjs.tz.guess()
    )}`;
    return formattedDate;
  };

  const navigate = useNavigate();
  const [showMeeting, setShowMeeting] = useState(false);
  const [selectedContact, setSelectedContact] = useState<string | null>(null);
  const { data: settings, isLoading: isLoadingConfig } = useConfigGet();
  const [meetingDuration, setMeetingDuration] = useState(30);
  useEffect(() => {
    if (settings && settings.data) {
      const onboardingLength = settings.data.find(
        (item) =>
          item.key ===
          (details.meetingDetails.type === "ONBOARDING"
            ? "crm.onboarding.session-length"
            : "crm.onboarding.review-meeting-length")
      )?.value;
      setMeetingDuration(
        getMeetingDurationInMinutes((onboardingLength as string) || "30M")
      );
    }
  }, [settings]);
  useEffect(() => {
    if (details && details.contacts.length > 0) {
      setSelectedContact(
        details.contactId || details.contacts[0].contact.contactId
      );
    }
  }, [details]);
  const externalParticipants =
    details.meetingDetails.activity?.externalParticipants?.map((e) => ({
      id: e.contactId,
      text: formatFullName(e)
    })) || [];
  const internalParticipants =
    details.meetingDetails.activity?.internalParicipants?.map((e) => ({
      id: e.userId,
      text: `${formatFullName(e.person)} ${
        e.userId === details.meetingDetails.assignedToId ? "(CSM)" : ""
      }`
    })) || [];
  const orderContact =
    details.contacts.find((c) => c.contactId == details.contactId) ||
    details.contacts[0];
  return (
    <Container>
      <Form>
        <Loader isLoading={isLoadingConfig}>
          <Grid container spacing={3}>
            <Grid md={6} xs={12} container direction="column">
              <Grid xs={12}>
                <TabHeader
                  header="Account Details"
                  buttonText="Edit Account"
                  onClick={() => navigate(`/crm/accounts/${accountId}/edit`)}
                />
                <HeaderUnderLine width="100%" />
                <AccountDetails
                  details={{
                    accountId: accountId,
                    ...details
                  }}
                  hideParent={!details.parent.name}
                  hideNoOfAthletes
                  hideSportsOffered
                  onParentClick={() =>
                    navigate(`/crm/accounts/${details.parent.accountId}`)
                  }
                />
              </Grid>
            </Grid>
            <Grid md={6} xs={12} container direction="column">
              {details?.contacts?.length > 0 ? (
                <Grid xs={12}>
                  <TabHeader
                    header="Contact Details"
                    buttonText="Edit Contact"
                    onClick={() => {
                      navigate(`/crm/contacts/${selectedContact}/edit`);
                    }}
                  />
                  <HeaderUnderLine width="100%" />
                  <ContactDetails
                    account={details}
                    contactSelected={setSelectedContact}
                    contactDetails={details.contacts.map((contact) => ({
                      contact: contact.contact,
                      isPrimary: contact.isPrimary,
                      workPhone: contact.workPhone,
                      workEmail: contact.workEmail
                    }))}
                  />
                </Grid>
              ) : (
                <Grid xs={12}>
                  <TabHeader
                    header="Contact Details"
                    buttonText="Add Contact"
                    onClick={() => {
                      navigate(`/crm/contacts/create?accountId=${accountId}`);
                    }}
                  />
                  <HeaderUnderLine width="100%" />
                  <div
                    style={{
                      padding: "15px",
                      background: "#f3f4f7",
                      borderRadius: "4px",
                      marginTop: "16px"
                    }}
                  >
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      spacing="20px"
                      justifyContent="center"
                    >
                      <Grid>
                        <span
                          style={{
                            color: "#0F0F0F",
                            fontWeight: 600
                          }}
                        >
                          No Contact added to this account
                        </span>
                      </Grid>
                      <Grid>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px"
                          }}
                        >
                          <AddIcon
                            style={{
                              height: "16px",
                              width: "16px",
                              color: "#007aff"
                            }}
                          />

                          <StyledLink
                            onClick={() => {
                              navigate(
                                `/crm/contacts/create?accountId=${accountId}`
                              );
                            }}
                          >
                            Add Contact
                          </StyledLink>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              )}
            </Grid>
            <Grid xs={12}>
              <TabHeader
                header="Meeting Details"
                buttonText="Edit"
                onClick={
                  details.activityId
                    ? () => {
                        navigate(`/crm/activity/${details.activityId}/edit`);
                      }
                    : undefined
                }
              />
              <HeaderUnderLine width="100%" />
              {!details.activityId &&
              details.meetingDetails.status === "AT_RISK" ? (
                <div style={{ marginTop: "10px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "#FFEEEE",
                      padding: "12px",
                      borderRadius: "8px",
                      width: "fit-content"
                    }}
                  >
                    <InfoOutlined htmlColor="#E82C2C" fontSize="small" />
                    <span
                      style={{
                        marginLeft: "10px",
                        color: "#E82C2C",
                        fontWeight: 500,
                        fontSize: "14px"
                      }}
                    >
                      {`Customer At Risk - ${
                        details.meetingDetails.type === "ONBOARDING"
                          ? "Onboarding"
                          : "Review"
                      } meeting not scheduled in the
                      time allowed`}
                    </span>
                  </div>
                  <Typography
                    style={{
                      fontSize: " 14px",
                      fontWeight: 600,
                      lineHeight: "18px",
                      color: "#007aff",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      marginTop: "15px",
                      gap: "10px"
                    }}
                    onClick={() => {
                      setShowMeeting(true);
                    }}
                  >
                    <AddIcon fontSize="small" />
                    Add Meeting
                  </Typography>
                  {showMeeting && (
                    <CreateOnboardingEventModal
                      name={`${
                        details.meetingDetails?.type == "ONBOARDING"
                          ? "Onboarding"
                          : "Review"
                      } Meeting with ${details.name}`}
                      open={showMeeting}
                      setOpen={setShowMeeting}
                      selectedAccount={accountId}
                      autoSuggestOptions={[
                        ...(orderContact?.workEmail
                          ? [
                              {
                                label: formatFullName(
                                  orderContact.contact as ModelPerson
                                ),
                                text: orderContact?.workEmail,
                                value: orderContact.contactId!,
                                isInternal: false,
                                emailDetails: {
                                  email: orderContact?.workEmail,
                                  emailInfo: `Work Email`,
                                  isPrimaryStar: orderContact?.isPrimary
                                }
                              }
                            ]
                          : []),
                        ...(orderContact.contact?.email
                          ? [
                              {
                                label: formatFullName(
                                  orderContact.contact as ModelPerson
                                ),
                                text: orderContact.contact.email,
                                value: orderContact.contactId!,
                                isInternal: false,
                                emailDetails: {
                                  email: orderContact.contact.email,
                                  emailInfo: `Personal Email`,
                                  isPrimaryStar: orderContact?.isPrimary
                                }
                              }
                            ]
                          : [])
                      ]}
                      meetingDuration={meetingDuration}
                      eventId={details.meetingDetails.eventId}
                      onRefresh={() => {
                        onRefresh();
                      }}
                    />
                  )}
                </div>
              ) : (
                <StableGrid container direction="row">
                  <StableGrid item xs={6} marginTop="20px">
                    <div>
                      {details.meetingDetails.order && (
                        <>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px"
                            }}
                          >
                            Start Date
                          </Typography>
                          <Typography
                            style={{
                              fontWeight: 400,
                              color: "#666666",
                              fontSize: "16px"
                            }}
                          >
                            {formatDateForDisplay(
                              new Date(
                                details.meetingDetails.order.revisedStartDate ||
                                  details.meetingDetails.order.originalStartDate
                              )
                            )}
                          </Typography>
                        </>
                      )}
                      {details.meetingDetails.meetingDate && (
                        <div style={{ marginTop: "10px" }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px"
                            }}
                          >
                            Onboarding Session Date & Time
                          </Typography>
                          <Typography
                            style={{
                              fontWeight: 400,
                              color: "#666666",
                              fontSize: "16px"
                            }}
                          >
                            {dateFormat(
                              details.meetingDetails.meetingDate,
                              meetingDuration
                            )}
                          </Typography>
                        </div>
                      )}
                      {details.meetingDetails.activity?.meeting && (
                        <div style={{ marginTop: "10px" }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px"
                            }}
                          >
                            Onboarding Meeting Link
                          </Typography>

                          <StyledLink>
                            <ToolTip
                              title="Click to Visit"
                              placement="top-start"
                            >
                              <span
                                style={{
                                  fontWeight: 400,
                                  fontSize: "16px"
                                }}
                                onClick={() =>
                                  websiteClick(
                                    details.meetingDetails.activity.meeting
                                      ?.meetingUrl
                                  )
                                }
                              >
                                {
                                  details.meetingDetails.activity.meeting
                                    ?.meetingUrl
                                }
                              </span>
                            </ToolTip>
                          </StyledLink>
                        </div>
                      )}
                      {details.meetingDetails.activity?.meeting && (
                        <div style={{ marginTop: "10px" }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px"
                            }}
                          >
                            Participants
                          </Typography>
                          <div>
                            {internalParticipants.map((i, idx) => (
                              <Typography
                                key={i.id}
                                style={{
                                  fontWeight: 400,
                                  color: "#666666",
                                  fontSize: "16px",
                                  ...(idx !== 0 && {
                                    marginTop: "4px"
                                  })
                                }}
                              >
                                {i.text}
                              </Typography>
                            ))}
                            {externalParticipants.map((i) => (
                              <Typography
                                key={i.id}
                                style={{
                                  fontWeight: 400,
                                  color: "#666666",
                                  fontSize: "16px",
                                  marginTop: "4px"
                                }}
                              >
                                {i.text}
                              </Typography>
                            ))}
                          </div>
                        </div>
                      )}
                      {details.meetingDetails.customerNotes && (
                        <div style={{ marginTop: "10px" }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px"
                            }}
                          >
                            Customer Notes
                          </Typography>
                          <Typography
                            style={{
                              fontWeight: 400,
                              color: "#666666",
                              fontSize: "16px"
                            }}
                          >
                            {details.meetingDetails.customerNotes}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </StableGrid>
                  {details.meetingDetails?.activity?.notes && (
                    <StableGrid
                      item
                      container
                      direction="column"
                      spacing="10px"
                      xs={6}
                      marginTop="10px"
                    >
                      <StableGrid item>
                        <Typography
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px"
                          }}
                        >
                          Internal Meeting Notes
                        </Typography>
                      </StableGrid>
                      <StableGrid item>
                        <MeetingNotes
                          htmlContent={extractHTMLFromDelta(
                            details.meetingDetails.activity.notes
                          )}
                        />
                      </StableGrid>
                    </StableGrid>
                  )}
                </StableGrid>
              )}
            </Grid>
          </Grid>
        </Loader>
      </Form>
    </Container>
  );
};
