import { Grid } from "@mui/material";
import { ModelSeason } from "@sportsgravyengineering/sg-api-react-sdk";

export const FilterSeasonItem = ({ season }: { season: ModelSeason }) => {
  return (
    <Grid container direction="row" spacing="15px">
      <Grid item>{season?.name}</Grid>
      <Grid
        item
        style={{
          color: "#64748B"
        }}
      >
        {season.sport?.name}
      </Grid>
      {season.startDate && season.endDate && (
        <Grid
          item
          style={{
            color: "#64748B"
          }}
        >{`(${new Date(season.startDate).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric"
        })} - ${new Date(season.endDate).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric"
        })})`}</Grid>
      )}
    </Grid>
  );
};
