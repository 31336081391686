import { Button } from "@components/Button";
import { Container } from "@components/crud/Container";
import { NoRecords } from "@components/NoRecords";
import { RenderTableView } from "@components/RenderTableView";
import { ToolTip } from "@components/ToolTip";
import { Add, Download, Mail, Visibility } from "@mui/icons-material";
import { Box, IconButton, styled, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { usePDF } from "@react-pdf/renderer";
import {
  ModelMedia,
  ModelOpportunity,
  ModelPerson,
  ModelSendGridMail,
  useConfigGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { CallEmailTemplateContext } from "@templates/CallEmailTemplate";
import formatFullName from "@utils/formatFullName";
import { useContext, useEffect, useState } from "react";
import { QuotePDF } from "./QuotePdf";
import { Loader } from "@components/crud/Loader";
import {
  CRM_OPPORTUNITY_STAGES,
  CRM_OPPORTUNITY_TYPES
} from "@utils/constants";
import { formatCurrency } from "@utils/formatCurrency";
import { formatPhoneWithCountryCode } from "@utils/phoneFormatters";
import { useRecoilValue } from "recoil";
import { profileAtom } from "@recoil/auth";
import { Tier } from "@pages/settings/CRMSetting";
import { AttachmentMediaViewer } from "../activities/components/AttachmentMediaViewer";
import { downloadPDFFromLink } from "@utils/downloadPdfFromLink";

const IconStyle = {
  height: "20px",
  width: "20px"
};
const StyledBox = styled(Box)`
  padding: 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
const formatDateForDisplay = (dateStr) => {
  const inputDate = new Date(dateStr);
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const day = inputDate.getDate().toString().padStart(2, "0");
  const year = inputDate.getFullYear();
  // Create the formatted date string in "MM/DD/YYYY" format
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
};
const generateFile = (instance) => {
  if (instance.blob) {
    const file = new File([instance.blob], "Quote.pdf", {
      type: "application/pdf"
    });
    return file;
  }
};

export const OpportunityQuotes = ({
  quotes,
  opportunityDetails,
  setRefresh
}: {
  quotes;
  opportunityDetails: ModelOpportunity;
  setRefresh: () => void;
}) => {
  const { setEmailTo, emailTo } = useContext(CallEmailTemplateContext);
  const [selectedQuote, setSelectedQuote] = useState<
    | {
        type: "DOWNLOAD" | "VIEW";
        media: ModelMedia | undefined;
        quoteId?: string;
      }
    | undefined
  >(undefined);
  const user = useRecoilValue(profileAtom);
  const { data: config, isLoading: configLoading } = useConfigGet();

  const getPricingTier = (
    tier: Tier[],
    noOfAthletes: number,
    countryId: string
  ) => {
    return tier.find(
      (t) =>
        Number(t.startAthlete) <= noOfAthletes &&
        Number(t.endAthlete) >= noOfAthletes &&
        t.countryId === countryId
    );
  };

  const downloadQuote = async (
    url: string,
    fileName: string,
    quoteId: string
  ) => {
    setSelectedQuote({ type: "DOWNLOAD", media: undefined, quoteId });
    downloadPDFFromLink(url, fileName).then(() => {
      setSelectedQuote(undefined);
    });
  };

  const CONTACT_COLUMNS: GridColDef<{
    quoteId: string;
    name: string;
    type: string;
    numberOfAthletes: string;
    amount: number;
    stage: string;
    mail: ModelSendGridMail;
    createdAt: string;
  }>[] = [
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderHeader: () => {
        return <div style={{ padding: "20px 20px" }}>Actions</div>;
      },
      renderCell: (params) => {
        return (
          <div style={{ padding: "20px 20px", display: "flex" }}>
            <IconButton
              onClick={() =>
                setSelectedQuote({
                  type: "VIEW",
                  media: params.row.mail.attachments?.[0]
                })
              }
            >
              <ToolTip title="View Quote" placement="top">
                <Visibility style={IconStyle} />
              </ToolTip>
            </IconButton>
            <Loader
              isLoading={
                selectedQuote?.quoteId === params.row.quoteId &&
                selectedQuote.type === "DOWNLOAD"
              }
            >
              <IconButton
                onClick={() =>
                  params.row.mail.attachments?.[0].baseUrl &&
                  downloadQuote(
                    params.row.mail.attachments?.[0].baseUrl +
                      params.row.mail.attachments?.[0].path,
                    "Quote.pdf",
                    params.row.quoteId
                  )
                }
              >
                <ToolTip title="Download Quote" placement="top">
                  <Download style={IconStyle} />
                </ToolTip>
              </IconButton>
            </Loader>
            <IconButton
              onClick={() => {
                const { opportunityId, contact, account } = opportunityDetails;
                const accountContact = contact?.accounts?.find(
                  (a) => a.accountId === account?.accountId
                );
                setEmailTo({
                  to: contact?.email,
                  name: formatFullName(contact as ModelPerson),
                  relatesTo: "OPPORTUNITY",
                  opportunityId: opportunityId,
                  accountId: account?.accountId,
                  accountName: account?.name,
                  autoSuggestOptions: [
                    ...(accountContact?.workEmail
                      ? [
                          {
                            label: formatFullName(contact as ModelPerson),
                            text: accountContact?.workEmail,
                            value: contact!.contactId!,
                            isInternal: false,
                            emailDetails: {
                              email: accountContact?.workEmail,
                              emailInfo: `Work Email`,
                              isPrimaryStar: accountContact?.isPrimary,
                              isWork: true
                            }
                          }
                        ]
                      : []),
                    ...(contact?.email
                      ? [
                          {
                            label: formatFullName(contact as ModelPerson),
                            text: contact.email,
                            value: contact.contactId!,
                            isInternal: false,
                            emailDetails: {
                              email: contact.email,
                              emailInfo: `Personal Email`,
                              isPrimaryStar: accountContact?.isPrimary
                            }
                          }
                        ]
                      : [])
                  ],
                  ...(!!params.row.mail?.attachments?.length && {
                    files: [
                      {
                        id: params.row.mail.attachments[0].mediaId!,
                        file: params.row.mail.attachments[0]
                      }
                    ]
                  })
                });
              }}
            >
              <ToolTip title="Mail Quote" placement="top">
                <Mail style={IconStyle} />
              </ToolTip>
            </IconButton>
          </div>
        );
      }
    },
    {
      field: "name",
      headerName: "Opportunity Name",
      flex: 1,
      minWidth: 200,
      valueGetter: ({ row }) => row.name
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 175,
      valueGetter: ({ row }) =>
        CRM_OPPORTUNITY_TYPES.find((o) => o.value === row.type)?.label || "-"
    },
    {
      field: "numberOfAthletes",
      headerName: "No. of Athlete Registrations",
      flex: 1,
      sortable: false,
      minWidth: 250,
      valueFormatter: ({ value }) => value
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      minWidth: 200,
      valueGetter: ({ row }) => formatCurrency(row.amount)
    },
    {
      field: "stage",
      headerName: "Stage",
      flex: 1,
      minWidth: 200,
      valueGetter: ({ row }) =>
        CRM_OPPORTUNITY_STAGES.find((c) => c.value === row.stage)?.label
    },
    {
      field: "sentTo",
      headerName: "Sent To",
      flex: 1,
      minWidth: 200,
      valueGetter: ({ row }) => {
        const to = row.mail?.to?.find(
          (t) => !t.email.includes("sportsgravy.com")
        );
        if (to?.email)
          return `${to?.isWorkEmail ? "[W] " : "[P] "}${to?.email || ""}`;
        else "-";
      }
    },
    {
      field: "createdAt",
      headerName: "Created at",
      flex: 1,
      minWidth: 200,
      valueGetter: ({ row }) => formatDateForDisplay(row.createdAt)
    }
  ];

  const [quoteInstance, setQuoteInstance] = usePDF();
  const generateQuoteInstance = () => {
    setQuoteInstance(
      <QuotePDF
        hasFooter={false}
        hasHeader={true}
        quote={{
          to: {
            name: opportunityDetails.account?.name,
            address: opportunityDetails.account?.billingAddress,
            email:
              opportunityDetails.contact?.accounts?.find(
                (ac) => ac.accountId === opportunityDetails.account?.accountId
              )?.workEmail || "",
            phone:
              formatPhoneWithCountryCode(
                opportunityDetails.contact?.accounts?.find(
                  (ac) => ac.accountId === opportunityDetails.account?.accountId
                )?.workPhone
              ) || "",
            contact: formatFullName(opportunityDetails.contact as ModelPerson)
          },
          date: new Date().toLocaleDateString(),
          preparedBy: {
            firstName: user?.person?.firstName,
            lastName: user?.person?.lastName
          },
          oneTimeSetupFee:
            Number(
              getPricingTier(
                config?.data.find((c) => c.key === "crm.order.pricing-tier")
                  ?.value as Tier[],
                opportunityDetails.numberOfAthletes as number,
                opportunityDetails.account?.billingAddress.split(", ").pop() ||
                  "US"
              )?.setupPrice
            ) || 0,
          noOfAthletes: opportunityDetails.numberOfAthletes as number,
          costPerAthlete: opportunityDetails.approvedPricePerAthlete as number,
          upFrontDiscount:
            (config?.data.find((c) => c.key === "crm.order.upfront-discount")
              ?.value as number) || 0,
          from: {
            name: "SportsGravy LLC.",
            address: "9900 Hemingway Ave S, Cottage Grove, MN, 55016, USA"
          }
        }}
      />
    );
  };

  useEffect(() => {
    if (!quoteInstance.loading) {
      const file = generateFile(quoteInstance);
      const { opportunityId, contact, account } = opportunityDetails;
      const accountContact = contact?.accounts?.find(
        (a) => a.accountId === account?.accountId
      );
      if (file) {
        setSendQuoteActive(true);
        setEmailTo({
          to: contact?.email,
          name: formatFullName(contact as ModelPerson),
          relatesTo: "OPPORTUNITY",
          opportunityId: opportunityId,
          accountId: account?.accountId,
          accountName: account?.name,
          autoSuggestOptions: [
            ...(accountContact?.workEmail
              ? [
                  {
                    label: formatFullName(contact as ModelPerson),
                    text: accountContact?.workEmail,
                    value: contact!.contactId!,
                    isInternal: false,
                    emailDetails: {
                      email: accountContact?.workEmail,
                      emailInfo: `Work Email`,
                      isPrimaryStar: accountContact?.isPrimary,
                      isWork: true
                    }
                  }
                ]
              : []),
            ...(contact?.email
              ? [
                  {
                    label: formatFullName(contact as ModelPerson),
                    text: contact.email,
                    value: contact.contactId!,
                    isInternal: false,
                    emailDetails: {
                      email: contact.email,
                      emailInfo: `Personal Email`,
                      isPrimaryStar: accountContact?.isPrimary
                    }
                  }
                ]
              : [])
          ],
          opportunityQuoteId: opportunityId,
          ...(file && { files: [{ id: "1", file: file! }] })
        });
      }
    }
  }, [quoteInstance]);

  const [sendQuoteActive, setSendQuoteActive] = useState(false);
  useEffect(() => {
    if (!emailTo && sendQuoteActive) {
      setRefresh();
      setSendQuoteActive(false);
    }
  }, [emailTo]);
  return (
    <Loader isLoading={configLoading}>
      <Container>
        <StyledBox>
          <div>
            <Typography
              style={{
                color: "#1E293B",
                fontWeight: 300,
                fontSize: "32px"
              }}
            >
              Quotes
            </Typography>
            <Button
              isLoading={quoteInstance.loading}
              disabled={quoteInstance.loading}
              variant="admin-primary"
              startIcon={<Add style={{ height: "27px", width: "27px" }} />}
              onClick={generateQuoteInstance}
            >
              Send Quote
            </Button>
          </div>
        </StyledBox>
        <RenderTableView
          title=""
          hideToolbar
          rows={quotes}
          columns={CONTACT_COLUMNS}
          getRowId={(row) => row.quoteId}
          hasActionColumn={false}
          hideFooter
          sortingMode="client"
          getRowHeight={() => "auto"}
          noRecordsFoundElemet={
            <NoRecords
              title="The list is empty"
              description="Send Quotes for this opportunity"
              buttonClick={() => {}}
              buttonText="Send Quote"
              buttonIcon={<Add />}
            />
          }
          pinnedColumns={{
            left: ["action", "name"]
          }}
        />
      </Container>
      {selectedQuote?.media && selectedQuote.type === "VIEW" && (
        <AttachmentMediaViewer
          media={selectedQuote.media}
          onClose={() => setSelectedQuote(undefined)}
        />
      )}
    </Loader>
  );
};
