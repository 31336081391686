import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import { AccountDetailsForm } from "./components/AccountDetailsForm";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getCountries } from "@services/Network";
import {
  ModelAccount,
  ModelCountry,
  ModelOrganizationAccount,
  useGetAdminCrmAccountAccountId,
  useGetAdminCrmOrgAccountAccountId,
  usePutAdminCrmAccountAccountId,
  usePutAdminCrmOrgAccountAccountId
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Footer } from "@components/crud/Footer";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { RelatedLink } from "../components/RelatedLinks";
import { enqueueSnackbar } from "notistack";
import { cleanObject } from "@utils/cleanObject";
import { hasPermission } from "@services/Casbin";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";

export const AccountEdit = () => {
  const navigate = useNavigate();
  const {
    control,
    reset,
    setValue,
    getValues,
    formState: { isValid, isDirty },
    trigger
  } = useForm({
    mode: "onBlur"
  });

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        organizationId ? "ORGANIZATION" : "SYSTEM",
        organizationId || "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const edit = await checkPermission("crm.accounts", "EDIT");
      if (!edit) navigate("/not-found");
    };
    fetchPermissions();
  }, []);

  const countries = getCountries();
  const [isBillingSame, setIsBillingSame] = useState(true);
  const organizationId = useRecoilValue(organizationAtom);
  const [isShippingSame, setIsShippingSame] = useState(true);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [existingAccountSelected, setExistingAccountSelected] = useState<
    object | undefined
  >(undefined);
  const [country, setCountry] = useState<ModelCountry | undefined>(undefined);

  const selectAddressSuggestion = (
    place,
    addressType: "officeAddress" | "billingAddress" | "shippingAddress"
  ) => {
    const addressComponents = place?.address_components || [];
    const streetNumber = addressComponents.find((c) =>
      c.types.includes("street_number")
    );
    const route = addressComponents.find((c) => c.types.includes("route"));
    const address1 = `${streetNumber?.long_name} ${route?.long_name}`;

    const country = addressComponents.find((c) => c.types.includes("country"));
    const state = addressComponents.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    const city = addressComponents.find(
      (c) => c.types.includes("locality") || c.types.includes("sublocality")
    );
    const zip = addressComponents.find((c) => c.types.includes("postal_code"));

    let address: string = "";

    if (address1 && !address1.includes("undefined")) address = `${address1}, `;
    if (city?.long_name && city?.long_name != "undefined")
      address += `${city?.long_name}, `;
    if (state?.short_name && state?.short_name != "undefined")
      address += `${state?.short_name}, `;
    if (zip?.long_name && zip?.long_name != "undefined")
      address += `${zip?.long_name}, `;
    if (country?.short_name && country?.short_name != "undefined")
      address += `${country?.short_name}`;
    setValue(addressType, address);
    if (
      countries &&
      countries.data &&
      countries.data.find((c) => c.countryId == country?.short_name)
    ) {
      setCountry(
        countries.data.find((c) => c.countryId == country?.short_name)
      );
    }
  };

  const { accountId } = useParams();
  const { data: accountData, isLoading: isAccountLoading } = organizationId
    ? useGetAdminCrmOrgAccountAccountId(accountId!)
    : useGetAdminCrmAccountAccountId(accountId!);
  const [socialMediaLinks, setSocialMediaLinks] = useState<RelatedLink[]>([]);
  const [parentId, setParentId] = useState<string | null>(null);

  useEffect(() => {
    if (accountData?.data) {
      const account = organizationId
        ? (accountData.data as ModelOrganizationAccount)
        : (accountData.data as ModelAccount);
      const billingSame = account.officeAddress === account.billingAddress;
      const shippingSame =
        account.shippingAddress === account.officeAddress ||
        account.shippingAddress === account.billingAddress;
      const shippingSameAs = shippingSame
        ? account.shippingAddress === account.officeAddress
          ? "OFFICE"
          : "BILLING"
        : "OFFICE";
      const links =
        account.links?.reduce((acc, link, idx) => {
          const key = `url${idx}`;
          acc[key] = link.link;
          if (link.altName) acc[`urlOtherName${idx}`] = link.altName;
          return acc;
        }, {}) || {};
      reset({
        name: account.name,
        type: account.type,
        category: account.category,
        parentId: account.parentId,
        parent: account.parent?.name,

        officeAddress: account.officeAddress,
        billingAddress: account.billingAddress,
        shippingAddress: account.shippingAddress,
        phoneType: account.phoneType,
        phone: account.phone,
        fax: account.fax,
        email: account.email,
        website: account.website,
        ...(!organizationId
          ? {
              sportsOffered:
                (account as ModelAccount).sports?.map((s) => s.sportId) || [],
              noOfAtheletes: (account as ModelAccount).numberOfAthletes,
              bdrOwner: (account as ModelAccount).bdrOwner?.userId,
              aeOwner: (account as ModelAccount).aeOwner?.userId,
              csmOwner: (account as ModelAccount).csmOwner?.userId
            }
          : {
              owner: (account as ModelOrganizationAccount).owner?.userId
            }),
        notes: account.notes,
        isBillingSameAs: billingSame,
        isShippingSameAs: shippingSame,
        shippingSameAs: shippingSameAs,
        ...links
      });
      setIsBillingSame(billingSame);
      setIsShippingSame(shippingSame);
      setCountry(countries.data.find((c) => c.countryId == "US"));
      setSocialMediaLinks(
        account.links?.map((l) => ({
          url: l.link!,
          otherName: l.altName || "",
          name: l.type!
        })) || []
      );
      setParentId(account.parentId || null);
    }
  }, [accountData]);

  const { mutate: save, isLoading: isSaving } = organizationId
    ? usePutAdminCrmOrgAccountAccountId()
    : usePutAdminCrmAccountAccountId();
  const onSave = () => {
    const billingAddress = getValues().isBillingSameAs
      ? getValues().officeAddress
      : getValues().billingAddress;
    const shippingAddress = getValues().isShippingSameAs
      ? getValues().shippingSameAs === "OFFICE"
        ? getValues().officeAddress
        : getValues().billingAddress
      : getValues().shippingAddress;
    const data = {
      name: getValues().name,
      ...(!organizationId && {
        type: getValues().type
      }),
      category: getValues().category,

      parentId: parentId,
      officeAddress: getValues().officeAddress,
      billingAddress: billingAddress,
      shippingAddress: shippingAddress,
      ...(!organizationId
        ? {
            numberOfAthletes: parseInt(getValues().noOfAtheletes) || 0,
            csmOwnerId: getValues().csmOwner,
            aeOwnerId: getValues().aeOwner,
            bdrOwnerId: getValues().bdrOwner,
            sports: getValues().sportsOffered,
            phoneType: getValues().phoneType || "MOBILE"
          }
        : {
            ownerId: getValues().owner
          }),
      email: getValues().email,
      phone: getValues().phone,
      fax: getValues().fax,
      notes: getValues().notes,
      website: getValues().website,
      links: socialMediaLinks.map((l) => ({
        type: l.name,
        link: l.url,
        altName: l.otherName
      }))
    };
    save(
      {
        accountId: accountId!,
        data: cleanObject(data)
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Account Edited successfully!", {
            variant: "success"
          });

          navigate("/crm/accounts");
        },
        onError: () => {
          enqueueSnackbar("Failed to Edit Account!", {
            variant: "error"
          });
        }
      }
    );
  };

  return (
    <Container>
      <Toolbar title="Edit Account" />
      <Loader isLoading={isAccountLoading}>
        <AccountDetailsForm
          control={control}
          isBillingSame={isBillingSame}
          isShippingSame={isShippingSame}
          setIsBillingSame={setIsBillingSame}
          setIsShippingSame={setIsShippingSame}
          selectAddressSuggestion={selectAddressSuggestion}
          country={country}
          relatedLinks={socialMediaLinks}
          setRelatedLinks={setSocialMediaLinks}
          parentId={parentId}
          setParentId={setParentId}
          mode="edit"
          setExistingAccountSelected={setExistingAccountSelected}
          trigger={trigger}
        />
      </Loader>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnLabel={existingAccountSelected ? "View Account" : "Save"}
        saveBtnClick={() => {
          if (existingAccountSelected) {
            navigate(`/crm/accounts/${1}?tab=Account+Details`);
          } else onSave();
        }}
        isDisabled={existingAccountSelected ? false : !isValid || !isDirty}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/crm/accounts")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
