import {
  Autocomplete,
  FormHelperText,
  FormLabel,
  IconButton,
  Typography,
  styled
} from "@mui/material";
import { FormInput } from "@components/FormInput";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import {
  createCRMActivity,
  getCountries,
  getCrmAccounts,
  getCrmOrgAccounts
} from "@services/Network";
import { SearchInput } from "@components/SearchInput";
import { FormCheckbox } from "@components/FormCheckbox";
import { ModelAccount } from "@sportsgravyengineering/sg-api-react-sdk";
import Grid from "@mui/system/Unstable_Grid";
import EmailIcon from "@mui/icons-material/Email";
import { CallEmailTemplateContext } from "@templates/CallEmailTemplate";
import { Loader } from "@components/crud/Loader";
import { enqueueSnackbar } from "notistack";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import CallIcon from "@mui/icons-material/Call";
import { useRecoilValue } from "recoil";
import { profileAtom } from "@recoil/auth";
import { ToolTip } from "@components/ToolTip";

const StyledAccountOption = styled(Grid)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F5F5F5"
  }
}));
const AccountOption = ({
  onClick,
  account,
  ...props
}: {
  onClick: () => void;
  account: ModelAccount;
}) => {
  const { name, officeAddress } = account;

  return (
    <StyledAccountOption
      container
      direction="column"
      padding="10px 16px 10px 16px"
      {...props}
      onClick={onClick}
    >
      <Grid>
        <Typography
          style={{
            color: "#000",
            fontSize: "13px",
            fontWeight: 500
          }}
        >
          {name}
        </Typography>
      </Grid>
      <Grid>
        <Typography
          style={{
            color: "#666666",
            fontSize: "13px",
            fontWeight: 400
          }}
        >
          {officeAddress}
        </Typography>
      </Grid>
    </StyledAccountOption>
  );
};

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

export const RelatedAccounts = (props: {
  relatedAccount;
  control;
  selectedAccountIndex;
  setAccountIndexValue;
  disabled?;
  defaultSearchValue?: string;
  onOptionClick?: (isNewAccount: boolean, selectedAccount) => void;
  contact?: {
    contactName: string;
    contactPhone: string;
    contactId: string;
  };
  organizationId?: string;
  setValue?;
}) => {
  const countries = getCountries();
  dayjs.extend(timezone);
  const user = useRecoilValue(profileAtom);
  const [accountSearchBlurred, setAccountSearchBlurred] = useState(false);
  const onOptionClick = props.onOptionClick;
  const [accountSearch, setAccountSearch] = useState("");
  const [addUserMenuOpen, setAddUserMenuOpen] = useState(false);
  const onAddAccountSearchChange = (event) => {
    setAccountSearch(event.target.value);
  };
  const mutation = createCRMActivity();

  const {
    setCallerDetails,
    connectToCall,
    setCallAccepted,
    callInstance,
    setEmailTo
  } = useContext(CallEmailTemplateContext);

  useEffect(() => {
    if (props.defaultSearchValue) setAccountSearch(props.defaultSearchValue);
  }, [props.defaultSearchValue]);
  const { data: addAccountResults, isFetching: isLoading } =
    props.organizationId
      ? getCrmOrgAccounts(
          {
            textSearch: accountSearch,
            organizationId: props.organizationId
          },
          { staleTime: Infinity, enabled: !!accountSearch?.length }
        )
      : getCrmAccounts(
          {
            textSearch: accountSearch
          },
          { staleTime: Infinity, enabled: !!accountSearch?.length }
        );
  const addAccountOptions = [...addAccountResults];

  useEffect(() => {
    if (props.relatedAccount.jobTitle && props.setValue) {
      props.setValue(
        `accounts[${props.selectedAccountIndex}].jobTitle`,
        props.relatedAccount.jobTitle
      );
    }
  }, [props.relatedAccount]);

  return (
    <Grid container spacing={3}>
      <Grid xs={6}>
        {!props.disabled ? (
          <>
            <StyledFormLabel required>
              <Typography display="inline" variant="formLabel">
                Account Name
              </Typography>
            </StyledFormLabel>
            <Autocomplete
              sx={{ marginTop: "4px" }}
              open={addUserMenuOpen}
              clearOnBlur={false}
              disabled={props.disabled}
              onOpen={() => setAddUserMenuOpen(true)}
              onClose={() => setAddUserMenuOpen(false)}
              // value={secondaryContact}
              inputValue={accountSearch}
              getOptionLabel={(option) =>
                (option ? option?.name : "") as string
              }
              isOptionEqualToValue={(option, value) =>
                option?.accountId === value?.accountId
              }
              renderInput={(params) => (
                <SearchInput
                  data-testid="ADD_ACC_SEARCH_PERSON_INPUT"
                  {...params}
                  value={accountSearch}
                  style={{
                    height: "53px"
                  }}
                  onChange={onAddAccountSearchChange}
                />
              )}
              filterOptions={(options) =>
                addUserMenuOpen && !isLoading ? options : []
              }
              renderOption={(props, option: ModelAccount) => {
                return (
                  <div
                    data-testid={"SEARCH_OPTION_" + option.accountId}
                    key={option.accountId}
                    style={{
                      height: "55px"
                    }}
                  >
                    <AccountOption
                      key={option.accountId}
                      account={option}
                      onClick={() => {
                        if (onOptionClick) {
                          onOptionClick(false, {
                            account: option.name,
                            accountId: option.accountId,
                            jobTitle: "",
                            isPrimaryContact: true,
                            workLocation: option.officeAddress,
                            workPhone: "",
                            workEmail: "",
                            accountPhone: option.phone,
                            accountEmail: option.email
                          });
                        }
                        setAccountSearch(option!.name!);
                        setAddUserMenuOpen(false);
                      }}
                    />
                  </div>
                );
              }}
              onBlur={() => setAccountSearchBlurred(true)}
              options={addAccountOptions || []}
              loading={isLoading}
              loadingText="Loading..."
            />
            {accountSearchBlurred &&
              (!props.relatedAccount.accountId ||
                accountSearch.length === 0) && (
                <FormHelperText error>Account is required</FormHelperText>
              )}
          </>
        ) : (
          <FormInput
            name={`accounts[${props.selectedAccountIndex}].account`}
            control={props.control}
            label="Account Name"
            required
            type="text"
            disabled
            enteredValue={props.relatedAccount.account || ""}
            rules={{
              required: { value: true, message: "Account is required" }
            }}
          />
        )}
      </Grid>
      <Grid xs={4}>
        <FormInput
          name={`accounts[${props.selectedAccountIndex}].jobTitle`}
          control={props.control}
          label="Job Title"
          required
          type="text"
          disabled={props.disabled}
          enteredValue={props.relatedAccount.jobTitle || ""}
          rules={{
            required: "Job Title is required"
          }}
          onChange={(e) => {
            props.setAccountIndexValue(
              props.selectedAccountIndex,
              "jobTitle",
              (e as ChangeEvent<HTMLInputElement>).target.value
            );
          }}
        />
      </Grid>
      <Grid xs={2} sx={{ display: "flex" }}>
        <FormCheckbox
          name={`isAccPrimaryContact[${props.selectedAccountIndex}]`}
          control={props.control}
          value={props.relatedAccount.isPrimaryContact}
          label="Primary Contact"
          disabled={props.disabled}
          onChange={(e) => {
            props.setAccountIndexValue(
              props.selectedAccountIndex,
              "isPrimaryContact",
              e.target.checked
            );
          }}
        />
      </Grid>

      <Grid xs={6}>
        <FormInput
          control={props.control}
          name={`workPhone${props.selectedAccountIndex}`}
          type="tel"
          label={"Work Phone"}
          disabled={props.disabled}
          enteredValue={props.relatedAccount.workPhone}
          triggerCountryCodeChange={true}
          {...((!props.relatedAccount.workPhone ||
            props.relatedAccount.workPhone === "") &&
            props.relatedAccount.workLocation && {
              country: countries.data.find(
                (country) =>
                  country.countryId ===
                  props.relatedAccount.workLocation.split(", ")[
                    props.relatedAccount.workLocation.split(", ").length - 1
                  ]
              )
            })}
          onChange={(e, field, countryCode) => {
            if (!e)
              props.setAccountIndexValue(
                props.selectedAccountIndex,
                "workPhone",
                undefined
              );
            else {
              const value = (
                e as ChangeEvent<HTMLInputElement>
              ).target.value.replace(/\D/g, "");
              (e as ChangeEvent<HTMLInputElement>).target.value = value;
              props.setAccountIndexValue(
                props.selectedAccountIndex,
                "workPhone",
                `${countryCode}${value}`
              );
            }
          }}
          InputProps={{
            ...(props.disabled &&
              !props.organizationId && {
                endAdornment: (
                  <Loader align="flex-end" isLoading={mutation.isLoading}>
                    <IconButton
                      onClick={async () => {
                        if (callInstance) {
                          enqueueSnackbar("You are already on a call!", {
                            variant: "error"
                          });
                          return;
                        }
                        mutation.mutate(
                          {
                            data: {
                              accountId: props.relatedAccount.accountId,
                              contactId: props.contact?.contactId,
                              type: "CALL",
                              relatesTo: "CONTACT",
                              date: new Date(),
                              timezone: dayjs.tz.guess(),
                              direction: "outbound",
                              status: "LIVE",
                              outcome: "CONNECTED_WITH_CONTACT",
                              internalParticipants: [user?.userId as string],
                              externalParticipants: [
                                {
                                  phone: props.relatedAccount?.workPhone || ""
                                }
                              ]
                            }
                          },
                          {
                            onSuccess: (data) => {
                              setCallerDetails({
                                showContactDetails: true,
                                activityId: data?.data?.activity?.activityId,
                                accountId: props.relatedAccount.accountId,
                                accountName: props.relatedAccount.account,
                                callDirection: "outbound",
                                location: props.relatedAccount?.workLocation,
                                phoneType: "WORK",
                                phone: props.relatedAccount?.workPhone,
                                isConnected: true,
                                contact: {
                                  contactName: props.contact?.contactName,
                                  contactPhone: props.relatedAccount?.workPhone,
                                  isPrimary:
                                    props.relatedAccount.isPrimaryContact
                                }
                              });
                              connectToCall(
                                props.relatedAccount?.workPhone as string,
                                data?.data?.activity?.activityId
                              );
                              setCallAccepted(true);
                            }
                          }
                        );
                      }}
                    >
                      <ToolTip title="Click to Call">
                        <CallIcon style={{ color: "#007AFF" }} />
                      </ToolTip>
                    </IconButton>
                  </Loader>
                )
              })
          }}
        />
      </Grid>
      <Grid xs={6}>
        <FormInput
          control={props.control}
          name={`workEmail${props.selectedAccountIndex}`}
          type="text"
          label="Work Email"
          disabled={props.disabled}
          enteredValue={props.relatedAccount.workEmail}
          rules={{
            validate: (value) => {
              const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (!regex.test(value) && value && value != "") {
                return "Invalid Email";
              }
            }
          }}
          onChange={(e) => {
            props.setAccountIndexValue(
              props.selectedAccountIndex,
              "workEmail",
              (e as ChangeEvent<HTMLInputElement>).target.value
            );
          }}
          InputProps={{
            ...(props.disabled && {
              endAdornment: (
                <ToolTip title="Click to Email">
                  <IconButton
                    onClick={() =>
                      setEmailTo({
                        to: props.relatedAccount.workEmail,
                        name: props.relatedAccount.account,
                        accountId: props.relatedAccount.accountId,
                        relatesTo: "ACCOUNT"
                      })
                    }
                  >
                    <EmailIcon style={{ color: "#007AFF" }} />
                  </IconButton>
                </ToolTip>
              )
            })
          }}
        />
      </Grid>
      <Grid xs={12}>
        <FormInput
          control={props.control}
          label="Account Address"
          name={`workLocation[${props.selectedAccountIndex}]`}
          type="text"
          disabled
          enteredValue={props.relatedAccount.workLocation || ""}
          onChange={(e) => {
            props.setAccountIndexValue(
              props.selectedAccountIndex,
              "workLocation",
              (e as ChangeEvent<HTMLInputElement>).target.value
            );
          }}
        />
      </Grid>
      <Grid xs={6}>
        <FormInput
          control={props.control}
          name={`accountPhone${props.selectedAccountIndex}`}
          type="tel"
          label={"Account Phone"}
          disabled
          enteredValue={props.relatedAccount.accountPhone}
          triggerCountryCodeChange={true}
          InputProps={{
            ...(props.disabled &&
              !props.organizationId && {
                endAdornment: (
                  <Loader align="flex-end" isLoading={mutation.isLoading}>
                    <IconButton
                      onClick={async () => {
                        if (callInstance) {
                          enqueueSnackbar("You are already on a call!", {
                            variant: "error"
                          });
                          return;
                        }
                        mutation.mutate(
                          {
                            data: {
                              accountId: props.relatedAccount.accountId,
                              contactId: props.contact?.contactId,
                              type: "CALL",
                              relatesTo: "CONTACT",
                              date: new Date(),
                              timezone: dayjs.tz.guess(),
                              direction: "outbound",
                              status: "LIVE",
                              outcome: "CONNECTED_WITH_CONTACT",
                              internalParticipants: [user?.userId as string],
                              externalParticipants: [
                                {
                                  phone: props.relatedAccount?.workPhone || ""
                                }
                              ]
                            }
                          },
                          {
                            onSuccess: (data) => {
                              setCallerDetails({
                                showContactDetails: true,
                                activityId: data?.data?.activity?.activityId,
                                accountId: props.relatedAccount.accountId,
                                accountName: props.relatedAccount.account,
                                callDirection: "outbound",
                                phoneType: "WORK",
                                location: props.relatedAccount?.workLocation,
                                phone: props.relatedAccount?.workPhone,
                                isConnected: true,
                                contact: {
                                  contactName: props.contact?.contactName,
                                  contactPhone:
                                    props.relatedAccount?.accountPhone,
                                  isPrimary:
                                    props.relatedAccount.isPrimaryContact
                                }
                              });
                              connectToCall(
                                props.relatedAccount?.accountPhone as string,
                                data?.data?.activity?.activityId
                              );
                              setCallAccepted(true);
                            }
                          }
                        );
                      }}
                    >
                      <ToolTip title="Click to Call">
                        <CallIcon style={{ color: "#007AFF" }} />
                      </ToolTip>
                    </IconButton>
                  </Loader>
                )
              })
          }}
          {...((!props.relatedAccount.workPhone ||
            props.relatedAccount.workPhone === "") &&
            props.relatedAccount.workLocation && {
              country: countries.data.find(
                (country) =>
                  country.countryId ===
                  props.relatedAccount.workLocation.split(", ")[
                    props.relatedAccount.workLocation.split(", ").length - 1
                  ]
              )
            })}
        />
      </Grid>
      <Grid xs={6}>
        <FormInput
          control={props.control}
          name={`accountEmail${props.selectedAccountIndex}`}
          type="text"
          label="Account Email"
          disabled
          enteredValue={props.relatedAccount.accountEmail}
          InputProps={{
            ...(props.disabled && {
              endAdornment: (
                <ToolTip title="Click to Email">
                  <IconButton
                    onClick={() =>
                      setEmailTo({
                        to: props.relatedAccount.accountEmail,
                        name: props.relatedAccount.account,
                        accountId: props.relatedAccount.accountId,
                        relatesTo: "ACCOUNT"
                      })
                    }
                  >
                    <EmailIcon style={{ color: "#007AFF" }} />
                  </IconButton>
                </ToolTip>
              )
            })
          }}
        />
      </Grid>
    </Grid>
  );
};
