import { FormCheckbox } from "@components/FormCheckbox";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { FormSwitch } from "@components/FormSwitch";
import Spacer from "@components/Spacer";
import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import { FormLabel, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import {
  ModelRole,
  RoleType,
  useAdminPermissionGet,
  useAdminSubroleRoleIdDelete,
  useAdminSubroleRoleIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "../../recoil/auth";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { useSnackbar } from "notistack";
import { hasPermission } from "@services/Casbin";
import { capitalize } from "@utils/capitalize";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));
const StyledDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  backgroundColor: "#F4F6F9",
  padding: "8px 20px 8px 0",
  minWidth: 600,
  width: "65%",
  [theme.breakpoints.down("xl")]: {
    width: "100%"
  }
}));
const roleTypes = Object.values(RoleType).map((roleType) => {
  return { label: capitalize(roleType), value: roleType };
});

export const SubroleView = () => {
  const navigate = useNavigate();
  const { roleId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const organizationId = useRecoilValue(organizationAtom);
  const { control, reset, setValue, watch } = useForm({
    mode: "onBlur"
  });
  const [actionPermissions, setActionPermissions] = useState({
    edit: false,
    delete: false
  });
  const roleType = watch("type");
  const [toDelete, setToDelete] = React.useState<ModelRole | null>(null);
  const {
    data: roleResponse,
    isFetching: isRoleFetching,
    error: error
  } = useAdminSubroleRoleIdGet(roleId as string);
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [error]);
  const { data: permissionsRequest, isFetching: isPermissionsFetching } =
    useAdminPermissionGet({
      organizationId
    });

  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminSubroleRoleIdDelete();
  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const del = await checkPermission("user.sub-roles", "DELETE");
      const edit = await checkPermission("user.sub-roles", "EDIT");
      setActionPermissions({
        edit,
        delete: del
      });
    };
    fetchPermissions();
  }, [organizationId]);
  const onConfirmDelete = async () => {
    if (!toDelete?.roleId) return;
    try {
      await deleteAsync({ roleId: toDelete.roleId });
      enqueueSnackbar("Sub role deleted successfully", { variant: "success" });
      navigate("/subroles");
      setToDelete(null);
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete sub role.", {
        variant: "error"
      });
      setToDelete(null);
    }
  };

  const role = useMemo(() => roleResponse?.data, [roleResponse]);
  const permissions = useMemo(
    () => permissionsRequest?.data || [],
    [permissionsRequest]
  );
  const renderChildren = (
    parentId,
    children,
    control,
    setValue,
    indentLevel = 1
  ) => {
    return (
      <>
        {children
          .filter(
            (c) =>
              role?.parent?.permissions?.some(
                (rp) => rp.permissionId === c.permissionId
              )
          )
          .map((child) => {
            const key = child.permissionId.replace(/\./g, "__");
            const override = role?.permissions
              ?.find((rp) => rp.permissionId === child.permissionId)
              ?.permission?.roleTypeAliasPermissions?.find(
                (rtap) =>
                  rtap.roleAlias === role?.alias && rtap.roleType === role.type
              );
            if (override) {
              child.name = override.nameOverride;
              child.actions = override.allowActions;
            }
            return (
              <div key={key} data-testid={child.permissionId}>
                <StyledDiv>
                  <Typography
                    variant="permissionNames"
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: 300,
                      paddingLeft: `${indentLevel * 20}px`
                    }}
                  >
                    {role?.permissions
                      ?.find((rp) => rp.permissionId === child.permissionId)
                      ?.permission?.roleTypeAliasPermissions?.find(
                        (rtap) =>
                          rtap.roleAlias === role?.alias &&
                          rtap.roleType === role.type
                      )?.nameOverride || child.name}
                  </Typography>

                  <div
                    style={{ display: "flex", flexDirection: "row", flex: 1 }}
                  >
                    {child.type === "MULTI" &&
                      ["ALL", "VIEW", "ADD", "EDIT", "DELETE"].map((action) => {
                        if (child.actions.includes(action)) {
                          return (
                            <FormCheckbox
                              key={`${key}__${action}`}
                              control={control}
                              name={`${key}__${action}`}
                              label={action[0] + action.slice(1).toLowerCase()}
                              labelPadding="4px 0"
                              labelTypographyProps={{
                                variant: "body1",
                                marginLeft: "8px"
                              }}
                              disabled={true}
                            />
                          );
                        } else {
                          return (
                            <div
                              key={`${key}__${action}`}
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                width: "100%"
                              }}
                            />
                          );
                        }
                      })}
                    {child.type === "BOOL" && (
                      <FormSwitch
                        control={control}
                        name={`${key}`}
                        disabled={true}
                      />
                    )}
                  </div>
                </StyledDiv>
                {([
                  "general",
                  "user",
                  "admin",
                  "live-streaming",
                  "tech",
                  "hr",
                  "support"
                ].includes(parentId) ||
                  [
                    "crm.leads",
                    "crm.accounts-owner-edit",
                    "crm.contacts",
                    "crm.manager-feedback",
                    "crm.override-pricing",
                    ...(roleType === "ORGANIZATION" || organizationId
                      ? ["crm.activities", "feed.canned-messaged"]
                      : [])
                  ].includes(child.permissionId)) && (
                  <Spacer
                    pb={8}
                    style={{ backgroundColor: "#FFF", maxWidth: 800 }}
                  />
                )}
                {child.children &&
                  renderChildren(
                    child.permissionId,
                    child.children,
                    control,
                    setValue,
                    indentLevel + 1
                  )}
              </div>
            );
          })}
      </>
    );
  };

  const rolePermissions = useMemo(() => {
    if (!permissions.length || !role) {
      return {};
    }

    return (
      role?.permissions?.reduce((acc, permission) => {
        const key = permission?.permissionId?.replace(/\./g, "__");
        if (!key) return acc;
        switch (permission?.permission?.type) {
          case "BOOL":
            acc[key] = permission.actions?.includes("ON");
            break;
          case "MULTI":
            permission.actions?.map((action) => {
              acc[key + "__" + action] = true;
            });
            break;
        }
        return acc;
      }, {}) || {}
    );
  }, [permissions, role]);

  useEffect(() => {
    if (role && rolePermissions) {
      reset(
        {
          name: role?.name || "",
          displayText: role?.displayText || "",
          parentName: role?.parent?.name || "",
          description: role?.description || "",
          type: role?.type || "",
          ...rolePermissions
        },
        {
          keepDirty: true
        }
      );
    }
  }, [role, rolePermissions]);

  return (
    <Container>
      <Toolbar
        title="View Sub Role &amp; Permissions"
        backBtnClick={() => navigate("/subroles")}
        {...(actionPermissions.edit && {
          editBtnClick: () => navigate(`/subroles/${roleId}/edit`)
        })}
        //@ts-ignore
        {...(actionPermissions.delete && {
          deleteBtnClick: () => setToDelete(roleResponse!.data!)
        })}
      />

      <Form>
        <Loader isLoading={isRoleFetching || isPermissionsFetching}>
          <Grid container spacing={2}>
            <Grid xs={12} md={6} data-testid="subrole-name">
              <FormInput
                control={control}
                disabled={true}
                name="name"
                type="text"
                label="Name"
                required={true}
                InputProps={{
                  placeholder: role?.name
                }}
              />
            </Grid>
            {!organizationId && (
              <Grid xs={12} md={6} data-testid="subrole-displayText">
                <FormInput
                  control={control}
                  disabled={true}
                  name="displayText"
                  type="text"
                  label="Display Text"
                  required={true}
                  rules={{
                    required: "Display Text is required"
                  }}
                />
              </Grid>
            )}
            <Grid xs={12} md={6} data-testid="subrole-role">
              <FormInput
                control={control}
                disabled={true}
                name="parentName"
                type="text"
                label="Role"
                InputProps={{
                  placeholder: role?.parent?.name
                }}
                required={true}
              />
            </Grid>
            {!organizationId && (
              <Grid xs={12} md={6} data-testid="subrole-roleType">
                <FormSelect
                  control={control}
                  disabled={true}
                  name="type"
                  label="Role Type"
                  required={true}
                  options={roleTypes}
                  rules={{
                    required: "Role Type is required"
                  }}
                />
              </Grid>
            )}
            <Grid xs={12} data-testid="subrole-description">
              <FormInput
                control={control}
                disabled={true}
                name="description"
                type="text"
                label="Description"
                required={true}
                InputProps={{
                  placeholder: role?.description
                }}
                multiline={true}
                TextProps={{
                  rows: 2
                }}
              />
            </Grid>
            <Grid data-testid="permissions" xs={12}>
              <StyledFormLabel required={true}>
                <Typography display="inline" variant="formLabel">
                  Permissions
                </Typography>
              </StyledFormLabel>
              {permissions
                .filter(
                  (permission) =>
                    role?.parent?.permissions?.some(
                      (rp) =>
                        rp.permission?.parentId === permission.permissionId
                    )
                )
                .map((permission) => {
                  const key = permission.permissionId?.replace(/\./g, "__");
                  return (
                    <React.Fragment key={key}>
                      <Spacer mt={8} />
                      <Typography
                        data-testid={permission.permissionId}
                        sx={{
                          textTransform: "uppercase",
                          color: "#ccc",
                          fontWeight: "bold"
                        }}
                      >
                        {permission.name}
                      </Typography>
                      {renderChildren(
                        permission.permissionId,
                        permission.children,
                        control,
                        setValue
                      )}
                    </React.Fragment>
                  );
                })}
            </Grid>
          </Grid>
        </Loader>
      </Form>
      <ConfirmationDialog
        open={!!toDelete}
        title="Delete Sub Role?"
        body="Are you sure you want to delete this Sub Role?"
        close={() => setToDelete(null)}
        onConfirm={onConfirmDelete}
        onCancel={() => setToDelete(null)}
        isConfirming={isDeleting}
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Container>
  );
};
