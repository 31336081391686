import {
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ModelPermission,
  ModelRole,
  ModelRolePermission,
  PermissionAction
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useState } from "react";
import { CoachOption } from "../pages/teams-programs/components/CoachSelectionForm";
import { PlayerOption } from "../pages/teams-programs/components/PlayerSelectionForm";
import { ManagerOption } from "../pages/teams-programs/components/ManagerSelectionForm";
import { FormCheckbox } from "./FormCheckbox";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { FormSwitch } from "./FormSwitch";
import { InfoOutlined } from "@mui/icons-material";
import { ToolTip } from "./ToolTip";

const StyledList = styled(List)(() => ({
  "&": {
    border: "1px solid rgba(0, 0, 0, 0.24)",
    borderRadius: "6px",
    overflowY: "scroll"
  }
}));

export const Permission = (props: {
  permissions: ModelPermission[];
  disabled?: boolean;
  userList: (CoachOption | PlayerOption | ManagerOption)[];
  masterList: (CoachOption | PlayerOption | ManagerOption)[];
  setMasterList: (
    value: (CoachOption | PlayerOption | ManagerOption)[]
  ) => void;
  role: ModelRole;
  setUserPermissions: (value: []) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<FieldValues, any, undefined>;
  defaultPermissions: (ModelRole & {
    permissions: ModelRolePermission[];
  })[];
}) => {
  const [highlightedOption, setHighlightedOption] = useState<string>(
    props.role.roleId!
  );

  const { setValue, getValues, control } = props.form;

  const [users, setUsers] = useState(props.userList);

  const [usersWithChangedPermissions, setUsersWithChangedPermissions] =
    useState<string[]>([]);

  useEffect(() => {
    const usersToAdd = [] as string[];
    users.forEach((user) => {
      props.permissions.forEach((permission, key) => {
        if (permission.type === "MULTI") {
          permission.actions?.forEach((action) => {
            if (
              getValues(`${props.role.roleId}__${key}__${action}`) !==
              user.permissions![key].actions?.includes(
                action as PermissionAction
              )
            ) {
              usersToAdd.push(user.id);
            }
          });
        }
        if (permission.type === "BOOL") {
          if (
            getValues(`${props.role.roleId}__${key}`) !==
            user.permissions![key].actions?.includes("ON")
          ) {
            usersToAdd.push(user.id);
          }
        }
      });
    });

    setUsersWithChangedPermissions([...usersToAdd]);
  }, [users]);
  return (
    <Grid
      item
      container
      direction="row"
      spacing="10px"
      data-testid={"PERMISSION_ROLE_" + props.role.roleId}
    >
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        xs={12}
        style={{
          color: "#1E2941",
          fontSize: 15,
          fontWeight: "400",
          paddingTop: 0,
          paddingBottom: 0
        }}
        data-testid="PERMISSION_ROLE_NAME"
      >
        <Grid item>
          <p
            style={{
              textTransform: "uppercase",
              color: "#1E2941",
              fontSize: "14px",
              fontWeight: 400,
              opacity: "50%",
              lineHeight: "16px"
            }}
          >
            {props.role.name} Role{" "}
          </p>
        </Grid>
        <Grid item>
          <ToolTip
            placement="right"
            title={
              <div data-testid="infoTooltip">
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Black dot (located to the right of a user's name): Custom
                  permissions set for this user.
                </Typography>
                <Typography
                  style={{
                    fontSize: "12px",
                    fontWeight: 400,
                    marginTop: "15px"
                  }}
                >
                  While viewing individual permissions:
                </Typography>
                <ul style={{ padding: "10px", paddingLeft: "20px" }}>
                  <li>
                    <Typography
                      style={{
                        fontSize: "12px",
                        fontWeight: 400
                      }}
                    >
                      Green: Permissions added for this user.
                    </Typography>
                  </li>
                  <li style={{ marginTop: "15px" }}>
                    <Typography
                      style={{
                        fontSize: "12px",
                        fontWeight: 400
                      }}
                    >
                      Red: Permissions removed for this user.
                    </Typography>
                  </li>
                </ul>
              </div>
            }
          >
            <IconButton data-testid="infoIcon">
              <InfoOutlined />
            </IconButton>
          </ToolTip>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <StyledList
          sx={{ height: 200, borderRadius: "0px 0px 6px 6px !important" }}
          data-testid="PERMISSION_USER_LIST"
        >
          <ListItemButton
            onClick={() => setHighlightedOption(props.role.roleId!)}
            data-testid={"PERMISSION_DEFAULT_" + props.role.roleId}
            selected={props.role.roleId === highlightedOption}
            key={props.role.roleId}
            style={{
              backgroundColor:
                props.role.roleId === highlightedOption ? "#C0C0C0" : "",
              height: "30px",
              width: "230px",
              flex: 1
            }}
          >
            <ListItemText>Default Permission</ListItemText>
          </ListItemButton>
          {props.userList.map((option) => {
            return (
              <ListItemButton
                data-testid={"PERMISSION_USER_" + option.id}
                key={option.id}
                onClick={() => setHighlightedOption(option.id)}
                selected={option.id === highlightedOption}
                style={{
                  backgroundColor:
                    option.id === highlightedOption ? "#C0C0C0" : "",
                  height: "30px",
                  width: "230px",
                  flex: 1
                }}
              >
                <ListItemText>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing="10px"
                    minWidth="230px"
                  >
                    <Grid item maxWidth="90%">
                      {option.label}
                    </Grid>
                    {usersWithChangedPermissions.includes(option.id) && (
                      <Grid item>
                        <div
                          style={{
                            height: "8px",
                            width: "8px",
                            backgroundColor: "#0F0F0F",
                            borderRadius: "20px"
                          }}
                        ></div>
                      </Grid>
                    )}
                  </Grid>
                </ListItemText>
              </ListItemButton>
            );
          })}
        </StyledList>
      </Grid>
      <Grid item xs={7}>
        {props.role.roleId === highlightedOption && (
          <div
            data-testid="PERMISSION_LIST_DEFAULT_CONTAINER"
            key={props.role.roleId}
          >
            {props.permissions.map((permission, key) => {
              const override = permission.roleTypeAliasPermissions?.find(
                (roleTypeAliasPermission) =>
                  roleTypeAliasPermission.roleType === "TEAM" ||
                  roleTypeAliasPermission.roleType === "TRAINING_PROGRAM"
              );
              if (override) {
                permission.name = override.nameOverride;
              }
              return (
                <div key={`${props.role.roleId}_${key}`}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "#F4F6F9",
                      paddingTop: 8,
                      paddingLeft: 0,
                      paddingRight: 20,
                      minWidth: 600,
                      width: "100%",
                      height: 50
                    }}
                  >
                    <Typography
                      variant="permissionNames"
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: 300,
                        paddingLeft: `${1 * 20}px`
                      }}
                    >
                      {permission.name}
                    </Typography>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flex: 1
                      }}
                    >
                      {permission.type === "MULTI" &&
                        ["ALL", "VIEW", "ADD", "EDIT", "DELETE"].map(
                          (action) => {
                            if (
                              permission.actions?.includes(
                                action as PermissionAction
                              )
                            ) {
                              return (
                                <FormCheckbox
                                  disabled
                                  key={`${props.role.roleId}__${key}__${action}`}
                                  control={control}
                                  name={`${props.role.roleId}__${key}__${action}`}
                                  label={
                                    action[0] + action.slice(1).toLowerCase()
                                  }
                                  labelPadding="4px 0"
                                  labelTypographyProps={{
                                    variant: "body1"
                                  }}
                                />
                              );
                            } else {
                              return (
                                <div
                                  key={`${key}__${action}`}
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    width: "100%"
                                  }}
                                />
                              );
                            }
                          }
                        )}
                      {permission.type === "BOOL" && (
                        <FormSwitch
                          disabled
                          control={control}
                          name={`${props.role.roleId}__${key}`}
                          key={`${props.role.roleId}__${key}`}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {props.userList.map((option) => {
          if (option.id === highlightedOption)
            return (
              <div
                data-testid={"PERMISSION_LIST_" + option.id + "_CONTAINER"}
                key={`${props.role.roleId}_${option.id}`}
              >
                {props.permissions.map((permission, key) => {
                  const override = permission.roleTypeAliasPermissions?.find(
                    (roleTypeAliasPermission) =>
                      roleTypeAliasPermission.roleType === "TEAM"
                  );
                  if (override) {
                    permission.name = override.nameOverride;
                  }
                  return (
                    <div key={`${props.role.roleId}__${option.id}__${key}`}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          backgroundColor: "#F4F6F9",
                          paddingTop: 8,
                          paddingLeft: 0,
                          paddingRight: 20,
                          minWidth: 600,
                          width: "100%",
                          height: 50
                        }}
                      >
                        <Typography
                          variant="permissionNames"
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            width: 300,
                            paddingLeft: `${1 * 20}px`
                          }}
                        >
                          {permission.name}
                        </Typography>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flex: 1
                          }}
                        >
                          {permission.type === "MULTI" &&
                            ["ALL", "VIEW", "ADD", "EDIT", "DELETE"].map(
                              (action) => {
                                if (
                                  permission.actions?.includes(
                                    action as PermissionAction
                                  )
                                ) {
                                  return (
                                    <FormCheckbox
                                      disabled={props.disabled}
                                      key={`${props.role.roleId}__${option.id}__${key}__${action}`}
                                      control={control}
                                      name={`${props.role.roleId}__${option.id}__${key}__${action}`}
                                      label={
                                        action[0] +
                                        action.slice(1).toLowerCase()
                                      }
                                      labelPadding="4px 0"
                                      labelTypographyProps={{
                                        variant: "body1"
                                      }}
                                      color={
                                        getValues(
                                          `${props.role.roleId}__${key}__${action}`
                                        ) === true
                                          ? !getValues(
                                              `${props.role.roleId}__${option.id}__${key}__${action}`
                                            )
                                            ? "red"
                                            : "green"
                                          : undefined
                                      }
                                      onChange={(e) => {
                                        const user = users.find(
                                          (user) => user.id === option.id
                                        );
                                        const up = user?.permissions![key];
                                        const checked = e.target.checked;
                                        if (action === "ALL") {
                                          permission.actions?.map((action) => {
                                            setValue(
                                              `${props.role.roleId}__${option.id}__${key}__${action}`,
                                              checked
                                            );
                                          });

                                          if (up) {
                                            if (checked) {
                                              up.actions = [
                                                "ALL",
                                                "VIEW",
                                                "EDIT",
                                                "DELETE"
                                              ];
                                            } else {
                                              up.actions = [];
                                            }
                                          }
                                        } else if (action === "VIEW") {
                                          if (!checked) {
                                            permission.actions?.map(
                                              (action) => {
                                                if (
                                                  [
                                                    "ADD",
                                                    "EDIT",
                                                    "DELETE"
                                                  ].includes(action)
                                                ) {
                                                  setValue(
                                                    `${props.role.roleId}__${option.id}__${key}__${action}`,
                                                    checked
                                                  );
                                                }
                                              }
                                            );

                                            if (up) {
                                              up.actions = [];
                                            }
                                          } else {
                                            if (
                                              up?.actions?.indexOf("VIEW") ===
                                              -1
                                            ) {
                                              up.actions.push("VIEW");
                                            }
                                          }
                                        } else if (
                                          ["ADD", "EDIT", "DELETE"].includes(
                                            action
                                          )
                                        ) {
                                          if (checked) {
                                            setValue(
                                              `${props.role.roleId}__${option.id}__${key}__VIEW`,
                                              checked
                                            );
                                            if (
                                              up &&
                                              !up.actions?.includes(
                                                action as PermissionAction
                                              )
                                            ) {
                                              up.actions?.push(
                                                action as PermissionAction
                                              );
                                            }
                                            if (
                                              up &&
                                              up.actions?.indexOf("VIEW") === -1
                                            ) {
                                              up.actions.push("VIEW");
                                            }
                                          } else {
                                            if (
                                              up &&
                                              up.actions?.includes(
                                                action as PermissionAction
                                              )
                                            ) {
                                              up.actions?.splice(
                                                up.actions.indexOf(
                                                  action as PermissionAction
                                                ),
                                                1
                                              );
                                            }
                                          }
                                        }

                                        const actionsLength =
                                          permission.actions!.length - 1;

                                        let actionsChecked = 0;
                                        permission.actions?.map((action) => {
                                          if (
                                            getValues(
                                              `${props.role.roleId}__${option.id}__${key}__${action}`
                                            ) &&
                                            action !== "ALL"
                                          ) {
                                            actionsChecked++;
                                          }
                                        });

                                        setValue(
                                          `${props.role.roleId}__${option.id}__${key}__ALL`,
                                          actionsLength === actionsChecked
                                        );
                                        if (
                                          actionsLength === actionsChecked &&
                                          up?.actions?.indexOf("ALL") === -1
                                        ) {
                                          up?.actions?.push("ALL");
                                        }

                                        setUsers([...users]);
                                        const masterList = [
                                          ...props.masterList
                                        ];
                                        if (
                                          [
                                            "COACH",
                                            "MANAGER",
                                            "PLAYER"
                                          ].includes(props.role.alias!)
                                        ) {
                                          const masterUser = masterList.find(
                                            (user) => user.id === option.id
                                          )!;
                                          const mp =
                                            masterUser.permissions![key];
                                          mp.actions = up?.actions;
                                        } else {
                                          // it is a parent
                                          const masterUser = masterList.find(
                                            (user) =>
                                              user.parent?.id === option.id
                                          )!;

                                          const mp =
                                            masterUser.parent!.permissions![
                                              key
                                            ];

                                          mp.actions = up?.actions;
                                        }
                                      }}
                                    />
                                  );
                                } else {
                                  return (
                                    <div
                                      key={`${key}__${action}`}
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        width: "100%"
                                      }}
                                    />
                                  );
                                }
                              }
                            )}
                          {permission.type === "BOOL" && (
                            <FormSwitch
                              disabled={props.disabled}
                              control={control}
                              key={`${props.role.roleId}__${option.id}__${key}`}
                              renderBorderStyle={
                                getValues(`${props.role.roleId}__${key}`) !==
                                getValues(
                                  `${props.role.roleId}__${option.id}__${key}`
                                )
                                  ? {
                                      "& .MuiSwitch-track": {
                                        border: `3px solid ${
                                          getValues(
                                            `${props.role.roleId}__${key}`
                                          ) === true
                                            ? "red"
                                            : "green"
                                        }`,
                                        borderRadius: "20px"
                                      },
                                      "& .MuiSwitch-thumb": {
                                        border: `2px solid ${
                                          getValues(
                                            `${props.role.roleId}__${key}`
                                          ) === true
                                            ? "red"
                                            : "green"
                                        }`
                                      }
                                    }
                                  : {}
                              }
                              name={`${props.role.roleId}__${option.id}__${key}`}
                              onChange={(e) => {
                                const checked = e.target.checked;
                                const up = users.find(
                                  (user) => user.id === option.id
                                )!.permissions![key];
                                if (checked) {
                                  up.actions = ["ON"];
                                } else {
                                  up.actions = [];
                                }
                                setUsers([...users]);
                                const masterList = [...props.masterList];
                                if (
                                  ["COACH", "MANAGER", "PLAYER"].includes(
                                    props.role.alias!
                                  )
                                ) {
                                  const masterUser = masterList.find(
                                    (user) => user.id === option.id
                                  )!;

                                  const mp = masterUser.permissions![key];
                                  mp.actions = up?.actions;
                                } else {
                                  // it is a parent
                                  const masterUser = masterList.find(
                                    (user) => user.parent?.id === option.id
                                  )!;

                                  const mp =
                                    masterUser.parent!.permissions![key];

                                  mp.actions = up?.actions;
                                }
                                props.setMasterList(masterList);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
        })}
      </Grid>
    </Grid>
  );
};
