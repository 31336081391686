import { Form } from "@components/crud/Form";
import { Grid, IconButton, Modal, styled, Typography } from "@mui/material";
import ReactPDF, { usePDF } from "@react-pdf/renderer";
import { ModelInvoice } from "@sportsgravyengineering/sg-api-react-sdk";
import { CRM_ORDER_PAYMENT_TYPES } from "@utils/constants";
import { formatCurrency } from "@utils/formatCurrency";
import { Close } from "@mui/icons-material";
import { InvoiceReceipt } from "@components/InvoiceReceipt";
import { Button } from "@components/Button";
import { downloadPDFFromLink } from "@utils/downloadPdfFromLink";

const MainContainer = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "auto",
  width: "555px",
  minHeight: "529px",

  backgroundColor: "white",
  borderRadius: 16,
  "&:focus-visible": {
    outline: "transparent"
  }
});

const StyledButtonGrid = styled(Grid)({
  "& button": {
    width: "100%"
  }
});

export const OrderInvoiceModal = ({
  open,
  invoice,
  invoiceInstance,
  onClose
}: {
  open: boolean;
  invoice: ModelInvoice;
  invoiceInstance: ReactPDF.UsePDFInstance;
  onClose: () => void;
}) => {
  const invoiceDetails = {
    accountName: invoice?.order?.account?.name,
    paymentType: invoice.paymentDetail?.paymentType,
    number: "",
    paymentDate: new Date(invoice?.paymentDetail?.createdAt),
    transactionId: invoice.transactionId,
    invoiceAmount: invoice.subTotal,
    total: invoice.total,
    status: invoice.status
  };

  const [receiptInstance] = usePDF({
    document: (
      <InvoiceReceipt
        hasFooter={false}
        hasHeader={true}
        receiptDetails={{
          to: "SportsGravy LLC." as string,
          amount: invoice.total!,
          invoiceNumber: "SG-" + invoice.invoiceNumber!,
          invoiceAmount: invoice.subTotal!,
          status: invoice.status === "PAID" ? "PAID" : "FAILED",
          paymentMethod:
            `${CRM_ORDER_PAYMENT_TYPES.find(
              (f) => f.value === invoice.paymentDetail?.paymentType
            )?.label} ${invoice.paymentDetail?.info?.payment?.bin.slice(-4)}` ||
            "",
          transactionId: invoice.transactionId || "",
          from: {
            name: "SportsGravy Billing",
            email: "info@sportsgravy.com",
            web: "sportsgravy.com"
          }
        }}
      />
    )
  });
  const formValues = [
    {
      key: "Account",
      value: invoiceDetails.accountName
    },
    {
      key: "Payment Type",
      value: `${CRM_ORDER_PAYMENT_TYPES.find(
        (f) => f.value === invoiceDetails.paymentType
      )?.label} ${invoice?.paymentDetail?.info?.payment?.bin.slice(-4)}`
    },
    {
      key: "Payment Date",
      value: invoiceDetails.paymentDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric"
      })
    },
    {
      key: "Transaction ID",
      value: invoiceDetails.transactionId
    },
    {
      key: "Invoice Amount",
      value: formatCurrency(invoiceDetails.invoiceAmount!)
    },
    {
      key: "Total",
      value: formatCurrency(invoiceDetails.total!)
    },
    {
      key: "Status",
      value: invoiceDetails.status === "PAID" ? "Paid" : "Failed"
    }
  ];

  return (
    <Modal open={open}>
      <MainContainer
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{
          borderTop: `10px solid ${
            invoiceDetails.status === "PAID" ? "#1ABC9C" : "#E82C2C"
          }`
        }}
      >
        <Form>
          <Grid container direction="column" spacing="20px">
            <Grid item alignSelf="center">
              <Typography
                style={{
                  color:
                    invoiceDetails.status === "PAID" ? "#1ABC9C" : "#E82C2C",
                  fontWeight: 700,
                  fontSize: "24px"
                }}
              >{`Payment ${
                invoiceDetails.status === "PAID" ? "Success" : "Failed"
              }!`}</Typography>
            </Grid>
            <div style={{ position: "fixed", right: 10, top: 10 }}>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </div>
            {formValues.map((val) => {
              return (
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  key={val.key}
                >
                  <Grid item>{val.key}</Grid>
                  <Grid item>
                    <Typography
                      style={{
                        color: "#000000",
                        fontWeight: 600,
                        fontSize: "14px"
                      }}
                    >
                      {val.value}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
            {invoiceDetails.status === "PAID" && (
              <StyledButtonGrid item>
                <Button
                  variant="admin-primary"
                  onClick={() =>
                    downloadPDFFromLink(
                      receiptInstance.url,
                      `${invoice.invoiceId}-receipt.pdf`
                    )
                  }
                >
                  Download Receipt
                </Button>
              </StyledButtonGrid>
            )}
            {invoiceDetails.status === "PAID" && (
              <StyledButtonGrid item marginTop="-5px">
                <Button
                  variant="admin-secondary"
                  onClick={() =>
                    downloadPDFFromLink(
                      invoiceInstance.url,
                      `${invoice.invoiceId}.pdf`
                    )
                  }
                >
                  Download Invoice
                </Button>
              </StyledButtonGrid>
            )}
            <Grid item marginTop="-5px" alignSelf="center">
              <Typography color="#666666" fontSize="12px">
                @2024 SportsGravy. All rights reserved.
              </Typography>
            </Grid>
          </Grid>
        </Form>
      </MainContainer>
    </Modal>
  );
};
