import { Checkbox, Grid, Typography } from "@mui/material";
import { ModelTag } from "@sportsgravyengineering/sg-api-react-sdk";

export const FilterTagOption = ({
  tag,
  onCheck,
  selectedTags
}: {
  tag: ModelTag;
  onCheck: (checked: boolean) => void;
  selectedTags: string[];
}) => {
  return (
    <Grid container direction="row" spacing="15px" alignItems="flex-start">
      <Grid item>
        <Checkbox
          checked={!!selectedTags.find((x) => x === tag.tagId)}
          onChange={(e) => {
            onCheck(e.target.checked);
          }}
        />
      </Grid>
      <Grid item>
        <Typography
          style={{
            overflowWrap: "break-word",
            maxWidth: "500px",
            marginTop: "10px"
          }}
        >
          {tag.name}
        </Typography>
      </Grid>
    </Grid>
  );
};
