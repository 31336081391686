export const daysBetweenDate = (date: Date) => {
  const today = new Date();

  // Clear the time portion of both dates for an accurate comparison
  today.setHours(0, 0, 0, 0);
  date.setHours(0, 0, 0, 0);

  // Calculate the difference in milliseconds
  const diffInMilliseconds = today - date;

  // Convert milliseconds to days
  const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

  return diffInDays;
};
export const getTimezoneAbbreviation = (timezone) => {
  const date = new Date();
  const options = { timeZone: timezone, timeZoneName: "long" };
  const timeZoneName = new Intl.DateTimeFormat("en-US", options)
    .formatToParts(date)
    .find((part) => part.type === "timeZoneName").value;
  const abbreviation = timeZoneName
    ?.split(" ")
    ?.map((word) => word[0])
    ?.join("");

  return `${timeZoneName} (${abbreviation})`;
};

export const dateFormatWithTimeZone = (date) => {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
    hour12: true
  };
  //@ts-ignore
  return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
};
