/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, styled, IconButton, Typography } from "@mui/material";
import {
  ModelStream,
  ModelStreamScore,
  useAdminLiveStreamStreamIdScoreGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ReactPlayer from "react-player";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { useEffect, useRef, useState } from "react";
import useWebSocket from "react-use-websocket";
import { useRecoilValue } from "recoil";
import { profileAtom } from "@recoil/auth";
import LiveStreamBanner from "@assets/images/livestreamBanner.png";
import { LoadingSpinner } from "@components/LoadingSpinner";
import { capitalize } from "@utils/capitalize";
import SGWhiteLogo from "@assets/icons/SGWhiteLogo.svg";

const StyledBox = styled(Box)(({ theme }) => ({
  position: "relative",
  backgroundColor: "#000",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "calc(100vh - 300px)",
  zIndex: 9999,
  overflow: "hidden",
  borderRadius: "5px",
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px"
  }
}));

const TeamName = styled(Typography)({
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "16px",
  color: "#fff"
});

const TeamScore = styled(Typography)({
  fontWeight: 700,
  fontSize: "13px",
  lineHeight: "16px",
  color: "#fff"
});

const ErrorBox = styled("div")(() => ({
  ".thumbnail-content": {
    position: "absolute",
    textAlign: "center",
    color: "#fff",
    ".header": {
      fontSize: "33px",
      lineHeight: "45px"
    },
    ".reason": {
      fontWeight: 700,
      fontSize: "63px",
      letterSpacing: "-4%",
      lineHeight: "96px"
    },
    ".description": {
      fontSize: "26px",
      lineHeight: "43px"
    }
  }
}));

export const LiveStreamWatch = ({
  liveStream
}: {
  liveStream: ModelStream;
}) => {
  const user = useRecoilValue(profileAtom);
  const [error, setError] = useState<string | undefined>(undefined);
  const wsDomain =
    window.location.hostname === "app.sportsgravy.com"
      ? "wss://ws.sportsgravy.com"
      : "wss://dev.ws.sportsgravy.com";
  const [socketUrl] = useState(
    `${wsDomain}?userId=${user?.userId}&streamId=${liveStream.streamId}`
  );
  const [scores, setScores] = useState<ModelStreamScore | undefined>(undefined);
  const [interval, setInterval] = useState<number | undefined>(undefined);
  const [isHome, setIsHome] = useState<boolean>(
    liveStream.isHomeTeam as boolean
  );

  const { data: score } = useAdminLiveStreamStreamIdScoreGet(
    liveStream.streamId as string
  );
  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(true);
  const [muted, setMuted] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isScoreExpanded, setIsScoreExpanded] = useState(false);
  const [status, setStatus] = useState<string | undefined>("LIVE");
  const [errorCount, setErrorCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { lastMessage } = useWebSocket(socketUrl);

  useEffect(() => {
    if (lastMessage && lastMessage.data) {
      const lastMessageData = JSON.parse(lastMessage.data);
      if (lastMessageData.detailType === "live-stream-score-update") {
        setScores(lastMessageData.message.scores[0] as ModelStreamScore);
        setInterval(lastMessageData.message.intervalCount);
        setIsHome(lastMessageData.message.isHomeTeam);
      }
      if (lastMessageData.detailType === "live-stream-status-change") {
        if (lastMessageData?.message?.status === "COMPLETED") {
          setStatus("COMPLETED");
        }
        if (lastMessageData?.message?.status === "PAUSED") {
          setErrorCount(0);
          setStatus("PAUSED");
        }
        if (lastMessageData?.message?.status === "LIVE") {
          setErrorCount(0);
          setStatus("LIVE");
        }
        if (lastMessageData?.message?.status === "INTERMISSION") {
          setErrorCount(0);
          setStatus("INTERMISSION");
        }
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    if (score?.data)
      if (score.data.scores && score?.data?.scores.length > 0) {
        setScores(score.data.scores[0] as ModelStreamScore);
      }
    if (score?.data.status) setStatus(score?.data.status);
    if (score?.data.isHomeTeam) setIsHome(score?.data.isHomeTeam as boolean);
    if (score?.data.intervalCount) setInterval(score?.data.intervalCount);
  }, [score]);

  const handlePlayPause = () => setPlaying(!playing);
  const handleMuteUnmute = () => setMuted(!muted);
  const toggleScoreExpand = () => setIsScoreExpanded(!isScoreExpanded);
  const handleFullscreen = () => {
    const elem = document.getElementById("livestream-player");
    if (elem) {
      if (!isFullscreen && elem.requestFullscreen) {
        elem.requestFullscreen();
      }
      if (isFullscreen && document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };
  useEffect(() => {
    const elem = document.getElementById("livestream-player");
    if (elem) {
      elem.addEventListener("fullscreenchange", () => {
        setIsFullscreen(!!document.fullscreenElement);
      });
    }
  });

  const handleRetry = () => {
    setTimeout(() => {
      setErrorCount((prevCount) => prevCount + 1);
    }, 3000);
  };

  return (
    <StyledBox data-testid="feed-media-viewer" id="livestream-player">
      {!error && status == "LIVE" && (
        <>
          <div
            className="react-player"
            onClick={handlePlayPause}
            style={{ width: "100%", height: "100%" }}
          >
            {isLoading && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)"
                }}
              >
                <LoadingSpinner size={50} />
              </div>
            )}
            <ReactPlayer
              key={errorCount}
              ref={playerRef}
              playing={playing}
              muted={muted}
              loop
              height="100%"
              width="100%"
              url={liveStream.playbackUrl}
              controls={false}
              onStart={() => setIsLoading(false)}
              onError={(e) => {
                if (errorCount < 10) {
                  setIsLoading(true);
                  handleRetry();
                } else {
                  setIsLoading(false);
                  setError(e);
                }
              }}
            />
          </div>

          {(liveStream.contestType == "GAME" ||
            liveStream.contestType == "SCRIMMAGE") &&
            liveStream.scores &&
            liveStream.scores?.length > 0 &&
            scores && (
              <Box
                sx={{
                  position: "absolute",
                  top: "20px",
                  left: "20px",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  background: "#5f5f5f"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box
                      sx={{
                        background: "#E82C2C",
                        width: "100%",
                        padding: "4px 8px",
                        textAlign: "center",
                        fontWeight: "700",
                        height: "30px",
                        borderTopLeftRadius: "8px",
                        color: "#fff"
                      }}
                    >
                      {`${
                        liveStream.team?.genders?.includes("MALE")
                          ? liveStream.sport?.organizations?.[0]
                              .maleIntervalAbbreviation
                          : liveStream.sport?.organizations?.[0]
                              .femaleIntervalAbbreviation
                      }${!interval ? liveStream.intervalCount : interval}`}
                    </Box>
                    <Box
                      sx={{
                        background: "#2B337A",
                        width: "100%",
                        color: "#fff",
                        fontWeight: "700",
                        padding: "4px 8px",
                        textAlign: "center",
                        height: "30px",
                        borderBottomLeftRadius: "8px"
                      }}
                    >
                      {`H ${scores.homeScore} - ${scores.awayScore} A`}
                    </Box>
                  </Box>
                  {isScoreExpanded && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "0 8px",
                        rowGap: "10px"
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          justifyContent: "space-between"
                        }}
                      >
                        <TeamName>
                          {isHome ? liveStream.team?.name : liveStream.opponent}{" "}
                          (H)
                        </TeamName>
                        <TeamScore>{scores.homeScore}</TeamScore>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          justifyContent: "space-between"
                        }}
                      >
                        <TeamName>
                          {!isHome
                            ? liveStream.team?.name
                            : liveStream.opponent}{" "}
                          (A)
                        </TeamName>
                        <TeamScore>{scores.awayScore}</TeamScore>
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    background: "#848484",
                    width: "20px",
                    height: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderTopRightRadius: "8px",
                    borderBottomRightRadius: "8px",
                    cursor: "pointer"
                  }}
                  onClick={toggleScoreExpand}
                >
                  {isScoreExpanded ? (
                    <ChevronLeftIcon
                      sx={{
                        fill: "#fff",
                        height: "20px",
                        width: "120x"
                      }}
                    />
                  ) : (
                    <ChevronRightIcon
                      onClick={toggleScoreExpand}
                      sx={{
                        fill: "#fff",
                        height: "20px",
                        width: "20px"
                      }}
                    />
                  )}
                </Box>
              </Box>
            )}
          <Box
            sx={{
              position: "absolute",
              bottom: "35px",
              padding: "0 30px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={handlePlayPause}>
                {!playing ? (
                  <PlayArrowIcon sx={{ fill: "#fff" }} />
                ) : (
                  <PauseIcon sx={{ fill: "#fff" }} />
                )}
              </IconButton>
              <div
                style={{
                  marginLeft: "5px",
                  height: "10px",
                  width: "10px",
                  borderRadius: "99px",
                  background: "#E82C2C"
                }}
              ></div>
              <span
                style={{
                  marginLeft: "7px",
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "18px",
                  color: "#fff"
                }}
              >
                LIVE
              </span>
            </Box>
            <Box>
              <IconButton onClick={handleMuteUnmute} sx={{ color: "#fff" }}>
                {muted ? (
                  <VolumeOffIcon sx={{ fill: "#fff" }} />
                ) : (
                  <VolumeUpIcon sx={{ fill: "#fff" }} />
                )}
              </IconButton>
              <IconButton onClick={handleFullscreen} sx={{ color: "#fff" }}>
                {isFullscreen ? (
                  <FullscreenExitIcon sx={{ fill: "#fff" }} />
                ) : (
                  <FullscreenIcon sx={{ fill: "#fff" }} />
                )}
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: "25px",
              left: "30px",
              right: "30px",
              height: "4px",
              backgroundColor: "red"
            }}
          />
        </>
      )}
      {(error || status != "LIVE") && (
        <ErrorBox
          className="thumbnail-container"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%"
          }}
        >
          <img
            className="thumbnail"
            style={{ width: "100%", opacity: 1 }}
            src={LiveStreamBanner}
          />
          <div className="thumbnail-content">
            <div className="header">
              {status == "INTERMISSION" ? "It's" : "Live Stream is"}
            </div>
            <div className="reason">
              {status != "LIVE" ? capitalize(status as string) : "UNAVAILABLE"}
            </div>
            {status == "INTERMISSION" && (
              <div className="description">time folks!</div>
            )}
            {error && (
              <div className="description">Sorry for the inconvenience</div>
            )}
          </div>
        </ErrorBox>
      )}
      <Box
        sx={{
          position: "absolute",
          top: "20px",
          right: "20px"
        }}
      >
        <img src={SGWhiteLogo} />
      </Box>
    </StyledBox>
  );
};
