/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Checkbox, FormLabel, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactNode } from "react";
import { Controller } from "react-hook-form";
// import colors from "theme/colors";

const FormCheckboxContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const StyledFormLabel = styled(FormLabel)(() => ({
  paddingLeft: "20px"
}));

const StyledCheckbox = styled(Checkbox)(() => ({
  padding: "0"
  // "svg path": {
  //   color: colors.primary.main
  // }
}));

export const FormCheckbox = (props: {
  name: string;
  control: any;
  rules: any | undefined;
  label?: string | Element;
  required: boolean | undefined;
  disabled: boolean | undefined;
  labelPadding?: string;
  checkboxPadding?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  labelTypographyProps?: any;
  inverseValue?: boolean;
  onClickLabel?: () => void;
  labelVariant?: string;
  color?: string;
  value?: boolean;
  notUnCheckable?: boolean;
  tooltip?: ReactNode;
}) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={Object.assign(
        props.required ? { required: !!props.required } : {},
        props.rules || {}
      )}
      render={({ field }) => {
        return (
          <FormCheckboxContainer data-testid={"FORM_CHECKBOX_" + props.name}>
            <StyledCheckbox
              value={!!props.value || !!field.value}
              checked={
                props.inverseValue
                  ? !field.value
                  : !!props.value || !!field.value
              }
              onChange={(event) => {
                if (!props.notUnCheckable || event.target.checked)
                  field.onChange(event);

                if (props.onChange) {
                  props.onChange(event);
                }
              }}
              onBlur={field.onBlur}
              required={!!props.required}
              disabled={!!props.disabled}
              sx={{
                padding: props.checkboxPadding || "10",
                color: props.color || "primary",
                // apply when checked
                "&.Mui-checked": {
                  borderColor: props.color || "primary"
                }
              }}
            />
            {!!props?.label && (
              <StyledFormLabel
                required={props.required}
                sx={{
                  padding: props.labelPadding || "9px 0 9px 9px"
                }}
              >
                <Typography
                  display="inline"
                  variant={props.labelVariant}
                  onClick={props.onClickLabel}
                  {...props.labelTypographyProps}
                >
                  {props.label}
                </Typography>
              </StyledFormLabel>
            )}
            {props.tooltip && props.tooltip}
          </FormCheckboxContainer>
        );
      }}
    />
  );
};

FormCheckbox.defaultProps = {
  rules: undefined,
  required: false,
  disabled: false,
  inverseValue: false,
  labelVariant: "formLabel"
};
