import { Backdrop, Box, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Unstable_Grid2";
import colors from "theme/colors";
import { ModelSportsgravyUser } from "@sportsgravyengineering/sg-api-react-sdk";
import { InsightSummaryBox } from "@components/InsightSummaryBox";
import { getQaMetric } from "@services/Network";
import { useEffect, useMemo, useState } from "react";
import { CustomColumnGraph } from "@pages/dashboard/components/CustomColumnGraph";
import { FormSelect } from "@components/FormSelect";

const columns = [
  {
    title: "TC Not Tested",
    color: "#E5E5E5"
  },
  {
    title: "TC Passed",
    color: "#1ABC9C"
  },
  {
    title: "TC Failed",
    color: "#E82C2C"
  },
  {
    title: "TC Onhold",
    color: "#FF710A"
  },
  {
    title: "TC Blocked",
    color: "#F48D8D"
  }
];

const StyledBox = styled(Box)(() => ({
  backgroundColor: "#fff",
  borderRadius: "16px",
  maxHeight: "calc(100vh - 64px)",
  overflowY: "auto",
  overflowX: "hidden",
  width: "110%"
}));

const StyledBoxHeader = styled(Box)({
  height: "64px",
  backgroundColor: colors.info.main,
  borderRadius: "16px 16px 0px 0px"
});

const StyledColumnGraph = styled("div")`
  margin-top: 32px;
  color: #000;
  .graph {
    margin: 0 !important;
    max-height: calc(100vh - 250px);
    overflowy: auto;
  }
`;

export const GanttChartQaOverview = ({
  testerId,
  onClose,
  releases
}: {
  testerId: string;
  onClose: () => void;
  releases: {
    releaseId: string;
    testers: ModelSportsgravyUser[];
    name: string;
    platforms: string[];
  }[];
}) => {
  const [featureBreakdown, setFeatureBreakdown] = useState({});
  const [selectedTesterId, setSelectedTesterId] = useState<string | undefined>(
    undefined
  );
  const [releaseId, setReleaseId] = useState<string>("");
  const [platfrom, setPlatform] = useState<string>("");

  const ganttChartQaMetric = (params, options) => {
    params.showOn = "GANTT_CHART";
    return getQaMetric(params, options);
  };

  useEffect(() => {
    const firstRelease = releases.find((release) =>
      release.testers.some((tester) => tester.userId === testerId)
    );
    setReleaseId(firstRelease?.releaseId || releases[0]?.releaseId);
    setPlatform(firstRelease?.platforms[0] || "MOB");
    setSelectedTesterId(testerId);
  }, [releases]);

  const releasesOptions = useMemo(
    () =>
      (releases &&
        releases.map((release) => ({
          label: release.name!,
          value: release.releaseId
        }))) ||
      [],
    [releases]
  );

  const testerOptions = useMemo(() => {
    const testersFromRelease =
      releases &&
      releases
        .find((r) => r.releaseId === releaseId)
        ?.testers.map((tester) => ({
          label: `${tester?.person?.firstName} ${tester?.person?.lastName}`,
          value: tester?.person?.userId
        }));

    const options = testersFromRelease
      ? [{ label: "All Testers", value: "" }, ...testersFromRelease]
      : [];

    return options;
  }, [releaseId]);

  const platformOptions: { label: string; value: string }[] = useMemo(() => {
    if (releases && releaseId) {
      const release = releases.find((r) => r.releaseId === releaseId);
      if (release) {
        const releasePlatforms = Array.from(new Set(release.platforms));
        if (
          releasePlatforms.includes("AND") ||
          releasePlatforms.includes("IOS")
        ) {
          releasePlatforms.push("MOB");
        }
        const platforms = releasePlatforms.map((platform) => ({
          label: platform,
          value: platform
        }));
        platforms.sort((a, b) => {
          const order = ["MOB", "IOS", "AND", "WEB"];
          return order.indexOf(a.value) - order.indexOf(b.value);
        });
        return platforms;
      }
    }
    return [];
  }, [releaseId]);

  return (
    <Backdrop
      sx={{
        overflow: "hidden",
        overflowY: "none",
        color: "#fff",
        zIndex: 1200
      }}
      open={true}
    >
      <Container>
        <StyledBox>
          <StyledBoxHeader>
            <Grid
              container
              style={{
                padding: "18px 20px 20px 32px",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Grid>
                <Typography
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                    fontStyle: "normal",
                    lineHeight: "20px",
                    fontWeight: "600"
                  }}
                >
                  QA Overview
                </Typography>
              </Grid>
              <Grid>
                <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />
              </Grid>
            </Grid>
          </StyledBoxHeader>
          <Box
            paddingBottom="24px"
            sx={{
              minHeight: "300px",
              maxHeight: "calc(100vh - 150px)",
              overflowY: "auto",
              overflowX: "hidden"
            }}
          >
            <Grid
              container
              paddingLeft="24px"
              marginTop="16px"
              columnSpacing={2}
            >
              <Grid xs={2}>
                <FormSelect
                  isReleaseSelect={true}
                  name="releaseFilter"
                  onChange={(e) => {
                    const newReleaseId = e.target.value;
                    setReleaseId(newReleaseId);
                    const newRelease = releases.find(
                      (release) => release.releaseId === newReleaseId
                    );
                    if (
                      newRelease &&
                      !newRelease.testers.some(
                        (tester) => tester.userId === testerId
                      )
                    ) {
                      setSelectedTesterId(undefined);
                    }
                    if (
                      newRelease &&
                      newRelease.testers.some(
                        (tester) => tester.userId === testerId
                      )
                    ) {
                      setSelectedTesterId(testerId);
                    }
                    if (
                      newRelease &&
                      !newRelease.platforms.includes(platfrom)
                    ) {
                      setPlatform(newRelease.platforms[0]);
                    }
                  }}
                  options={releasesOptions}
                  value={releaseId}
                />
              </Grid>
              <Grid xs={3}>
                <FormSelect
                  name="testerFilter"
                  options={testerOptions}
                  value={selectedTesterId}
                  onChange={(e) => {
                    setSelectedTesterId(e.target.value);
                  }}
                />
              </Grid>
              <Grid xs={2}>
                <FormSelect
                  name="platformFilter"
                  options={platformOptions}
                  value={platfrom}
                  onChange={(e) => {
                    setPlatform(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid padding="24px" paddingTop="0">
              {releaseId && (
                <InsightSummaryBox
                  title="QA & Testing Insights (Releases)"
                  description="Quick Insights related to SportGravy Product Releases"
                  isLoading={true}
                  releaseId={releaseId}
                  testerId={selectedTesterId}
                  platform={platfrom}
                  useGet={ganttChartQaMetric}
                  setFeatureBreakDown={setFeatureBreakdown}
                  isQaInsights={true}
                  defaultTimeFilter="TODAY"
                  colsInFirstRow={4}
                />
              )}
              <StyledColumnGraph>
                <CustomColumnGraph
                  columns={columns}
                  values={featureBreakdown}
                />
              </StyledColumnGraph>
            </Grid>
          </Box>
        </StyledBox>
      </Container>
    </Backdrop>
  );
};
