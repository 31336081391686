import { styled, Typography } from "@mui/material";
import SGLogo from "@assets/images/sg-logo.png";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import React, { useEffect, useState } from "react";
import { StepProgressLine } from "@components/StepProgressLine";
import { useForm } from "react-hook-form";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import Contract from "./Contract";
import {
  AdvertiserInvoiceSchemaPaymentType,
  useAdminCrmOrderBase64UnauthorizedGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { Container } from "@components/crud/Container";
import CheckIcon from "@mui/icons-material/Check";
import { PaymentDetailsForm } from "@components/PaymentDetailsForm";

const StyledHeader = styled("div")`
  display: flex;
  padding: 8px 32px;
  justify-content: space-between;
  box-shadow: 0px 1px 3px 0px #00000026;
  align-items: center;
`;
const StyledStepperContainer = styled("div")`
  margin-top: 1px;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
`;
const StyledSteps = styled("div")`
  padding: 32px 15%;
  @media (max-width: 900px) {
    padding: 32px 5%;
  }
`;
const StyledTabText = styled(Typography)`
  font-size: 32px;
  color: #1e293b;
  font-weight: 300;
  @media (max-width: 900px) {
    font-size: 26px;
  }
  @media (max-width: 576px) {
    font-size: 20px;
  }
`;

export const SignContract = () => {
  const navigate = useNavigate();
  const { trigger } = useForm();
  const { orderId, compressedEmail } = useParams();
  const [searchParams] = useSearchParams();
  const [MSASigned, setMSASigned] = useState(true);
  const [contractSigned, setContractSigned] = useState(true);
  const isMobile = useMediaQuery("(max-width: 756px)");
  const [, setSelectedPaymentType] =
    useState<AdvertiserInvoiceSchemaPaymentType>("DEBIT_CARD");

  const [tab, setTab] = useState(
    !isMobile ? "Master Subscription Agreement" : "MSA"
  );
  const steps = [
    !isMobile ? "Master Subscription Agreement" : "MSA",
    "Contract",
    "Payment Details"
  ];

  const {
    data: order,
    isLoading: isOrderLoading,
    error
  } = useAdminCrmOrderBase64UnauthorizedGet(
    orderId as string,
    compressedEmail as string
  );

  useEffect(() => {
    if (error) {
      navigate("/not-found");
      return;
    }
    if (order) {
      if (
        order.data.agreements?.find((a) => a.agreementType === "MSA")
          ?.status === "FULLY_EXECUTED"
      )
        setMSASigned(false);
      if (
        order.data.agreements?.find((a) => a.agreementType === "CONTRACT")
          ?.status === "FULLY_EXECUTED"
      )
        setContractSigned(false);
    }
  }, [order, error]);

  useEffect(() => {
    if (searchParams.get("tab")) {
      setTab(searchParams.get("tab") as string);
    }
  }, [searchParams]);

  const activeStepNumber = steps.findIndex((step) => step === tab);

  return (
    <Loader isLoading={isOrderLoading}>
      <div style={{ backgroundColor: "#f3f4f7" }}>
        <StyledHeader>
          <div>
            <img src={SGLogo} width="160px" />
          </div>
          <div>
            <HelpOutlineIcon style={{ color: "#666" }} />
          </div>
        </StyledHeader>
        {tab !== "Verify" && (
          <StyledStepperContainer>
            <StyledSteps>
              <StepProgressLine
                steps={[
                  !isMobile ? "Master Subscription Agreement" : "MSA",
                  "Contract",
                  "Payment Details"
                ]}
                activeStepNumber={activeStepNumber}
                onEditClick={(active) => {
                  setTab(steps[active]);
                  setTimeout(() => {
                    trigger();
                  }, 50);
                }}
              />
            </StyledSteps>
            <div
              style={{
                padding: "32px",
                paddingTop: "0",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <StyledTabText>{tab}</StyledTabText>
              <div
                style={{
                  background: "#1ABC9C",
                  padding: "8px 16px",
                  display: "flex",
                  gap: "8px",
                  borderRadius: "99px",
                  alignItems: "center"
                }}
              >
                <LockOutlinedIcon style={{ color: "#fff" }} />
                <span style={{ color: "#fff" }}>This page is secure</span>
              </div>
            </div>
          </StyledStepperContainer>
        )}
        {(tab === "Master Subscription Agreement" || tab === "MSA") && (
          <Contract
            type="MSA"
            nextClick={() => setTab("Contract")}
            setMSASigned={setMSASigned}
            MSASigned={MSASigned}
            agreementId={
              order?.data.agreements?.find((a) => a.agreementType === "MSA")
                ?.agreementId as string
            }
          />
        )}
        {tab === "Contract" && (
          <Contract
            type="CONTRACT"
            nextClick={() => setTab("Payment Details")}
            setContractSigned={setContractSigned}
            contractSigned={contractSigned}
            agreementId={
              order?.data.agreements?.find(
                (a) => a.agreementType === "CONTRACT"
              )?.agreementId as string
            }
          />
        )}
        {tab === "Payment Details" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "32px"
            }}
          >
            <PaymentDetailsForm
              setPaymentType={setSelectedPaymentType}
              txnType="TOKENIZATION"
              details={{
                merchantId: import.meta.env.VITE_APP_MERCHANT_ID,
                invoiceId: orderId as string,
                compressedEmail: compressedEmail as string
              }}
              paymentFor="ORDER"
            />
          </div>
        )}
      </div>
      {tab === "Verify" && (
        <Container>
          <div
            style={{
              height: "calc(100vh - 55px)",
              margin: "auto auto",
              paddingTop: "80px",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "360px"
            }}
          >
            <div
              style={{
                height: "80px",
                width: "80px",
                background: "#1ABC9C",
                borderRadius: "99px",
                padding: "12px"
              }}
            >
              <CheckIcon
                style={{ color: "#fff", height: "50px", width: "50px" }}
              />
            </div>
            <Typography
              style={{
                fontWeight: 300,
                fontSize: "24px",
                lineHeight: "36px",
                marginTop: "24px",
                textAlign: "center"
              }}
            >
              Your payment informations have successfully sent!
            </Typography>
          </div>
        </Container>
      )}
    </Loader>
  );
};
