import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Toolbar } from "@components/crud/Toolbar";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Form } from "@components/crud/Form";
import formatFullName from "@utils/formatFullName";
import { profileAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";
import {
  adminCrmOpportunityOpportunityIdGet,
  ContactResponse,
  getAdminCrmAccountAccountIdContact,
  ModelAccount,
  ModelOpportunity,
  ModelPerson,
  useAdminCrmOrderPost,
  useConfigGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { OrderDetailsForm } from "./OrderDetailsForm";
import { enqueueSnackbar } from "notistack";
import { Loader } from "@components/crud/Loader";
import { hasPermission } from "@services/Casbin";
import { getCostPerAthlete } from "@utils/GetCostPerAthlete";
import { Tier } from "@pages/settings/CRMSetting";
import { DevTool } from "@hookform/devtools";
import { getCountryFromAddress } from "@utils/getCountryFromAddress";
import { ORDER_BILLING_OPTIONS } from "@utils/constants";
import { ConfirmOrderModale } from "./ConfirmOrderModale";

export const OrderCreate = () => {
  const navigate = useNavigate();
  const form = useForm({
    mode: "onBlur",
    shouldUnregister: true
  });

  const {
    reset,
    getValues,
    formState: { isValid }
  } = form;

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("crm.orders", "ADD");
      if (!create) navigate("/not-found");
    };
    fetchPermissions();
  }, []);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const opportunityId = searchParams.get("opportunityId");
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedContact, setSelectedContact] = useState("");

  const [contacts, setContacts] = useState<
    {
      label: string;
      value: string;
      isPrimary: boolean;
      details: ContactResponse;
      jobTitle: string;
      workEmail: string;
      workPhone: string;
    }[]
  >([]);

  const userName = formatFullName(
    useRecoilValue(profileAtom)?.person as ModelPerson
  );
  const userId = useRecoilValue(profileAtom)?.userId || "";
  const [showSendOrderModale, setShowSendOrderModale] = useState(false);
  const { data: settings, isLoading: isLoadingConfig } = useConfigGet();
  const [pricingTier, setPricingTier] = useState<Tier[]>([]);
  const [startDateRequirement, setStartDateRequirement] = useState<number>();
  const [billingOptions, setBillingOptions] = useState<
    { label: string; value: string; description?: string }[]
  >([]);
  const [ratePerAthlete, setRaatePerAthlete] = useState<number>();
  useEffect(() => {
    if (settings && settings.data) {
      const priceTiersList = (settings.data.find(
        (item) => item.key === "crm.order.pricing-tier"
      )?.value || []) as Tier[];
      setPricingTier(priceTiersList);
      const startDateRequirement = settings.data.find(
        (item) => item.key === "crm.order.start-date.after-order-date"
      )?.value as number;

      setStartDateRequirement(startDateRequirement);

      const billingOptions = [] as {
        label: string;
        value: string;
        description?: string;
      }[];
      if (
        settings.data.find(
          (item) => item.key === "crm.order.billing-option.yearly-monthly"
        )?.value
      )
        billingOptions.push(ORDER_BILLING_OPTIONS[0]);
      if (
        settings.data.find(
          (item) =>
            item.key === "crm.order.billing-option.yearly-monthly-per-athlete"
        )?.value
      )
        billingOptions.push(ORDER_BILLING_OPTIONS[1]);
      if (
        settings.data.find(
          (item) => item.key === "crm.order.billing-option.upfront"
        )?.value
      )
        billingOptions.push(ORDER_BILLING_OPTIONS[2]);
      setBillingOptions([...billingOptions]);
    }
  }, [settings]);

  useEffect(() => {
    if (startDateRequirement && !opportunityId) {
      const today = new Date();
      const startDate = new Date();
      startDate.setDate(today.getDate() + startDateRequirement);
      const endDate = new Date();
      endDate.setDate(today.getDate() + startDateRequirement);
      endDate.setFullYear(startDate.getFullYear() + 1);
      reset({
        submittedBy: userName || "",
        orderDate: new Date(),
        startDate,
        endDate,
        product: "SportsGravy Core",
        orderType: "PA-PR Subscription",
        billingFrequency: "Per Registration"
      });
    }
  }, [startDateRequirement]);

  const { mutate: save, isLoading: isSaving } = useAdminCrmOrderPost();

  const onSave = (contactWorkEmail?: string) => {
    const data = {
      submittedBy: userId,
      orderDate: new Date(),
      product: getValues().product,
      orderType: getValues().orderType,
      numberOfAthletes: parseInt(getValues().noOfAthletes),
      yearlyAmount: parseFloat(getValues().amount),
      ratePerAthlete: ratePerAthlete,
      billingType: getValues().billingOption,
      ...(getValues().notes && {
        notes: getValues().notes
      }),
      accountId: selectedAccount,
      contactId: selectedContact,
      ...(opportunityId && {
        opportunityId: opportunityId
      }),
      originalStartDate: getValues().startDate,
      originalEndDate: getValues().endDate,
      status: "PENDING",
      ...(contactWorkEmail && {
        contactWorkEmail: contactWorkEmail
      })
    };
    save(
      {
        data: data
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Order Created successfully!", {
            variant: "success"
          });

          navigate("/crm/orders");
        },
        onError: () => {
          enqueueSnackbar("Failed to Create Order!", {
            variant: "error"
          });
        }
      }
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (opportunityId && startDateRequirement) {
        const today = new Date();
        const startDate = new Date();
        startDate.setDate(today.getDate() + startDateRequirement + 1);
        const endDate = new Date();
        endDate.setDate(today.getDate() + startDateRequirement + 1);
        endDate.setFullYear(startDate.getFullYear() + 1);
        const data = await adminCrmOpportunityOpportunityIdGet(opportunityId);
        if (data.data.accountId) {
          const account = data.data.account as ModelAccount;
          const opportunity = data.data as ModelOpportunity;
          // const contact = data.data.contact;

          reset({
            noOfAthletes: opportunity?.numberOfAthletes,
            costOfAthletesPeryear: `$ ${
              opportunity.approvedPricePerAthlete
                ? opportunity.approvedPricePerAthlete
                : getCostPerAthlete(
                    opportunity.numberOfAthletes!,
                    pricingTier.filter(
                      (p) =>
                        p.countryId ===
                          getCountryFromAddress(account.officeAddress!) || "US"
                    )
                  )
            } Per Athlete, Per Registration`,
            amount: opportunity?.amount,
            account: {
              name: account?.name || "",
              parent: account?.parent?.name || "",
              category: account?.category,
              type: account?.type,
              officeAddress: account?.officeAddress,
              email: account?.email,
              website: account?.website,
              sportsOffered: account?.sports?.map((sp) => sp.sportId) || [],

              aeOwner: account?.aeOwner?.person
                ? formatFullName(account?.aeOwner?.person)
                : ""
            },
            submittedBy: userName || "",
            orderDate: new Date(),
            startDate: startDate,
            endDate: endDate,
            product: "SportsGravy Core",
            orderType: "PA-PR Subscription"
          });
          setSelectedAccount(data.data.accountId);
          setRaatePerAthlete(
            opportunity.approvedPricePerAthlete
              ? opportunity.approvedPricePerAthlete
              : (getCostPerAthlete(
                  opportunity.numberOfAthletes!,
                  pricingTier.filter(
                    (p) =>
                      p.countryId ===
                        getCountryFromAddress(account.officeAddress!) || "US"
                  )
                ) as number)
          );
          // setSelectedContact(data.data.contactId);
        }
      }
    };
    fetchData();
  }, [opportunityId, pricingTier, startDateRequirement]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedAccount) {
        try {
          const data = await getAdminCrmAccountAccountIdContact(
            selectedAccount
          );
          setContacts(
            data.data.map((c) => ({
              label: `${c.contact.firstName} ${c.contact.lastName}`,
              value: c.contactId as string,
              isPrimary: !!c.isPrimary,
              details: c.contact as ContactResponse,
              jobTitle: c.jobTitle || "",
              workEmail: c.workEmail || "",
              workPhone: c.workPhone || ""
            }))
          );
          const primaryOrFirstContact =
            data.data.find((c) => c.isPrimary)?.contactId ||
            data.data?.[0]?.contactId;
          setSelectedContact(primaryOrFirstContact || "");
        } catch (error) {
          setContacts([]);
        }
      }
    };
    fetchData();
  }, [selectedAccount, reset]);
  return (
    <Container>
      <Toolbar title="Add Order" />
      <Loader isLoading={isLoadingConfig}>
        <Form>
          <OrderDetailsForm
            form={form}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            selectedContact={selectedContact}
            setSelectedContact={setSelectedContact}
            contacts={contacts}
            setContacts={setContacts}
            billingOptions={billingOptions}
            startDateRequirement={startDateRequirement!}
          />
          <DevTool control={form.control} />
        </Form>
      </Loader>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={() => {
          setShowSendOrderModale(true);
        }}
        saveBtnLabel="Save & Submit Order"
        isDisabled={!isValid}
        isLoading={isSaving}
      />
      {showSendOrderModale && (
        <ConfirmOrderModale
          onClose={() => {
            setShowSendOrderModale(false);
          }}
          onSave={(workEmail) => {
            onSave(workEmail);
          }}
          contactName={getValues().contact?.name}
          contactEmail={getValues().contact?.workEmail}
        />
      )}
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/crm/orders")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
