export const getMeetingDurationInMinutes = (val: string) => {
  switch (val) {
    case "30M":
      return 30;
    case "1H":
      return 60;
    case "1H30M":
      return 90;
    case "2H":
      return 120;
    default:
      return 60;
  }
};
