import React, { useEffect } from "react";
import { Container } from "@components/crud/Container";
import { useFormContext } from "react-hook-form";
import Grid from "@mui/system/Unstable_Grid";
import { FormSelect } from "@components/FormSelect";
import { FormInput } from "@components/FormInput";
import { FormDatePicker } from "@components/FormDatePicker";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormTimePicker } from "@components/FormTimePicker";
import { axios } from "@sportsgravyengineering/sg-api-react-sdk";
import Sentry from "@services/Sentry";
import { IconButton } from "@mui/material";
import { OpenInNew } from "@mui/icons-material";

const BOARDING_STATUS = [
  {
    label: "Not Ready",
    value: 0
  },
  {
    label: "Board Immediately",
    value: 1
  }
];

const MERCHANT_TYPE = [
  {
    label: "Merchant accepts cards in person primarily",
    value: "MERCHANT_ACCEPT_CARDS_IN_PERSON"
  },
  {
    label: "Customer enter their payment information online primarily",
    value: "CUSTOMER_ENTER_PAYMENT_INFORMATION"
  },
  {
    label:
      "Merchant takes payment information over the phone or mail primarily",
    value: "MERCHANT_TAKES_PAYMENT_OVER_PHONE"
  }
];

export const AccountSetup = () => {
  const { control, watch, trigger, setValue } = useFormContext();
  const tncAccepted = watch("tnc");

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://jsonip.com");
        setValue("tcAcceptIp", response.data.ip);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    fetchIpAddress();
  }, []);

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid xs={6}>
          <FormSelect
            control={control}
            name="status"
            required
            label="Boarding Status"
            options={BOARDING_STATUS}
            defaultValue={0}
            rules={{
              required: "Boarding Status is required"
            }}
            tooltip="To select 'Board Immediately' an MCC code must be entered in the MCC field."
          />
        </Grid>
        <Grid xs={6}>
          <FormSelect
            control={control}
            name="mcc"
            label="Add MCC"
            options={[
              // { label: "Sports and Riding Apparel Stores", value: "5655" },
              {
                value: "7941",
                label:
                  "Athletic Fields, Commercial Sports, Professional Sport Clubs, and Sport Promoters"
              }
              // {
              //   value: "7997",
              //   label:
              //     "Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses"
              // }
            ]}
            required
            rules={{
              required: "MCC is required"
            }}
            tooltip="Locate and select the business category most relevant to your company from the dropdown menu"
          />
        </Grid>
        <Grid xs={6}>
          <FormSelect
            control={control}
            name="merchantType"
            label="Merchant Type"
            options={MERCHANT_TYPE}
            required
            rules={{
              required: "Merchant Type is required"
            }}
          />
        </Grid>
        <Grid xs={6}></Grid>
        <Grid container spacing={3}>
          <Grid xs={10}>
            <FormCheckbox
              control={control}
              name="tnc"
              label="Accept Terms and Conditions"
              required
              rules={{
                required: "Terms and Conditions is required"
              }}
            />
          </Grid>
          <Grid xs={2}>
            <IconButton
              size="small"
              onClick={() =>
                window.open("https://portal.sportsgravy.com/terms")
              }
            >
              <OpenInNew />
            </IconButton>
          </Grid>
        </Grid>
        <Grid xs={6}></Grid>
        {tncAccepted && (
          <>
            <Grid xs={5}>
              <FormSelect
                control={control}
                name="tcVersion"
                label="Version Accepted"
                options={[
                  { label: "Payrix Sub Merchant Agreement", value: "072023" },
                  {
                    label: "DIRECT AGREEMENT & FIFTH THIRD BANK DISCLOSURE",
                    value: "10.2022."
                  },
                  {
                    label: "PAYRIX SUB-MERCHANT AGREEMENT ADDENDUM A",
                    value: "072023"
                  }
                ]}
                required
                rules={{
                  required: "Version Accepted is required"
                }}
              />
            </Grid>
            <Grid xs={3}>
              <FormDatePicker
                control={control}
                name="tcAcceptDate"
                label="Date"
                required
                rules={{
                  required: "Date is required"
                }}
                trigger={trigger}
                disableFuture
              />
            </Grid>
            <Grid xs={3}>
              <FormTimePicker
                control={control}
                name="tcAcceptTime"
                label="Time"
                required
                rules={{
                  required: "Time is required"
                }}
              />
            </Grid>
            <Grid xs={6}>
              <FormInput
                control={control}
                type="text"
                name="tcAcceptIp"
                label="IP Address"
                required
                rules={{
                  required: "IP Address is required"
                }}
                disabled
              />
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};
