/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import {
  Page,
  View,
  Document,
  StyleSheet,
  Image,
  Text,
  Font
} from "@react-pdf/renderer";
import SGLogo from "@assets/images/sg-logo.png";
import { formatCurrency } from "@utils/formatCurrency";
import { ModelPerson } from "@sportsgravyengineering/sg-api-react-sdk";

export type QuotePDFDetailsProps = {
  to: {
    name: string;
    address: string;
    phone: string;
    email: string;
    contact: string;
  };
  date: string;
  preparedBy: ModelPerson;
  noOfAthletes: number;
  oneTimeSetupFee: number;
  costPerAthlete: number;
  upFrontDiscount: number;
  from: {
    name: string;
    address: string;
  };
};

const formatDate = (
  iosDateString: string | Date | undefined | null
): string => {
  if (!iosDateString) return "";
  const date = new Date(iosDateString);
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "../../fonts/Inter400.ttf",
      fontWeight: 400
    },
    {
      src: "../../fonts/Inter500.ttf",
      fontWeight: 500
    },
    {
      src: "../../fonts/InterItalic.ttf",
      fontStyle: "italic"
    }
  ]
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    padding: 20,
    display: "flex",
    height: "100vh",
    letterSpacing: 0.5
  },
  view: {
    display: "flex",
    flexDirection: "column"
  },
  seperator: {
    marginTop: "14px",
    width: "100%",
    borderBottomWidth: "3px",
    borderBottomColor: "#E5E5E5",
    borderBottomStyle: "solid"
  },
  textCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  fromDetails: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  label: {
    fontSize: 16,
    fontWeight: 500
  },
  value: {
    color: "#666666",
    fontSize: "10px",
    width: "50%",
    lineHeight: 1.6,
    fontWeight: 400
  },
  billTo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  invoiceDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    marginTop: "-25px"
  },
  invoiceDetails: {
    display: "flex",
    flexDirection: "row",
    gap: 25,
    justifyContent: "space-between"
  },
  invoiceDetailsTitle: {
    fontWeight: 500,
    fontSize: "10px"
  },
  invoiceDetailsValue: {
    color: "#666666",
    fontWeight: 400,
    fontSize: "10px"
  },
  table: {
    width: "100%"
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 400,
    border: "1px solid #B3B3B3",
    backgroundColor: "#F3F4F7"
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 400,
    border: "1px solid #B3B3B3",
    borderTop: "none"
  },
  tableCell: {
    padding: 8,
    fontSize: "9px",
    fontWeight: 400
  },
  tableFooter: {
    display: "flex",
    flexDirection: "row"
  },
  tableTotalContainer: {
    border: "1px solid #B3B3B3",
    borderTop: "none"
  },
  tableTotalCell: {
    padding: 8
  },
  footer: {
    position: "absolute",
    textAlign: "center",
    fontSize: "12px",
    color: "#666666",
    bottom: 40,
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto"
  }
});

export const QuotePDF = ({
  hasHeader,
  hasFooter,
  quote
}: {
  hasHeader: boolean;
  hasFooter?: boolean;
  quote: QuotePDFDetailsProps;
}) => {
  const discountValue =
    quote.noOfAthletes * quote.costPerAthlete * (quote.upFrontDiscount / 100);
  const amountTotalWithoutDiscount =
    quote.noOfAthletes * quote.costPerAthlete + quote.oneTimeSetupFee;
  const amountTotalWithDiscount = amountTotalWithoutDiscount - discountValue;

  const renderDescription = (upfront: boolean) => {
    return (
      <>
        <View style={styles.tableRow}>
          <View style={[styles.tableCell, { width: "55%", lineHeight: 1.5 }]}>
            <Text>Yearly Subscription - Billed Monthly</Text>
            <Text style={{ marginTop: 4 }}>
              &nbsp;&nbsp;&nbsp;•&nbsp; {quote.noOfAthletes} Athlete
              Registrations
            </Text>
            <Text>
              &nbsp;&nbsp;&nbsp;•&nbsp; {formatCurrency(quote.costPerAthlete)}{" "}
              Per Athlete, Per Registration
            </Text>
            <Text
              style={{ marginTop: 4, fontStyle: "italic", color: "#666666" }}
            >
              Extra athletes billed at {formatCurrency(quote.costPerAthlete)}{" "}
              per registration
            </Text>
          </View>
          <View
            style={[
              styles.tableCell,
              styles.textCenter,
              {
                width: "10%"
              }
            ]}
          >
            <Text>{quote.noOfAthletes}</Text>
          </View>
          <View
            style={[
              styles.tableCell,
              styles.textCenter,
              { width: "15%", textAlign: "center" }
            ]}
          >
            <Text>{formatCurrency(quote.costPerAthlete)}</Text>
          </View>
          <View
            style={[
              styles.tableCell,
              styles.textCenter,
              {
                width: "20%",
                textAlign: "right",
                marginRight: "10px",
                letterSpacing: 0.8
              }
            ]}
          >
            <Text>
              {formatCurrency(quote.noOfAthletes * quote.costPerAthlete)}
            </Text>
          </View>
        </View>
        {!upfront && (
          <View style={styles.tableRow}>
            <View style={[styles.tableCell, { width: "55%", lineHeight: 1.5 }]}>
              <Text>Paid Upfront Discount ({quote.upFrontDiscount}%)</Text>
            </View>
            <View
              style={[
                styles.tableCell,
                styles.textCenter,
                {
                  width: "10%"
                }
              ]}
            >
              <Text>1</Text>
            </View>
            <View
              style={[
                styles.tableCell,
                styles.textCenter,
                { width: "15%", textAlign: "center" }
              ]}
            >
              <Text>&nbsp;</Text>
            </View>
            <View
              style={[
                styles.tableCell,
                styles.textCenter,
                {
                  width: "20%",
                  textAlign: "right",
                  marginRight: "10px",
                  letterSpacing: 0.8
                }
              ]}
            >
              <Text>-{formatCurrency(discountValue)}</Text>
            </View>
          </View>
        )}
        <View style={styles.tableRow}>
          <View style={[styles.tableCell, { width: "55%", lineHeight: 1.5 }]}>
            <Text>One time Setup Fee</Text>
          </View>
          <View
            style={[
              styles.tableCell,
              styles.textCenter,
              {
                width: "10%"
              }
            ]}
          >
            <Text>1</Text>
          </View>
          <View
            style={[
              styles.tableCell,
              styles.textCenter,
              { width: "15%", textAlign: "center" }
            ]}
          >
            <Text>{formatCurrency(quote.oneTimeSetupFee)}</Text>
          </View>
          <View
            style={[
              styles.tableCell,
              styles.textCenter,
              {
                width: "20%",
                textAlign: "right",
                marginRight: "10px",
                letterSpacing: 0.8
              }
            ]}
          >
            <Text>{formatCurrency(quote.oneTimeSetupFee)}</Text>
          </View>
        </View>
        <View style={styles.tableFooter}>
          <View
            style={[styles.tableCell, { width: "10%", textAlign: "center" }]}
          ></View>
          <View
            style={[
              styles.tableCell,
              {
                width: "70%",
                textAlign: "right"
              }
            ]}
          >
            <Text style={[styles.tableTotalCell, { width: "330px" }]}>
              Sub Total
            </Text>
            <Text style={[styles.tableTotalCell, { width: "330px" }]}>
              Tax (12%)
            </Text>
            <Text
              style={[
                styles.tableTotalCell,
                { width: "310px", fontWeight: 500 }
              ]}
            >
              Total
            </Text>
          </View>
          <View
            style={[
              styles.tableCell,
              styles.tableTotalContainer,
              { width: "20%" }
            ]}
          >
            <View>
              <Text
                style={[
                  styles.tableTotalCell,
                  { textAlign: "right", letterSpacing: 0.8 }
                ]}
              >
                {upfront
                  ? formatCurrency(amountTotalWithoutDiscount)
                  : formatCurrency(amountTotalWithDiscount)}
              </Text>
            </View>
            <View>
              <Text
                style={[
                  styles.tableTotalCell,
                  { textAlign: "right", letterSpacing: 0.8 }
                ]}
              >
                {upfront
                  ? formatCurrency(
                      Number((amountTotalWithoutDiscount * 0.12).toFixed(2))
                    )
                  : formatCurrency(
                      Number((amountTotalWithDiscount * 0.12).toFixed(2))
                    )}
              </Text>
            </View>
            <View
              style={[
                {
                  borderTop: "1px solid #B3B3B3",
                  right: 8,
                  width: "102px",
                  letterSpacing: 0.8
                }
              ]}
            >
              <Text
                style={[
                  styles.tableTotalCell,
                  {
                    textAlign: "right",
                    fontWeight: 500
                  }
                ]}
              >
                {upfront
                  ? formatCurrency(amountTotalWithoutDiscount * 1.12)
                  : formatCurrency(amountTotalWithDiscount * 1.12)}
              </Text>
            </View>
          </View>
        </View>
      </>
    );
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.view}>
          {hasHeader && (
            <View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Image
                  src={SGLogo}
                  style={{ width: "200px", height: "35px" }}
                />
                <Text style={{ fontSize: "26px" }}>Quote</Text>
              </View>
              <View style={styles.seperator} />
            </View>
          )}

          <View style={[styles.fromDetails, { marginTop: "14px" }]}>
            <View>
              <Text style={styles.label}>{quote.from.name}</Text>
              <Text style={[styles.value, { marginTop: "5px" }]}>
                {quote.from.address}
              </Text>
              <View style={[styles.fromDetails, { marginTop: "14px" }]}>
                <View>
                  <Text style={{ fontSize: "12px", fontWeight: 500 }}>
                    Quote for
                  </Text>
                </View>
              </View>
              <View style={{ marginTop: "5px" }}>
                <Text style={styles.value}>{quote.to.name}</Text>
                <Text style={styles.value}>{quote.to.contact}</Text>
                <Text style={styles.value}>{quote.to.phone}</Text>
                <Text style={styles.value}>{quote.to.email}</Text>
              </View>
            </View>
            <View
              style={[styles.invoiceDetailsContainer, { marginTop: "0px" }]}
            >
              <View style={styles.invoiceDetails}>
                <Text style={styles.invoiceDetailsTitle}>Date</Text>
                <Text style={styles.invoiceDetailsValue}>
                  {formatDate(quote.date)}
                </Text>
              </View>
              <View style={styles.invoiceDetails}>
                <Text style={styles.invoiceDetailsTitle}>Prepared by</Text>
                <Text
                  style={[styles.invoiceDetailsValue, { maxWidth: "100px" }]}
                >
                  {quote.preparedBy.firstName} {quote.preparedBy.lastName}
                </Text>
              </View>
              <View style={[styles.invoiceDetails, { marginTop: "14px" }]}>
                <Text style={styles.invoiceDetailsTitle}>Valid until</Text>
                <Text style={styles.invoiceDetailsValue}>
                  {formatDate(
                    new Date(
                      new Date(quote.date).setMonth(
                        new Date(quote.date).getMonth() + 1
                      )
                    )
                  )}
                </Text>
              </View>
            </View>
          </View>

          <Text
            style={{
              marginTop: 14,
              fontWeight: 500,
              fontSize: "10px",
              marginBottom: 8
            }}
          >
            Yearly Subscription Paid Upfront Quote
          </Text>
          <View>
            <View style={styles.table}>
              <View style={styles.tableHeader}>
                <View style={[styles.tableCell, { width: "55%" }]}>
                  <Text>Description</Text>
                </View>
                <View
                  style={[
                    styles.tableCell,
                    { width: "10%", textAlign: "center" }
                  ]}
                >
                  <Text>QTY</Text>
                </View>
                <View
                  style={[
                    styles.tableCell,
                    { width: "15%", textAlign: "center" }
                  ]}
                >
                  <Text>Unit Price</Text>
                </View>
                <View
                  style={[
                    styles.tableCell,
                    {
                      width: "12%",
                      textAlign: "right",
                      marginRight: "20px"
                    }
                  ]}
                >
                  <Text>Amount</Text>
                </View>
              </View>
              {renderDescription(true)}
            </View>
          </View>

          <Text
            style={{
              marginTop: 14,
              fontWeight: 500,
              fontSize: "10px",
              marginBottom: 8
            }}
          >
            Yearly Subscription Billed Monthly Quote
          </Text>
          <View>
            <View style={styles.table}>
              <View style={styles.tableHeader}>
                <View style={[styles.tableCell, { width: "55%" }]}>
                  <Text>Description</Text>
                </View>
                <View
                  style={[
                    styles.tableCell,
                    { width: "10%", textAlign: "center" }
                  ]}
                >
                  <Text>QTY</Text>
                </View>
                <View
                  style={[
                    styles.tableCell,
                    { width: "15%", textAlign: "center" }
                  ]}
                >
                  <Text>Unit Price</Text>
                </View>
                <View
                  style={[
                    styles.tableCell,
                    {
                      width: "12%",
                      textAlign: "right",
                      marginRight: "20px"
                    }
                  ]}
                >
                  <Text>Amount</Text>
                </View>
              </View>
              {renderDescription(false)}
            </View>
          </View>
        </View>
        <View style={[styles.footer, { bottom: hasFooter ? 70 : 20 }]}>
          <Text>THANK FOR YOUR BUISNESS!</Text>
        </View>
        {hasFooter && (
          <View
            style={[
              styles.footer,
              {
                bottom: 30,
                fontSize: "10px",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "center",
                gap: "5px"
              }
            ]}
          >
            <Text>Powered By</Text>
            <Image
              src={SGLogo}
              style={{
                width: "80px",
                height: "15px"
              }}
            />
          </View>
        )}
      </Page>
    </Document>
  );
};
